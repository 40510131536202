// perfect 12345567890
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError, getCodePageDataApi,generateSasTokenAPI } from "../../../Service/Api.js";
import { HeaderCom } from "../../Header.jsx";
import DynamicGrid from './DynamicTable.jsx';
import SearchComponent from './SearchComponent.jsx';
import FilterComponent from './FilterComponent.jsx';
import DownloadComponent from './DownloadComponent.jsx';
import ReasonComponent from './ReasonComponent.jsx';
import * as XLSX from 'xlsx';
import ViewInstructions from "./viewInstructions.jsx";


function CodeReviewDev(props) {

    const accountName = 'avaeusgenetlsametaprod';
    const containerName = 'uc-migration';

    // const generateSasTokenAPI = async () => {
    //     const response = await fetch('http://localhost:3001/generate-sas');
    //     if (!response.ok) throw new Error('Failed to fetch SAS token');
    //     const { sasToken } = await response.json();
    //     console.warn('SAS token generation should be done server-side');
    //     return sasToken;
    // };

    const fetchJsonFromBlob = async (blobURL) => {
        try {
            const sasToken = await generateSasTokenAPI();
            // const blobPath = `${orgName}/${projectName}/json/${fileName}`;
            // const url = `https://avaeusgenetlsametaprod.blob.core.windows.net/chatmainsample/GenETL KT New/New Clark/json/Sample_CodeReviewDev.json?${sasToken}`;
            let url = `${blobURL}?${sasToken}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonData = await response.json();
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };


    const { state } = useLocation();
    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);
    const [actualValue, setActualValue] = useState([]);
    const [dropDownValues, setDropDownValues] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [whetherUpdated, setWhetherUpdated] = useState(false);

    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [currentOperation, setCurrentOperation] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [noteBookNames, setNoteBookNames] = useState([]);
    const [activeNoteBook, setActiveNoteBook] = useState('');
    const [blobUrl, setblobUrl] = useState('');

    const handleEdit = (item) => {
        setCurrentOperation('edit');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    const handleAdd = (newItem) => {
        setCurrentOperation('add');
        setCurrentItem(newItem);
        setShowReasonModal(true);
    };

    const handleDelete = (item) => {
        setCurrentOperation('delete');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    const handleReasonSave = async () => {
        try {
            let updatedData;
            switch (currentOperation) {
                case 'edit':
                    updatedData = gridData.map(item =>
                        item.index === currentItem.index ? { ...currentItem, reason } : item
                    );
                    break;
                case 'add':
                    updatedData = [...gridData, { ...currentItem, reason, index: gridData.length }];
                    break;
                case 'delete':
                    updatedData = gridData.filter(item => item.index !== currentItem.index);
                    break;
                default:
                    throw new Error('Invalid operation');
            }

            await updateJsonInBlob(blobUrl, updatedData);

            // Update local state
            setGridData(updatedData);
            setActualValue(updatedData);
            setShowReasonModal(false);
            setReason('');
            setCurrentOperation(null);
            setCurrentItem(null);
            setWhetherUpdated(true);
        } catch (error) {
            console.error('Error updating data:', error);
            // Handle error (show message to user, etc.)
        }
    };


    const updateJsonInBlob = async (blobUrl, updatedData) => {
        try {
            const sasToken = await generateSasTokenAPI();
            // const blobPath = `${orgName}/${projectName}/json/${fileName}`;
            // const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
            const url = `${blobUrl}?${sasToken}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Data updated successfully in Azure Blob');
        } catch (error) {
            console.error('Error updating JSON in blob:', error);
            throw error;
        }
    };


    const [settings, setSettings] = useState({
        TableConfig: [
            { key: 'SNo', header: 'SNo', sortable: true, type: 'normal' },
            { key: 'CommandPurpose', header: 'CommandPurpose', sortable: true, type: 'normal' },
            { key: 'CheckListStep', header: 'ChecklistStep', sortable: true, type: 'normal' },
            { key: 'Status', header: 'Status', sortable: true, type: 'normal' },


        ],
        ActionItems: { delete: true, edit: true },
        deFaultRow: [],
        type: "NoDefaultRow",
        Page: "CodeReviewDev"

    });



    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchDropDown(), fetchPseudo()]);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const fetchDropDown = async () => {
        try {
            let data = { type: props.data.NotebookName, projectId: state.projectId, userId: state.userId }
            let dropDownValues = await fetchDropDownValues(data, state.jwt)
            if (dropDownValues.data) {
                setDropDownValues(dropDownValues.data);
                updateSettingsWithDropdownValues(dropDownValues.data);
                console.log(dropDownValues, "v1")
            }
        } catch (e) {
            handleError(e);
        }
    }

    const updateSettingsWithDropdownValues = (data) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            deFaultRow: prevSettings.deFaultRow.map(row => {
                if (row.key === 'SubjectArea') {
                    return { ...row, values: data.DDuniqueSubjectArea || [] };
                }
                if (row.key === 'SchemaName') {
                    return { ...row, values: data.uniqueSchemaName || [] };
                }
                if (row.key === 'TableName') {
                    return { ...row, values: data.uniqueTableName || [] };
                }
                return row;
            })
        }));
    }

    const fetchPseudo = async () => {

        const body = {
            projectId: state.projectId,
            type: "UCchecklist",
            userId: state.userId,
            orgId: state.orgId,
        };

        try {
            document.getElementById('pageLoader').style.display = "block";

            const res = await getCodePageDataApi(body, state.jwt);
            console.log(res, "response from DB")
            if (res.status === 403) {
                sessionExpired();
            } else if (res.status !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                console.log("The Response Is 200");
                let code = res?.data;
                console.log(code, "data code")
                if (code) {
                    // Extract unique notebook names
                    let notebooks = [...new Set(code.map(item => item.NotebookName))];
                    setNoteBookNames(notebooks);
                
                    // Set the first notebook as the active notebook
                    let firstNotebook = notebooks[0];
                    setActiveNoteBook(firstNotebook);
                
                    // Create pseudo data with index
                    let pseudo = code.map((obj, index) => ({
                        ...obj,
                        index: index
                    }));
                
                    // Function to sort based on alphanumeric designId after removing "Step" prefix
                    const alphanumericSort = (a, b) => {
                        // Replace "Step" and convert the remaining part to integer for comparison
                        let aNum = parseInt(a.DesignID.replace("Step ", ''), 10);
                        let bNum = parseInt(b.DesignID.replace("Step ", ''), 10);
                        // Compare the numerical values
                        return aNum - bNum;
                    };
                
                    // Sort the pseudo data based on designId
                    pseudo.sort(alphanumericSort);
                
                    // Filter and sort initial data for the first notebook
                    const initialFilteredData = pseudo
                        .filter(item => item.NotebookName === firstNotebook)
                        .map((item, index) => ({ ...item, SNo: index + 1 }));
                
                    // Set the state with the sorted and filtered data
                    setActualValue(pseudo);
                    setGridData(initialFilteredData);
                
                    console.log(pseudo, "Mapped and sorted data");
                }else {
                    throw new Error("Failed to fetch data from Azure Blob Storage");
                }
                document.getElementById('pageLoader').style.display = "none";

            }
        } catch (e) {
            console.error('Error fetching pseudocode data:', e);
            handleError(e);
        }
    }

    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };


    const handleError = (e) => {
        insertError({
            errorMessage: e.message,
            serviceName: "frontend",
            module: "Datadictionary",
            functionName: "DataDictionaryTable",
            userId: state.userId
        });
        console.log("error", e);
        setError(e.message);
    }

    const handleTabClick = (name) => {
        setActiveNoteBook(name);
        setGridData(actualValue.filter(item => item.NotebookName === name).map((item, index) => ({ ...item, SNo: index + 1 })));
    };

    const handleDownload = () => {
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: 'CodeReview Checklist',
        };
   
        noteBookNames.forEach(notebook => {
            // Filter the data for the current notebook
            const notebookData = actualValue.filter(item => item.NotebookName === notebook);
   
            // Process the data to remove 'codeReviewChecklistId' and 'projectId' columns
            const wsData = notebookData.map(({ index, codeReviewChecklistId, projectId,NotebookName, ...rest }, i) => ({ SNo: i + 1, ...rest }));
           
            // Convert the processed JSON data to a worksheet
            const ws = XLSX.utils.json_to_sheet(wsData);
   
            // Append the worksheet to the workbook with the notebook name
            XLSX.utils.book_append_sheet(wb, ws, notebook);
        });
   
        // Write the workbook to a file and download it
        XLSX.writeFile(wb, 'CodeReview_Checklist.xlsx');
    };
   

    const renderTabs = () => {
        return noteBookNames.map((name, index) => (
            <div
                key={index}
                className={`tab font-bold ${name === activeNoteBook ? 'active' : ''}`}
                onClick={() => handleTabClick(name)}
            >
                {name}
            </div>
        ));
    }



    const passCount = gridData.filter(item => item.Status === 'Pass').length;
    const failCount = gridData.filter(item => item.Status === 'Fail').length;
    const totalCount = gridData.length;


      const [isModalOpen, setModalOpen] = useState(false);
                
        const handleOpenModal = () => {
            setModalOpen(true);
          };
        
          const handleCloseModal = () => {
            setModalOpen(false);
          };

    return (
        <div className="container-fluid">
             <div className="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                    <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                        <div className="d-flex">
                            <div className="toast-body">
                                <span>
                                    <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                                </span>
                                <span className="mx-3 font-14 loader-text">Session has expired. Please login Again</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                    <div className="position-absolute top-50 start-50  translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div className="spinner-border Loader text-dark align-center " role="status">
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                    Oops! Something went wrong
                </button>
                <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body px-4 pb-0">
                                <div className="row text-center">
                                    <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                    <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                    <p className="font-14 font-regular text-black">Please try again later</p>
                                </div>
                            </div>
                            <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                                <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            <HeaderCom value="1" />
            <div className="col-md-12 pt-4 mt-5">
                <div class="mt-3">
                    <ul class="cust-breadcrumb mb-0 ">
                        <li class="font-16 font-medium"><a href="#">Manage Projects</a></li>
                        <li class="font-16 font-medium"><a>{state.projectName}</a></li>
                        <li class="font-16 font-medium active">{props.data.hyperlinkText}</li>
                    </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between p-4">
                    <div className="d-flex">
                        <a className="d-flex">
                            <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px', cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
                        </a>
                        <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}
                        <button type="button" onClick={handleOpenModal} className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3">
                                <img src="images/info-icon-ETL.svg" className alt="info" />
                            </button></h2>
                            {isModalOpen && <ViewInstructions notebooktype='changesPseudo' onClose={handleCloseModal} />}

                    </div>
                    <div className="d-flex">
                        <div className="cust-input-sty font-14 rounded-2 font-regular text-grey px-3 py-2 me-3 d-flex align-items-center">
                            <span className="border-end d-flex align-items-center pe-3">
                                <img src="images/verified-icon.svg" alt="verified-icon" className="verified-logo-sty me-2" />
                                <span className="text-nowrap font-regular">Pass - <span className="font-medium">{passCount}</span></span>
                            </span>
                            <span className="border-end d-flex align-items-center px-3">
                                <img src="images/not-verified-icon.svg" alt="not-verified-icon" className="verified-logo-sty me-2" />
                                <span className="text-nowrap font-regular">Fail - <span className="font-medium">{failCount}</span></span>
                            </span>
                            <span className="ps-3">
                                <span className="text-nowrap font-regular">Total - <span className="font-medium">{totalCount}</span></span>
                            </span>
                            </div>
                        <button
                            onClick={handleDownload}
                            className="btn cust-secondary-btn font-16 font-medium"
                        >
                            <span className="me-2">
                                <img src="images/download-icon.svg" alt="download-icon" />
                            </span>
                            Download
                        </button>
                    </div>
                </div>
                <div className="code-review-page checklists tabs">
                    {renderTabs()}
                </div>
                <DynamicGrid
                    data={gridData}
                    settings={settings}
                    setGridData={setGridData}
                    actualValue={actualValue}
                    setWhetherUpdated={setWhetherUpdated}
                    onEdit={handleEdit}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                />
                <ReasonComponent
                    isOpen={showReasonModal}
                    onClose={() => setShowReasonModal(false)}
                    onSave={handleReasonSave}
                    reason={reason}
                    setReason={setReason}
                />
            </div>
        </div>
    );
}

export default CodeReviewDev;
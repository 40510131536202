import React, { useState } from "react";
import * as XLSX from "xlsx";

const UploadConfirmedNotebooks = ({ notebookNames, onSave, close }) => {
    const [validationErrors, setValidationErrors] = useState([]);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [confirmedNotebooksData, setConfirmedNotebooksData] = useState({
        fileName: null,
        file: null,
        data: [],
    });
    const [show, setShow] = useState(true);

    const exportToExcel = () => {
        const data = [
            { Notebook: "sovrn_offline_feed", NotebookToBeTested: "Yes" },
            { Notebook: "customer_journey_report", NotebookToBeTested: "No" },
            { Notebook: "30-day_seed_url-cumulative urlhash-sketches", NotebookToBeTested: "Yes" },
        ];
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "NotebookToBeTested");
        XLSX.writeFile(workbook, "Notebook_Template.xlsx");
    };

    const handleFileUpload = (event) => {
        setValidationErrors([]);
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = "NotebookToBeTested";

                if (!workbook.Sheets[sheetName]) {
                    setValidationErrors((prev) => [...prev, `EM002: Missing Mandatory Sheets: ${sheetName}`]);
                    setFileUploaded(false);
                    return;
                }

                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                const requiredColumns = ["Notebook", "NotebookToBeTested"];
                const headers = Object.keys(jsonData[0] || {});
                requiredColumns.forEach((col) => {
                    if (!headers.includes(col)) {
                        setValidationErrors((prev) => [
                            ...prev,
                            `EM001: Header Field <${col}> is missing or not in cell <A1> in ${sheetName}`,
                        ]);
                    }
                });

                jsonData.forEach((row, index) => {
                    if (!row.Notebook || !row.NotebookToBeTested) {
                        setValidationErrors((prev) => [
                            ...prev,
                            `EM003: <${!row.Notebook ? "Notebook" : "NotebookToBeTested"}> value should not be empty in ${sheetName} in row ${index + 2} (<A${index + 2}>)`,
                        ]);
                    } else if (!notebookNames.includes(row.Notebook)) {
                        setValidationErrors((prev) => [
                            ...prev,
                            `${row.Notebook} is missing`,
                        ]);
                    }
                });

                setConfirmedNotebooksData((prev) => ({
                    ...prev,
                    fileName: file.name,
                    file,
                    data: jsonData,
                }));
                setFileUploaded(true);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const submitDetails = () => {
        if (fileUploaded && validationErrors.length === 0) {
            const notebooksToTest = confirmedNotebooksData.data
                .filter((row) => row.NotebookToBeTested === "Yes")
                .map((row) => row.Notebook);
            onSave(notebooksToTest);
        } else {
            onSave([]);
        }
    };

    return (
        <div className="container-fluid">
            <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div
                    className={`modal fade ${show ? "show" : ""}`}
                    id="Confrm"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="ConfrmLabel"
                    aria-hidden="true"
                    style={show ? { display: "block", paddingLeft: "0px" } : {}}
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">
                                    Upload Data
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        setShow(false);
                                        close();
                                        setValidationErrors([]);
                                        setFileUploaded(false);
                                    }}
                                ></button>
                            </div>
                            <div className="modal-body py-0">
                                <div className="row mt-3">
                                    <div className="col-md-12 col-sm-12 mb-3">
                                        <label className="form-label text-black font-14 font-medium">
                                            Upload Notebooks to be tested sheet
                                        </label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="mb-5">
                                            <span className="upload-btn-wrapper d-block">
                                                {!confirmedNotebooksData.fileName ? (
                                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                        <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                                        <span className="font-medium font-13 d-block text-grey mb-2">
                                                            Browse or drag and drop file
                                                        </span>
                                                        <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                                            Use this template to add info -{" "}
                                                            <a
                                                                onClick={exportToExcel}
                                                                className="primary-color font-medium cursor-pointer text-decoration-none down-templ-sty"
                                                            >
                                                                Download Template
                                                            </a>{" "}
                                                        </span>
                                                    </button>
                                                ) : (
                                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                        <img src="images/sample-logo.svg" alt="sample-logo" className="mb-2" />
                                                        <a className="font-medium font-12 d-block">{confirmedNotebooksData.fileName}</a>
                                                        <a
                                                            style={{ position: "relative", zIndex: "100" }}
                                                            onClick={() => {
                                                                setValidationErrors([]);
                                                                setFileUploaded(false);
                                                                setConfirmedNotebooksData({
                                                                    fileName: null,
                                                                    file: null,
                                                                    data: [],
                                                                });
                                                            }}
                                                        >
                                                            <span className="ms-2">
                                                                <img src="images/link-close-icon.svg" alt="link-close-icon" />
                                                            </span>
                                                        </a>
                                                    </button>
                                                )}
                                                <input
    type="file"
    name="myfile"
    className="cursor-pointer cust-file"
    accept=".xlsx"
    id="definedocumentid"
    onChange={handleFileUpload}
    title={confirmedNotebooksData.fileName || ""}
/>
                                            </span>
                                            {validationErrors.length > 0 ? (
                                                <div className="code-doc-error">
                                                    {validationErrors.map((data, index) => (
                                                        <span
                                                            className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section"
                                                            key={index}
                                                        >
                                                            <img src="images/warning-red-icon.svg" alt="Warning icon" />
                                                            <span className="ms-2">{data}</span>
                                                        </span>
                                                    ))}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 px-4">
                                <button
                                    type="button"
                                    className="btn cust-secondary-btn font-14 font-medium me-2"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        setShow(false);
                                        close();
                                        setValidationErrors([]);
                                        setFileUploaded(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
    type="button"
    data-bs-dismiss="modal"
    disabled={!fileUploaded || validationErrors.length > 0}
    className="btn cust-primary-btn font-14 font-medium"
    onClick={() => {
        submitDetails(); // Make sure submitDetails is a function
        setShow(false);
        close();
    }}
>
    Save Details
</button>
                            </div>
                        </div>
                    </div>
                </div>
                {show && <div className="modal-backdrop show"></div>}
            </div>
        </div>
    );
};

export default UploadConfirmedNotebooks;
import React from 'react';

const CatalogComponent = ({ catalogNames, selectedCatalog, setSelectedCatalog }) => {
  const handleChange = (event) => {
    setSelectedCatalog(event.target.value);
  };

  return (
    <div className="input-group position-relative search-wid me-md-3">
      <select 
        className="form-select cust-input-sty font-14 rounded-3 font-regular" 
        aria-label="Select CatalogName" 
        value={selectedCatalog} 
        onChange={handleChange}
      >
        {catalogNames.map((catalogName, index) => (
          <option key={index} value={catalogName}>
            {catalogName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CatalogComponent;
// BlobStorageComponent.js
import React, { useState, useEffect } from 'react';
import { fetchJsonFromBlob, putJsonToBlob, updateJsonInBlob } from './BlobStorageService';

const BlobStorageComponent = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const runExample = async () => {
            setLoading(true);
            try {
                const sampleJson = {
                    name: "John Doe",
                    age: 30,
                    city: "New York"
                };

                await putJsonToBlob("first_org", "first_project", sampleJson, "sample.json");
                const fetchedData = await fetchJsonFromBlob("first_org", "first_project", "chatmain_response_sample.json");
                setData(fetchedData);

                const updateData = {
                    age: 31,
                    job: "Developer"
                };
                // Uncomment the next line if you want to update the blob
                const updatedData = await updateJsonInBlob("first_org", "first_project", "sample.json", updateData);
                // setData(updatedData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        runExample();
    }, []);

    return (
        <div>
            <h1>Azure Blob Storage Example</h1>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {data && (
                <div>
                    <h2>Fetched Data:</h2>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default BlobStorageComponent;
// qwerty
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError, generateSasTokenAPI } from "../../../Service/Api.js";
import { HeaderCom } from "../../Header.jsx";
import DynamicGrid from './DynamicTable.jsx';
import SearchComponent from './SearchComponent.jsx';
import FilterComponent from './FilterComponent.jsx';
import DownloadComponent from './DownloadComponent.jsx';
import ReasonComponent from './ReasonComponent.jsx';
import Editor from '@monaco-editor/react';
import ViewInstructions from "./viewInstructions.jsx";
import { CopyToClipboard } from "react-copy-to-clipboard";

function NoteBookChanges(props) {

    const accountName = 'avaeusgenetlsametaprod';
    const containerName = 'uc-migration';



    const fetchJsonFromBlob = async (blobURL) => {
        try {
            const sasToken = await generateSasTokenAPI();

            let url = `${blobURL}?${sasToken}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonData = await response.json();
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };


    const { state } = useLocation();
    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);
    const [actualValue, setActualValue] = useState([]);
    const [dropDownValues, setDropDownValues] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [whetherUpdated, setWhetherUpdated] = useState(false);

    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [currentOperation, setCurrentOperation] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [newRow, setNewRow] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [codeSnippet, setCodeSnippet] = useState('');
    const [blobURL, setblobURL] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [copiedText, setCopiedText] = useState(false);

    const handleCopyText = () => {

        setCopiedText(true);

        setTimeout(() => setCopiedText(false), 2000);

    };
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };


    const handleEdit = (item) => {
        setCurrentOperation('edit');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    const handleAdd = (newItem) => {
        setCurrentOperation('add');
        setCurrentItem(newItem);
        setShowReasonModal(true);
    };

    const handleDelete = (item) => {
        setCurrentOperation('delete');
        setCurrentItem(item);
        setShowReasonModal(true);
    };
    const onViewCode = (code) => {
        setCodeSnippet(code);
        setShowModal(true);
    };

    const handleReasonSave = async () => {
        try {
            let updatedData;
            switch (currentOperation) {
                case 'edit':
                    updatedData = gridData.map(item =>
                        item.index === currentItem.index ? { ...currentItem, reason } : item
                    );
                    break;
                case 'add':
                    updatedData = [...gridData, { ...currentItem, reason, index: gridData.length }];
                    break;
                case 'delete':
                    updatedData = gridData.filter(item => item.index !== currentItem.index);
                    break;
                default:
                    throw new Error('Invalid operation');
            }

            await updateJsonInBlob(blobURL, updatedData);

            // Update local state
            setGridData(updatedData);
            setActualValue(updatedData);
            setShowReasonModal(false);
            setReason('');
            setCurrentOperation(null);
            setCurrentItem(null);
            setWhetherUpdated(true);
        } catch (error) {
            console.error('Error updating data:', error);
            // Handle error (show message to user, etc.)
        }
    };



    const updateJsonInBlob = async ( updatedData) => {
        try {
            const sasToken = await generateSasTokenAPI();
            // const blobPath = `${orgName}/${projectName}/json/${fileName}`;
            // const url = `https://avaeusgenetlsametaprod.blob.core.windows.net/chatmainsample/GenETL KT New/New Clark/json/Sample_NotebookChanges.json?${sasToken}`;
            const url = `${blobURL}?${sasToken}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Data updated successfully in Azure Blob');
        } catch (error) {
            console.error('Error updating JSON in blob:', error);
            throw error;
        }
    };


    const [settings, setSettings] = useState({
        TableConfig: [
            { key: 'NotebookName', header: 'NotebookName', sortable: true, type: 'normal' },
            { key: 'Issues', header: 'Issues', sortable: true, type: 'normal' },
            { key: 'FailedCode', header: 'FailedCode', sortable: true, type: 'normal', deFaultRow: 'viewcode' },
            { key: 'suggestion', header: 'Suggestion', sortable: true, type: 'normal', deFaultRow: 'viewcode' },
        ],
        ActionItems: { tick: false, cancel: false },
        deFaultRow: [],
        type: "NoDefaultRow",
        Page: "NotebookChangesRecommendation"

    });


    const searchColumns = ['NotebookName'];
    const FilterColumns = ['NotebookName'];

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchPseudo()]);
                console.log(gridData, "update")
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const fetchPseudo = async () => {

        const body = {
            projectId: state.projectId,
            type: "notebookrecommendation",
            userId: state.userId,
            orgId: state.orgId,
        };

        try {
            setIsLoading(false)
            // document.getElementById('pageLoader').style.display = "block";
            const res = await fetchPseudocodeData(body, state.jwt);

            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                console.log("The Response Is 200");
                const blobUrl = res?.data[0]?.noteBookChangesURL;
                //  data = res?.data[0]?.noteBookChangesURL;
                console.log(blobUrl, "datadtadtadtadtadtadtadtadtadtadtadtadtadtadtadtadatdtadatdatdat")
                setblobURL(blobUrl)

                try {
                    const jsonData = await fetchJsonFromBlob(blobUrl);
                    console.log(jsonData, "Data from blob");

                    if (jsonData) {
                        let pseudo = jsonData.map((obj, index) => ({
                            ...obj,
                            index: index
                        }));
                        setGridData(pseudo);
                        setActualValue(pseudo);

                        console.log(pseudo, "Mapped data");
                        setIsLoading(false)
                        // document.getElementById('pageLoader').style.display = "none";
                    } else {
                        throw new Error("Failed to fetch data from Azure Blob Storage");
                    }
                } catch (e) {
                    console.error('Error fetching JSON from blob:', e);
                    handleError(e);
                }
            }
        } catch (e) {
            console.error('Error fetching pseudocode data:', e);
            handleError(e);
        }
    }
    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };


    const handleError = (e) => {
        insertError({
            errorMessage: e.message,
            serviceName: "frontend",
            module: "Datadictionary",
            functionName: "DataDictionaryTable",
            userId: state.userId
        });
        console.log("error", e);
        setError(e.message);
    }



    if (error) return <div>Error: {error}</div>;

    return (
        <div className="container-fluid">
            <HeaderCom value="1" />
            <div className="col-md-12 pt-4 mt-5">
            <div className="mt-3 px-2">
                    <ul className="cust-breadcrumb mb-0">
                        <li className="font-16 font-medium">
                            <a>Manage Projects</a>
                        </li>
                        <li className="font-16 font-medium">
                            <a>{state.projectName}</a>
                        </li>
                        <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                    </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between p-4">
                    <div className="d-flex">
                        <a className="d-flex">
                            <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '35px', cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
                        </a>
                        <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}
                            <button type="button" onClick={handleOpenModal} className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3">
                                <img src="images/info-icon-ETL.svg" className alt="info" />
                            </button></h2>
                        {isModalOpen && <ViewInstructions notebooktype='notebookRecommendations' onClose={handleCloseModal} />}
                    </div>
                    <div className="d-flex">
                        <SearchComponent
                            columns={searchColumns}
                            actualValue={actualValue}
                            setGridData={setGridData}
                        />
                        <FilterComponent
                            columns={FilterColumns}
                            actualValue={actualValue}
                            setGridData={setGridData}
                        />
                        <DownloadComponent
                            type="excel"
                            data={actualValue}
                            fileName="NoteBookChanges"
                        />
                    </div>
                </div>
                <DynamicGrid
                    data={gridData}
                    settings={settings}
                    setGridData={setGridData}
                    actualValue={actualValue}
                    setWhetherUpdated={setWhetherUpdated}
                    onEdit={handleEdit}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    onViewCode={onViewCode}
                    newRow={newRow}
                    setNewRow={setNewRow}
                    updateJsonInBlob={updateJsonInBlob}

                />

                {/* Modal for View Code */}
                {showModal && (
                    <div className="modal show d-block" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header border-0 px-4">
                                    <h5 className="modal-title d-flex align-items-center font-medium font-20">Code Snippet</h5>
                                    <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                                </div>
                                <div className="modal-body py-0">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-10">
                                            <div className="">
                                                <Editor  defaultLanguage="javascript"
                                                    className="form-control custom-textarea logger-textarea-scroll add-code-snippet no-word-wrap"
                                                    value={codeSnippet}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 px-4">
                                    <button
                                        type="button"
                                        className="btn cust-primary-btn font-14 font-medium"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Cancel
                                    </button>
                                    <CopyToClipboard text={codeSnippet} onCopy={handleCopyText}>
                                        <button
                                            type="button"
                                            className="btn cust-primary-btn font-14 font-medium"
                                        >
                                            {copiedText ? "Copied!" : "Copy Code"}
                                        </button>
                                    </CopyToClipboard>

                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {isLoading &&
                <div
                    className="overlay"
                    id="pageLoader"
                    style={{ display: "none" }}
                >
                    <div className="position-absolute top-50 start-50  translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div
                                className="spinner-border Loader text-dark align-center"
                                role="status"
                            >
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default NoteBookChanges;
import React, { useState, useEffect } from 'react';

const DynamicGrid = ({
    data,
    settings,
    setGridData,
    actualValue,
    setWhetherUpdated,
    onEdit,
    onAdd,
    onDelete,
    onViewCode,
    onAddCodeSnippet,
    newRow,
    setNewRow,
    scriptAdded,
    handleCancelScript,
    updateJsonInBlob
}) => {
    const [editingIndex, setEditingIndex] = useState(null);
    const [sortOrder, setSortOrder] = useState({ column: null, direction: 'asc' });
    const [editedRow, setEditedRow] = useState({});
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        setGridData(data);
    }, [data, setGridData]);

    const renderTableHeader = () => {
        return settings.TableConfig.map((column, index) => (
            <th key={index}>
                {column.header}
                {column.sortable && (
                    <span className="ms-2 cursor-pointer">
                        <img
                            src={sortOrder.column === column.key ? (sortOrder.direction === 'asc' ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg") : "images/sort-down-icon.svg"}
                            alt="sort"
                            onClick={() => handleSort(column.key)}
                        />
                    </span>
                )}
            </th>
        ));
    };

    const renderTableBody = () => {

        if (data.length === 0) {

            return (

                <tr>

                    <td colSpan="30" className="text-center">No Records Found</td>

                </tr>

            );

        }



        return data.map((row, rowIndex) => (

            <tr key={rowIndex}>

                {settings.TableConfig.map((column, colIndex) => (

                    <td key={colIndex}>

                        {editingIndex === rowIndex ? renderEditableCell(column, row) : renderCell(column, row)}

                    </td>

                ))}

                {settings.Page !== "CodeReviewDev" && (

                    <td  className={`fixed-column-cell ${rowIndex%2!=0?"grid-fixed-td-color-white":"grid-fixed-td-color"}`}>

                        {settings.Page === 'NotebookChangesRecommendation' ? (

                            row.Action === 'Approved' ? (

                                <span className="text-success">Approved</span>

                            ) : row.Action === 'Rejected' ? (

                                <span className="text-danger">Rejected</span>

                            ) : (

                                <>

                                    <img

                                        src="images/tick-icon.svg"

                                        alt="approve"

                                        className="cust-cursor-pointer me-3"

                                        title="Approve"

                                        onClick={() => handleStatusChange(rowIndex, 'Approved')}

                                    />

                                    <img

                                        src="images/cross-red.svg"

                                        alt="reject"

                                        className="cust-cursor-pointer"

                                        title="Reject"

                                        onClick={() => handleStatusChange(rowIndex, 'Rejected')}

                                    />

                                </>

                            )

                        ) : (editingIndex === rowIndex ? renderEditActions(row) : renderActionButtons(rowIndex))}

                    </td>

                )}

            </tr>

        ));

    };



  const handleStatusChange = (index, action) => {

        const updatedData = [...data];

        updatedData[index].Action = action;

        setGridData(updatedData);

        if (updateJsonInBlob) {

            updateJsonInBlob(updatedData);

        }

    };
    const renderCell = (column, row) => {
        if (column.deFaultRow === 'viewcode') {
            return (
                <div>
                    <div>{row[column.key] ? row[column.key].substring(0, 60) + "..." : ''}</div>
                    <a className="view-code-link" onClick={() => onViewCode(row[column.key])}>View Code</a>
                </div>
            );
        }
        return column.key === 'Status' && settings.Page === 'CodeReviewDev' ? (
            <span className={row[column.key] === 'Pass' ? 'text-success' : 'text-danger'}>
                {row[column.key]}
            </span>
        ) : (
            row[column.key]
        );
    };

    const renderEditableCell = (column, row) => {
        if (column.deFaultRow === 'dropdown') {
            return (
                <select
                    className="form-select cust-input-sty font-14 font-regular"
                    value={editedRow[column.key] || ''}
                    onChange={(e) => handleEditChange(column.key, e.target.value)}
                >
                    <option value="">Select</option>
                    {settings.deFaultRow?.find(item => item.key === column.key)?.values.map((value, i) => (
                        <option key={i} value={value}>{value}</option>
                    ))}
                </select>
            );
        } else if (column.deFaultRow === 'datetime') {
            return (
                <input
                    type="datetime-local"
                    className="form-control cust-input-sty font-14 rounded-3 font-regular"
                    value={editedRow[column.key] || ''}
                    onChange={(e) => handleEditChange(column.key, e.target.value)}
                />
            );
        } else {
            return (
                <input
                    type="text"
                    className="form-control cust-input-sty font-14 rounded-3 font-regular"
                    value={editedRow[column.key] || ''}
                    onChange={(e) => handleEditChange(column.key, e.target.value)}
                />
            );
        }
    };

    const renderEditActions = (row) => (
        <>
            <img
                src="images/right.svg"
                alt="save-icon"
                className={`cust-cursor-pointer me-3 ${isEdited ? '' : 'disabled'}`}
                title="save"
                onClick={() => isEdited && handleSave(row)}
            />
            <img
                src="images/cross-red.svg"
                alt="cancel-icon"
                className="cust-cursor-pointer me-3"
                title="cancel"
                onClick={handleCancelEdit}
            />
        </>
    );

    const renderActionButtons = (rowIndex) => (
        <>
            <img
                src="images/edit-icon.svg"
                alt="edit"
                className="cust-cursor-pointer me-3"
                title="edit"
                onClick={() => handleEdit(rowIndex)}
            />
            <img
                src="images/delete-icon.svg"
                alt="delete"
                className="cust-cursor-pointer"
                title="delete"
                onClick={() => onDelete(data[rowIndex])}
            />
        </>
    );

    const handleEditChange = (key, value) => {
        const updatedRow = { ...editedRow, [key]: value };
        setEditedRow(updatedRow);
        
        // Check if any field has been edited and is not empty
        const isAnyFieldEdited = Object.keys(updatedRow).some(k => updatedRow[k] !== data[editingIndex][k] && updatedRow[k] !== '');
        setIsEdited(isAnyFieldEdited);
    };

    const handleEdit = (index) => {
        setEditingIndex(index);
        setEditedRow({ ...data[index] });
        setIsEdited(false);
    };

    const handleSave = (row) => {
        if (isEdited) {
            const updatedRow = { ...row, ...editedRow };
            onEdit(updatedRow); // Call the external edit handler
        }
        setEditingIndex(null);
        setEditedRow({});
        setIsEdited(false);
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
        setEditedRow({});
        setIsEdited(false);
    };

    const handleSort = (columnKey) => {
        const direction = sortOrder.column === columnKey && sortOrder.direction === 'asc' ? 'desc' : 'asc';
        setSortOrder({ column: columnKey, direction });
        
        // Sort data based on the selected column and direction
        const sortedData = [...data].sort((a, b) => {
            if (a[columnKey] < b[columnKey]) return direction === 'asc' ? -1 : 1;
            if (a[columnKey] > b[columnKey]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        
        setGridData(sortedData);
    };

    const renderDefaultRow = () => {
        const allFieldsFilled = settings.TableConfig.every(column => {
            if (column.deFaultRow === 'input' || column.deFaultRow === 'dropdown' || column.deFaultRow === 'datetime' || column.deFaultRow === 'viewcode') {
                return newRow[column.key] && newRow[column.key].trim() !== '';
            }
            return true;
        });
     
        return (
            <tr>
                {settings.TableConfig.map((column, index) => (
                    <td key={index}>
                        {renderNewRowInput(column)}
                    </td>
                ))}
                <td className={`fixed-column-cell grid-fixed-td-color-white`}>
                    <img
                        src="images/add-icons.svg"
                        alt="add"
                        className="cust-cursor-pointer"
                        onClick={allFieldsFilled ? handleAdd : null}
                        style={{
                            opacity: allFieldsFilled ? 1 : 0.3,
                            cursor: allFieldsFilled ? 'pointer' : 'not-allowed'
                        }}
                    />
                </td>
            </tr>
        );
    };

    const renderNewRowInput = (column) => {
        if (column.deFaultRow === 'input') {
            return (
                <input
                    type="text"
                    className="form-control cust-input-sty font-14 rounded-3 font-regular"
                    placeholder="Enter"
                    onChange={(e) => handleNewRowChange(column.key, e.target.value)}
                    value={newRow[column.key] || ''}
                />
            );
        } else if (column.deFaultRow === 'dropdown') {
            return (
                <select
                    className="form-select cust-input-sty font-14 font-regular"
                    onChange={(e) => handleNewRowChange(column.key, e.target.value)}
                    value={newRow[column.key] || ''}
                >
                    <option value="">Select</option>
                    {settings.deFaultRow?.find(item => item.key === column.key)?.values.map((value, i) => (
                        <option key={i} value={value}>{value}</option>
                    ))}
                </select>
            );
        } else if (column.deFaultRow === 'datetime') {
            return (
                <input
                    type="datetime-local"
                    className="form-control cust-input-sty font-14 rounded-3 font-regular"
                    placeholder="Enter Date and Time"
                    onChange={(e) => handleNewRowChange(column.key, e.target.value)}
                    value={newRow[column.key] || ''}
                />
            );
        } 
        
        
         // Handle specific cases like code snippets
         if (column.key === 'DDLScripts') {
             return scriptAdded ? (
                 <div className="d-flex align-items-center">
                     <a className="view-code-link">Script Added</a>
                     <img src="images/cross-red.svg" alt="cancel" className="ms-2 cust-cursor-pointer" onClick={handleCancelScript} />
                 </div>
             ) : (
                 <a className="view-code-link" onClick={onAddCodeSnippet}>Add Code Snippet</a>
             );
         }

         return null; // Default case if no matching input type is found.
     };

     const handleNewRowChange = (key, value) => {
         setNewRow(prev => ({ ...prev, [key]: value }));
     };

     const handleAdd = () => {
         const requiredFields = settings.TableConfig.filter(column =>
             column.deFaultRow === 'input' || column.deFaultRow === 'dropdown'||column.deFaultRow === 'viewcode'
         ).map(column => column.key);

         const allFieldsFilled = requiredFields.every(field => newRow[field]);
         if (allFieldsFilled) {
             onAdd(newRow);
             setNewRow({});
         } else {
             console.log('Please fill in all fields before adding.');
         }
     };

     return (
         <div className="table-responsive rounded-3">
             <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                 <thead className="sticky-top-pos">
                     <tr>
                         {renderTableHeader()}
                         {settings.Page !== 'CodeReviewDev' && (
                             <th className="text-center sticky-action-column fixed-column-header">Actions</th>
                         )}
                     </tr>
                 </thead>
                 <tbody>
                     {settings.type !== "NoDefaultRow" && renderDefaultRow()}
                     {renderTableBody()}
                 </tbody>
             </table>
             <p className="font-regular font-14 text-grey mt-3">
                 # of Records: 
                 <span className="text-black font-medium">{data.length} out of {actualValue.length}</span>
             </p>
         </div>
     );
};

export default DynamicGrid;
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchPseudocodeData, fetchDropDownValues, insertError } from "../../Service/Api";
import { saveAs } from 'file-saver';
import axios from 'axios';
import { HeaderCom } from '../Header';
import CopyToClipboard from 'react-copy-to-clipboard';
import { generateSasTokenAPI } from '../../Service/Api';

const DynamicUmlView = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [UMLDiagram, setUMLDiagram] = useState('');
  const [zoom, setZoom] = useState(1);
  const [umlImage, setUmlImage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [catalogNames, setCatalogNames] = useState(['ALL CATALOG']);
  const [selectedCatalog, setSelectedCatalog] = useState('ALL CATALOG');
  const [copiedText, setCopiedText] = useState(false);
  const [error, setError] = useState(null);
  const [whetherUpdated, setWhetherUpdated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchDropDown();  // Fetch catalog names
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCatalog) {
      fetchPseudo(selectedCatalog);
    }
  }, [selectedCatalog]); // Fetch UML data when selected catalog changes

  useEffect(() => {
    if (UMLDiagram) {
      fetchKrokiDiagram(UMLDiagram);
    }
  }, [UMLDiagram]); // Fetch and render the UML diagram whenever the UML text changes

  const fetchDropDown = async () => {
    setIsLoading(true);
    try {
      const data = { type: "inventorydd", projectId: state.projectId, userId: state.userId };
      const dropDownValues = await fetchDropDownValues(data, state.jwt);
      const oldCatalogNames = dropDownValues.data.oldCatalogName || [];

      setCatalogNames(['ALL CATALOG', ...oldCatalogNames]);
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchPseudo = async (catalog) => {
    document.getElementById("pageLoader").style.display = "block";

    const body = {
      projectId: state.projectId,
      type: "inventorydataModel",
      catalogName: catalog,
      userId: state.userId,
      orgId: state.orgId,
    };

    try {
      const res = await fetchPseudocodeData(body, state.jwt);
      console.log(res,'this is the errorrrrrrrrrrr')
      if (res.statusCode === 403) {
        sessionExpired();
      } else if (res.statusCode !== 200) {
        handleError(new Error('Failed to fetch UML data.'));
      } else {
        const inventoryDDURL = res.data[0].inventoryDMURL;
        const jsonData = await fetchJsonFromBlob(inventoryDDURL);
        setUMLDiagram(JSON.parse(jsonData));
        console.log(jsonData,"this is the error")
      }
    } catch (e) {
      console.error('Error fetching pseudocode data:', e);
      handleError(e);
    }
    finally{
      document.getElementById("pageLoader").style.display = "none";
    }
  }


  const fetchJsonFromBlob = async (inventoryDDURL) => {
    try {
      const sasToken = await generateSasTokenAPI();
      const url = `${inventoryDDURL}?${sasToken}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.text();
    } catch (error) {
      console.error('Error fetching JSON from blob:', error);
      throw error;
    }
  };

  const fetchKrokiDiagram = async (umlCode) => {
    try {
      const response = await axios.post('https://kroki.io/plantuml/svg', umlCode, {
        headers: {
          'Content-Type': 'text/plain',
        },
      });
      console.log(response, 'response.data)response.data)response.data)response.data)response.data)response.data)');

      setUmlImage(response.data); // Set the SVG image source
    } catch (error) {
      console.error('Error fetching diagram from Kroki:', error);
      setUmlImage('<div>Error generating diagram</div>');
    }
  };

  const sessionExpired = () => {
    localStorage.clear();
    document.getElementById('toastMessage').style.display = "block";

    setTimeout(() => {
      document.getElementById('toastMessage').style.display = "none";
      navigate('/');
    }, 3000);
  };

  const handleError = (e) => {
    insertError({
      errorMessage: e.message,
      serviceName: "frontend",
      module: "Datadictionary",
      functionName: "DynamicUmlView",
      userId: state.userId
    });
    setError(e.message);
  }

  const handleZoomIn = () => setZoom(zoom + 0.1);
  const handleZoomOut = () => setZoom(zoom - 0.1);

  const handleCaptureClick = async () => {
    try {
      const imgElement = document.getElementById('uml-image').getElementsByTagName('img')[0];
      let srcValue = imgElement.getAttribute('src');
      if (srcValue.startsWith('http:')) {
        srcValue = srcValue.replace('http:', 'https:');
      }

      const response = await fetch(srcValue);
      const blob = await response.blob();
      saveAs(blob, `Inventory_DataModel_${selectedCatalog}.svg`);
    } catch (error) {
      console.log('Error downloading the image:', error);
    }
  };

  const handleCopyText = () => {
    setCopiedText(true);
    setTimeout(() => setCopiedText(false), 2000);
  };

  const handleCatalogSelect = (catalog) => {
    setSelectedCatalog(catalog);
  };


  return (
    <div className="container-fluid px-md-4">
      <div className="row">
        <HeaderCom value='1' />
        <div className="col-md-12 pt-4 mt-5">
          <div className="mt-3 px-2">
            <ul className="cust-breadcrumb mb-0 ">
              <li className="font-16 font-medium">
                <a>Manage Projects</a>
              </li>
              <li className="font-16 font-medium active">
                {state.isEdit ? state.projectName : state.projectName}
              </li>
              <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
            </ul>
          </div>

          <div className="d-flex align-items-center justify-content-between p-4 pb-3">
            <div className="d-flex align-items-center">
              <a className="d-flex" style={{paddingRight: '10px'}}>
              <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px', cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
              </a>
              <h2 className="text-black font-bold font-22 mb-0"> {props.data.hyperlinkText}
                <button type="button" className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">
                  <img src="images/info-icon-ETL.svg" className="" alt="info" />
                </button>
              </h2>
            </div>
            <div className="d-flex">
              <div className="me-2 dropdown-width">
              <div className="dropdown">
    <a
        className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center custum-width-230"
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ zIndex: 1001 }}
    >
        {selectedCatalog} {/* Display selected catalog */}
    </a>
    <ul className="dropdown-menu w-100 cust-drop-menu" aria-labelledby="dropdownMenuLink">
        {catalogNames.map((name, index) => (
            <li key={index}>
                <a
                    className="dropdown-item font-12"
                    onClick={() => handleCatalogSelect(name)}
                >
                    {name}
                </a>
            </li>
        ))}
    </ul>
</div>

              </div>
              <button type="button" className="btn cust-secondary-btn font-14 font-regular me-md-2" onClick={handleCaptureClick}>
                <span className="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download
              </button>
              <CopyToClipboard text={UMLDiagram} onCopy={handleCopyText}>
                <button type="button" className="btn cust-primary-btn font-14 font-regular">
                  <span className="me-2"><img src="images/copy-code-icon.svg" alt="add-icon" /></span>{copiedText ? "Copied!" : "Copy Code"}
                </button>
              </CopyToClipboard>
            </div>
          </div>

          <div className="col-md-12 px-4">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12 pt-2 mb-5">
                <div>
                  <textarea className="form-control custom-textarea logger-textarea-scroll add-code-snippet" value={UMLDiagram} readOnly></textarea>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 pt-2 mb-5">
                <div>
                  <div className="position-relative">
                    <div className="position-absolute custom-btn-position bg-white rounded-3">
                      <button type="button" className="btn btn-common-style btn-zoom-in custom-bottom-brd" onClick={handleZoomIn}>
                        <img className="" src="images/zoomin-icon.svg" alt="zoom-in" />
                      </button>
                      <button type="button" className="btn btn-common-style btn-zoom-out" onClick={handleZoomOut}>
                        <img className="" src="images/zoomout-icon.svg" alt="zoom-out" />
                      </button>
                    </div>
                    <div className="custom-inventory-textarea logger-textarea-scroll" id="uml-image">
                      <div style={{ transform: `scale(${zoom})`, transformOrigin: 'top left' }}>
                        {umlImage && (
                          <img
                            src={`data:image/svg+xml;base64,${btoa(umlImage)}`}
                            alt="Before Diagram"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog view-instructions-popup modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="view-instructions-popup-left-section">
                    <img
                      src="images/view-instructions-popup-banner-img.png"
                      alt="view-instructions-popup-banner-img"
                      className="popup-banner-img"
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="view-instructions-popup-right-section">
                    <div className="d-flex justify-content-between">
                      <h1 className="font-bold font-20 m-0">View Instructions</h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <ul className="mt-4 view-instructions-list-items">
                      <li>Please validate whether the data’s are correct</li>
                      <li>
                        If things are fine just click{" "}
                        <span className="font-bold">‘back’</span> then on{" "}
                        <span className="font-bold">‘Looks good’</span> and proceed to
                        next steps
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
                className="overlay"
                id="pageLoader"
                style={{ display: "none" }}
            >
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div
                            className="spinner-border Loader text-dark align-center"
                            role="status"
                        >
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
    </div>
  );
};

export default DynamicUmlView;
// perfect
import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const DownloadComponent = ({ type, data, fileName }) => {
    const handleDownload = () => {
        switch (type) {
            case 'excel':
                downloadExcel(data, fileName);
                break;
            case 'svg':
                downloadSVG(data, fileName);
                break;
            case 'py':
                downloadPython(data, fileName);
                break;
            default:
                console.error('Unsupported file type:', type);
        }
    };

    const downloadExcel = (data, fileName) => {
        // Remove 'index' key from each object in the dataset
        const processedData = data.map(({ index, ...rest }) => rest);

        // Convert JSON to worksheet
        const ws = XLSX.utils.json_to_sheet(processedData);

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Generate and download the Excel file
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };

    const downloadSVG = async (imageId, fileName) => {
        const svgContainer = document.getElementById(imageId);
        if (!svgContainer) {
            console.error('SVG container not found');
            return;
        }

        const svgElement = svgContainer.querySelector('img');
        if (!svgElement) {
            console.error('SVG element not found');
            return;
        }

        let svgUrl = svgElement.src;
        if (svgUrl.startsWith('http:')) {
            svgUrl = svgUrl.replace('http:', 'https:');
        }

        try {
            const response = await fetch(svgUrl);
            const blob = await response.blob();
            saveAs(blob, `${fileName}.svg`);
        } catch (error) {
            console.error('Error downloading SVG:', error);
        }
    };

    const downloadPython = (textInput, fileName) => {
        const element = document.createElement('a');
        const file = new Blob([textInput], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `${fileName}.py`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    return (
        <button onClick={handleDownload}
            className="btn cust-secondary-btn font-16 font-medium"
        >
            <span className="me-2">
                <img src="images/download-icon.svg" alt="download-icon" />
            </span>
            Download
        </button>
    );
};

export default DownloadComponent;
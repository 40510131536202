import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FileUploaderComponent from '../NotebookUploadComponent.jsx'
import UploadConfirmedNotebooks from './AnalyseDocumentUpload'
import ViewInstructions from './viewInstructions';
import { fetchPseudocodeData,generateSasTokenAPI } from '../../../Service/Api';
import { insertError } from '../../../Service/Api';
import DownloadComponent from './DownloadComponent.jsx';
import * as XLSX from 'xlsx';
import { HeaderCom } from "../../Header.jsx";
import CodeComparisonComponent from './AnalyseDocumentCodeCompare.jsx';
import { generateAnalysisdocument } from '../../../Service/Api';


const AnalysisDocument = (props) => {
  const [matchedTables, setMatchedTables] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    matched: { column: "", direction: "asc" },
    unmatched: { column: "", direction: "asc" },
  });
  const [unmatchedTables, setUnmatchedTables] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showNotebookPopup, setNotebookPopup] = useState(false);
  const [confirmedNBPopup, setConfirmedNBPopup] = useState(false);
  const [notebooksToTest, setNotebooksToTest] = useState([]);
  const [filteredUnmatchedTables, setFilteredUnmatchedTables] = useState([]);
  const [isEdit, SetIsEdit] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [tabShowed, setTabShowed] = useState("matched")
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const[loader,setLoader]=useState(false)
  const[blobUrl,setblobUrl]=useState("")
  const[isChanged,setIsChanged]=useState(false)

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };


  const [showCodeComparison, setShowCodeComparison] = useState(false);
  const [codeComparisonData, setCodeComparisonData] = useState({
    notebookName: '',
    oldCode: '',
    newCode: ''
  });
 
  const handleNotebookClick = (notebookName, oldCode, newCode) => {
    setCodeComparisonData({
      notebookName,
      oldCode,
      newCode
    });
    setShowCodeComparison(true);
  };
  const [selectedNotebookName, setSelectedNotebookName] = useState("");
  const [selectedSourceSchemaName, setSelectedSourceSchemaName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchPseudo()]);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const fetchPseudo = async () => {
    try {
      try {
        // document.getElementById('pageLoader').style.display = 'block';
        // document.getElementById("pageLoader").style.display = "block";

        setLoader(() => true);
        let data = { type:"analysisdocumenturl",projectId: state.projectId, userId: state.userId, orgId: state.orgId, jwt: state.jwt }
        console.log(data, "payload")
        // document.getElementById('pageLoader').style.display = 'block';
        let urlData = await fetchPseudocodeData(data, state.jwt)
        console.log(urlData, "tata")
        setblobUrl(urlData.data[0].analysisDocumentURL)
        if (urlData.statusCode === 200) {
          let url = urlData.data[0].analysisDocumentURL
          const azureData = await fetchAzureBlobData(url);
          console.log(azureData, "blobdata")
          

          if (azureData && Array.isArray(azureData.matched_tables)) {
            // Add `notebookToBeTested` field
            const updatedMatchedTables = azureData.matched_tables.map((table) => ({
              ...table,
              notebookToBeTested: "" // Default value
            }));
    
            // Set states accordingly
            setMatchedTables(azureData.matched_tables);
            console.log(azureData.matched_tables,"match")
            setUnmatchedTables(azureData.unmatched_tables);
            console.log(azureData.unmatched_tables,"unmatch")
            setFilteredRecords(processMatchedTables(updatedMatchedTables));
            setFilteredUnmatchedTables(azureData.unmatched_tables); 
            // setLoader(() => false);// Initialize filtered unmatched tables
            // document.getElementById('pageLoader').style.display = 'none';
            // document.getElementById("pageLoader").style.display = "none";
            setLoader(() => false);
          } else {
            console.error('azureData or azureData.matched_tables is undefined or not an array:', azureData);
          }
        }
      }
      catch (e) {
        insertError({
          errorMessage: e.message,
          serviceName: "frontend",
          module: "AnalysisDocument",
          functionName: "AnalysisDocument",
          userId: state.userId
        });
        console.log("error", e);
      }


    } catch (error) {
      console.error(error);
    }
  };



  const notebookNames = [...new Set(matchedTables.map((item) => item.notebookName))];
    const accountName = 'avaeusgenetlsametaprod';
    const containerName = 'uc-migration';

  const handleExistingNotebook = () => {
    // let data = [...chatHistory];
    // data[data.length - 1].show = false;
    // data.push({ flow: "user", type: "text", text: "Notebook Details" });
    // data.push({ flow: "ai", type: "notebookPopup", notebookName: "notebookPopup", show: true, phase: "Define" });
    // setChatHistory(() => [...data]);
    // updateJsonInBlob(state.orgName, state.projectName, "chatHistory.json", [...data]);
    props.isEdit(true)
  };

  const fetchAzureBlobData = async (AnalyseUrl) => {
    try {
      const sasToken = await generateSasTokenAPI();
      let url = `${AnalyseUrl}?${sasToken}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonData = await response.json();

      console.log(jsonData, 'analysisData');
      return jsonData;
    } catch (error) {
      console.error('Error fetching JSON from blob:', error);
      throw error;
    }
  };

  const handleSave = async (notebooks) => {
    debugger
    console.log("Notebooks to be tested:", notebooks);
    setNotebooksToTest(notebooks);

    // Update matchedTables
    console.log(matchedTables,"Matched data")
    const updatedMatchedTables = matchedTables.map((item) => ({
      ...item,
      noteBookToBetested: notebooks.includes(item.notebookName) ? "yes" : item.noteBookToBetested,
    }));
    console.log(updatedMatchedTables,"+++")
    setMatchedTables(updatedMatchedTables);
    console.log(processMatchedTables(updatedMatchedTables),filteredRecords);
    
    setFilteredRecords(processMatchedTables(updatedMatchedTables)) // Update the state

    // Save to Azure Blob
    try {
      await saveDataToBlob(updatedMatchedTables, unmatchedTables, {
        // Include other data keys if necessary
      });
      setIsChanged(true)
    } catch (error) {
      console.error("Error saving data to Azure Blob:", error);
    }
  };



  const saveDataToBlob = async (updatedMatchedTables, unmatchedTables, otherDataKeys = {}) => {
    try {
      const sasToken = await generateSasTokenAPI();
      const url = `${blobUrl}?${sasToken}`;

      // Remove duplicates from matched_tables
      // const uniqueMatchedTables = Array.from(
      //   new Map(
      //     updatedMatchedTables.map((table) => [table.notebookName, table])
      //   ).values()
      // );

      // Prepare the updated data object
      const updatedData = { // Preserve other keys from the original data
        matched_tables: updatedMatchedTables,
        unmatched_tables: unmatchedTables,
      };
      console.log(updatedMatchedTables,"uniqueMatchedTablesuniqueMatchedTables");
      

      // Send PUT request to save the updated data
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error(`Failed to save data. HTTP status: ${response.status}`);
      }

      console.log("Data successfully saved to Azure Blob.");
    } catch (error) {
      console.error("Error saving data to Azure Blob:", error);
      throw error;
    }
  };



  const processMatchedTables = (tables) => {
    const groupedTables = {};
    tables.forEach((item) => {
      const notebookName = item.notebookName;
      if (!groupedTables[notebookName]) {
        groupedTables[notebookName] = {
          notebookName,
          rows: [],
        };
      }
      groupedTables[notebookName].rows.push(item);
    });
    return Object.values(groupedTables);
  };

  const handleSort = (type, column) => {
    const currentSort = sortOrder[type];

    // Toggle direction if the same column is clicked again, otherwise set to ascending by default
    const newDirection = currentSort.column === column
      ? (currentSort.direction === "asc" ? "desc" : "asc")
      : "asc";

    // Update the sort order for the matched or unmatched table based on 'type'
    setSortOrder({
      ...sortOrder,
      [type]: { column, direction: newDirection },
    });

    // Sort filtered records based on the column and direction
    let sortedData = [];
    if (type === "matched") {
      sortedData = [...filteredRecords].sort((a, b) => {
        const aValue = a[column];
        const bValue = b[column];

        // Adjust for how the data is structured (e.g., nested arrays or objects)
        if (aValue < bValue) return newDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return newDirection === "asc" ? 1 : -1;
        return 0;
      });

      setFilteredRecords(sortedData);
    } else if (type === "unmatched") {
      sortedData = [...filteredUnmatchedTables].sort((a, b) => {
        const aValue = a[column];
        const bValue = b[column];

        // Adjust for how the data is structured (e.g., nested arrays or objects)
        if (aValue < bValue) return newDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return newDirection === "asc" ? 1 : -1;
        return 0;
      });

      setFilteredUnmatchedTables(sortedData);
    }
  };


  const handleUploaderClose = () => {
    // Optionally, refetch matched and unmatched tables data.
    fetchPseudo(); // or call your data fetching function here
    setNotebookPopup(false);
    handleTabClick("matched")
  }

  const handleConfirmedClose = () => {
    // Optionally, refetch matched and unmatched tables data.
    //  // or call your data fetching function here
    setConfirmedNBPopup(false)
    handleTabClick("matched")
  }

      // PS_68-72: Handle session expiration
      const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";
    
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };
  async function generateAnalysisDocument() {
    try {
      setLoader(() => true);
        const notebookName = "Analysis Document";
        const phase = "Define";
        const referenceDocuments = ['Uploaded Notebooks', 'Confirmed Table Mapping Details'];

        const object = {
            isRegenerate: false,
            userId: state.userId,
            orgId: state.orgId,
            projectId: state.projectId,
            notebookName: "analysisDocument",
            jwt_token: state.jwt,
            projectName: state.projectName,
            orgName: state.orgName
        }

        const response = await generateAnalysisdocument(object, state.jwt);
        // call the generation function api
        // setLoader(() => false);
        if (response.status === 403) {
            sessionExpired()
            return;
        } else if (response.status !== 200) {
            document.getElementById("protoastMessageSomething").style.display = "block";
            setTimeout(() => { document.getElementById("protoastMessageSomething").style.display = "none"; }, 3000);
        } else {
          fetchPseudo()
        }

        setLoader(() => false);


    } catch (error) {
        console.error("Error generating Code Review Checklist:", error);
        setLoader(() => false);
    }
}

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    // Apply search on the filtered data (not the entire matched or unmatched data)
    const currentMatchedData = selectedNotebookName ? filteredRecords : matchedTables;
    const currentUnmatchedData = selectedSourceSchemaName ? filteredUnmatchedTables : unmatchedTables;

    if (term.length > 3) {
      const matched = currentMatchedData.filter((item) =>
      (item.notebookName?.toLowerCase().includes(term) ||
        item.tableNameInTheCatalog?.toLowerCase().includes(term) ||
        item.tableNamesInTheNoteBook?.some((tableName) =>
          tableName?.toLowerCase().includes(term)
        ))
      );
      setFilteredRecords(processMatchedTables(matched));

      const unmatched = currentUnmatchedData.filter((item) =>
      (item.SourceCatalogName?.toLowerCase().includes(term) ||
        item.SourceSchemaName?.toLowerCase().includes(term))
      );
      setFilteredUnmatchedTables(unmatched);
    } else {
      // Reset the filtered data if search term is cleared
      setFilteredRecords(processMatchedTables(matchedTables));
      setFilteredUnmatchedTables(unmatchedTables);
    }
  };


  const uniqueNotebookNames = [...new Set(matchedTables.map((item) => item.notebookName))];
  const uniqueSourceSchemaNames = [
    ...new Set(unmatchedTables.map((item) => item.sourceSchemaName)),
  ];

  const handleFilterApply = () => {
    let filteredMatched = matchedTables;
    let filteredUnmatched = unmatchedTables;

    // Filter matched tables based on selected notebook name
    if (selectedNotebookName) {
      filteredMatched = matchedTables.filter((item) => item.notebookName === selectedNotebookName);
    }

    // Filter unmatched tables based on selected source schema name
    if (selectedSourceSchemaName) {
      filteredUnmatched = unmatchedTables.filter(
        (item) => item.sourceSchemaName === selectedSourceSchemaName
      );
    }

    // Apply filtering to the records
    setFilteredRecords(processMatchedTables(filteredMatched));
    setFilteredUnmatchedTables(filteredUnmatched);
  };

  const handleFilterClear = () => {
    setSelectedNotebookName('');
    setSelectedSourceSchemaName('');
    setFilteredRecords(processMatchedTables(matchedTables));
    setFilteredUnmatchedTables(unmatchedTables);
  };

  var data = {};
  data["fileName"] = "newfile";

  const handleTabClick = (targetTab) => {
    setTabShowed(targetTab);
  };
  const handleDownload = () => {
    const processedData = matchedTables.map(item => ({
      ...item,
      tableNamesInTheNoteBook:item.tableNameInTheCatalog
  }));

  // Remove 'index' key from each object in the dataset
  const finalData = processedData.map(({ index,originalCode, updatedCode,noteBookToBetested, ...rest }) => rest);

  // Convert JSON to worksheet

  // Create a new workbook and append the worksheet
  
  const sheet2Data = matchedTables.map(item => ({
    notebookName: item.notebookName,
    originalCode: item.originalCode,
    updatedCode: item.updatedCode,
}));

const sheet4Data = matchedTables.map(item => ({
  notebookName: item.notebookName,
  noteBookToBetested:item.noteBookToBetested
}));
const ws = XLSX.utils.json_to_sheet(finalData);
const ws2 = XLSX.utils.json_to_sheet(sheet2Data);
const ws3 = XLSX.utils.json_to_sheet(unmatchedTables);
const ws4 = XLSX.utils.json_to_sheet(sheet4Data);

const wb = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(wb, ws, 'MatchedTables');
XLSX.utils.book_append_sheet(wb, ws2, 'Changes');
XLSX.utils.book_append_sheet(wb, ws3, 'UnmatchedTables');
XLSX.utils.book_append_sheet(wb, ws4, 'NotebookToBeTested');

  // Generate and download the Excel file
  XLSX.writeFile(wb, `analysis document.xlsx`);
  };

  return (
    <>
    {/* {console.log(JSON.stringify(props),"propspropspropspropspropspropspropspropspropspropspropspropsprops")    } */}
      {showNotebookPopup ? (
        // <FileUploaderComponent 
        //   fileName={""} // Replace with chatHistory.notebookName if available
        //   handleExistingNotebook={handleExistingNotebook}
        //   close={() => setNotebookPopup(false)}
        // />

        <FileUploaderComponent
    fileName={props?.fileName || ""}
    documentUploaded={"true"}
    fromAnalysis={"true"}
    uploaded={props?.uploaded}
    handleExistingNotebook={generateAnalysisDocument}
    // onChange={(data) => updateChatHistory(data, "definePopup")}
    notebookUploaded={()=>{setIsChanged(true)}}
    close={handleUploaderClose}
/>

      ) : confirmedNBPopup ? (
        <UploadConfirmedNotebooks
          notebookNames={notebookNames}
          onSave={handleSave}
          close={handleConfirmedClose}
          title={data.fileName || ""}
        />
      ) : (
        <div className="container-fluid px-md-4">
          <div className="row">
          <HeaderCom value="1" />
          {loader&&<div
                                    className="overlay"
                                    id="pageLoader"
                                    style={{ display: "block" }}
                                >
                                    <div className="position-absolute top-50 start-50  translate-middle">
                                        <div className="d-flex align-items-center loader-bg">
                                            <div
                                                className="spinner-border Loader text-dark align-center"
                                                role="status"
                                            >
                                                <span className="visually-hidden"></span>
                                            </div>
                                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                                Loading...
                                            </span>
                                        </div>
                                    </div>
                                </div>}
            <div className="col-md-12 pt-4 mt-5">
              <div className="mt-3 px-2">
                <ul className="cust-breadcrumb mb-0">
                  <li className="font-16 font-medium">
                    <a >Manage Projects</a>
                  </li>
                  <li className="font-16 font-medium">
                    <a >{state.projectName}</a>
                  </li>
                  <li className="font-16 font-medium active">Analysis Document</li>
                </ul>
              </div>

              <div className="d-flex align-items-center justify-content-between p-4">
                <div className="d-flex align-items-center">
                  <a className="d-flex">
                  <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '35px', cursor: "pointer", whiteSpace: "nowrap" }}  onClick={() => { props.setShowCode(); props.isEdit(isChanged)}} />
                  </a>
                  <h2 className="text-black font-bold font-22 mb-0">
                    Analysis Document
                    <button type="button" className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3" onClick={handleOpenModal}>
                      <img src="images/info-icon-ETL.svg" alt="info" />
                    </button>
                  </h2>
                  {isModalOpen && <ViewInstructions notebooktype='analysisDocument' onClose={handleCloseModal} />}
                </div>
                <div className="d-flex">
                  <div className="input-group position-relative search-wid me-md-3">
                    <input
                      type="search"
                      className="form-control cust-input-sty font-14 rounded-3 font-regular"
                      placeholder="Search"
                      onChange={handleSearch}
                      style={{ 'paddingRight': '30px' }}
                    />
                    <span className="search-icon-sty">
                      <a >
                        <img src="images/search-icon.svg" alt="search icon" />
                      </a>
                    </span>
                  </div>
                  <div className="btn-group me-3">
                    <button
                      className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="images/filter-icon.svg"
                        alt="filter-icon"
                        className="filt-icon"
                      />
                    </button>
                    <div
                      className="dropdown-menu custom-filter shadow-sm border-0 p-3"
                      style={{
                        transform: 'translate(-353px, 42px) !important',
                        width: '466px',
                        float: 'right',
                      }}
                    >
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                          <button
                            type="button"
                            className="btn-close"
                            // data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="row">
                          {/* Conditional Rendering for Matched and Unmatched */}
                          {tabShowed === 'matched' && (
                            <div className="col-md-6 col-sm-12">
                              <div className="mb-3">
                                <label className="form-label text-black font-14 font-medium">
                                  Notebook Name
                                </label>
                                <select
                                  className="form-select cust-input-sty font-14 font-regular"
                                  aria-label="Select Notebook Name"
                                  value={selectedNotebookName}
                                  onChange={(e) => setSelectedNotebookName(e.target.value)}
                                >
                                  <option value="" style={{ cursor: 'pointer' }}>
                                    Select
                                  </option>
                                  {uniqueNotebookNames.map((name, index) => (
                                    <option key={index} value={name}>
                                      {name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}

                          {tabShowed === 'unmatched' && (
                            <div className="col-md-6 col-sm-12">
                              <div className="mb-3">
                                <label className="form-label text-black font-14 font-medium">
                                  Source Schema Name
                                </label>
                                <select
                                  className="form-select cust-input-sty font-14 font-regular"
                                  aria-label="Select Source Schema Name"
                                  value={selectedSourceSchemaName}
                                  onChange={(e) => setSelectedSourceSchemaName(e.target.value)}
                                >
                                  <option value="" style={{ cursor: 'pointer' }}>
                                    Select
                                  </option>
                                  {uniqueSourceSchemaNames.map((name, index) => (
                                    <option key={index} value={name}>
                                      {name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="text-end mt-4 mb-2">
                          <button
                            type="button"
                            className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                            onClick={handleFilterClear}
                          >
                            Clear
                          </button>
                          <button
                            type="button"
                            className="btn cust-primary-btn font-14 font-medium px-4"
                            onClick={handleFilterApply}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <div className="dropdown me-3">
                    <a className="btn custom-dropdown-toggle custom-dropdown-selecttoggle upload-dropdown-btn form-control cust-input-sty font-14 font-regular d-flex align-items-center"  role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ zIndex: 1001 }}>
                      <img src="images/upload-icon-btn.svg" alt="upload" className="me-2" />
                      <span className="me-2">Upload</span>
                    </a>
                    <ul className="dropdown-menu w-100 cust-drop-menu upload-dropdown-menu" aria-labelledby="dropdownMenuLink">
  <li>
    <a
      className="dropdown-item font-12"
      style={{ cursor: 'pointer' }} // Inline style to change cursor
      onClick={(e) => {
        e.preventDefault(); // Prevent default link behavior
        setNotebookPopup(true);
        console.log(showNotebookPopup, 'thsjkl');
      }}
    >
      Existing Notebook
    </a>
  </li>
  <li>
    <a
      className="dropdown-item font-12"
      style={{ cursor: 'pointer' }} // Inline style to change cursor
      onClick={(e) => {
        e.preventDefault(); // Prevent default link behavior
        setConfirmedNBPopup(true);
      }}
    >
      Confirmed Notebook
    </a>
  </li>
</ul>
                  </div>
                  <div className="d-flex">
                  <button 
            onClick={handleDownload}
            className="btn cust-secondary-btn font-16 font-medium"
        >
            <span className="me-2">
                <img src="images/download-icon.svg" alt="download-icon" />
            </span>
            Download
        </button>
            </div>
                </div>
              </div>

              <div className="col-md-12 px-4">
                <div className="row justify-content-center">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="code-review-page tabs nav mb-3">
                      <div
                        className={`tab nav-link font-bold ${tabShowed === 'matched' ? 'active' : ''}`}
                        data-bs-toggle="pill"
                        data-bs-target="#matched-tables"
                        onClick={() => handleTabClick('matched')}
                      >
                        Matched Tables
                      </div>
                      <div
                        className={`tab nav-link font-bold ${tabShowed === 'unmatched' ? 'active' : ''}`}
                        data-bs-toggle="pill"
                        data-bs-target="#unmatched-tables"
                        onClick={() => handleTabClick('unmatched')}
                      >
                        Unmatched Tables
                      </div>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                      <div id="matched-tables" className="tab-pane fade show active code-review-tab tab-content">
                        <div className="table-responsive rounded-3">
                          <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0 analysis-doc-table">
                            <thead className="sticky-top-pos">
                              <tr>
                                <th>
                                  <span>NotebookName</span>
                                  <img
                                    src={sortOrder.matched.column === "notebookName" && sortOrder.matched.direction === "asc"
                                      ? "images/sort-up-icon.svg"
                                      : "images/sort-down-icon.svg"}
                                    alt="sort-icon"
                                    onClick={() => handleSort('matched', 'notebookName')}
                                  />
                                </th>
                                <th>
                                  <span>Table Name in the Catalog</span>
                                  <img
                                    src={sortOrder.matched.column === "tableNameInTheCatalog" && sortOrder.matched.direction === "asc"
                                      ? "images/sort-up-icon.svg"
                                      : "images/sort-down-icon.svg"}
                                    alt="sort-icon"
                                    onClick={() => handleSort('matched', 'tableNameInTheCatalog')}
                                  />
                                </th>
                                <th>
                                  <span>Table Name in the Notebook</span>
                                  <img
                                    src={sortOrder.matched.column === "tableNamesInTheNoteBook" && sortOrder.matched.direction === "asc"
                                      ? "images/sort-up-icon.svg"
                                      : "images/sort-down-icon.svg"}
                                    alt="sort-icon"
                                    onClick={() => handleSort('matched', 'tableNamesInTheNoteBook')}
                                  />
                                </th>
                                <th className="text-end">
                                  <span>Table Count in Notebook</span>
                                  <img
                                    src={sortOrder.matched.column === "tableCountInTheNoteBook" && sortOrder.matched.direction === "asc"
                                      ? "images/sort-up-icon.svg"
                                      : "images/sort-down-icon.svg"}
                                    alt="sort-icon"
                                    onClick={() => handleSort('matched', 'tableCountInTheNoteBook')}
                                  />
                                </th>
                                <th>
                                  <span>Notebooks to be Tested</span>
                                  <img
                                    src={sortOrder.matched.column === "notebooksToBeTested" && sortOrder.matched.direction === "asc"
                                      ? "images/sort-up-icon.svg"
                                      : "images/sort-down-icon.svg"}
                                    alt="sort-icon"
                                    onClick={() => handleSort('matched', 'notebooksToBeTested')}
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredRecords.length > 0 ? (
                                filteredRecords.flatMap((group) => {
                                  const rowSpan = group.rows.length;
                                  return group.rows.map((row, index) => (
                                    <tr key={`${group.notebookName}-${index}`}>
                                      {index === 0 && (
                                        <td
                                          rowSpan={rowSpan}
                                          className="vertical-align-start cust-border-bottom cursor-pointer"
                                          onClick={() => handleNotebookClick(group.notebookName, row.originalCode, row.updatedCode)} // Call the handler here
                                        >
                                          <a  className="anchor-link font-medium font-14 text-decoration-none">
                                            {group.notebookName}
                                          </a>
                                        </td>
                                      )}
                                      <td>{row.tableNameInTheCatalog}</td>
                                      <td>
                                        {row.tableNamesInTheNoteBook.map((tableName, idx) => (
                                          <div key={idx}>{tableName}</div>
                                        ))}
                                      </td>
                                      <td className="text-end">{row.tableCountInTheNoteBook}</td>
                                      {index === 0 && (
                                        <td rowSpan={rowSpan} className="vertical-align-start cust-border-bottom">
                                          {group.rows.some(obj=>obj.noteBookToBetested=="yes")?"Yes":"No"}{console.log(group) }
                                        </td>
                                      )}
                                    </tr>
                                  ));
                                })
                              ) : (
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    No Records Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <p className="font-regular font-14 text-grey mt-3">
                          # of Matched Records:{" "}
                          <span className="text-black font-medium">{filteredRecords.reduce((acc, group) => acc + group.rows.length, 0)}</span>
                        </p>
                      </div>
                      <div id="unmatched-tables" className="tab-pane code-review-tab tab-content">
                        <div className="table-responsive rounded-3">
                          <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0 analysis-doc-table">
                            <thead className="sticky-top-pos">
                              <tr>
                              <th>
                                  <span>CatalogName</span>
                                  <img
                                    src={sortOrder.unmatched.column === "sourceCatalogName" && sortOrder.unmatched.direction === "asc"
                                      ? "images/sort-up-icon.svg"
                                      : "images/sort-down-icon.svg"}
                                    alt="sort-icon"
                                    onClick={() => handleSort('unmatched', 'sourceCatalogName')}
                                  />
                                </th>
                                <th>
                                  <span>SchemaName</span>
                                  <img
                                    src={sortOrder.unmatched.column === "sourceSchemaName" && sortOrder.unmatched.direction === "asc"
                                      ? "images/sort-up-icon.svg"
                                      : "images/sort-down-icon.svg"}
                                    alt="sort-icon"
                                    onClick={() => handleSort('unmatched', 'sourceSchemaName')}
                                  />
                                </th>
                                <th>
                                  <span>TableName</span>
                                  <img
                                    src={sortOrder.unmatched.column === "sourceTableName" && sortOrder.unmatched.direction === "asc"
                                      ? "images/sort-up-icon.svg"
                                      : "images/sort-down-icon.svg"}
                                    alt="sort-icon"
                                    onClick={() => handleSort('unmatched', 'sourceTableName')}
                                  />
                                </th>
                               
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(filteredUnmatchedTables) && filteredUnmatchedTables.length > 0 ? (
                                filteredUnmatchedTables.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.sourceCatalogName}</td>
                                    <td>{item.sourceSchemaName}</td>
                                    <td>{item.sourceTableName}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="3" className="text-center">
                                    No Records Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <p className="font-regular font-14 text-grey mt-3">
                          # of Unmatched Records: <span className="text-black font-medium">{filteredUnmatchedTables.length || 0}</span>
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        {showCodeComparison && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
          <div className="modal-dialog modal-xl">
            <div className="modal-content px-2">
              <div className="modal-header border-0">
                <h5 className="modal-title d-flex align-items-center font-bold font-20">
                  {codeComparisonData.notebookName}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowCodeComparison(false)}
                />
              </div>
              <div className="modal-body py-0 pb-4">
                <CodeComparisonComponent
                  oldCode={codeComparisonData.oldCode}
                  newCode={codeComparisonData.newCode}
                  notebookName={codeComparisonData.notebookName}
                  isOpen={showCodeComparison}
                  onClose={() => setShowCodeComparison(false)}
                />
              </div>
            </div>
          </div>
          <div
                className="overlay"
                id="pageLoader"
                style={{ display: "none" }}
            >
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div
                            className="spinner-border Loader text-dark align-center"
                            role="status"
                        >
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
        </div>
      )}
    </>
  );


};

export default AnalysisDocument;
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { deployPBIPcode } from "../../Service/Api.js";

function DeployPBIPCode(props) {
    const [targetSystemDetails, setTargetSystemDetails] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);

    const { state } = useLocation();
    if (state == null) {
        window.location.href = "/";
    }

    useEffect(() => {
        // let da= {data:{userName: 'dasdas', repoName: 'asdasd', branchName: 'sad', accessToken: 'asd'}}

        if (props.fileUploaded != '') {
            setTargetSystemDetails(props.fileUploaded)
        }
        if (props.fileUploaded != ''){
            setIsDisabled(true)

        }
    }, []);

    const validateInputs = () => {
        const errors = {};
        if (!targetSystemDetails.userName) {
            errors.userName = "Please enter a valid Username";
        }
        if (!targetSystemDetails.repoName) {
            errors.repoName = "Please enter a valid Repo Name";
        }
        if (!targetSystemDetails.branchName) {
            errors.branchName = "Please enter a valid Branch Name";
        }
        if (!targetSystemDetails.accessToken) {
            errors.accessToken = "Please enter a valid Access Token";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const submitDetails = async () => {
        console.log("entered submitDetails");

        if (!validateInputs()) {
            return;
        }

        try {
            document.getElementById("pageLoader").style.display = "block";

            const body = {
                data: targetSystemDetails,
                fileName:props.filename,
                userId: state.userId,
                projectId: state.projectId,
                orgId: state.orgId,
            };
            console.log("body", body);
            const responseData = await deployPBIPcode(
               body,
                state.jwt
            );

            document.getElementById("pageLoader").style.display = "none";
            console.log("responseData", responseData);

            if (responseData.status == 200) {
                props.onChange(
                     responseData,
                    200,
                     targetSystemDetails
                
                );
                props.closePopup()
            } else {
                props.onChange(responseData,
                    200,
                     targetSystemDetails);

                     props.closePopup()
            }
        } catch (error) {
            console.error(error);
            props.closePopup()
        }
    };

    const handleInputChange = (field, value) => {
        setTargetSystemDetails({
            ...targetSystemDetails,
            [field]: value,
        });
    };

    console.log(targetSystemDetails, "gggggggggggggggggggggggggggggggggggggggggg");

    return (
        <div className="custom-deploy-popup">
            <div className="modal-content px-2">
                <div className="modal-header border-0">
                    <h5 className="modal-title d-flex align-items-center font-medium font-20" id="TarSysLabel">
                        Target System Details
                    </h5>
                    <button type="button" className="btn-close" onClick={()=> props.closePopup()}/>
                </div>
                <div className="modal-body py-0 my-4">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                            <label htmlFor="User-Name" className="form-label text-black font-14 font-medium">
                                Username <span className="text-red"> *</span>
                            </label>
                            <input
                                type="text"
                                className="form-control cust-input-sty font-14 font-regular"
                                id="User-Name"
                                onChange={(e) => handleInputChange("userName", e.target.value)}
                                value={targetSystemDetails?.userName || ""}
                                disabled={isDisabled}
                                placeholder="Enter username"
                            />
                            {validationErrors.userName && <span style={{ color: "red" }}>{validationErrors.userName}</span>}
                        </div>
                        <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                            <label htmlFor="Repo-Name" className="form-label text-black font-14 font-medium">
                                Repo Name <span className="text-red"> *</span>
                            </label>
                            <input
                                type="text"
                                className="form-control cust-input-sty font-14 font-regular"
                                id="Repo-Name"
                                onChange={(e) => handleInputChange("repoName", e.target.value)}
                                value={targetSystemDetails?.repoName || ""}
                                disabled={isDisabled}
                                placeholder="Enter Repo Name"
                            />
                            {validationErrors.repoName && <span style={{ color: "red" }}>{validationErrors.repoName}</span>}
                        </div>
                        <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                            <label htmlFor="Branch-Name" className="form-label text-black font-14 font-medium">
                                Branch Name <span className="text-red"> *</span>
                            </label>
                            <input
                                type="text"
                                className="form-control cust-input-sty font-14 font-regular"
                                id="Branch-Name"
                                onChange={(e) => handleInputChange("branchName", e.target.value)}
                                value={targetSystemDetails?.branchName || ""}
                                disabled={isDisabled}
                                placeholder="Enter Branch Name"
                            />
                            {validationErrors.branchName && <span style={{ color: "red" }}>{validationErrors.branchName}</span>}
                        </div>
                        <div className="col-lg-4 col-sm-12 mt-3 mt-lg-0">
                            <label htmlFor="Access-Token" className="form-label text-black font-14 font-medium">
                                Access Token <span className="text-red"> *</span>
                            </label>
                            <input
                                type="text"
                                className="form-control cust-input-sty font-14 font-regular"
                                id="Access-Token"
                                onChange={(e) => handleInputChange("accessToken", e.target.value)}
                                disabled={isDisabled}
                                value={targetSystemDetails?.accessToken || ""}
                                placeholder="Enter Access Token"
                            />
                            {validationErrors.accessToken && <span style={{ color: "red" }}>{validationErrors.accessToken}</span>}
                        </div>
                        <div className="modal-footer border-0 px-3 mb-4 mt-4">
                            <button
                                type="button"
                                className="btn cust-secondary-btn font-14 font-medium me-3"
                                // data-bs-dismiss="modal"
                                onClick={() => {setTargetSystemDetails({})
                                props.closePopup()
                                }
                            }
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                id="save"
                                // data-bs-dismiss="modal"
                                className="btn cust-primary-btn font-14 font-medium"
                                onClick={submitDetails}
                                disabled={isDisabled}
                            >
                                <span>Save & Proceed</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeployPBIPCode;
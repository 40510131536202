import React, { useState, useEffect } from 'react';
import Select from 'react-select';
// import './ACDynamicGrid.css';  // Import the CSS file

const ACDynamicGrid = ({ data, settings, setGridData, actualValue, setWhetherUpdated, onEdit, onAdd, onDelete, view}) => {
    const [editingIndex, setEditingIndex] = useState(null);
    const [newRow, setNewRow] = useState({});
    const [sortOrder, setSortOrder] = useState({ column: null, direction: 'asc' });
    const [editedRow, setEditedRow] = useState({});
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        setGridData(data);
    }, [data, setGridData]);

    const renderTableHeader = () => {
        return settings.TableConfig.map((column, index) => (
            <th key={index}>
                {column.header}
                {column.sortable && (
                    <span className="ms-2 cursor-pointer" onClick={() => handleSort(column.key)}>
                        <img  
                            src={sortOrder.column === column.key ? 
                                (sortOrder.direction === 'asc' ? "images/sort-up-icon.svg" : "images/sort-down-icon.svg") 
                                : "images/sort-down-icon.svg"} 
                            alt="sort" 
                        />
                    </span>
                )}
            </th>
        ));
    };

    const renderTableBody = () => {
        if (data.length === 0) {
            return (
                <tr>
                    <td colSpan={settings.TableConfig.length + 1} className="text-center">
                        No Records Found
                    </td>
                </tr>
            );
        }
    
        return data.map((row, rowIndex) => (
            <tr key={rowIndex}>
                {settings.TableConfig.map((column, colIndex) => (
                    <td key={colIndex}>
                        {editingIndex === rowIndex ? renderEditableCell(column, row) : row[column.key]}
                    </td>
                ))}
                <td className={`fixed-column-cell ${rowIndex%2!=0?"grid-fixed-td-color-white":"grid-fixed-td-color"}`} style={view==2?{width:"25px"}:{}}>
                    {editingIndex === rowIndex ? (
                        <>
                            <img
                                src="images/right.svg"
                                alt="save-icon"
                                className={`cust-cursor-pointer me-3 ${isEdited ? '' : 'disabled'}`}
                                title="save"
                                onClick={() => isEdited && handleSave(rowIndex)}
                            />
                            <img
                                src="images/cross-red.svg"
                                alt="cancel-icon"
                                className="cust-cursor-pointer me-3"
                                title="cancel"
                                onClick={handleCancelEdit}
                            />
                        </>
                    ) : (
                        <>
                            <img
                                src="images/edit-icon.svg"
                                alt="edit"
                                className="cust-cursor-pointer me-3"
                                title="edit"
                                onClick={() => handleEdit(rowIndex)}
                            />
                            <img
                                src="images/delete-icon.svg"
                                alt="delete"
                                className="cust-cursor-pointer"
                                title="delete"
                                onClick={() => onDelete(row)}
                            />
                        </>
                    )}
                </td>
            </tr>
        ));
    };

    const renderEditableCell = (column) => {
        if (column.deFaultRow === 'dropdown') {
            return (
                <Select
                    isMulti
                    value={(editedRow[column.key] || '').split(', ').filter(val => val).map(val => ({ label: val.trim(), value: val.trim() }))}
                    options={settings.deFaultRow.find(item => item.key === column.key)?.values.map(value => ({ label: value, value }))}
                    onChange={(selectedOptions) => handleEditChange(column.key, selectedOptions.map(option => option.value).join(', '))}
                    className="font-14 font-regular"
                    closeMenuOnSelect={false} // Ensure the menu stays open after selecting an option
                />
            );
        } else {
            return (
                <input 
                    type="text" 
                    className="form-control cust-input-sty font-14 rounded-3 font-regular" 
                    value={editedRow[column.key] || ''}
                    onChange={(e) => handleEditChange(column.key, e.target.value)}
                />
            );
        }
    };

    const renderDefaultRow = () => {

        const allFieldsFilled = settings.TableConfig.every(column => {
            if (column.deFaultRow === 'input' || column.deFaultRow === 'dropdown' || column.deFaultRow === 'datetime' || column.deFaultRow === 'viewcode') {
                return newRow[column.key] && newRow[column.key].trim() !== '';
            }
            return true;
        });
     
        return (
            <tr>
                {settings.TableConfig.map((column, index) => (
                    <td key={index}>
                        {column.deFaultRow === 'input' && (
                            <input 
                                type="text" 
                                className="form-control cust-input-sty font-14 rounded-3 font-regular" 
                                placeholder="Enter" 
                                onChange={(e) => handleNewRowChange(column.key, e.target.value)}
                                value={newRow[column.key] || ''}
                            />
                        )}
                        {column.deFaultRow === 'dropdown' && (
                            <Select
                                isMulti
                                value={(newRow[column.key] || '').split(', ').filter(val => val).map(val => ({ label: val.trim(), value: val.trim() }))}
                                options={settings.deFaultRow.find(item => item.key === column.key)?.values.map(value => ({ label: value, value }))}
                                onChange={(selectedOptions) => handleNewRowChange(column.key, selectedOptions.map(option => option.value).join(', '))}
                                className="font-14 font-regular"
                                closeMenuOnSelect={false} // Ensure the menu stays open after selecting an option
                            />
                        )}
                    </td>
                ))}
                <td className={`fixed-column-cell grid-fixed-td-color-white`}>
                    <img
                        src="images/add-icons.svg"
                        alt="add"
                        className="cust-cursor-pointer"
                        onClick={allFieldsFilled ? handleAdd : null}
                        style={{
                            opacity: allFieldsFilled ? 1 : 0.3,
                            cursor: allFieldsFilled ? 'pointer' : 'not-allowed'
                        }}
                    />
                </td>

                
            </tr>
        );
    };

    const handleNewRowChange = (key, value) => {
        setNewRow(prev => ({ ...prev, [key]: value }));
    };

    // const handleAdd = () => {
    //     debugger
    //     if (Object.values(newRow).every(value => value)) {
    //         onAdd(newRow);
    //         setNewRow({});
    //     }
    // };

    const handleAdd = () => {
        const requiredFields = settings.TableConfig.filter(column =>
            column.deFaultRow === 'input' || column.deFaultRow === 'dropdown'||column.deFaultRow === 'viewcode'
        ).map(column => column.key);

        const allFieldsFilled = requiredFields.every(field => newRow[field]);
        if (allFieldsFilled) {
            onAdd(newRow);
            setNewRow({});
        } else {
            console.log('Please fill in all fields before adding.');
        }
    };

    const handleEdit = (index) => {
        setEditingIndex(index);
        setEditedRow(data[index]);
        setIsEdited(false);
    };

    const handleEditChange = (key, value) => {
        const updatedRow = { ...editedRow, [key]: value };
        setEditedRow(updatedRow);

        // Check if any field has been edited and is not empty
        const isAnyFieldEdited = Object.keys(updatedRow).some(k => 
            updatedRow[k] !== data[editingIndex][k] && updatedRow[k] !== ''
        );
        // onEdit=isAnyFieldEdited
        setIsEdited(isAnyFieldEdited);
    };

    const handleSave = (index) => {
        if (isEdited) {
            onEdit(editedRow,index);
        }
        setEditingIndex(null);
        setEditedRow({});
        setIsEdited(false);
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
        setEditedRow({});
        setIsEdited(false);
    };

    const handleSort = (columnKey) => {
        const direction = sortOrder.column === columnKey && sortOrder.direction === 'asc' ? 'desc' : 'asc';
        setSortOrder({ column: columnKey, direction });
    
        setGridData(prev => [...prev].sort((a, b) => {
            if (typeof a[columnKey] === 'string' && typeof b[columnKey] === 'string') {
                return direction === 'asc' ? a[columnKey].localeCompare(b[columnKey]) : b[columnKey].localeCompare(a[columnKey]);
            } else {
                if (a[columnKey] < b[columnKey]) return direction === 'asc' ? -1 : 1;
                if (a[columnKey] > b[columnKey]) return direction === 'asc' ? 1 : -1;
                return 0;
            }
        }));
    };

    return (
        <div className="table-wrapper">
            <div className="table-responsive rounded-3">
                <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                    <thead className="sticky-top-pos">
                        <tr>
                            {renderTableHeader()}
                            <th className="text-center action-column">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderDefaultRow()}
                        {renderTableBody()}
                    </tbody>
                </table>
            </div>
            <p className="font-regular font-14 text-grey mt-3">
                # of Records: <span className="text-black font-medium">{data.length} out of {actualValue.length}</span>
            </p>
        </div>
    );
};

export default ACDynamicGrid;
// perfect 12345567890
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, updatePseudocodeData, insertError } from "../../../Service/Api.js";
import { HeaderCom } from "../../Header.jsx";
import DynamicGrid from './DynamicTable.jsx';
import SearchComponent from './SearchComponent.jsx';
import FilterComponent from './FilterComponent.jsx';
import DownloadComponent from './DownloadComponent.jsx';
import ReasonComponent from './ReasonComponent.jsx';
import ViewInstructions from "./viewInstructions.jsx";

function CodeReviewPseudo(props) {

    const { state } = useLocation();
    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);
    const [actualValue, setActualValue] = useState([]);
    const [error, setError] = useState(null);
    const [whetherUpdated, setWhetherUpdated] = useState(false);

    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [currentOperation, setCurrentOperation] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [newRow, setNewRow] = useState({});
    const [resultData, setresultData] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };



    function extractUniqueDataWithDetails(dataArray) {
        // Using a map to store unique items with checklist IDs and unique NotebookNames
        const uniqueMap = new Map();

        dataArray.forEach(item => {
            const key = `${item.DesignID}-${item.CheckListStep}`;

            if (!uniqueMap.has(key)) {
                // If the combination of DesignID and CheckListStep is not in the map, add it
                uniqueMap.set(key, {
                    DesignID: item.DesignID,
                    CommandPurpose: item.CommandPurpose,
                    CheckListStep: item.CheckListStep,
                    Status: item.Status,
                    comments: item.comments,
                    checklistIds: [item.codeReviewChecklistId], // Initialize checklistIds as an array
                    notebookNames: new Set([item.NotebookName]) // Use a Set to ensure uniqueness
                });
            } else {
                // If the combination exists, add the checklist ID and notebook name to their respective collections
                const existingEntry = uniqueMap.get(key);
                existingEntry.checklistIds.push(item.codeReviewChecklistId);
                existingEntry.notebookNames.add(item.NotebookName); // Add to Set, automatically ensures uniqueness
            }
        });

        // Convert map values to a list and transform Sets to Arrays for the final output
        const resultList = Array.from(uniqueMap.values()).map(entry => ({
            ...entry,
            notebookNames: Array.from(entry.notebookNames) // Convert Set back to Array
        }));

        // Sort results by DesignID, assuming DesignID follows a sortable pattern like "Step 1", "Step 2", etc.
        resultList.sort((a, b) => {
            const extractStepNumber = designID => parseInt(designID.replace(/\D/g, ''), 10); // Extract numerical part
            return extractStepNumber(a.DesignID) - extractStepNumber(b.DesignID);
        });

        return resultList;
    }
    const handleEdit = (item) => {
        setCurrentOperation('edit');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    const handleAdd = (newItem) => {
        setCurrentOperation('add');
        setCurrentItem(newItem);
        setShowReasonModal(true);
    };

    const handleDelete = (item) => {
        setCurrentOperation('delete');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    const handleReasonSave = async () => {
        try {
            console.log('Current operation:', currentOperation, gridData);
            console.log('Current item:', currentItem);

            let updatedData;
            let updatedData1;
            debugger;

            switch (currentOperation) {
                case 'edit':
                    updatedData = gridData.map((item, idx) =>
                        item.checklistIds[0] === currentItem.checklistIds[0] ? { ...currentItem, reason: reason } : item
                    );
                    break;

                case 'add':
                    updatedData = [
                        ...gridData,
                        { ...currentItem, reason: reason, index: gridData.length }
                    ];
                    updatedData1 = [
                        { ...currentItem, reason: reason }
                    ];
                    break;

                case 'delete':
                    // Remove the item with the current index, then reassign indices
                    updatedData = gridData.filter(item => item.checklistIds[0] !== currentItem.checklistIds[0]);
                    updatedData1 = currentItem.checklistIds
                    break;

                default:
                    throw new Error('Invalid operation');
            }

            console.log('Updated data:', updatedData);
            let dataToUpdate;
            if(currentOperation=="edit")
            {
                dataToUpdate=updatedData
            }
            else{
                dataToUpdate=updatedData1
            }
            // Assumption: passdata contains your transformed data for backend operation
            let passdata = {
                projectId: state.projectId,
                type: "codeReviewPseudoUC",
                updateData: dataToUpdate,
                operations: currentOperation,
                userId: state.userId,
            };

            // Trigger backend update operation
            document.getElementById('pageLoader').style.display = 'block';
            await updatePseudocodeData(passdata, state.jwt);
            await fetchPseudo()
            // Update local state post-backend operation
            document.getElementById('pageLoader').style.display = 'none';
            setGridData(updatedData);
            setActualValue(updatedData);
            setShowReasonModal(false);
            setReason('');
            setCurrentOperation(null);
            setCurrentItem(null);
            setWhetherUpdated(true);

        } catch (error) {
            console.error('Error updating data:', error);
        }
    };


    const [settings, setSettings] = useState({
        TableConfig: [
            { key: 'DesignID', header: 'DesignID', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'CommandPurpose', header: 'CommandPurpose', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'CheckListStep', header: 'ChecklistStep', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'Status', header: 'Status', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'comments', header: 'Comments', sortable: true, type: 'normal', deFaultRow: 'input' },
        ],
        ActionItems: { add: true, delete: true, edit: true },
        deFaultRow: []

    });

    const searchColumns = ['Status'];

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchPseudo()]);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            }
        };
        fetchData();
    }, []);


    const fetchPseudo = async () => {
        document.getElementById('pageLoader').style.display = "block";
        const body = {
            projectId: state.projectId,
            type: "ucchecklist",
            userId: state.userId,
            orgId: state.orgId,
        };

        try {
            const res = await fetchPseudocodeData(body, state.jwt);
            console.log(res, "response from DB")
            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                console.log("The Response Is 200");
                let resultData = res?.data;
                // setresultData(resultData)
                console.log(resultData, 'fetchJsonFromBlobfetchJsonFromBlob');
                try {
                    document.getElementById('pageLoader').style.display = "none";

                    if (resultData) {
                        let pseudo = resultData.map((obj, index) => ({
                            ...obj,
                            index: index
                        }));
                        let formatData = extractUniqueDataWithDetails(pseudo)
                        setGridData(formatData);
                        setActualValue(formatData);
                        console.log(formatData, "Mapped data");
                    } else {
                        throw new Error("Failed to fetch data from Azure Blob Storage");
                    }
                } catch (e) {
                    console.error('Error fetching JSON from blob:', e);
                    handleError(e);
                }
            }
        } catch (e) {
            console.error('Error fetching pseudocode data:', e);
            handleError(e);
        }
    }

    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };


    const handleError = (e) => {
        insertError({
            errorMessage: e.message,
            serviceName: "frontend",
            module: "Datadictionary",
            functionName: "DataDictionaryTable",
            userId: state.userId
        });
        console.log("error", e);
        setError(e.message);
    }


    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                    <div className="d-flex">
                        <div className="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                            </span>
                            <span className="mx-3 font-14 loader-text">Session has expired. Please login Again</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center " role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                Oops! Something went wrong
            </button>
            <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                <p className="font-14 font-regular text-black">Please try again later</p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <HeaderCom value="1" />
            <div className="col-md-12 pt-4 mt-5">
                <div className="mt-3 px-2">
                    <ul className="cust-breadcrumb mb-0">
                        <li className="font-16 font-medium">
                            <a>Manage Projects</a>
                        </li>
                        <li className="font-16 font-medium">
                            <a>{state.projectName}</a>
                        </li>
                        <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                    </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between p-4">
                    <div className="d-flex">
                        <a className="d-flex">
                            <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '35px', cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
                        </a>
                        <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}
                            <button type="button" onClick={handleOpenModal} className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3">
                                <img src="images/info-icon-ETL.svg" className alt="info" />
                            </button></h2>
                        {isModalOpen && <ViewInstructions notebooktype='codereviewdesign' onClose={handleCloseModal} />}
                    </div>
                    <div className="d-flex">
                        <SearchComponent
                            columns={searchColumns}
                            actualValue={actualValue}
                            setGridData={setGridData}
                        />
                        {/* <FilterComponent
                            columns={settings.TableConfig.map(config => config.key)}
                            actualValue={actualValue}
                            setGridData={setGridData}
                        /> */}
                        <DownloadComponent
                            type="excel"
                            data={actualValue}
                            fileName="CodeReviewPseudo"
                        />
                    </div>
                </div>
                <DynamicGrid
                    data={gridData}
                    settings={settings}
                    setGridData={setGridData}
                    actualValue={actualValue}
                    setWhetherUpdated={setWhetherUpdated}
                    onEdit={handleEdit}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    newRow={newRow}
                    setNewRow={setNewRow}
                />
                <ReasonComponent
                    isOpen={showReasonModal}
                    onClose={() => setShowReasonModal(false)}
                    onSave={handleReasonSave}
                    reason={reason}
                    setReason={setReason}
                />
            </div>
        </div>
    );
}

export default CodeReviewPseudo;
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { fetchJsonFromURL } from './BlobStorageService';

const DynamicFileUploadPopup = ({ validationColumns, text, saveFunction, closePopup, templateURL, chatHistory, fileUploaded, setImportUpload }) => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(text.FileName || '');
    const [errorMessages, setErrorMessages] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [show, setShow] = useState(true);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    

    useEffect(() => {
        const existingFileName = text.FileName;
        if (existingFileName) {
            setFileName(existingFileName);
        }
    }, [chatHistory, text]);

    useEffect(() => {
        const body = document.body;
        const backdrop = document.createElement('div');
        backdrop.className = 'modal-backdrop fade';
        if (show) {
            body.appendChild(backdrop);
            setTimeout(() => {
                backdrop.classList.add('show');
                body.classList.add('modal-open');
            }, 0);
        } else {
            const existingBackdrop = document.querySelector('.modal-backdrop');
            if (existingBackdrop) {
                existingBackdrop.classList.remove('show');
                setTimeout(() => {
                    if (existingBackdrop.parentNode) {
                        existingBackdrop.parentNode.removeChild(existingBackdrop);
                    }
                }, 150);
            }
            body.classList.remove('modal-open');
        }

        return () => {
            const existingBackdrop = document.querySelector('.modal-backdrop');
            if (existingBackdrop) {
                existingBackdrop.parentNode.removeChild(existingBackdrop);
            }
            body.classList.remove('modal-open');
        };
    }, [show]);

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            await validateFile(selectedFile);
        } else {
            setErrorMessages(['Invalid file format. Please upload a .xlsx file.']);
            setFile(null);
            setFileName('');
            setIsValid(false);
        }
    };

    const validateFile = async (file) => {
        const errors = [];

        try {
            const data = await readExcelFile(file);

            const requiredSheets = Object.keys(validationColumns);
            const missingSheets = requiredSheets.filter(sheet => !data[sheet]);
            if (missingSheets.length > 0) {
                errors.push(`Missing mandatory sheets: ${missingSheets.join(', ')}`);
            }

            requiredSheets.forEach(sheetName => {
                if (data[sheetName]) {
                    const sheetData = data[sheetName];
                    const sheetValidations = validationColumns[sheetName];

                    const headerRow = sheetData[0];
                    sheetValidations.forEach((validation, index) => {
                        const normalizedHeader = normalizeHeader(headerRow[index]);
                        const expectedHeader = normalizeHeader(validation.columnHeader);
                        if (normalizedHeader !== expectedHeader) {
                            errors.push(`Header Field '${validation.columnHeader}' is missing or incorrect in cell ${validation.columnCell} of ${sheetName}`);
                        }
                    });

                    sheetData.forEach((row, rowIndex) => {
                        if (rowIndex === 0) return; // Skip header row
                        sheetValidations.forEach((validation, columnIndex) => {
                            const cellValue = row[columnIndex];

                            if (validation.isNull === 0 && validation.NA === 0 && validation.isEmpty === 0) {
                                if (!cellValue || cellValue.toString().trim() === '' || cellValue === 'NA') {
                                    errors.push(`${validation.columnHeader} value should not be empty, NA, or null in ${sheetName}, row ${rowIndex + 1}`);
                                }
                            } else if (validation.isNull === 0 && (cellValue === null || cellValue === undefined)) {
                                errors.push(`${validation.columnHeader} value should not be null in ${sheetName}, row ${rowIndex + 1}`);
                            } else if (validation.NA === 0 && cellValue === 'NA') {
                                errors.push(`${validation.columnHeader} value should not be NA in ${sheetName}, row ${rowIndex + 1}`);
                            } else if (validation.isEmpty === 0 && cellValue.toString().trim() === '') {
                                errors.push(`${validation.columnHeader} value should not be empty in ${sheetName}, row ${rowIndex + 1}`);
                            }

                            if (validation.valueValidation) {
                                const validValues = Array.isArray(validation.valueValidation) ? validation.valueValidation : [validation.valueValidation];
                                const normalizedCellValue = cellValue ? cellValue.toString().trim().toLowerCase() : '';

                                const isValidValue = validValues.map(v => v.toLowerCase()).some(v => normalizedCellValue.startsWith(v));
                                if (!isValidValue) {
                                    errors.push(`${validation.columnHeader} value should start with one of ${validValues.join(', ')} in ${sheetName}, row ${rowIndex + 1}`);
                                }

                                if (validValues.includes("PathValidation")) {
                                    const filteredValidValues = validValues.filter(v => v !== "PathValidation");

                                    const includesValidValue = filteredValidValues.some(v => normalizedCellValue.startsWith(v.toLowerCase()));
                                    if (!includesValidValue) {
                                        errors.push(`${validation.columnHeader} value must start with one of ${filteredValidValues.join(', ')} when PathValidation is specified in ${sheetName}, row ${rowIndex + 1}`);
                                    }
                                }
                            }

                            const versionHistoryIdx = sheetValidations.findIndex(v => v.columnHeader === 'VersionHistory');
                            if (versionHistoryIdx >= 0 && validation.columnHeader === 'LoadType') {
                                const versionHistoryValue = row[versionHistoryIdx];
                                if (versionHistoryValue && versionHistoryValue.toString().trim().toLowerCase() === 'yes') {
                                    if (!cellValue || cellValue.toString().trim() === '' || cellValue === 'NA') {
                                        errors.push(`LoadType value should not be empty, NA, or null when VersionHistory is 'Yes' in ${sheetName}, row ${rowIndex + 1}`);
                                    } else {
                                        const validLoadTypes = ["CTAS", "Deep Clone", "Config and Sync"];
                                        const normalizedLoadTypeValue = cellValue ? cellValue.toString().trim().toLowerCase() : '';
                                        const isValidLoadType = validLoadTypes.map(v => v.toLowerCase()).includes(normalizedLoadTypeValue);
                                        if (!isValidLoadType) {
                                            errors.push(`LoadType value should be one of ${validLoadTypes.join(', ')} when VersionHistory is 'Yes' in ${sheetName}, row ${rowIndex + 1}`);
                                        }
                                    }
                                }
                            }
                        });
                    });
                }
            });

        } catch (error) {
            errors.push('Error reading file: ' + error.message);
        }

        setErrorMessages(errors);
        setIsValid(errors.length === 0);
    };

    const normalizeHeader = (header) => {
        return header.replace(/\s+/g, '').toLowerCase();
    };

    const readExcelFile = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const result = {};
                workbook.SheetNames.forEach(sheetName => {
                    result[sheetName] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
                });
                resolve(result);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    };

    const handleSave = async () => {
        if (!file || !isValid) {
            alert("Please select a valid file before saving.");
            return;
        }
        setIsSaveClicked(true)
        try {
            await saveFunction(file, text.popupText);
            closePopup();
        } catch (error) {
            console.error("Upload failed:", error);
            alert("Failed to upload file.");
        }
        setFile(null);
    };

    const handleCancel = () => {
        if(setImportUpload){
            setImportUpload(false)
        }
        setShow(false)
        setFile(null);
        setFileName('');
        setErrorMessages([]);
        setIsValid(false);
        closePopup();
    };

    const fetchTemplate = async () => {
        try {
            const tempURL = templateURL;
            console.log(tempURL, 'tempURLtempURLtempURL');

            const fetchedData = await fetchJsonFromURL(tempURL);

            let parsedData = fetchedData;
            if (typeof fetchedData === 'string') {
                try {
                    parsedData = JSON.parse(fetchedData);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            }

            if (parsedData) {
                const wb = XLSX.utils.book_new();
                for (const [key, value] of Object.entries(parsedData)) {
                    const ws = XLSX.utils.json_to_sheet(value);
                    XLSX.utils.book_append_sheet(wb, ws, key);
                }

                XLSX.writeFile(wb, 'SampleData.xlsx');
            }
        } catch (error) {
            console.error("Error exporting to Excel:", error);
        }
    };

    return (
        <div
            className={`modal fade${show ? ' show' : ''}`}
            id="SourceSys"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="SourceSysLabel"
            aria-modal="true"
            role="dialog"
            style={{ display: show ? 'block' : 'none' }}
        >
            <div className="modal-dialog modal-xl">
                <div className="modal-content px-2">
                    <div className="modal-header border-0">
                        <h5
                            className="modal-title d-flex align-items-center font-medium font-20"
                            id="SourceSysLabel"
                        >
                            {text.popupText}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={handleCancel}
                        />
                    </div>
                    <div className="modal-body py-0 pb-4">
                        <div className="col-md-12 col-sm-12 mb-3">
                            <label className="form-label text-black font-14 font-medium">
                                Upload Data<span className="text-red">*</span>
                            </label>
                            <span className="upload-btn-wrapper d-block ">
                                {!fileName ? (
                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                        <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                        <span className="font-medium font-13 d-block text-grey mb-2">Browse or drag and drop file</span>
                                        <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                            Use this template to add info -{" "}
                                            <a onClick={fetchTemplate} className="primary-color font-medium cursor-pointer text-decoration-none down-templ-sty">
                                                Download Template
                                            </a>{" "}
                                        </span>
                                    </button>
                                ) : (
                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                        <img src="images/sample-logo.svg" alt="sample-logo" className="mb-2" />
                                        <a className="font-medium font-12 d-block">{fileName}</a>
                                        <a
                                            style={{ position: "relative", zIndex: "100" }}
                                            onClick={() => {
                                                setFile(null);
                                                setErrorMessages([]);
                                                setIsValid(false);
                                                setFileName('');
                                                document.getElementById('definedocumentid').value = ''
                                            }}
                                        >
                                            <span className="ms-2">
                                                <img src="images/link-close-icon.svg" alt="link-close-icon" />
                                            </span>
                                        </a>
                                    </button>
                                )}
                                <input
                                    type="file"
                                    name="myfile"
                                    className="cursor-pointer cust-file"
                                    accept=".xlsx"
                                    id="definedocumentid"
                                    onChange={handleFileChange}
                                    disabled={fileUploaded !== '' && fileUploaded !== undefined  }
                                />
                            </span>
                            {errorMessages.length > 0 && (
                                <div className="code-doc-error">
                                    {errorMessages.map((message, index) => (
                                        <span className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section" key={index}>
                                            <img src="images/warning-red-icon.svg" alt="warning icon" />
                                            <span className="ms-2">{message}</span>
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer border-0 mb-4 mt-2 mx-3 d-flex p-0">
                        <button
                            type="button"
                            className="btn cust-secondary-btn font-14 font-medium me-3"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn cust-primary-btn font-14 font-medium m-0"
                            onClick={handleSave}
                            disabled={!isValid || !file || isSaveClicked}
                        >
                            Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default DynamicFileUploadPopup;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ACDynamicGrid from './Integrations/ACDynamicTable'; // Adjust the import path as necessary
import { fetchUserDetail } from '../../Service/Api';
import SearchComponent from './Integrations/SearchComponent';
import { HeaderCom } from '../Header';
import ReasonComponent from './Integrations/ReasonComponent';

const UserDetailsUpload = () => {
    const navigate = useNavigate();
    var { state } = useLocation();
    const [selectedFileName, setSelectedFileName] = useState('');
    const [userDetails, setUserDetails] = useState([]);
    const [actualValue, setActualValue] = useState([]);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState({ field: 'Username', direction: 'asc' });
    const [validationErrors, setValidationErrors] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [showUploadView, setShowUploadView] = useState(false);
    const [allRecord, setAllRecord] = useState(false);
    const [currentOperation, setCurrentOperation] = useState('');
    const [currentItem, setCurrentItem] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(false);
        const [enable, setEnable] = useState(true)
    
    useEffect(() => {
        fetchUserDetails();
    }, []);

    let stateValue = { ...state }
    console.log(stateValue, 'stateValue');

    const fetchUserDetails = async () => {
        let body = {
            projectId: state.projectId,
            generationtype: "FetchUsers",
            jwt_token: state.jwt,
            userId: state.userId,
        };
        document.getElementById('pageLoader').style.display = 'block';
        const data = await fetchUserDetail(body, state.jwt);
        console.log(data, "+++++++++++");
        setUserDetails(...userDetails, data);
        setActualValue(data);
        document.getElementById('pageLoader').style.display = 'none';
    };

    const searchColumns = ['Username'];

 
    const validateExcelData = (data) => {
            const errors = [];
            const isValid = data.every((row, index) => {
       
                // Check if Username and Email are provided
                if (!row.Username || !row["Email"]) {
                    errors.push(`Row ${index + 2}: Username and Email are required`);
                    return false;
                }
         
                // Check for invalid email format
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(row["Email"])) {
                    errors.push(`Row ${index + 2}: Invalid email format`);
                    return false;
                }
     
                // Check for "na", "NA", or "Na" in Username and Email
                const invalidValuePattern = /^(na|NA|Na)$/;
                if (invalidValuePattern.test(row.Username) || invalidValuePattern.test(row["Email"])) {
                    errors.push(`Row ${index + 2}: Username and Email value cannot be 'NA'`);
                    return false;
                }
                return true;
            });
            return { valid: isValid, errors };
        };
     

    const mapExcelData = (data) => {
        return data?.map(row => ({
            Username: row.Username,
            Email: row.Email
        }));
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
   
        setSelectedFileName(file.name);
        setFileUploaded(true);
        try {
            const data = await readExcelFile(file);
            const { valid, errors } = validateExcelData(data);
   
            if (!valid) {
                setValidationErrors(errors);
                setIsFormValid(false);
                return;
            }
   
            // Check for duplicate usernames in current grid
            const currentUsernames = new Set(userDetails.map(user => user.Username.toLowerCase()));
            const duplicateErrors = [];
            data.forEach((row, index) => {
                if (currentUsernames.has(row.Username.toLowerCase())) {
                    duplicateErrors.push(`Row ${index + 2}: Duplicate Username "${row.Username}" already exists`);
                }
            });
   
            if (duplicateErrors.length > 0) {
                setValidationErrors(duplicateErrors);
                setIsFormValid(false);
                return;
            }
   
            const mappedData = mapExcelData(data);
            setAllRecord(mappedData);
            setIsFormValid(true);
            setValidationErrors([]);
        } catch (error) {
            console.error("Error reading excel file:", error);
            setValidationErrors([`Error reading file: ${error.message}`]);
            setIsFormValid(false);
        }
    };
   

    const readExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    if (sheetName !== 'UserDetails') {
                        reject(new Error("Sheet name must be 'UserDetails'"));
                    }
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet);

                    console.log("Parsed Excel JSON Data:", jsonData);

                    resolve(jsonData);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => reject(error);
            reader.readAsBinaryString(file);
        });
    };

    const handleSubmit = async () => {
        if (!isFormValid) return;

        try {
            document.getElementById('pageLoader').style.display = 'block';
    
            const postData = [...userDetails, ...allRecord]; // Spread allRecord properly
            setUserDetails(postData);
    
            const body = {
                projectId:state.projectId,
                generationtype: "PostUser",
                users: postData,
                jwt_token:state.jwt,
                userId:state.userId
            };
    
            const data = await fetchUserDetail(body, state.jwt);
            document.getElementById('pageLoader').style.display = 'none';
            setShowUploadView(false); // Switch back to table view after upload
        } catch (error) {
            console.error("Error submitting user details:", error);
        }
    };
    const handleAddUser = async (newRow) => {
        const errors = [];
        let isValid = true;
    
        if (!newRow.Username) {
            errors.push("Username is required.");
            isValid = false;
        }
    
        // Check for duplicate username
        if (userDetails.some(user => user.Username.toLowerCase() === newRow.Username.toLowerCase())) {
            errors.push(`Duplicate Username "${newRow.Username}" already exists.`);
            isValid = false;
        }
    
        // Check for email validity (uncomment the below lines if you want to validate email as well)
        // if (!newRow.Email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newRow.Email)) {
        //     errors.push("A valid email is required.");
        //     isValid = false;
        // }
    
        if (!isValid) {
            setValidationErrors(errors);
            return;
        }
    
        setValidationErrors([]); // Clear previous errors
        let postData = [...userDetails, newRow];
        setUserDetails(postData);
        let body = {
            projectId: state.projectId,
            generationtype: "PostUser",
            users: postData,
            userId: state.userId,
            jwt_token: state.jwt
        };
        const data = await fetchUserDetail(body, state.jwt);
    };
    const handleEditUser = (updatedUser, index) => {
        setShowReasonModal(true);setCurrentOperation('edit');;setCurrentItem({ updatedUser, index });
    };

    const handleDeleteUser = (row) => {
        setShowReasonModal(true);setCurrentOperation('delete');;setCurrentItem(row);
    };

    const exportTemplate = () => {
        const ws = XLSX.utils.json_to_sheet([
            { Username: 'JohnDoe', Email: 'john.doe@example.com' },
            { Username: 'JaneSmith', Email: 'jane.smith@example.com' }
        ]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "UserDetails");
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, 'UserDetailsTemplate.xlsx');
    };

    const settings = {
        TableConfig: [
            { header: 'Username', key: 'Username', sortable: true, deFaultRow: 'input' },
            { header: 'Email', key: 'Email', sortable: true, deFaultRow: 'input' }
        ],
        deFaultRow: []
    };

    const handleReasonSave = async () => {
        let updatedData = [];

        try {
            if (currentOperation === 'edit') {
                const { updatedUser, index } = currentItem;
                updatedData = userDetails.map((user, i) => 
                    i === index ? { ...updatedUser, reason } : user
                );
            } else if (currentOperation === 'delete') {
                updatedData = userDetails.filter(user => user.Username !== currentItem.Username);
                setReason(reason);

            }

            const body = {
                projectId: state.projectId,
                generationtype: "PostUser",
                users: updatedData,
                userId: state.userId,
                jwt_token: state.jwt
            };

            await fetchUserDetail(body, state.jwt);

            setUserDetails(updatedData);
            setActualValue(updatedData);
            setShowReasonModal(false);
            setReason('');
            setCurrentOperation('');
            setCurrentItem(null);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    return (
        <div className="container-fluid">
            <HeaderCom value='1' />          
            <div className="col-md-12 pt-4 mt-5">
                <div className="mt-3">
                    <ul className="cust-breadcrumb mb-0">
                        <li className="font-12 font-medium"><a>Manage Projects</a></li>
                        <li className="font-12 font-medium active">{state.projectName}</li>
                    </ul>
                </div>
                <div className="row p-4">
                    <div className="d-flex mb-4 justify-content-between align-items-center">
                        <div className="d-flex align-items-center mb-xl-0">
                            <a className="d-flex" onClick={() => navigate('/uc-chat', { state: stateValue })}>
                                <img src="images/back-arrow.svg" alt="back-arrow" />
                            </a>
                            <h2 className="text-black font-bold font-22 mb-0 ms-3">{state.projectName}</h2>
                        </div>
                        <div className="d-lg-flex d-md-block">
                            <div className="d-flex mb-md-6 mb-sm-6">
                                <div className="cust-input-sty font-11 rounded-2 font-medium text-grey-v7 px-3 me-3 d-flex align-items-center">
                                    <span className="text-nowrap">Organization :</span>
                                    <span><img src={state.orgLogo} alt="hubspot-logo" className="figma-logo"/></span>
                                    <span className="me-2 text-nowrap font-medium font-12 text-black">{state.orgName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="code-review-tab-width">
                        <div className="code-review-page tabs nav">
                        <div className="tab nav-link font-bold" data-bs-toggle="pill" data-bs-target="#project-info">
                                <a
                                        onClick={() => {
                                            navigate("/ProjectInfo", {
                                                state: stateValue,
                                            });
                                        }}
                                    >
                                    Project Info
                                    </a>
                                    </div>
                                <div className="tab nav-link font-bold" data-bs-toggle="pill" data-bs-target="#ask-gen">
                                    <a
                                        onClick={() => {
                                            navigate("/uc-chat", {
                                                state: stateValue, // Corrected the syntax here
                                            });
                                        }}
                                    >
                                        Ask GenETL
                                    </a>
                                </div>
                            <div className="tab nav-link font-bold active" data-bs-toggle="pill" data-bs-target="#acc-control">Access Control</div>
                        </div>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div id="acc-control" className="tab-pane fade show active code-review-tab tab-content">
                            <div className="row mt-5">
                                <div className="col-md-2">
                                    <div className="tab-container">
                                        <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow">
                                            <li className="side-nav-item d-flex align-items-center active" onClick={() => navigate('/User', { state: stateValue })}>
                                                <span>User Details</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/Roles', { state: stateValue })}>
                                                <span>Roles</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/GroupList', { state: stateValue })}>
                                                <span>Groups</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/Script', { state: stateValue })}>
                                                <span>Scripts</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                <ReasonComponent
                                    isOpen={showReasonModal}
                                    onClose={() => setShowReasonModal(false)}
                                    onSave={handleReasonSave}
                                    reason={reason}
                                    setReason={setReason}
                                />
            {showUploadView ? (
                <>
                    <h4 className="font-20 font-bold text-black mb-3">User Details</h4>
                    <p className="font-16 font-medium text-black mb-0">
                        Upload Users <span className="text-red">*</span>
                    </p>
                    <div className="col-md-12 col-sm-12">
                        <div className="mb-5">
                            <span className="upload-btn-wrapper d-block">
                                {!fileUploaded ? (
                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                        <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                        <span className="font-medium font-13 d-block text-grey mb-2">Browse or drag and drop file</span>
                                        <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                            Use this template to add info -{" "}
                                            <a onClick={exportTemplate} className="primary-color font-medium cursor-pointer text-decoration-none down-templ-sty">
                                                Download Template
                                            </a>
                                        </span>
                                    </button>
                                ) : (
                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                    <img src="images/sample-logo.svg" alt="sample-logo" className="mb-2" />
                                        <a className="font-medium font-12 d-block">{selectedFileName}</a>
                                        <a
                                            style={{ position: "relative", zIndex: "100" }}
                                            onClick={() => {
                                                setValidationErrors([]);
                                                setIsFormValid(false);
                                                setFileUploaded(false);
                                                document.getElementById('definedocumentid').value = '';
                                            }}
                                        >
                                            <span className="ms-2">
                                                <img src="images/link-close-icon.svg" alt="link-close-icon" />
                                            </span>
                                        </a>
                                        </button>
                                )}
                                <input
                                    type="file"
                                    name="myfile"
                                    className="cursor-pointer cust-file"
                                    accept=".xlsx"
                                    id="definedocumentid"
                                    onChange={(event) => {
                                        handleFileUpload(event);
                                        setEnable(false);
                                    }}
                                    title={selectedFileName}
                                />
                            </span>
                            {validationErrors.length > 0 && (
                                <div className="code-doc-error">
                                    {validationErrors.map((data, index) => (
                                        <span className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section" key={index}>
                                            <img src="images/warning-red-icon.svg" alt="Warning icon" />
                                            <span className="ms-2">{data}</span>
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-5">
                        <button type="button" className="btn cust-primary-btn font-14 font-medium w-auto" onClick={handleSubmit} disabled={!isFormValid}>
                            <span className="px-2">Upload</span>
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="font-20 font-bold text-black mb-0">User Details</h4>
                        <div className="d-flex gap-3">
                            <SearchComponent
                                columns={searchColumns}
                                actualValue={actualValue}
                                setGridData={setUserDetails}
                            />
                            <button type="button" className="btn cust-secondary-btn" onClick={() => setShowUploadView(true)}>
                                <span className="text-nowrap">
                                    <img src="images/import-icon_ETL.svg" alt="Import" className="me-2" /> Import
                                </span>
                            </button>
                        </div>
                    </div>
        <ACDynamicGrid
            data={userDetails} // Correct variable here
            settings={settings}
            setGridData={setUserDetails}
            actualValue={userDetails}
            setWhetherUpdated={() => {}}
            onEdit={handleEditUser}
            onAdd={handleAddUser}
            onDelete={handleDeleteUser}
            view={2}
        />
    </>
)}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="toastMessage" className="toast-message" style={{ display: 'none' }}>
                Session expired. Redirecting to login...
            </div>
            <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDetailsUpload;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate,useLocation } from 'react-router-dom';
import { generateAccessControl } from '../../Service/Api';
import { HeaderCom } from '../Header';


const GroupList = () => {
  const [groups, setGroups] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');
  // const [editGroupName, setEditGroupName] = useState(null);
  // const [editGroupData, setEditGroupData] = useState({});
  const [catalogData, setCatalogData] = useState([]);
  const [rolesDD,setRolesDD] = useState([])
  const navigate = useNavigate();
  const {state} = useLocation()
  console.log(state,'stateeeeeee')
  let stateValue={...state}
  console.log(stateValue,'stateValue')
  // PS_EX_1
  useEffect(() => {
    console.log('Updated catalogData:', catalogData);
}, [JSON.stringify(catalogData)]);

// PS_EX_2-5
  const fetchGroups = async () => {
    const body = {
        projectId: state.projectId,
        generationtype: "FetchGroup",
        userId: state.userId,
        jwt_token:state.jwt
    };

    try {
        document.getElementById('pageLoader').style.display = "block"

      const res = await generateAccessControl(body,state.jwt);
      console.log("res", res, "Response from generateAccessControl");
      console.log("res", typeof(res), "Response from generateAccessControl");

      if (Array.isArray(res.groups)) {
        console.log(res.groups, 'Groups - already an array');
        setGroups(res.groups);
        tableData(sortedGroups)
      } else {
        console.error('Unexpected format for groups:', res.groups);
        setGroups([]);
      }
      document.getElementById('pageLoader').style.display = "none"

    } catch (error) {
      console.error("An error occurred while fetching groups:", error);
      setGroups([]);
    }
  };

  // PS_EX_6-9
const fetchRoles = async () => {
  const body = {
      projectId: state.projectId,
      generationtype: "FetchRoles",
      userId: state.userId,
      jwt_token:state.jwt
  };

  try {
    document.getElementById('pageLoader').style.display = "block"
      const res = await generateAccessControl(body,state.jwt);
      console.log("res", res, "Response from generateAccessControl");
      console.log("res type", typeof res, "Response from generateAccessControl");

      // Assuming the API returns roles in a property called 'roles'
      if (Array.isArray(res.roles)) { // Check for 'roles' instead of 'groups'
          console.log(res.roles, 'Roles - already an array');
          setRolesDD(res.roles); // Update state with fetched roles
      } else {
          console.error('Unexpected format for roles:', res.roles);
          setRolesDD([]); // Reset roles if unexpected format
      }
  } catch (error) {
      console.error("An error occurred while fetching roles:", error);
      setRolesDD([]); // Reset roles on error
  }
  document.getElementById('pageLoader').style.display = "none"

};
// PS_EX_10-13
const fetchCatalogData = async () => {
  const body = {
      projectId: state.projectId,
      generationtype: "FetchResource",
      userId: state.userId,
      jwt_token:state.jwt
  };

  try {
    document.getElementById('pageLoader').style.display = "block"
      const res = await generateAccessControl(body,state.jwt);
      console.log("Response from generateAccessControl:", res);

      if (res === null) {
          console.error('Received null response from generateAccessControl');
          setCatalogData([]); // Reset state if response is null
          return;
      }

      if (Array.isArray(res.resource)) {
          console.log('Catalog - already an array:', res);
          setCatalogData(res.resource);
      } else if (typeof res === 'object' && res !== null) {
          console.log('Response is an object:', res);
          // Assuming you want to extract users from the object
          if (res.resource && Array.isArray(res.resource)) {
              setCatalogData(res.resource); // Set catalog data from the resource property
          } else {
              console.error('Unexpected format for resource:', res);
              setCatalogData([]); // Optionally reset if the format is not as expected
          }
      } else {
          console.error('Unexpected response format:', res);
          setCatalogData([]); // Reset state if neither condition is met
      }
      
  } catch (error) {
      console.error("An error occurred while fetching groups:", error);
      setCatalogData([]);
  }
  document.getElementById('pageLoader').style.display = "none"

};
  // Session Expired Handler
  const sessionExpired = () => {
    localStorage.clear()
    document.getElementById('toastMessage').style.display = "block"

    // Navigate to Login page after 3 seconds
    setTimeout(() => {
      document.getElementById('toastMessage').style.display = "none"
      navigate('/');
    }, 3000);
  };

  // Insert Error Handler
  const insertError = (error) => {
    console.error("An error occurred:", error);
  };

  useEffect(() => {
    const getGroups = async () => {
      try {
        await fetchGroups();
        await fetchRoles();
        await fetchCatalogData();
      } catch (error) {
        insertError(error);
      } 
    };
    getGroups();
  }, []);
  // PS_EX_14

    // PS_EX_23-27
    const handleDelete = async (index) => {
      debugger
      const updatedGroups = groups.filter((_, groupIndex) => groupIndex !== index);
  
      const body = {
        projectId: state.projectId,
        generationtype: "UpdateGroup",
        userId: state.userId,
        groups: updatedGroups,
        jwt_token: state.jwt,
      };
  
      setGroups(updatedGroups);
  
      try {
        const res = await generateAccessControl(body, state.jwt);
        console.log("res", res, "Response from generateAccessControl");
  
        if (Array.isArray(res.groups)) {
          console.log(res.groups, 'Groups - already an array');
          setGroups(res.groups);
        } else {
          console.error('Unexpected format for groups:', res.groups);
          setGroups([]);
        }
  
        fetchGroups();
      } catch (error) {
        console.error("An error occurred while updating groups:", error);
        setGroups([]);
      }
    };
  
    // PS_EX_28
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const filteredGroups = groups.filter((group) =>
    group.name && group.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedGroups = [...filteredGroups].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
    //PS_EX_31 
  const navigateToGroupDetailsPage = (group) => {
    debugger
    const groupDetails = {
        name: group.name,
        role: group.role,
        users: group.users,
        resource: group.resource, // Ensure this property is defined in your group object
        isEdit: true // Add isEdit directly to the details
    };

    console.log('Navigating to group details:', groupDetails); // Improved log message
const stateValue ={...state}
    // Navigate to /Group with state containing group details
    navigate(`/Group`, {
        state: {
            stateValue: stateValue, // Use optional chaining to avoid errors if 'state' is undefined
            groupDetails // Include groupDetails directly
        }
    });
};
//PS_EX_32
const navigateToGroup = () => {
  
const stateValue ={...state}
  // Navigate to /Group with state containing group details
  navigate(`/Group`, {
      state: {
          stateValue: stateValue, // Use optional chaining to avoid errors if 'state' is undefined
         type:"New" // Include groupDetails directly
      }
  });
};
//PS_EX_34-35
const tableData = (groups) => {
  console.log(groups, 'groups');

  // Check if groups array is empty
  if (groups.length === 0) {
    return (
      <tr>
        <td colSpan="4" className="text-center">
          No records found
        </td>
      </tr>
    );
  }

//   return groups.map((group, index) => (
//     <tr key={index}>
//       <td>
      
//           <a className="text-decoration-none link-color-blue" onClick={() => navigateToGroupDetailsPage(group)}>
//             {group.name}
//           </a>
      
//       </td>
//       <td>
      
//           {group.role}
      
//       </td>
//       <td>
//         <a className="text-decoration-none link-color-blue">{group.users.length}</a>
//       </td>
//       <td className="text-center">
//         <div>
          



// <span
//   className={`cust-cursor-pointer ${state.adRole === '1' ? '' : 'disabled'}`}
//   onClick={state.adRole === '1' ? () => handleDelete(index) : null}
//   style={{
//     cursor: state.adRole === '1' ? 'pointer' : 'not-allowed',
//     position: 'relative' // Required for positioning tooltip
//   }}
// >
//   <img
//     src="images/delete-icon.svg"
//     alt="Delete"
//     style={{ opacity: state.adRole === '1' ? 1 : 0.5 }}
//   />
//   {state.adRole !== '1' && (
//     <span className="tooltip">You Don't have access</span>
//   )}
// </span>
         
//         </div>
//       </td>
//     </tr>
//   ));
// };


return groups.map((group, index) => (
  <tr key={index}>
    <td>
      <a className="text-decoration-none link-color-blue" onClick={() => navigateToGroupDetailsPage(group)}>
        {group.name}
      </a>
    </td>
    <td>{group.role}</td>
    <td>
      <a className="text-decoration-none link-color-blue">{group.users.length}</a>
    </td>
    <td className="text-center">
      <div>
        <span
          className={`cust-cursor-pointer ${state.adRole === '1' ? '' : 'disabled'}`}
          onClick={state.adRole === '1' ? () => handleDelete(index) : null}
          style={{
            cursor: state.adRole === '1' ? 'pointer' : 'not-allowed',
            position: 'relative' // Required for positioning tooltip
          }}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <img
            src="images/delete-icon.svg"
            alt="Delete"
            style={{ opacity: state.adRole === '1' ? 1 : 0.5 }}
          />
          {state.adRole !== '1' && hoveredIndex === index && (
            <span
              style={{
                visibility: 'visible',
                backgroundColor: '#333',
                color: '#fff',
                textAlign: 'center',
                borderRadius: '5px',
                padding: '5px 10px',
                position: 'absolute',
                zIndex: 1,
                top: '50%', // Position next to the element
                left: '125%',
                transform: 'translateY(-50%)',
                opacity: 1,
                transition: 'opacity 0.3s',
                whiteSpace: 'nowrap'
              }}
            >
              No access
            </span>
          )}
        </span>
      </div>
    </td>
  </tr>
));
};
  return (
    <div className="container-fluid">
      <div className="row">
      <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                    <div className="position-absolute top-50 start-50  translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div
                                className="spinner-border Loader text-dark align-center"
                                role="Status"
                            >
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                Loading...
                            </span>
                        </div>
                    </div>
                </div>
        <HeaderCom value='1' />

        <div className="col-md-12 pt-4 mt-5">
          <div className="mt-3">
            <ul className="cust-breadcrumb mb-0 ">
              <li className="font-12 font-medium"><a >Manage Projects</a></li>
              <li className="font-12 font-medium active">{state.projectName}</li>
            </ul>
          </div>

          <div className="row p-4">
            <div className="d-flex mb-4 justify-content-between align-items-center">
              <div className="d-flex align-items-center mb-xl-0 ">
                <a  className="d-flex"><img src="images/back-arrow.svg" alt="back-arrow"onClick={() => navigate('/Roles', { state: stateValue })} /></a>
                <h2 className="text-black font-bold font-22 mb-0 ms-3">{state.projectName}</h2>
              </div>
              <div className="d-lg-flex d-md-block">
                <div className="d-flex mb-md-6 mb-sm-6">
                  <div className="cust-input-sty font-11 rounded-2 font-medium text-grey-v7 px-3 me-3 d-flex align-items-center">
                    <span className="text-nowrap">Organization :</span>
                    <span><img src={state.orgLogo} alt="hubspot-logo"  className="figma-logo"/></span>
                    <span className="me-2 text-nowrap font-medium font-12 text-black">{state.orgName}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="code-review-tab-width">
              <div className="code-review-page tabs nav">
              <div className="tab nav-link font-bold" data-bs-toggle="pill" data-bs-target="#project-info">
                                <a
                                        onClick={() => {
                                            navigate("/ProjectInfo", {
                                                state: stateValue,
                                            });
                                        }}
                                    >
                                    Project Info
                                    </a>
                                    </div>
                                <div className="tab nav-link font-bold" data-bs-toggle="pill" data-bs-target="#ask-gen" onClick={() => {
                                            navigate("/uc-chat", {
                                                state: stateValue,
                                            });
                                        }}>
                                    <a
                                        
                                    >
                                        Ask GenETL
                                    </a>
                                </div>
                <div className="tab nav-link font-bold active" data-bs-toggle="pill" data-bs-target="#acc-control">Access Control</div>
              </div>
            </div>

            <div className="tab-content" id="pills-tabContent">
              <div id="project-info" className="tab-pane code-review-tab tab-content"></div>
              <div id="ask-gen" className="tab-pane fade code-review-tab tab-content"></div>
              <div id="acc-control" className="tab-pane fade show active code-review-tab tab-content">
                <div className="row mt-5">
                  <div className="col-md-2">
                    <div className="tab-container">
                    <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow">
                                            <li className="side-nav-item d-flex align-items-center " onClick={() => navigate('/User', { state: stateValue })}>
                                                <span>User Details</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/Roles', { state: stateValue })}>
                                                <span>Roles</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center active" onClick={() => navigate('/GroupList', { state: stateValue })}>
                                                <span>Groups</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/Script', { state: stateValue })}>
                                                    <span>Scripts</span>
                                            </li>
                                        </ul>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="font-20 font-bold text-black mb-0">Group Details</h4>
                      <div className="d-flex gap-3">
                        <div className="input-group input-search">
                          <input type="search" className="form-control border-end-0 font-14 font-regular border"
                            placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                          <button className="btn border-start-0 d-flex align-items-center border" type="button"><img
                            src="images/magnifying-glass.svg" alt="search" /></button>
                        </div>
                        <button
                          type="button"
                          className="btn cust-primary-btn font-14 font-regular w-auto text-nowrap px-3"
                          onClick={navigateToGroup}
                        >
                          <span>
                            <img src="images/Add-new.svg" alt="add" className="pe-2" />
                            Add New
                          </span>
                        </button>

                      </div>
                    </div>

                      <div className="table-responsive mt-3">
                        <table className="table table-borderless rounded custom-grid custom-metadata-table">
                          <thead className="sticky-top-pos custom-tableHeader" style={{ position: "relative", zIndex: "100" }}>
                          <tr>
  <th onClick={() => handleSort('name')}>
    Group Name
    <span className="cursor-pointer">
      <img
        src={sortConfig.key === 'name' ? (sortConfig.direction === 'asc' ? 'images/sort-up-icon.svg' : 'images/sort-down-icon.svg') : 'images/sort-down-icon.svg'}
        alt="Sort"
        className="ms-2"
      />
    </span>
  </th>
  <th onClick={() => handleSort('role')}>
    Role Name
    <span className="cursor-pointer">
      <img
        src={sortConfig.key === 'role' ? (sortConfig.direction === 'asc' ? 'images/sort-up-icon.svg' : 'images/sort-down-icon.svg') : 'images/sort-down-icon.svg'}
        alt="Sort"
        className="ms-2"
      />
    </span>
  </th>
  <th onClick={() => handleSort('users')}>
    #User
    <span className="cursor-pointer">
      <img
        src={sortConfig.key === 'users' ? (sortConfig.direction === 'asc' ? 'images/sort-up-icon.svg' : 'images/sort-down-icon.svg') : 'images/sort-down-icon.svg'}
        alt="Sort"
        className="ms-2"
      />
    </span>
  </th>
  <th className="text-center">Actions</th>
</tr>

                          </thead>
                          <tbody>
                            {tableData(sortedGroups)}
                          </tbody>
                        </table>
                        <p className="font-regular font-14 text-grey m-0"># of Records: <span
                          className="text-black font-medium">{sortedGroups.length} out of {groups.length}</span></p>
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default GroupList;
// perfect 12345567890
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError, generateSasTokenAPI } from "../../../Service/Api.js";
import { HeaderCom } from "../../Header.jsx";
import DynamicGrid from './DynamicTable.jsx';
import SearchComponent from './SearchComponent.jsx';
import FilterComponent from './FilterComponent.jsx';
import DownloadComponent from './DownloadComponent.jsx';
import ReasonComponent from './ReasonComponent.jsx';
import ViewInstructions from './viewInstructions.jsx'
function CatalogSchema(props) {

    const accountName = 'avaeusgenetlsametaprod';
    const containerName = 'uc-migration';

    const fetchJsonFromBlob = async (catalogAndSchemaDetailsURL) => {
        try {
            const sasToken = await generateSasTokenAPI();
            // const blobPath = `${orgName}/${projectName}/json/${fileName}`;
            // const url = `https://avaeusgenetlsametaprod.blob.core.windows.net/chatmainsample/GenETL KT New/New Clark/json/Sample_CatalogSchema.json?${sasToken}`;
            let url = `${catalogAndSchemaDetailsURL}?${sasToken}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonData = await response.json();
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };




    const { state } = useLocation();
    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);
    const [actualValue, setActualValue] = useState([]);
    const [dropDownValues, setDropDownValues] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [whetherUpdated, setWhetherUpdated] = useState(false);
    const [searchFilterTrigger, setSearchFilterTrigger] = useState(0);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [currentOperation, setCurrentOperation] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [newRow, setNewRow] = useState({});
    const [blobUrl, setblobUrl] = useState('');
    const [allCatalog, setAllcatalog] = useState('');



    const handleEdit = (item) => {
        setCurrentOperation('edit');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    // const handleAdd = (newItem) => {
    //     setCurrentOperation('add');
    //     setCurrentItem(newItem);
    //     setShowReasonModal(true);
    // };
    const handleAdd = (newItem) => {
        // setGridData(prevData => [...prevData, newItem]);
        // setNewRow({});  // Clear the newRow state after adding
        setCurrentOperation('add');
        setCurrentItem(newItem);
        setShowReasonModal(true);
    };



    const handleDelete = (item) => {
        setCurrentOperation('delete');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

const handleReasonSave = async () => {
    try {
         let updatedData;
         switch (currentOperation) {
            case 'edit':
                updatedData = actualValue.map(item =>
                    item.index === currentItem.index ? { ...item, ...currentItem, reason } : item
                );
                break;
            case 'add':
                updatedData = [...actualValue, { ...currentItem, reason, index: actualValue.length }];
                break;
            case 'delete':
                updatedData = actualValue.filter(item => item.index !== currentItem.index);
                break;
            default:
                throw new Error('Invalid operation');
        }
let body={
                type: "catalogAndSchemaDetails", 
                projectId: state.projectId, 
                userId: state.userId,
                orgId: state.orgId,
                comments:reason,
 
            }
          let res=  await updatePseudocodeData(body,state.jwt)
            await updateJsonInBlob(blobUrl, updatedData);
        await updateJsonInBlob(blobUrl, updatedData);
 
        // Update local state with original data and re-apply filter
        setActualValue(updatedData);
        setGridData(updatedData); // Optionally: apply previous filter/search to updatedData
 
        setShowReasonModal(false);
        setReason('');
        setCurrentOperation(null);
        setCurrentItem(null);
        setWhetherUpdated(true);
        setSearchFilterTrigger(prev => prev + 1);

    } catch (error) {
        console.error('Error updating data:', error);
    }
};

    // const checkAllData = async () => {
    //     const allCatalogNamesFilled = gridData.every(item => item.NewCatalogName.trim() !== '');
    //     console.log(allCatalogNamesFilled, 'allCatalogNamesFilled')
    //     setAllcatalog(allCatalogNamesFilled);
 
    //     // If you need to update state or pass data back up, use a handler function or local state
    //     if (allCatalogNamesFilled) {
    //         props.setIsAllCatalogFilled(allCatalogNamesFilled);
    //     }
    // }

    useEffect(() => {
        checkAllData(); // Initial check if component mounts and data is available
      }, [gridData]); // Ensure this runs when gridData changes
    
    //   const handleOpenModal = () => setIsModalOpen(true);
    //   const handleCloseModal = () => setIsModalOpen(false);
    
      const checkAllData = () => {
        const allCatalogNamesFilled = gridData.every(item => item.NewCatalogName.trim() !== '');
        console.log(allCatalogNamesFilled, 'allCatalogNamesFilled');
        setAllcatalog(allCatalogNamesFilled);
    
        // Pass data back up
        if (allCatalogNamesFilled) {
          props.setIsAllCatalogFilled(allCatalogNamesFilled);
        }
      };
    
      const handleBackClick = () => {
        props.setShowCode();
        props.isEdit(whetherUpdated);
        checkAllData();
      };

    const updateJsonInBlob = async (blobUrl, updatedData) => {
        try {
            const sasToken = await generateSasTokenAPI();
            // const blobPath = `${orgName}/${projectName}/json/${fileName}`;
            // const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
            let url = `${blobUrl}?${sasToken}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Data updated successfully in Azure Blob');
        } catch (error) {
            console.error('Error updating JSON in blob:', error);
            throw error;
        }
    };

    let data;

    // Attempt to parse the newCatalogName from state
    try {
        data = JSON.parse(state.newCatalogName);
    } catch (error) {
        console.error("Failed to parse newCatalogName:", error);
        data = state.newCatalogName; // Assign original value if parsing fails
    }
    
    // If data is not an array, ensure it's an empty array
    if (!Array.isArray(data)) {
        console.log("Data is not an array:", data);
        data = []; // Default to an empty array if not valid
    } else {
        // Trim whitespace from each catalog name in the array
        data = data.map(catalogName => catalogName.trim());
    }
    
    // Log the current state for debugging
    console.log(state, "note------");
    
    // Update settings state with the correct NewCatalogName
    const [settings, setSettings] = useState({
        TableConfig: [
            { key: 'OldCatalogName', header: 'OldCatalogName', sortable: true, type: 'normal', deFaultRow: 'dropdown' },
            { key: 'OldSchemaName', header: 'OldSchemaName', sortable: true, type: 'normal', deFaultRow: 'dropdown' },
            { key: 'NewCatalogName', header: 'NewCatalogName', sortable: true, type: 'normal', deFaultRow: 'dropdown' },
            { key: 'NewSchemaName', header: 'NewSchemaName', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'NewStorageType', header: 'NewStorageType', sortable: true, type: 'normal', deFaultRow: 'dropdown' },
        ],
        ActionItems: { add: true, delete: true, edit: true },
        deFaultRow: [
            { key: 'OldCatalogName', values: [] },
            { key: 'OldSchemaName', values: [] },
            { key: 'NewCatalogName', values: data }, // Directly assign the trimmed data array here
            { key: 'NewStorageType', values: ['MANAGED', 'EXTERNAL', 'SOURCETYPE'] }
        ]
    });
    

    const searchColumns = ['OldCatalogName', 'OldSchemaName'];


    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchDropDown(),
                fetchPseudo()
                ]);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                console.log("I am in finallllyyyyyy")
            }
        };
        fetchData();
    }, []);

    const fieldsToDownload = ['OldCatalogName', 'OldSchemaName', 'NewCatalogName', 'NewStorageType', 'NewSchemaName'];
    const filteredData = actualValue.map(item => {
        const filteredItem = {};
        fieldsToDownload.forEach(field => {
            if (item[field] !== undefined) {
                filteredItem[field] = item[field];
            }
        });
        return filteredItem;
    });


    const fetchDropDown = async () => {
        try {
            let data = {
                type: "inventorydd",
                projectId: state.projectId,
                userId: state.userId
            };

            document.getElementById('pageLoader').style.display = 'block';
            let dropDownValues = await fetchDropDownValues(data, state.jwt);
            document.getElementById('pageLoader').style.display = 'none';
            if (dropDownValues.statusCode === 403) {
                sessionExpired();
            } else if (dropDownValues.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                if (dropDownValues.data) {
                    // Directly update the settings with the received dropdown values
                    const updatedSettings = { ...settings };

                    // Update OldCatalogName dropdown
                    const oldCatalogNameIndex = updatedSettings.deFaultRow.findIndex(item => item.key === 'OldCatalogName');
                    if (oldCatalogNameIndex !== -1) {
                        updatedSettings.deFaultRow[oldCatalogNameIndex].values = dropDownValues.data.oldCatalogName || [];
                    }

                    // Update OldSchemaName dropdown
                    const oldSchemaNameIndex = updatedSettings.deFaultRow.findIndex(item => item.key === 'OldSchemaName');
                    if (oldSchemaNameIndex !== -1) {
                        updatedSettings.deFaultRow[oldSchemaNameIndex].values = dropDownValues.data.oldSchemaName || [];
                    }

                    // Set the updated settings
                    setSettings(updatedSettings);
                    setDropDownValues(dropDownValues.data);
                } else {
                    console.log("No dropdown values found");
                }
            }
        }
        catch (e) {
            insertError({
                errorMessage: e.message,
                serviceName: "frontend",
                module: "Datadictionary",
                functionName: "DataDictionaryTable",
                userId: state.userId
            });
            console.error("Error fetching dropdown values:", e);
        }
    };

    const updateSettingsWithDropdownValues = (data) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            deFaultRow: prevSettings.deFaultRow.map(row => {
                if (row.key === 'SubjectArea') {
                    return { ...row, values: data.DDuniqueSubjectArea || [] };
                }
                if (row.key === 'SchemaName') {
                    return { ...row, values: data.uniqueSchemaName || [] };
                }
                if (row.key === 'TableName') {
                    return { ...row, values: data.uniqueTableName || [] };
                }
                return row;
            })
        }));
    }

    const fetchPseudo = async () => {
        const body = {
            projectId: state.projectId,
            type: "catalogandschemadetails",
            userId: state.userId,
            orgId: state.orgId,
            jwt: state.jwt
        };

        try {
            document.getElementById('pageLoader').style.display = "block";
            const res = await fetchPseudocodeData(body, state.jwt);
            console.log(res, "tata")
            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                console.log("The Response Is 200");

                // Check if res.data exists and has at least one element
                if (res.data && res.data.length > 0) {
                    let catalogAndSchemaDetailsURL = res.data[0].catalogAndSchemaDetailsURL;
                    console.log(res.data[0].catalogAndSchemaDetailsURL, "test1")
                    console.log(res, "test2")
                    setblobUrl(catalogAndSchemaDetailsURL)
                    console.log(catalogAndSchemaDetailsURL, 'fetchJsonFromBlobfetchJsonFromBlob');
                    try {
                        const jsonData = await fetchJsonFromBlob(catalogAndSchemaDetailsURL);
                        console.log(jsonData, "Data from blob");

                        if (jsonData) {
                            let pseudo = jsonData.map((obj, index) => ({
                                ...obj,
                                index: index
                            }));
                            setGridData(pseudo);
                            setActualValue(pseudo);
                            console.log(pseudo, "Mapped data");
                        } else {
                            throw new Error("Failed to fetch data from Azure Blob Storage");
                        }
                    } catch (e) {
                        console.error('Error fetching JSON from blob:', e);
                        handleError(e);
                    }
                } else {
                    throw new Error("No data found in response");
                }
            }
            document.getElementById('pageLoader').style.display = "none";
        } catch (e) {
            console.error('Error fetching pseudocode data:', e);
            handleError(e);
        }
    };



    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };


    const handleError = (e) => {
        insertError({
            errorMessage: e.message,
            serviceName: "frontend",
            module: "Datadictionary",
            functionName: "DataDictionaryTable",
            userId: state.userId
        });
        console.log("error", e);
        setError(e.message);
    }
    const [isModalOpen, setModalOpen] = useState(false);


    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">

                    <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                        <div className="position-absolute top-50 start-50  translate-middle">
                            <div className="d-flex align-items-center loader-bg">
                                <div
                                    className="spinner-border Loader text-dark align-center"
                                    role="status"
                                >
                                    <span className="visually-hidden"></span>
                                </div>
                                <span className="ms-3 font-18 loader-text mt-2 font-medium">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    </div>
                    <HeaderCom value="1" />
                    <div className="col-md-12 pt-4 mt-5">
                        {/* Breadcrumbs starts here */}
                        <div className="mt-3 px-2">
                            <ul className="cust-breadcrumb mb-0 ">
                                <li className="font-16 font-medium"><a >Manage Projects</a></li>
                                <li className="font-16 font-medium"><a>{state.projectName}</a></li>
                                <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                            </ul>
                        </div>
                        {/* Breadcrumbs ends here */}
                        <div className="d-flex align-items-center justify-content-between p-4">
                            {/* <div className="d-flex">
                                <a className="d-flex" onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated); checkAllData() }} >
                                    <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '35px', cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated); checkAllData() }} />
                                </a>
                                <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText} <button type="button" className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3" onClick={handleOpenModal}>
                                    <img src="images/info-icon-ETL.svg" alt="info" />
                                </button></h2>
                                {isModalOpen && <ViewInstructions notebooktype='catalogAndSchema'
                                    onClose={handleCloseModal} />}
                            </div> */}

<div className="d-flex">
      <a
        className="d-flex"
        onClick={handleBackClick}
        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
      >
        <img
          src="images/back-arrow.svg"
          alt="back-arrow"
          style={{ width: '24px', height: '35px' }}
        />
      </a>
      <h2 className="text-black font-bold font-22 mb-0 ms-3">
        {props.data.hyperlinkText}{' '}
        <button
          type="button"
          className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3"
          onClick={handleOpenModal}
        >
          <img src="images/info-icon-ETL.svg" alt="info" />
        </button>
      </h2>
      {isModalOpen && (
        <ViewInstructions
          notebooktype="catalogAndSchema"
          onClose={handleCloseModal}
        />
      )}
    </div>
                            <div className="d-flex">
                                <SearchComponent
                                    columns={searchColumns}
                                    actualValue={actualValue}
                                    setGridData={setGridData}
                                    searchFilterTrigger={searchFilterTrigger}
                                />
                                <DownloadComponent
                                    type="excel"
                                    data={filteredData}
                                    fileName="CatalogSchema"
                                
                                />
                            </div>
                        </div>
                        <DynamicGrid
                            data={gridData}
                            settings={settings}
                            setGridData={setGridData}
                            actualValue={actualValue}
                            setWhetherUpdated={setWhetherUpdated}
                            onEdit={handleEdit}
                            onAdd={handleAdd}
                            onDelete={handleDelete}
                            newRow={newRow}
                            setNewRow={setNewRow}
                        />
                        <ReasonComponent
                            isOpen={showReasonModal}
                            onClose={() => setShowReasonModal(false)}
                            onSave={handleReasonSave}
                            reason={reason}
                            setReason={setReason}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CatalogSchema;
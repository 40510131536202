import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PlantUML from 'react-plantuml';
import { saveAs } from 'file-saver';
import io from 'socket.io-client';
import { CopyToClipboard, ClipboardItem } from "react-copy-to-clipboard";
import ViewInstructions from './viewInstructions';
// import { postSourceToTargetFlowStatus, getSourceToTargetFlow } from '../../../Service/Api';
import { mockFetchCatalogSchemaResponse, mockFetchSourceToTargetFlowResponse } from '../mockData';
import { generateBronzeToSilverER, generateDataModelGoldUML, fetchDropDownValues, postSourceToTargetFlowAPI, generatesourceTotargetFlowAPI } from '../../../Service/Api';
import { HeaderCom } from '../../Header';
// import ViewsourcetotargetInstructions from './viewInstructionsourceToTarget.jsx';
import TableMapping from './TableMapping.jsx';
import axios from 'axios';



const SourceToTargett = (props) => {
    var { state } = useLocation();
    const navigate = useNavigate();

    const [catalogName, setCatalogName] = useState('');
    const [schemaName, setSchemaName] = useState('');
    const [uniqueCatalogName, setUniqueCatalogName] = useState([]);
    const [uniqueSchemaName, setUniqueSchemaName] = useState([]);
    const [showCatalog, setShowCatalog] = useState(true);
    const [showSchema, setShowSchema] = useState(true);
    const [showTable, setShowTable] = useState(false);
    const [approvedCombinations, setApprovedCombinations] = useState({});
    const [sourceToTargetUML, setSourceToTargetUML] = useState('');
    const [zoom, setZoom] = useState(1);
    const [socket, setSocket] = useState(null);
    const [isSourceToTargetAllApproved, setIsSourceToTargetAllApproved] = useState(false);
    const [copiedText, setCopiedText] = useState(false);
    const [tableMapping, setTableMapping] = useState(false);
    const [schemasByCatalog, setSchemasByCatalog] = useState({}); // Define schemasByCatalog as state
    const [umlCode, setUmlCode] = useState("@startuml\nBob -> Alice : hello\n@enduml"); // Example UML
    const [totalCount, setTotalCount] = useState(0);
  const [umlImage, setUmlImage] = useState('');

  const fetchKrokiDiagram = async (umlCode) => {
    try {
        const response = await axios.post('https://kroki.io/plantuml/svg', umlCode, {
            headers: {
                'Content-Type': 'text/plain',
            },
        });
        setUmlImage(response.data); // Store SVG data directly
    } catch (error) {
        console.error('Error fetching diagram from Kroki:', error);
        setUmlImage('<div>Error generating diagram</div>');
    }
};

useEffect(() => {
    if (sourceToTargetUML) {
        fetchKrokiDiagram(sourceToTargetUML);
    }
}, [sourceToTargetUML]);

    const backendUrl = "https://genetlnpapi.asami.ai";
    console.log("backendurlbackendurlbackendurl",backendUrl);
    useEffect(() => {
        // Check if state is available
        if (!state) {
            navigate('/');
            return;
        }
        const newSocket = io(backendUrl, {
            path: '/meta/socket.io',  // Ensure path matches the backend Socket.IO path
            transports: ['websocket', 'polling'],  // Define transports for flexibility
        });

        //   newSocket.on('dataDictionaryUpdate', handleSocketUpdate);

        newSocket.on('connect_error', (err) => {
            console.error('Connection error:', err.message);
        });

        newSocket.on('connect', () => {
            console.log('Connected to server');
            newSocket.emit('joinProject', state.projectId);
        });

        newSocket.on('initialApprovals', (initialApprovals) => {
            setApprovedCombinations(initialApprovals);
        });

        newSocket.on('receiveApproval', (update) => {
            console.log("Received approval update:", update);
            handleReceivedApproval(update);
        });

        setSocket(newSocket);

        fetchData();

        return () => {
            newSocket.emit('leaveProject', state.projectId);
            // newSocket.off('dataDictionaryUpdate', handleSocketUpdate);
            newSocket.off('receiveApproval');
            newSocket.off('initialApprovals');
            newSocket.close();
        };
    }, [state]);

    useEffect(() => {
        if (uniqueCatalogName.length > 0) {
            const initialCatalog = uniqueCatalogName[0];
            setCatalogName(initialCatalog);
            // Ensure the list is populated with "ALL Schema" as the first option
            setUniqueSchemaName(["ALL Schema", ...(schemasByCatalog[initialCatalog] || [])]);

        }
    }, [schemasByCatalog, uniqueCatalogName]);


    useEffect(() => {
        if (catalogName && schemaName) {
            fetchSourceToTargetFlow();
        }
    }, [catalogName, schemaName, showCatalog, showSchema, showTable]);


    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };
    const statuses = {};
    const fetchData = async () => {
        try {
            document.getElementById("pageLoader").style.display = "block";
            const requestBody = {
                userId: state.userId,
                projectId: state.projectId,
                jwt_token: state.jwt,
                type: 'sourcetotargetflow'
            };
            let dropDownValues = await fetchDropDownValues(requestBody, state.jwt);
            console.log(dropDownValues, 'dropDownValues')
            dropDownValues.data = dropDownValues.data.dropdownDatas;
            setTotalCount(dropDownValues.data.length)
            if (dropDownValues.statusCode === 200) {
                const catalogsSet = new Set(); // Unique catalog names
                const schemasMap = {};
                 // To store the approval status of combinations

                // Process data
                dropDownValues.data.forEach(({ catalogName, schemaName, Status }) => {
                    catalogsSet.add(catalogName);

                    if (!schemasMap[catalogName]) {
                        schemasMap[catalogName] = new Set();
                    }
                    schemasMap[catalogName].add(schemaName);

                    // Ensure statuses are set correctly
                    const combinationKey = `${catalogName}-${schemaName}`;
                    console.log(Status, 'this is the statu')
                    if (Status == "Approved") {
                        statuses[combinationKey] = true
                    }
                    // statuses[combinationKey] = Status == "Approved"; // Set `true` if approved
                });

                // Update the state for approved combinations
                setApprovedCombinations(statuses);

                // Convert Sets to Arrays
                const uniqueCatalogs = Array.from(catalogsSet);
                const schemasByCatalog = {};
                for (const catalog in schemasMap) {
                    schemasByCatalog[catalog] = Array.from(schemasMap[catalog]);
                }

                setSchemasByCatalog(schemasByCatalog);
                setUniqueCatalogName(uniqueCatalogs);

                // Set initial values for catalog and schema
                if (uniqueCatalogs.length > 0) {
                    let initialCatalog = "";
                    let initialSchema = "";
                
                    // Find the first unapproved combination
                    let foundUnapproved = false;
                    for (const catalog of uniqueCatalogs) {
                        const schemas = schemasByCatalog[catalog] || [];
                        for (const schema of schemas) {
                            const combinationKey = `${catalog}-${schema}`;
                            if (!statuses[combinationKey]) { // If not approved
                                initialCatalog = catalog;
                                initialSchema = schema;
                                foundUnapproved = true;
                                break;
                            }
                        }
                        if (foundUnapproved) break;
                    }
                
                    // If all are approved, simply set to the first available
                    if (!foundUnapproved) {
                        initialCatalog = uniqueCatalogs[0];
                        const firstCatalogSchemas = schemasByCatalog[initialCatalog] || [];
                        initialSchema = firstCatalogSchemas.length > 0 ? firstCatalogSchemas[0] : "";
                    }
                
                    if (initialCatalog && initialSchema) {
                        setCatalogName(initialCatalog);
                        setSchemaName(initialSchema);
                
                        // Check and set the initial approval status
                        setIsSourceToTargetAllApproved(statuses[`${initialCatalog}-${initialSchema}`]);
                    }
               }

                setShowCatalog(true);
                setShowSchema(true);
                setShowTable(false);
                console.log("Approved combinations:", statuses);

            }
            else if (dropDownValues.statusCode === 403) {
                sessionExpired()
            }
            else {
                console.error("Error fetching data: Invalid dropDownValues status", dropDownValues.statusCode);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            document.getElementById("pageLoader").style.display = "none";
        }
    };



    const updateSchemasForCatalog = (selectedCatalog, schemasByCatalog) => {
        if (schemasByCatalog[selectedCatalog] && schemasByCatalog[selectedCatalog].length > 0) {
            setUniqueSchemaName(["ALL Schema", ...(schemasByCatalog[selectedCatalog] || [])]);
            setSchemaName(schemasByCatalog[selectedCatalog][0]);
        } else {
            setSchemaName(""); // Reset schema if no schemas are available for the selected catalog
        }
    };

    // Call this function whenever the catalog changes
    const handleCatalogChange = (newCatalog) => {
        setCatalogName(newCatalog);
        updateSchemasForCatalog(newCatalog, schemasByCatalog); // Ensure to pass the current schemas map
    };

    // PS_14 to PS_15
    // PS_35 to PS_51
    const fetchSourceToTargetFlow = async () => {
        document.getElementById("pageLoader").style.display = "block";
        const object = {
            projectId: state.projectId,
            catalogName: catalogName,
            schemaName: schemaName,
            orgId: state.orgId,
            userId: state.userId,
            jwt_token: state.jwt
        };

        try {
            // Using mock data
            const response = await generatesourceTotargetFlowAPI(object, state.jwt);
            console.log(response, 'this si teh response')
            // Actual backend fetch, keep these lines commented.
            // const response = await getSourceToTargetFlow(object, state.jwt);
            if (showSchema === true && showTable === false) {
                setSourceToTargetUML(modifyUML(response.uml_schema, showCatalog, showSchema, showTable));

            } else if (showSchema === true && showTable === true) {
                setSourceToTargetUML(modifyUML(response.uml_table, showCatalog, showSchema, showTable));

            } else {
                setSourceToTargetUML(modifyUML(response.uml_catalog, showCatalog, showSchema, showTable));
            }
            document.getElementById("pageLoader").style.display = "none";

        } catch (error) {
            console.error('Error fetching source to target flow:', error);
        }
    };

    // PS_31 to PS_34
    const handleReceivedApproval = (update) => {
        const { combinationKey, isApproved } = update;
        setApprovedCombinations(prev => ({ ...prev, [combinationKey]: isApproved }));
    };
    // PS_75 to PS_76
    const handleZoomIn = () => setZoom(prevZoom => prevZoom + 0.1);
    // PS_77 to PS_78
    const handleZoomOut = () => setZoom(prevZoom => Math.max(prevZoom - 0.1, 0.1));
    // PS_52 to PS_56
    const handleCheckBox = (type) => {
        switch (type) {
            case 'catalog':
                setShowCatalog(showCatalog);
                break;
            case 'schema':
                setShowSchema(!showSchema);
                setShowTable(false);
                break;
            case 'table':
                setShowTable(!showTable);
                setShowSchema(true);
                break;
        }
    };
    const checkAllApproved = () => {
        // Calculate whether all known combinations are approved
        console.log(totalCount , Object.keys(approvedCombinations).length,'this is the console')
        const allCombinations = totalCount-1 == (Object.keys(approvedCombinations).length);
    
        if (allCombinations) {
            setIsSourceToTargetAllApproved(true);
            props.setIsSourceAllApproved(true);
            document.getElementById('approvaltoastMessage').style.display = "block";
            setTimeout(() => {
                document.getElementById('approvaltoastMessage').style.display = "none";
            }, 3000);
        }
    };

    // PS_84 to PS_92
    const handleApprove = () => {
        const combinationKey = `${catalogName}-${schemaName}`;
        setApprovedCombinations(prev => {
            const updated = { ...prev, [combinationKey]: true };
            checkAllApproved();  // Check here instead of setNextCombination
            return updated;
        });
        if (socket) {
            console.log('Emitting updateData event for existing record...');
            socket.emit('sendApproval', {
                projectId: state.projectId,
                combinationKey
            });
            console.log('Event emitted successfully:', { projectId: state.projectId, combinationKey });
        } else {
            console.error('Socket is not connected');
            return;  // Exit if socket is not connected
        }

        let object = {
            userId: state.userId,
            orgId: state.orgId,
            projectId: state.projectId,
            catalog: catalogName,
            schema: schemaName,
            status: "approved",
            notebookName: props.data.notebookName,
            approvedon: "11:10:00",
            jwt_token: state.jwt
        }
        // Commented actual backend call
        const response = postSourceToTargetFlowAPI(object, state.jwt);
        console.log(response, 'responseeeeeeee');


        setNextCombination();
    };

    const handleViewCode = () => {
        setShowModal(true);
        setUmlCode(sourceToTargetUML);
    };


    // PS_57 to PS_68
    const setNextCombination = () => {
        // document.getElementById("pageLoader").style.display = "block";
    
        const currentCatalogIndex = uniqueCatalogName.indexOf(catalogName);
        const currentSchemaIndex = uniqueSchemaName.indexOf(schemaName);
    
        console.log('Current Catalog:', catalogName, 'Index:', currentCatalogIndex);
        console.log('Current Schema:', schemaName, 'Index:', currentSchemaIndex);
    
        if (currentSchemaIndex < uniqueSchemaName.length - 1) {
            setSchemaName(uniqueSchemaName[currentSchemaIndex + 1]);
            handleSchemaSelect(uniqueSchemaName[currentSchemaIndex + 1])
            console.log('Switched to next schema in current catalog:', uniqueSchemaName[currentSchemaIndex + 1]);
        } else if (currentCatalogIndex < uniqueCatalogName.length - 1) {
            const selectedCatalog = uniqueCatalogName[currentCatalogIndex + 1];
            setCatalogName(selectedCatalog);
            handleCatalogSelect(selectedCatalog);
            console.log('Switched to next catalog:', selectedCatalog);
        } else {
            console.log('Sequential check completed, checking all for any unapproved');
            
            let foundUnapproved = false;
            for (const catalog of uniqueCatalogName) {
                const schemas = schemasByCatalog[catalog] || [];
                for (const schema of schemas) {
                    const combinationKey = `${catalog}-${schema}`;
                    console.log('Checking combination:', combinationKey, 'Status:', approvedCombinations[combinationKey]);
    
                    if (!approvedCombinations[combinationKey]) {
                        setCatalogName(catalog);
                        setSchemaName(schema);
                        handleCatalogSelect(catalog); // to update uniqueSchemaName with catalog-specific schemas
                        console.log('Found unapproved combination:', catalog, schema);
                        foundUnapproved = true;
                        break;
                    }
                }
                if (foundUnapproved) break;
            }
    
            if (!foundUnapproved) {
                console.log("All combinations have been processed");
                setIsSourceToTargetAllApproved(true);
                props.setIsSourceAllApproved(true);
    
                state = { ...state, isSourceToTargetAllApproved: true };
                document.getElementById('approvaltoastMessage').style.display = "block";
    
                setTimeout(() => {
                    document.getElementById('approvaltoastMessage').style.display = "none";
                }, 3000);
            }
        }
        // document.getElementById("pageLoader").style.display = "none";
    };
    // PS_106 to PS_117
    const handleSkip = () => {
        setNextCombination();
    };

    // PS_117 to PS_120
    const handleEdit = () => {
        setTableMapping(true);

        // navigate('/table-mapping', { state: { ...state, sourceToTargetUML, fromSourceToTarget: true } });
    };
    // PS_121 to PS_126
    const handleCaptureClick = async () => {
        try {
            var imgElement = document.getElementById('daimage123').getElementsByTagName('img')[0];
            var srcValue = imgElement.getAttribute('src');
            if (srcValue.startsWith('http:')) {
                srcValue = srcValue.replace('http:', 'https:');
            }

            fetch(srcValue)
                .then(resp => resp.blob())
                .then(blob => {
                    saveAs(blob, 'Source to Target.svg');
                })
                .catch(() => alert('Oh no, there was an error!'));
        } catch (error) {
            console.log(error);
        }
    };

    const [isCatalogOpen, setIsCatalogOpen] = useState(false);
    const [isSchemaOpen, setIsSchemaOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

console.log(isSourceToTargetAllApproved,"pppppppppppppppppp");

    // PS_131 to PS_135
    const toggleCatalogDropdown = () => {
        setIsSchemaOpen(false);
        setIsCatalogOpen(!isCatalogOpen);
    };

    // PS_136 to PS_140
    // Refs for dropdowns
    const catalogRef = useRef(null);
    const schemaRef = useRef(null);

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (catalogRef.current && !catalogRef.current.contains(event.target)) {
                setIsCatalogOpen(false);
            }
            if (schemaRef.current && !schemaRef.current.contains(event.target)) {
                setIsSchemaOpen(false);
            }
        };

        // Add event listener
        document.addEventListener('click', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    const toggleSchemaDropdown = () => {
        setIsCatalogOpen(false);
        setIsSchemaOpen(!isSchemaOpen);
    };

    // PS_141 to PS_145
    const handleSchemaSelect = (selectedSchema) => {
        setSchemaName(selectedSchema); // Set the selected schema
        setIsSchemaOpen(false); // Hide schema dropdown
        setIsCatalogOpen(false); // Hide catalog dropdown
    };

    // PS_146 to PS_152
    const handleCatalogSelect = (selectedCatalog) => {
        setCatalogName(selectedCatalog); // Set the selected catalog
        const schemas = schemasByCatalog[selectedCatalog] || [];
        setUniqueSchemaName(["ALL Schema", ...(schemas || [])]);
        // Update schema list for the selected catalog

        if (schemas.length > 0) {
            setSchemaName(schemas[0]); // Automatically select the first schema
        } else {
            setSchemaName(''); // Reset schema selection if no schemas exist
        }

        setIsCatalogOpen(false); // Hide catalog dropdown
        setIsSchemaOpen(false); // Ensure schema dropdown is hidden
    };


    console.log(approvedCombinations, 'this are the approved combinationsssssssssssssssssss')
    const isApproved = approvedCombinations[`${catalogName}-${schemaName}`] || false;
    console.log("Current isApproved state:", isApproved);

    // PS_154 to PS_160
    const handleBackClick = () => {
        console.log();
        debugger
        if (isSourceToTargetAllApproved) {
            state = { ...state, isSourceToTargetAllApproved: true };
            props.setIsSourceAllApproved(true)
        }
        props.setShowCode();
        props.isEdit(false);

        console.log(state, 'ssssssssssssssssssssss');

    };


    const modifyUML = (umlCode, showCatalog, showSchema, showTable) => {
        let modifiedUML = umlCode;

        if (!showTable) {
            modifiedUML = modifiedUML.replace(/TableName : .*\n/g, '');
        }
        if (!showCatalog) {
            modifiedUML = modifiedUML.replace(/CatalogName : .*\n/g, '');
        }
        if (!showSchema) {
            modifiedUML = modifiedUML.replace(/SchemaName : .*\n/g, '');
        }
        return modifiedUML;
    };
    // PS_161 to PS_164
    const handleCopyText = () => {
        setCopiedText(true);
        setTimeout(() => setCopiedText(false), 2000);
    };
    const handleOpenModal = () => {
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };
    
    
    
    return (
        <>
            <div
                id="approvaltoastMessage"
                className="toast custom-toast align-items-center mt-5"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                data-bs-delay="3000"
                style={{
                    position: "fixed",
                   
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1050,  // Ensure it appears above other content
                    display: "none"
                }}
            >
                <div className="d-flex">
                    <div className="toast-body">
                        <span>
                            <img src="images/check 1.svg" style={{ height: "22px" }} alt="check-icon" />
                        </span>
                        <span className="mx-3 font-14 loader-text">
                            Approvals are done
                        </span>
                    </div>
                </div>
            </div>
    
            <div className="container-fluid">
                {tableMapping ? (
                    <TableMapping
                        data={{
                            ...props.data,
                            hyperlinkText: "TableMapping"
                        }}
                        setShowCode={props.setShowCode}
                        onChange={props.onChange}
                        isEdit={props.isEdit}
                        filter={{ catalog: catalogName, schemaName: schemaName }}
                        uploadedData={props.uploadedData}
                        closePopup={props.closePopup}
                        validationColumns={props.validationColumns}
                        textData='Table Mapping Details'
                        saveFunction={props.saveFunction}
                        templateURL="https://example.com/template_table_mapping.xlsx"
                    />
                ) : (
                    <>
                        <div style={{ marginTop: '80px' }}>
                            <HeaderCom value="1" />
                            <div className="mt-3 px-2">
                                <ul className="cust-breadcrumb mb-0">
                                    <li className="font-16 font-medium">
                                        <a>Manage Projects</a>
                                    </li>
                                    <li className="font-16 font-medium">
                                        <a>{state.projectName}</a>
                                    </li>
                                    <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-md-12 pt-4 mt-5 px-md-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex align-items-center justify-content-between pb-4">
                                        <div className="d-flex align-items-center">
                                            <a onClick={handleBackClick}>
                                                <img src="images/back-arrow-new.svg" alt="back-arrow" className="me-3 mb-2"  style={{ cursor: 'pointer' }}/>
                                            </a>
                                            <h2 className="text-black font-bold font-22 mb-0">
                                                Source to Target Diagram
                                                <button type="button" className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3" onClick={handleOpenModal}>
                                                    <img src="images/info-icon-ETL.svg" alt="info" />
                                                </button>
                                            </h2>
                                            {isModalOpen && (
                                                <ViewInstructions
                                                    notebooktype="sourceToTarget"
                                                    onClose={handleCloseModal}
                                                />
                                            )}
                                        </div>
                                        <div className="d-flex">
                                            <div className="me-2 dropdown-width" ref={catalogRef}>
                                                <div className="dropdown">
                                                    <a
                                                        className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"
                                                        role="button"
                                                        id="dropdownMenuLink"
                                                        onClick={() => setIsCatalogOpen(prev => !prev)}
                                                        aria-expanded={isCatalogOpen}
                                                    >
                                                        {catalogName.length > 10 ? catalogName.substring(0, 10) + "..." : catalogName || "Select Catalog"}
                                                    </a>
                                                    {isCatalogOpen &&
                                                        <ul className="dropdown-menu w-100 cust-drop-menu show" aria-labelledby="dropdownMenuLink">
                                                            {uniqueCatalogName.map((catalog, index) => (
                                                                <li key={index}>
                                                                    <a
                                                                        className="dropdown-item font-12"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => handleCatalogSelect(catalog)}
                                                                    >
                                                                        {catalog}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
    
                                            <div className="me-2 dropdown-width" ref={schemaRef}>
                                                <div className="dropdown">
                                                    <a
                                                        className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"
                                                        role="button"
                                                        id="dropdownMenuLink"
                                                        onClick={() => setIsSchemaOpen(prev => !prev)}
                                                        aria-expanded={isSchemaOpen}
                                                    >
                                                        {schemaName.length > 10 ? schemaName.substring(0, 10) + "..." : schemaName || "Select Schema"}
                                                    </a>
                                                    {isSchemaOpen &&
                                                        <ul className="dropdown-menu w-100 cust-drop-menu show" aria-labelledby="dropdownMenuLink">
                                                            {uniqueSchemaName.length > 0 ? (
                                                                uniqueSchemaName.map((schema, index) => (
                                                                    <li key={index}>
                                                                        <a
                                                                            className="dropdown-item font-12"
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleSchemaSelect(schema)}
                                                                        >
                                                                            {schema}
                                                                        </a>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li>
                                                                    <a className="dropdown-item font-12">No schemas available</a>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
    
                                            <button type="button" className="btn cust-secondary-btn font-14 font-regular me-md-2" onClick={handleCaptureClick}>
                                                <span className="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download
                                            </button>
                                            <button type="button" className="btn cust-primary-btn edit-btn font-14 font-regular me-md-2" onClick={handleEdit}>
                                                <span className="me-2"><img src="images/edit-icon-white.svg" alt="add-icon" /></span> Edit
                                            </button>
                                            <button type="button" className="btn cust-primary-btn skip-btn font-14 font-regular me-md-2" style={{ backgroundColor: '#FFA41C', borderColor: '#FFA41C' }} onClick={handleSkip}>
                                                <span className="me-2"><img src="images/skip-icon.svg" alt="add-icon" /></span> Skip
                                            </button>
                                            <button
                                                type="button"
                                                className="btn cust-primary-btn approve-btn font-14 font-regular me-md-2"
                                                style={{
                                                    backgroundColor: isApproved || schemaName === "ALL Schema" ? '#ccc' : '#00AB08',
                                                    borderColor: isApproved || schemaName === "ALL Schema" ? '#ccc' : '#00AB08'
                                                }}
                                                onClick={handleApprove}
                                                disabled={isApproved || schemaName === "All Schema"}
                                            >
                                                <span className="me-2">
                                                    <img src="images/approve-icon.svg" alt="approve-icon" />
                                                </span>
                                                {isApproved ? 'Approved' : 'Approve'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <a
                                            className="font-14 font-medium text-decoration-none"
                                            onClick={handleViewCode}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            View Code
                                        </a>
                                    </div>
                                    <div className="bg-textarea">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center me-3 p-3">
                                                <input className="form-check-input custom-checkbox mt-0 me-2" type="checkbox" id="Catalog" checked={showCatalog} onChange={() => handleCheckBox('catalog')} />
                                                <label className="font-13 font-regular" htmlFor="Catalog">Catalog</label>
                                            </div>
                                            <div className="d-flex align-items-center me-3">
                                                <input className="form-check-input custom-checkbox mt-0 me-2" type="checkbox" id="Schema" checked={showSchema} onChange={() => handleCheckBox('schema')} />
                                                <label className="font-13 font-regular" htmlFor="Schema">Schema</label>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <input className="form-check-input custom-checkbox mt-0 me-2" type="checkbox" id="Table" checked={showTable} onChange={() => handleCheckBox('table')} />
                                                <label className="font-13 font-regular" htmlFor="Table">Table</label>
                                            </div>
                                        </div>
                                        <div className="position-relative">
                                            <div className="position-absolute custom-btn-position source-diag bg-white rounded-3">
                                                <button type="button" className="btn btn-common-style btn-zoom-in custom-bottom-brd" onClick={handleZoomIn}><img src="images/zoomin-icon.svg" alt="zoom-in" /></button>
                                                <button type="button" className="btn btn-common-style btn-zoom-out" onClick={handleZoomOut}><img src="images/zoomout-icon.svg" alt="zoom-out" /></button>
                                            </div>
                                            <div className="form-control custom-textarea logger-textarea-scroll" id="daimage123">
                                            <div style={{ transform: `scale(${zoom})`, transformOrigin: 'top left' }}>
                                            {umlImage ? (
                                                        <img src={`data:image/svg+xml;base64,${btoa(umlImage)}`} alt="UML Diagram" />
                                                    ) : (
                                                        <p>Loading diagram...</p>
                                                    )}
      </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    {showModal && (
                                        <div className="modal fade show" id="Confrm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                            aria-labelledby="ConfrmLabel" aria-hidden="true" style={{ display: 'block' }}>
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0">
                                                        <h5 className="modal-title d-flex align-items-center font-medium font-20"
                                                            id="ConfrmLabel">Code Snippet</h5>
                                                        <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body py-0">
                                                        <div className="row d-flex justify-content-center">
                                                            <div className="col-10">
                                                                <textarea className="form-control custom-textarea logger-textarea-scroll add-code-snippet"
                                                                    id="exampleFormControlInput1" value={umlCode} readOnly></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer border-0 px-4">
                                                        <button
                                                            type="button"
                                                            className="btn cust-primary-btn font-14 font-medium"
                                                            onClick={() => setShowModal(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <CopyToClipboard text={umlCode} onCopy={handleCopyText}>
                                                            <button
                                                                type="button"
                                                                className="btn cust-primary-btn font-14 font-medium"
                                                            >
                                                                {copiedText ? "Copied!" : "Copy Code"}
                                                            </button>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div className="spinner-border Loader text-dark align-center" role="status">
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SourceToTargett;
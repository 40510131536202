import React from 'react';

const TableMappingPopup = ({ uploadedData, closePopup, validationColumns, popupText, templateURL }) => {
  const renderTable = (sheetName, sheetData) => {
    if (!sheetData || sheetData.length === 0) return null;

    const headers = sheetData[0];
    const rows = sheetData.slice(1);

    return (
      <div key={sheetName}>
        <h3>{sheetName}</h3>
        <table border="1">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="popup">
      <h2>{popupText}</h2>
      {uploadedData && Object.entries(uploadedData).map(([sheetName, sheetData]) => renderTable(sheetName, sheetData))}
      <button onClick={closePopup}>Close</button>
      <button onClick={() => window.open(templateURL, '_blank')}>Download Template</button>
    </div>
  );
};

export default TableMappingPopup;
import React, { useState, useEffect } from 'react';

const FilterComponent = ({ columns, actualValue, setGridData, searchFilterTrigger }) => {
    const [filters, setFilters] = useState({});
    const [showFilter, setShowFilter] = useState(false);

    useEffect(() => {
        // Clear filters when the trigger changes
        setFilters({});
        setGridData(actualValue);
    }, [searchFilterTrigger]);

    const handleFilterChange = (column, value) => {
        setFilters(prev => ({ ...prev, [column]: value }));
    };

    const applyFilters = () => {
        const filteredData = actualValue.filter(item =>
            Object.entries(filters).every(([column, value]) =>
                value === '' || item[column] === value
            )
        );
        setGridData(filteredData);
        setShowFilter(false);
    };

    const clearFilters = () => {
        setFilters({});
        setGridData(actualValue);
        setShowFilter(false);
    };

    return (
        <div className="btn-group me-3">
            <button
                className="btn rounded-2 cust-filter-btn py-1 d-flex align-items-center"
                type="button"
                onClick={() => setShowFilter(!showFilter)}
            >
                <img
                    src="images/filter-icon.svg"
                    alt="filter-icon"
                    className="filt-icon"
                />
            </button>
            {showFilter && (
                <div className="dropdown-menu custom-filter shadow-sm border-0 p-3 show">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="font-18 font-bold text-black mb-0">Filter</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => setShowFilter(false)}
                            />
                        </div>
                        {columns.map(column => (
                            <div key={column} className="mb-3">
                                <label htmlFor={column} className="form-label text-black font-14 font-medium">
                                    {column}
                                </label>
                                <select
                                    className="form-select cust-input-sty font-14 font-regular"
                                    id={column}
                                    value={filters[column] || ''}
                                    onChange={(e) => handleFilterChange(column, e.target.value)}
                                >
                                    <option value="">Select</option>
                                    {[...new Set(actualValue.map(item => item[column]))].map(value => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                            </div>
                        ))}
                        <div className="text-end mt-4 mb-2">
                            <button
                                type="button"
                                className="btn btn-link text-decoration-none text-black shadow-none font-14 font-medium px-3 me-3"
                                onClick={clearFilters}
                            >
                                Clear
                            </button>
                            <button
                                type="button"
                                className="btn cust-primary-btn font-14 font-medium px-4"
                                onClick={applyFilters}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterComponent;
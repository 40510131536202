import React, { useState } from 'react';

const ViewInstructions = ({ onClose , notebooktype}) => {
  return (
    <div
      className="modal fade show d-block"
      tabIndex={-1}
      role="dialog"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="modal-dialog view-instructions-popup modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              <div className="col-md-4">
                <div className="view-instructions-popup-left-section">
                  <img
                    src="images/view-instructions-popup-banner-img.png"
                    alt="view-instructions-popup-banner-img"
                    className="popup-banner-img"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="view-instructions-popup-right-section">
                  <div className="d-flex justify-content-between">
                    <h1 className="font-bold font-20 m-0">View Instructions</h1>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={onClose} // Handle close
                    />
                  </div>
                  {
                  notebooktype === 'analysisDocument' && (
                  <ul className="mt-4 view-instructions-list-items">
                    <li>Please validate whether the data’s are correct</li>
                    <li>
                      If there are additional Notebooks to be added, click on{' '}
                      <span className="font-bold">‘Upload’</span> and select{' '}
                      <span className="font-bold">‘Existing Notebook’</span>
                    </li>
                    <li>
                      Please click on the Notebook Name hyperlink to view the old and
                      New versions of the Notebook's code.
                    </li>
                    <li>
                      The Notebook to be tested column will be empty as that needs to
                      be confirmed with the client along with the Analysis document
                      generated.
                    </li>
                    <li>
                      Please upload the Analysis document back into the application
                      along with the confirmed notebooks to be tested from the client
                      until which the{' '}
                      <span className="font-bold">‘Looks Good’</span> will not be
                      enabled in the chat.
                    </li>
                    <li>
                      If things are fine just click{' '}
                      <span className="font-bold">‘back’</span> then on{' '}
                      <span className="font-bold">‘Looks good’</span> and proceed to
                      next steps
                    </li>
                  </ul>)
                  }
                  {
  notebooktype === 'inventoryDocument' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>Please validate whether the subject areas are mapped correctly</li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'inventorydatamodel' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'catalogAndSchema' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'tableMapping' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        Double tap on the grid record to edit the records in the inline directly
      </li>
      <li>
        Download the Table Mapping details and get that approved from the client and{' '}
        <span className="font-bold">Upload</span> the updated document back to the application
      </li>
      <li>
        Please confirm the following columns with the client namely,{' '}
        <span className="font-bold">Version History</span>,{' '}
        <span className="font-bold">Load Type</span> and{' '}
        <span className="font-bold">InScope/OutScope</span>
      </li>
      <li>
        Approve the changes made in the Table Mapping details post client's approval in the{' '}
        <span className="font-bold">"Upload Confirmed Table Mapping Sheet"</span> pop-up
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'sourceToTarget' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>Please validate and approve each Catalog and Schema combination</li>
      <li>
        If you are not fine with the way the diagram is represented, make use of the{' '}
        <span className="font-bold">copy code</span> button in the{' '}
        <span className="font-bold">View Code</span> pop-up and use{' '}
        <span className="font-bold">Asami</span> to make any changes in the diagrammatic representation
      </li>
      <li>
        Check the <span className="font-bold">Tables</span> checkbox to view the Source To Target diagram
        at the table level
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'approachDiagram' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}

{
  notebooktype === 'dataDictionary' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please select the catalog from the list of options to see the Data Dictionary at the Catalog level
      </li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'metaDataTable' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'ddlPseudo' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please select the catalog from the list of options to see the DDL Scripts for the Tables under the respective Catalog
      </li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'loggerPseudo' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'utilitiesPseudo' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'configurationPseudo' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please validate whether the pseudocode is generated only for the Configuration Migration Patterns
      </li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'traditionalPseudo' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please validate whether the pseudocode is generated for the Load Type mentioned as{' '}
        <span className="font-bold">CTAS</span> and the Migration Pattern as{' '}
        <span className="font-bold">Migration</span>
      </li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'copyClonePseudo' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please validate whether the pseudocode is generated for the Load Type mentioned as{' '}
        <span className="font-bold">Deep Clone</span> and the Migration Pattern as{' '}
        <span className="font-bold">Migration</span>
      </li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'changesPseudo' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'codereviewdesign' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'unitTestCasePseudo' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'dataQualityPseudo' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If any changes are required, update them accordingly using{' '}
        <span className="font-bold">‘Actions’</span> on the last column
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'dataQualityCode' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'loggerCode' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}

{
  notebooktype === 'utilityCode' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}


{
  notebooktype === 'configurationCode' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please Validate whether the code is generated only for the Configuration Migration Patterns
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'traditionalCode' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please validate whether the code is generated for the Load Type mentioned as CTAS and the Migration Pattern as Migration
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'copyCloneCode' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please validate whether the code is generated for the Load Type mentioned as Deep Clone and the Migration Pattern as Migration
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'changesCode' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please validate whether the code is generated for the changes involved in the Notebooks
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'codereviewdev' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'unitTestCaseCode' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please upload the Unit Test Case result post completing the unit test case code generation
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'notebookRecommendations' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please click on the Information Icon beside the Issues header to view the Log ID and its description
      </li>
      <li>
        If you are fine with the suggestion, approve by clicking the tick icon in the actions column and reject by clicking on the cross icon
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'dataQualityCode' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>Please validate whether the data’s are correct</li>
      <li>
        Please upload the Data Quality result post completing the Data Quality code generation
      </li>
      <li>
        If things are fine, just click <span className="font-bold">‘back’</span> then on{' '}
        <span className="font-bold">‘Looks good’</span> and proceed to next steps
      </li>
    </ul>
  )
}
{
  notebooktype === 'dataQualityResults' && (
    <ul className="mt-4 view-instructions-list-items">
      <li>
        Compare row counts between source and destination tables. If they don't match, investigate the last update timestamps for both the Hive Metastore table and the Unity Catalog table.
      </li>
      <li>
        Verify if any rows have been removed from the Hive Metastore table subsequent to the data transfer into the Unity Catalog table.
      </li>
      <li>
        If steps 1 and 2 don't reveal the cause of the discrepancy, examine the implementation details of both the copy-clone method pseudocode and the traditional data transfer approach pseudocode.
      </li>
    </ul>
  )
}


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default ViewInstructions;
import React, { useState, useEffect } from 'react';
import {  useLocation } from 'react-router-dom';
import { fetchPseudocodeData, updatePseudocodeData, generateSasTokenAPI } from '../../Service/Api.js';
import { HeaderCom } from "../Header.jsx";

function DaxGrid(props) {
    const [filteredRecord, setFilteredRecord] = useState([]);
    const [newData, setNewData] = useState({
        name: "",
        dax_expression: ""
    });
    const [action, setAction] = useState("");
    const [editableRow, setEditableRow] = useState("empty");
    const [whetherUpdated, setwhetherUpdated] = useState(false);
    const { state } = useLocation();
    const [selectedCode, setSelectedCode] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        fetchJsonFromBlob()
    }, []);
    const accountName = 'avaeusgenetlsametadev';
    const containerName = 'uc-migration';
    const fetchJsonFromBlob = async () => {
        try {
            document.getElementById('pageLoader').style.display = "block";
            
            const sasToken = await generateSasTokenAPI();
            const blobURL = `organizations/${state.orgId}/${state.projectId}/files/Dax.json`;
            const burl = `${blobURL}?${sasToken}`;
            const url = `https://${accountName}.blob.core.windows.net/${containerName}/${burl}`;
            const response = await fetch(url);
            console.log(response, "Response from the blob");
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            // Read the response stream and convert to a JSON object
            const data = await response.text();
            const jsonData = JSON.parse(data);
    
            document.getElementById('pageLoader').style.display = "none";
            setFilteredRecord(jsonData);
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            document.getElementById('pageLoader').style.display = "none";
            document.getElementById('openErrorModal').click();
        }
    };


    const bindGrid = () => {
        try {
            if (Array.isArray(filteredRecord) && filteredRecord.length > 0) {
                return (
                    <>
                        {filteredRecord.map((item, index) => {
                            const truncatedDax = item.dax_expression.length > 20 
                                ? item.dax_expression.substring(0, 20) + '... '
                                : item.dax_expression;
                            
                            return (
                                <tr key={index}>
                                    <td>{item.name || "NA"}</td>
                                    <td>
                                        {truncatedDax}
                                        {item.dax_expression.length > 20 && (
                                            <a 
                                                href="#" 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSelectedCode(item.dax_expression);
                                                    setShowModal(true);
                                                }}
                                            >
                                                View Code
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="2" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindGrid:", error);
            return null;
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(selectedCode).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
        });
    };

    return (
        <div className="container-fluid px-md-4">
            <div className="row">
                <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div className="d-flex align-items-center loader-bg">
                            <div className="spinner-border Loader text-dark align-center" role="status">
                                <span className="visually-hidden"></span>
                            </div>
                            <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                        </div>
                    </div>
                </div>
                <HeaderCom value='1' />
                <div className="col-md-12 pt-4 mt-5">
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a>Manage Projects</a></li>
                            <li className="font-16 font-medium"><a>{state.projectName}</a></li>
                            <li className="font-16 font-medium active">Tableau Expression to Power Bi Dax</li>
                        </ul>
                    </div>
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div className="d-flex">
                            <a className="d-flex"><img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px' }} onClick={() => { props.closeDax(); }} /></a>
                            <h2 className="text-black font-bold font-22 mb-0 ms-3">Tableau Expression to Power Bi Dax</h2>
                        </div>
                    </div>
                    <div className="col-md-12 px-4">
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                                <div className="table-responsive cust-table rounded-3">
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th className="text-nowrap">Name<span className="ms-2 cursor-pointer"></span></th>
                                                <th className="text-nowrap">Dax Code<span className="ms-2 cursor-pointer"></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bindGrid()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <div className="modal show d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-0 px-4">
                                <h5 className="modal-title d-flex align-items-center font-medium font-20">DAX Expression</h5>
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowModal(false)}></button>
                            </div>
                            <div className="modal-body py-0">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-10">
                                        <textarea
                                            className="form-control custom-textarea logger-textarea-scroll add-code-snippet no-word-wrap"
                                            id="exampleFormControlInput1"
                                            value={selectedCode}
                                            readOnly
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 px-4 mx-5 mt-3">
                                <button type="button" className="btn cust-primary-btn font-14 font-medium me-2"
                                    onClick={handleCopy}
                                >
                                    <img src="images/copy-code-icon.svg" alt="copy" className="me-2" />
                                    <span>{isCopied ? "Copied" : "Copy Code"}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DaxGrid;


//   PS_TM_01  Import React, useState, useEffect, useRef, useMemo, useLocation, useNavigate, socket.io-client, UUID generator, components, and any other necessary libraries or modules 
import { isValidDateValue } from "@testing-library/user-event/dist/utils";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError, generateSasTokenAPI, postTableMapping } from "../../../Service/Api.js";
import { HeaderCom } from "../../Header";
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import ViewInstructions from './viewInstructions.jsx'


import * as XLSX from 'xlsx';
import DynamicFileUploadPopup from "../DynamicFileUploadPopup.jsx";
import ConfirmationTMU from "./ConfirmedTableMapping.jsx";

// PS_TM_02  Declare the functional component TableMapping that will render the table mapping interface 
function TableMapping(props) {

    //  PS_TM_03 to PS_TM_33  declare the state variable used to hold the data
    const navigate = useNavigate();
    const [socket, setSocket] = useState(null);
    let { state } = useLocation();
    const { filter } = props; // Extracting filter from props
    const [jsonData, setJsonData] = useState([]);
    const [whetherUpdated, setWhetherUpdated] = useState(false);
    const [response, setResponse] = useState([])
    const [isEditingData, setIsEditingData] = useState(false)
    const [searchTerm, setSearchTerm] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' }); const [editingIndex, setEditingIndex] = useState(null);
    const [reason, setReason] = useState("");
    const [pendingChange, setPendingChange] = useState(null);
    const [blobUrl, setblobUrl] = useState('');
    const [importUpload, setImportUpload] = useState(false);
    const [popHeader, setPopHeader] = useState('');
    const [inputValues, setInputValues] = useState({
        InScopeOrOutScope: 'Select',
        NewCatalogName: '',
        MigrationPattern: '',
        LoadType: '',
        NewSchemaName: '',
        NewStoragePath: '',
        NewStorageType: 'Select',
        NewTableName: '',
        SourceCatalogName: '',
        SourceSchemaName: '',
        SourceStoragePath: '',
        SourceStorageType: 'Select',
        SourceTableName: '',
        VersionHistory: 'Select',
        validated: 'false'
    });


    // const [inputValues, setInputValues] = useState({})
    const [subjectArea, setSubjectArea] = useState([])
    // const [schemaName, setSchemaName] = useState([])
    const [body, setBody] = useState({})
    // Define your initial filter criteria as a constant
    const [filterCriteria, setFilterCriteria] = useState({
        SourceCatalogName: filter?.catalog || "",
        SourceSchemaName: filter?.schemaName || "",
        NewCatalogName: "",
        NewSchemaName: "",
        InScopeOrOutScope: ""
    });

    const [IsFilteredApplied, setIsFilteredApplied] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [constraints, setConstraints] = useState(["NULL", "NOTNULL", "Primary Key", "Foreign Key", "Unique"]);
    const [editedRow, setEditedRow] = useState({});
    const [isInlineEditing, setIsInlineEditing] = useState(false);
    const [editingCell, setEditingCell] = useState({ row: null, column: null });
    const [changes, setChanges] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [popupErrors, setPopupErrors] = useState({});
    const [isTableEdited, setIsTableEdited] = useState(false);
    const [deleteData, setDeleteData] = useState([])
    const [deleteItem, setDeleteItem] = useState(null);

    const [formValues, setFormValues] = useState({
        InScopeOrOutScope: 'Select',
        NewCatalogName: '',
        MigrationPattern: '',
        LoadType: '',
        NewSchemaName: '',
        NewStoragePath: '',
        NewStorageType: 'Select',
        NewTableName: '',
        SourceCatalogName: '',
        SourceSchemaName: '',
        SourceStoragePath: '',
        SourceStorageType: 'Select',
        SourceTableName: '',
        VersionHistory: 'Select',
        validated: 'false'

    });
    useEffect(() => {
        if (filter) {
            // Check if 'ALL Schema' is selected
            const isAllSchema = filter.schemaName === "ALL Schema";

            setFilterCriteria(prevFilterCriteria => ({
                ...prevFilterCriteria,
                SourceCatalogName: filter.catalog,
                SourceSchemaName: isAllSchema ? '' : filter.schemaName // If ALL Schema, set to empty string
            }));
        }
    }, [filter]);


    useEffect(() => {
        console.log("Applied Filter Criteria: ", filterCriteria);
    }, [filterCriteria]);

    // const state = {"projectId":"28CDF1E7-ADCC-43D6-A2AD-D1C608E8783B"}

    //  PS_TM_33 to  PS_TM_35  Sketch isPopFormFilled() function to validate all necessary form fields are filled before data submission 
    
    const isPopFormFilled = () => {
        const requiredFields = [
            'SourceCatalogName',
            'SourceSchemaName',
            'SourceTableName',
            'SourceStorageType',
            'SourceStoragePath',
            'NewCatalogName',
            'NewSchemaName',
            'NewTableName',
            'NewStorageType',
            'VersionHistory',
            'MigrationPattern',
            'LoadType',
            'InScopeOrOutScope'
        ];
    
        // Check if all required fields are filled
        const allRequiredFilled = requiredFields.every(field => {
            const value = formValues[field];
            const isValid = value && value.trim() !== '';
            console.log(`Field ${field}: ${value}, isValid: ${isValid}`);
            return isValid;
        });
    
        // NewStoragePath is only required if NewStorageType is 'EXTERNAL' or 'SOURCETYPE'
        const isNewStoragePathValid = (formValues.SourceStorageType === 'MANAGED' && formValues.NewStorageType === 'SOURCETYPE')||
            (formValues.NewStorageType === 'EXTERNAL' || formValues.NewStorageType === 'SOURCETYPE') && 
            (formValues.NewStoragePath && formValues.NewStoragePath.trim() !== '');
    
        // Log the state for debugging
        console.log(`isNewStoragePathValid: ${isNewStoragePathValid}`);
    
        return allRequiredFilled && (formValues.NewStorageType === 'MANAGED' ? true : isNewStoragePathValid);
    };
    
    // PS_TM_36 to  PS_TM_37  Define handleFormChange function to update state when form inputs change, ensuring the form reflects user input 
    // const handleFormChange = (e) => {
    //     const { name, value } = e.target;

    //     setFormValues({
    //         ...formValues,
    //         [name]: value
    //     });
    // };

  

    console.log("stateeee", state)

    // PS_TM_39 to  PS_TM_41  Implement useEffect to listen for state change in form fields that dynamically set another field, specifically MigrationPattern, based on changes in SourceStorageType or NewStorageType 
    useEffect(() => {
        // Determine the new value for MigrationPattern based on current form values
        const newMigrationPattern =
            (formValues.SourceStorageType === 'EXTERNAL' ) &&
                (formValues.NewStorageType === 'EXTERNAL' || formValues.NewStorageType === 'SOURCETYPE')
                ? 'Configuration'
                : 'Migration';

        // Update MigrationPattern in formValues
        setFormValues(prev => ({ ...prev, MigrationPattern: newMigrationPattern }));
    }, [formValues.SourceStorageType, formValues.NewStorageType]); // 


    const accountName = 'avaeusgenetlsametaprod';
    const containerName = 'uc-migration';

    //  PS_TM_42 to  PS_TM_49  Draft an asynchronous fetchJsonFromBlob function to make HTTP GET requests to Azure Blob Storage including obtaining a valid SAS token and handling the blob URL 
    const fetchJsonFromBlob = async (UCTableMappingURL) => {
        try {
            const sasToken = await generateSasTokenAPI();
            console.log(sasToken, 'sas')
            // const blobPath = `${orgName}/${projectName}/json/${fileName}`;
            // const url = `https://avaeusgenetlsametaprod.blob.core.windows.net/chatmainsample/my_org/my_project_suman/json/tablemap.json?${sasToken}`;
            let url = `${UCTableMappingURL}?${sasToken}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonData = await response.json();
            console.log(jsonData, 'tablemapdata');
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };
    // PS_TM_50 to  PS_TM_55  Trigger a useEffect hook to establish a WebSocket connection on mount using the backend URL and appropriate path configuration 
    useEffect(() => {
        const backendUrl = 'https://genetlnpapi.asami.ai';

        // Adjusted for path
        const newSocket = io(backendUrl, {
            path: '/meta/socket.io',  // Ensure path matches the backend Socket.IO path
            transports: ['websocket', 'polling'],  // Define transports for flexibility
        });

        newSocket.on('connect', () => {
            console.log('Connected to server');
            newSocket.emit('joinProject', state.projectId);
        });

        newSocket.on('dataDictionaryUpdate', handleSocketUpdate);

        newSocket.on('connect_error', (err) => {
            console.error('Connection error:', err.message);
        });

        setSocket(newSocket);

        return () => {
            newSocket.emit('leaveProject', state.projectId);
            newSocket.off('dataDictionaryUpdate', handleSocketUpdate);
            newSocket.close();
        };
    }, [state.projectId]);

    // PS_TM_56 to  PS_TM_62  Define handleSocketUpdate function to manage data updates received through the WebSocket 
    const handleSocketUpdate = ({ actionType, data }) => {
        switch (actionType) {
            case 'init':
                setJsonData(data);
                break;
            case 'add':
                setJsonData(prevData => [...prevData, data]);
                break;
            case 'edit':
                setJsonData(prevData => prevData.map(item =>
                    item.TableMappingID === data.TableMappingID ? data : item
                ));
                break;
            case 'delete':
                setJsonData(prevData => prevData.filter(item =>
                    item.TableMappingID !== data.TableMappingID
                ));
                break;
            case 'validate':
                setJsonData(prevData => prevData.map(item =>
                    item.TableMappingID === data.TableMappingID
                        ? { ...item, validated: data.validated }
                        : item
                ));
                break;
            default:
                console.log("Unknown update type:", actionType);
        }
        fetchPseudo()
    };

    // PS_TM_63 to PS_TM_67  Implement handleValidateChange function to update validation status and hold edits until save 
    const handleValidateChange = async (index, validated) => {
        const currentItem = jsonData[index];
        const updatedDataItem = { ...currentItem, validated: validated.toString() };

        // Create updated data array using TableMappingID
        const updatedData = jsonData.map((item) =>
            item.TableMappingID === currentItem.TableMappingID
                ? updatedDataItem
                : item
        );
        console.log(updatedData);

        // Update local state
        setJsonData(updatedData);

        // Emit socket event
        if (socket) {
            socket.emit('updateData', {
                projectId: state.projectId,
                actionType: 'validate',
                data: updatedDataItem,
            });
        }

        try {
            const response = await updateJsonInBlob(blobUrl, updatedData);

            // if (response.ok) {
            //     console.log('Successfully updated validate status in blob storage');
            // } else {
            //     console.error('Failed to update validate status in blob storage. Response status:', response.status);
            // }
        } catch (error) {
            console.error('Error updating validate status in blob storage:', error);
        }
    };

    // PS_TM_68 to  PS_TM_71  Compose the getFilteredData function to apply filtering logic and return the filtered version of jsonData 
    const getFilteredData = (jsonData, searchKeyword, filterCriteria) => {
        let data = jsonData;

        if (searchKeyword.length > 3) {
            const keyword = searchKeyword.toLowerCase();
            data = data.filter(item => {
                return item.SourceSchemaName.toLowerCase().includes(keyword) ||
                    item.SourceCatalogName.toLowerCase().includes(keyword) ||
                    item.NewSchemaName.toLowerCase().includes(keyword);
            });
        } else {
            data = jsonData.filter(item => {
                return (
                    (!filterCriteria.SourceCatalogName || item.SourceCatalogName.toLowerCase() === filterCriteria.SourceCatalogName.toLowerCase()) &&
                    (filterCriteria.SourceSchemaName === 'all' || !filterCriteria.SourceSchemaName || item.SourceSchemaName.toLowerCase() === filterCriteria.SourceSchemaName.toLowerCase()) &&
                    (!filterCriteria.NewCatalogName || item.NewCatalogName.toLowerCase() === filterCriteria.NewCatalogName.toLowerCase()) &&
                    (!filterCriteria.NewSchemaName || item.NewSchemaName.toLowerCase() === filterCriteria.NewSchemaName.toLowerCase()) &&
                    (!filterCriteria.InScopeOrOutScope || item.InScopeOrOutScope.toLowerCase() === filterCriteria.InScopeOrOutScope.toLowerCase())
                );
            });
        }

        return data;
    };

    // Memoize filtered and sorted data
    const handleSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction });
    };

    // Determine sort icon based on the current direction
    const getSortIcon = (columnKey) => {
        if (sortConfig.key === columnKey) {
            return sortConfig.direction === 'ascending' ? 'sort-up-icon.svg' : 'sort-down-icon.svg';
        }
        return 'sort-down-icon.svg';  // Default icon when not sorted
    };

    // Memoizing the sorted data
    const filteredData = useMemo(() => {
        const filtered = getFilteredData(jsonData, searchKeyword, filterCriteria);

        if (sortConfig.key !== null) {
            return [...filtered].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return filtered;
    }, [jsonData, searchKeyword, filterCriteria, sortConfig]);


    useEffect(() => {
        if (isTableEdited) {
            /**
            */
            console.log('Table is being edited');
        }
    }, [isTableEdited]);
    // PS_TM_72 to  PS_TM_74  Prepare useEffect to invoke data fetching functions and manipulate loading/error states upon component mount 
    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchDropDown(), fetchPseudo()]);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        console.log(jsonData, 'JSONNNN')
        fetchData();
    }, []);

    // PS_TM_75 to  PS_TM_81  Define fetchPseudo as an asynchronous function to request and retrieve pseudocode data from the server API 
    const fetchPseudo = async () => {
        document.getElementById('pageLoader').style.display = 'block';
        const body = {
            projectId: state.projectId,
            type: "tablemapping",
            userId: state.userId,
            orgId: state.orgId,
        };

        try {
            const res = await fetchPseudocodeData(body, state.jwt);
            document.getElementById('pageLoader').style.display = 'none';
            console.log(res, 'tata');
            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                console.log("The Response Is 200");
                let UCTableMappingURL = res?.data[0]?.UCTableMappingURL;
                setblobUrl(UCTableMappingURL)
                console.log(UCTableMappingURL, 'fetchJsonFromBlobfetchJsonFromBlob');
                try {
                    const jsonData = await fetchJsonFromBlob(UCTableMappingURL);
                    console.log(jsonData, "Data from blob");

                    if (jsonData) {
                        let pseudo = jsonData.map((obj, index) => ({
                            ...obj,
                            index: index
                        }));
                        setJsonData(jsonData)
                        console.log(pseudo, "Mapped data");
                    } else {
                        throw new Error("Failed to fetch data from Azure Blob Storage");
                    }
                } catch (e) {
                    console.error('Error fetching JSON from blob:', e);
                    handleError(e);
                }
            }
        } catch (e) {
            console.error('Error fetching pseudocode data:', e);
            document.getElementById('pageLoader').style.display = 'none';
            handleError(e);
        }
    }
    //  PS_TM_82 to  PS_TM_84  Establish sessionExpired function to clear user session and redirect to the login route upon session expiration 
    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };

    // PS_TM_85 to  PS_TM_86  Draft handleError function to provide user feedback when an error occurs within the component 
    const handleError = (e) => {
        insertError({
            errorMessage: e.message,
            serviceName: "frontend",
            module: "Datadictionary",
            functionName: "DataDictionaryTable",
            userId: state.userId
        });
        console.log("error", e);
        setError(e.message);
    }
    // PS_TM_87 to  PS_TM_92  Compose an asynchronous function 'updateJsonInBlob' to send updated data back to Azure Blob Storage 
    const updateJsonInBlob = async (blobUrl, updatedData) => {
        try {
            const sasToken = await generateSasTokenAPI();
            // const blobPath = `${orgName}/${projectName}/json/${fileName}`;

            // const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
            let url = `${blobUrl}?${sasToken}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            console.log('Data updated successfully in Azure Blob');
        } catch (error) {
            console.error('Error updating JSON in blob:', error);
            throw error;
        }
    };

    //  PS_TM_93 to  PS_TM_101  Establish an asynchronous function 'handleAddClick' to manage the addition or update of a record 
    const handleAddClick = async () => {
        debugger
        setPopHeader('')
        console.log('Attempting to add or update a record...');
        setIsTableEdited(true);
        // Generate a new TableMappingID if it doesn't exist
        if (!formValues.TableMappingID) {
            console.log('Generating new TableMappingID for new record...');
            formValues.TableMappingID = uuidv4();
        }

        // Confirm the TableMappingID value
        const formTableMappingID = formValues.TableMappingID;
        console.log('Form TableMappingID:', formTableMappingID);

        // Find the index of the existing record that matches the criteria
        const existingRecordIndex = jsonData.findIndex(record =>
            record.TableMappingID === formTableMappingID
        );

        let newRecord;
        let changesPayload = null;

        if (existingRecordIndex !== -1) {
            console.log('Entered if for updating existing record');
            const existingRecord = jsonData[existingRecordIndex];
            console.log('Updating existing record:', existingRecord);

            const changes = [];

            // Compare current row with the original record to capture changes
            for (const key in formValues) {
                if (formValues[key] !== existingRecord[key] && formValues[key] !== '') {
                    changes.push({
                        changedColumn: key,   // Column name
                        previousValue: existingRecord[key],  // Previous value
                        ChangedValue: formValues[key]  // Current value
                    });
                }
            }

            // Identify changes by comparing formValues with existingRecord
            changesPayload = {
                projectId: state.projectId,
                userId: state.userId,
                tableMappingId: formTableMappingID,  // Ensure TableMappingId exists in data
                changes: changes,
                type: 'postData',  // Type as 'postData'
                CreatedBy: state.userName,
                jwt_token: state.jwt
            };

            // Update the existing record with the new form values
            newRecord = {
                ...existingRecord,
                ...formValues
            };

            // Replace the old record with the updated record
            jsonData[existingRecordIndex] = newRecord;

            console.log('Record updated:', newRecord);

            // Emit socket event since we are updating an existing record
            if (socket) {
                console.log('Emitting updateData event for existing record...');
                socket.emit('updateData', {
                    projectId: state.projectId,
                    actionType: 'edit',
                    data: newRecord,  // Send the updated record data
                });
                console.log('Event emitted successfully:', { projectId: state.projectId, actionType: 'edit', data: newRecord });
            } else {
                console.error('Socket is not connected');
                return;  // Exit if socket is not connected
            }

        } else {
            console.log('Entered else for creating new record');

            // Create a new record
            newRecord = {
                TableMappingID: formTableMappingID,
                ...formValues
            };

            console.log('New record being created:', newRecord);

            // Append the new record to the existing records
            jsonData.push(newRecord);
            console.log('Updated jsonData:', jsonData);
            if (socket) {
                console.log('Emitting updateData event for new record...');
                socket.emit('updateData', {
                    projectId: state.projectId,
                    actionType: 'add',
                    data: newRecord,  // Send the new record data
                });
                console.log('Event emitted successfully:', { projectId: state.projectId, actionType: 'add', data: newRecord });
            } else {
                console.error('Socket is not connected');
                return;  // Exit if socket is not connected
            }
        }

        try {
            debugger
            // If we have changes, send them to the postMapping endpoint
            console.log(changesPayload, '12345678')
            if (changesPayload && changesPayload.changes.length > 0) {
                console.log('Changes payload:', changesPayload);
                // Uncomment when ready to make the API call
                const mappingResponse = await postTableMapping(changesPayload, state.jwt);
                console.log('Changes sent successfully:', mappingResponse);
            }

            // Update JSON blob with new data regardless of whether it was a new or updated record
            const response = await updateJsonInBlob(blobUrl, jsonData);

            console.log('Response from updating blob:', response);

            // Update state and re-render the grid if blob update was successful
            setJsonData([...jsonData]);
            console.log('Final updated Data in state:', jsonData);
            setFormValues({
                InScopeOrOutScope: 'Select',
                NewCatalogName: '',
                MigrationPattern: '',
                LoadType: '',
                NewSchemaName: '',
                NewStoragePath: '',
                NewStorageType: 'Select',
                NewTableName: '',
                SourceCatalogName: '',
                SourceSchemaName: '',
                SourceStoragePath: '',
                SourceStorageType: 'Select',
                SourceTableName: '',
                VersionHistory: 'Select'
            });
            bindgrid();  // Refresh grid data after update

        } catch (error) {
            console.error('Error updating JSON blob:', error);
        }
    };

    const handleCancelClick = async () => {
        setFormValues({
        InScopeOrOutScope: 'Select',
        NewCatalogName: '',
        MigrationPattern: '',
        LoadType: '',
        NewSchemaName: '',
        NewStoragePath: '',
        NewStorageType: 'Select',
        NewTableName: '',
        SourceCatalogName: '',
        SourceSchemaName: '',
        SourceStoragePath: '',
        SourceStorageType: 'Select',
        SourceTableName: '',
        VersionHistory: 'Select',
        validated: 'false'

        });
        setPopHeader('')

    }


    // Rest of your React component code

    // This function is to fetch the dropdown values from the backedAPI called fetchDropdownValeus
    // PS_SDD_08 TO 25
    const fetchDropDown = async () => {
        try {

            let data = { type: "data dictionary", projectId: state.projectId, userId: state.userId }
            // let dropDownValues = await fetchDropDownValues(data, state.jwt)
            // console.log("resfrom fetchDropDownValues", dropDownValues);
            // if (dropDownValues.data?.DDuniqueSubjectArea) {
            //     setSubjectArea(dropDownValues.data.DDuniqueSubjectArea);
            // } else {
            //     console.log("No subject areas found");
            // }

            // if (dropDownValues.data?.uniqueSchemaName) {
            //     setSchemaName(dropDownValues.data.uniqueSchemaName);
            // } else {
            //     console.log("No schema names found");
            // }
        }
        catch (e) {
            // insertError({
            //     errorMessage: e.message,
            //     serviceName: "frontend",
            //     module: "Datadictionary",
            //     functionName: "DataDictionaryTable",
            //     userId: state.userId
            // });
            console.log("error", e);
        }
    }



    // 'when user enters the input in the grid rows. the handleInputChange() function is triggered
    // PS_SDD_172 TO 
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedRow(prevState => ({
            ...prevState,
            [name]: value
        }));
        setJsonData(editedRow)
    };

    // When user clicks the dropdown field in the grid, the handleDropDownChange() function is triggered
    // PS_SDD_174 TO 175
    const handleDropDownChange = (key, value) => {
        setInputValues(prevInputValues => ({
            ...prevInputValues,
            [key]: value
        }));
    }


    // PS_TM_102 to  PS_TM_105  Define an asynchronous function 'handleCancel' to reset edit state and clear any reasoning for changes 
    const handleCancel = async () => {
        setEditingIndex(null);
        setInputValues(jsonData)
        setReason("")
        setIsTableEdited(false);

    }

    //  PS_TM_106 to  PS_TM_107 handleReason function is trigerred when the characters is typed in the reason popup

    const handleReason = async (e) => {
        setReason(e.target.value)
    }



    //  PS_TM_108 to  PS_TM_113 The function handleSaveDetails() to save the details in the database

    const handleSaveDetails = async () => {
        // Log the edited row and its TableMappingId
        console.log("editedRow:", editedRow);
        console.log("editedRow.TableMappingId:", editedRow.TableMappingID);

        // Early exit if no reason or pending changes
        if (!editedRow.TableMappingID) {
            console.error("Edited row doesn't have a valid TableMappingId");
            return;
        }

        // Indicate that an update is in progress
        setWhetherUpdated(true);
        setEditingIndex(null);

        try {
            // Log jsonData to verify its contents
            console.log("jsonData:", jsonData);

            // Find the index of the original record in jsonData based on TableMappingId
            const originalRecordIndex = jsonData.findIndex(record => record.TableMappingID === editedRow.TableMappingID);

            // Log the original record index
            console.log("Original Record Index:", originalRecordIndex);

            if (originalRecordIndex === -1) {
                console.error('Original record not found, cannot proceed with update.');
                return; // Exit if the original record does not exist in jsonData
            }

            const originalRecord = jsonData[originalRecordIndex];
            const changes = [];

            // Compare current row with the original record to capture changes
            for (const key in editedRow) {
                if (editedRow[key] !== originalRecord[key] && editedRow[key] !== '') {
                    changes.push({
                        changedColumn: key,   // Column name
                        previousValue: originalRecord[key],  // Previous value
                        ChangedValue: editedRow[key]  // Current value
                    });
                }
            }

            // If there are no changes, exit early
            if (changes.length === 0) {
                console.log('No changes detected.');
                return;
            }

            // Prepare the payload for the API call
            const payload = {
                projectId: state.projectId,
                userId: state.userId,
                tableMappingId: editedRow.TableMappingID,  // Ensure TableMappingId exists in data
                changes: changes,
                type: 'postData',  // Type as 'postData'
                CreatedBy: state.userName,
                jwt_token: state.jwt // Set correct created by ID
            };

            // Log the payload for debugging
            console.log('Payload for API call:', payload);

            // Update the jsonData by replacing the item at the original record index with the new row data
            const updatedData = jsonData.map((item, index) => index === originalRecordIndex ? editedRow : item);

            console.log('Updated Data:', updatedData);

            // Set the updated jsonData state
            setJsonData(updatedData);

            // Reset the editing index after saving
            setEditingIndex(-1);
            debugger

            // Make the API call to post the changes
            const response = await postTableMapping(payload, state.jwt);
            console.log('Changes sent successfully:', response);
            let body = {
                type: "TableMapping",
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                comments: reason,

            }
            let res = await updatePseudocodeData(body, state.jwt)
            // Call API to update the JSON data in the blob
            await updateJsonInBlob(blobUrl, updatedData);

            // Successfully updated
            console.log('Successfully updated JSON blob:', updatedData);

            if (socket) {
                console.log('Emitting updateData event for existing record...');
                socket.emit('updateData', {
                    projectId: state.projectId,
                    actionType: 'edit',
                    data: editedRow,  // Send the updated record data
                });
                console.log('Event emitted successfully:', { projectId: state.projectId, actionType: 'edit', data: editedRow });
            } else {
                console.error('Socket is not connected');
                return;  // Exit if socket is not connected
            }

            // Trigger grid refresh or other necessary operations
            bindgrid();

            // Set body state for further actions
            setBody({
                type: "dataDictionarySilver",
                actionType: "edit",
                index: editedRow.index,
                comments: reason, // Include the reason in the body state
                value: editedRow
            });

            // Reset form input values and reason
            setInputValues({
                SubjectArea: 'Select',
                TableName: '',
                ColumnName: '',
                DataType: '',
                Constraints: 'Select',
                Description: ''
            });

            setReason("");
            setIsTableEdited(true); 
            setPopHeader('')  // Clear the reason after saving

        } catch (error) {
            console.error('Error during save operation:', error);
        }
    };

    // PS_TM_114 to   PS_TM_115  Outline 'handleEditClick' function to populate 'formValues' with data from the clicked item and set 'editedRow' for editing 
    const handleEditClick = (item) => {
        setFormValues({
            TableMappingID: item.TableMappingID, // Ensure to include this line
            SourceCatalogName: item.SourceCatalogName,
            SourceSchemaName: item.SourceSchemaName,
            SourceTableName: item.SourceTableName,
            SourceStorageType: item.SourceStorageType,
            SourceStoragePath: item.SourceStoragePath,
            NewCatalogName: item.NewCatalogName,
            NewSchemaName: item.NewSchemaName,
            NewTableName: item.NewTableName,
            NewStorageType: item.NewStorageType,
            NewStoragePath: item.NewStoragePath,
            VersionHistory: item.VersionHistory,
            MigrationPattern: item.MigrationPattern,
            LoadType: item.LoadType,
            InScopeOrOutScope: item.InScopeOrOutScope
        });
        setPopHeader('Edit')
        // Here you'd also set the item as the currently edited item if needed
        setEditedRow(item);
    };

    //  PS_TM_116 to  PS_TM_121 'When user clicks the delete icon in the action column the handleDelete() function is triggered.

    const handleDelete = (index, item) => {
        // Log the item to be deleted
        console.log("Deleting item", item);

        // Remove the item from jsonData and save it in state until confirmation
        const updatedData = jsonData.filter((_, i) => i !== index);
        setDeleteData(updatedData);
        setDeleteItem(item);


    };

    const handleConfirmDelete = async () => {
        if (deleteItem && socket) {
            socket.emit('updateData', {
                projectId: state.projectId,
                actionType: 'delete',
                data: deleteItem,
            });
        }

        console.log("Deleting item", deleteData);
        try {
            const response = await updateJsonInBlob(blobUrl, deleteData);

            if (response.ok) {
                console.log('Updated Data:', deleteData);
            } else {
                throw new Error('Failed to update the blob');
            }
            setJsonData(deleteData); // Ensure data is updated in state
        } catch (error) {
            console.error('Error updating JSON blob:', error);
        }

        setIsTableEdited(true);

        setReason("");
        setDeleteItem(null);
        setDeleteData([]);
    };

    // Handler for closing the modal and clearing states
    const handleCancelDelete = () => {
        setReason("");
        setDeleteItem(null);
        setDeleteData([]);
    };


    // PS_TM_122  Define 'handleInlineEdit' to trigger editing mode for a row and preserve the item's data in 'editedRow' 
    const handleInlineEdit = (index, item) => {
        setEditingIndex(index);
        setEditedRow(item);
        setIsInlineEditing(true);
    };

    //  PS_TM_123  Implement 'handleCellChange' to update individual cell data during inline editing of a row 
    // const handleCellChange = (event, field) => {
    //     setEditedRow({ ...editedRow, [field]: event.target.value });
    //     setIsTableEdited(true);
    //     console.log(editedRow, 'editedrow')
    // };

    const handleCellChange = (event, field) => {
        const { value } = event.target;
    
        setEditedRow((prevRow) => {
            // Check conditions for clearing NewStoragePath
            const shouldClearNewStoragePath =
                (prevRow.SourceStorageType === 'MANAGED' && value === 'MANAGED') ||
                (prevRow.SourceStorageType === 'MANAGED' && value === 'SOURCETYPE') ||
                (prevRow.SourceStorageType === 'EXTERNAL' && value === 'MANAGED');
    
            if (shouldClearNewStoragePath) {
                return {
                    ...prevRow,
                    [field]: value,
                    NewStoragePath: '', // Clear value
                };
            }
    
            return {
                ...prevRow,
                [field]: value,
            };
        });
    
        setIsTableEdited(true);
        console.log(editedRow, 'editedrow');
    };
    
    const handleFormChange = (e) => {
        const { name, value } = e.target;
    
        setFormValues((prevValues) => {
            // Check conditions for clearing NewStoragePath
            const shouldClearNewStoragePath =
                (prevValues.SourceStorageType === 'MANAGED' && value === 'MANAGED') ||
                (prevValues.SourceStorageType === 'MANAGED' && value === 'SOURCETYPE') ||
                (prevValues.SourceStorageType === 'EXTERNAL' && value === 'MANAGED');
    
            if (shouldClearNewStoragePath) {
                return {
                    ...prevValues,
                    [name]: value,
                    NewStoragePath: '', // Clear value
                };
            }
    
            return {
                ...prevValues,
                [name]: value,
            };
        });
    };
    
    
    

    //  PS_TM_124  Formulate 'isFormFilled' to check if all inputs in the row have valid data and no default placeholder values are present 
    const isFormFilled = () => {
        const defaultValues = ['Select', '']; // Default values to check against
        const inputValuesArray = Object.values(editedRow); // Get an array of input values from editedRow
        const hasDefaultValues = inputValuesArray.some(value => defaultValues.includes(value)); // Check if any default values are present
        return !hasDefaultValues; // Return true if no default values are present
    };

    //  PS_TM_125 to  PS_TM_126  Establish 'viewChanges' function to request and display historical changes made to a particular record identified by 'tableMappingID' 
    const viewChanges = async (tableMappingID) => {
        // Construct the payload for fetching changes
        ;
        debugger
        console.log(tableMappingID, 'tableMappingIDtableMappingID');

        const payload = {
            projectId: state.projectId, // Use the correct project ID
            userId: state.userId, // Replace with the current user ID
            tableMappingId: tableMappingID,
            type: "fetchChanges",
            jwt_token: state.jwt // Set correct created by ID
        };

        console.log(payload, 'payloadpayload');

        try {
            // Fetch changes data from the API
            const response = await postTableMapping(payload, state.jwt);
            // Assuming response is already in JSON format (an array of objects)
            const changesData = response.message; // Extract the `message` array from the response

            // Transform changesData into a format suitable for rendering
            const formattedChanges = changesData.map(change => ({
                TableMappingId: change.TableMappingId,  // Transform each field accordingly
                CreatedBy: change.CreatedBy,
                CreatedOn: new Date(change.CreatedOn).toLocaleString(),  // Convert ISO DateTime string to readable format
                ChangedColumn: change.ChangedColumn,
                previousValue: change.previousValue,
                ChangedValue: change.ChangedValue,
            }));

            console.log(formattedChanges, 'formattedChanges');

            // Set the changes data in the state
            setChanges(formattedChanges);

        } catch (error) {
            console.error('Error fetching changes:', error);
        }
    };

    // FILTER
    // To remove duplicate values and keep unique ones
    // To remove duplicate values and keep unique non-empty values
    // PS_TM_127  Devise 'uniqueValues' function to return an array of unique, non-empty and non-placeholder values from 'jsonData' for a specified key, catering for dropdown filters 
    const uniqueValues = (key) => {
        return [...new Set(
            jsonData
                .map(item => item[key]) // Extract values based on the provided key
                .filter(value => value && value.toLowerCase() !== 'na' && value.toLowerCase() !== 'null') // Filter out empty strings, 'NA', and 'null'
        )];
    };
    // Function to reset filter criteria
    //  PS_TM_128  Define 'handleClearFilters' to reset 'filterCriteria' to the initial state, effectively clearing all applied filters 
    const handleClearFilters = () => {
        setFilterCriteria({
            SourceCatalogName: "",
            SourceSchemaName: "",
            NewCatalogName: "",
            NewSchemaName: "",
            InScopeOrOutScope: ""
        });
        setIsTableEdited(false);
    };

    //  PS_TM_129  Create 'handleSearchChange' to update the 'searchKeyword' state with the user's input from the search field 
    const handleSearchChange = (e) => {
        setSearchKeyword(e.target.value);
        setIsTableEdited(false);

    };

    //  PS_TM_130   Construct 'handleClearSearch' to clear the 'searchKeyword' state, resetting the search term 
    const handleClearSearch = () => {
        setSearchKeyword('');
        setIsTableEdited(false);

    };


    //  PS_TM_131 to  PS_TM_135  Initialize variable  with an array of predefined options for the source storage type dropdown 
    const sourceStorageTypeOptions = ["MANAGED", "EXTERNAL"];
    const newStorageTypeOptions = ["MANAGED", "EXTERNAL", "SOURCETYPE"];
    const versionHistoryOptions = ["Yes", "No"];
    const inScopeOrOutScopeOptions = ["InScope", "OutScope"];
    const loadtype = ["CTAS", "DeepClone", "Config & Sync"]

    //  PS_TM_136 to   PS_TM_146  Define a function 'bindgrid' that renders the rows of the data grid based on the currently filtered data 
    const bindgrid = () => {
        try {
            if (filteredData.length > 0) {
                return (
                    <>
                        {filteredData.map((item, index) => (
                            editingIndex === index ? (
                                <tr key={index}>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>
                                            <input
                                                type="text"
                                                className="form-control cust-input-sty font-14 font-regular"
                                                value={editedRow.SourceCatalogName}
                                                onChange={(e) => handleCellChange(e, 'SourceCatalogName')}
                                            />
                                        </div>
                                        <div style={{ color: 'gray' }}>
                                            <input
                                                type="text"
                                                className="form-control cust-input-sty font-14 font-regular"
                                                value={editedRow.SourceSchemaName}
                                                onChange={(e) => handleCellChange(e, 'SourceSchemaName')}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            value={editedRow.SourceTableName}
                                            onChange={(e) => handleCellChange(e, 'SourceTableName')}
                                        />
                                    </td>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>
                                            <select
                                                className="form-select cust-input-sty font-14 font-regular"
                                                value={editedRow.SourceStorageType}
                                                onChange={(e) => handleCellChange(e, 'SourceStorageType')}
                                            >
                                                {sourceStorageTypeOptions.map(option => (
                                                    <option key={option} value={option}>{option}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div style={{ color: 'gray' }}>
                                            <input
                                                type="text"
                                                className="form-control cust-input-sty font-14 font-regular"
                                                value={editedRow.SourceStoragePath}
                                                onChange={(e) => handleCellChange(e, 'SourceStoragePath')}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>
                                            <input
                                                type="text"
                                                className="form-control cust-input-sty font-14 font-regular"
                                                value={editedRow.NewCatalogName}
                                                onChange={(e) => handleCellChange(e, 'NewCatalogName')}
                                            />
                                        </div>
                                        <div style={{ color: 'gray' }}>
                                            <input
                                                type="text"
                                                className="form-control cust-input-sty font-14 font-regular"
                                                value={editedRow.NewSchemaName}
                                                onChange={(e) => handleCellChange(e, 'NewSchemaName')}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            value={editedRow.NewTableName}
                                            onChange={(e) => handleCellChange(e, 'NewTableName')}
                                        />
                                    </td>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>
                                            <select
                                                className="form-select cust-input-sty font-14 font-regular"
                                                value={editedRow.NewStorageType}
                                                onChange={(e) => handleCellChange(e, 'NewStorageType')}
                                            >
                                                {newStorageTypeOptions.map(option => (
                                                    <option key={option} value={option}>{option}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div style={{ color: 'gray' }}>
                                            <input
                                                type="text"
                                                className="form-control cust-input-sty font-14 font-regular"
                                                value={editedRow.NewStoragePath}
                                                disabled={ (editedRow.SourceStorageType === 'MANAGED' && editedRow.NewStorageType === 'MANAGED') ||
                                                    (editedRow.SourceStorageType === 'EXTERNAL' && editedRow.NewStorageType === 'MANAGED') ||
                                                    (editedRow.SourceStorageType === 'MANAGED' && editedRow.NewStorageType === 'SOURCETYPE')
                                                }
                                                onChange={(e) => handleCellChange(e, 'NewStoragePath')}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <select
                                            className="form-select cust-input-sty font-14 font-regular"
                                            value={editedRow.VersionHistory}
                                            onChange={(e) => handleCellChange(e, 'VersionHistory')}
                                        >
                                            {versionHistoryOptions.map(option => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control cust-input-sty font-14 font-regular"
                                            value={editedRow.MigrationPattern}
                                            onChange={(e) => handleCellChange(e, 'MigrationPattern')}
                                        />
                                    </td>
                                    <td>
                                        <select
                                            className="form-select cust-input-sty font-14 font-regular"
                                            value={editedRow.LoadType}
                                            onChange={(e) => handleCellChange(e, 'LoadType')}
                                        >
                                            {loadtype.map(option => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            className="form-select cust-input-sty font-14 font-regular"
                                            value={editedRow.InScopeOrOutScope}
                                            onChange={(e) => handleCellChange(e, 'InScopeOrOutScope')}
                                        >
                                            {inScopeOrOutScopeOptions.map(option => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{ alignItems: 'center' }}>
                                        <a href='#' data-bs-toggle="modal" data-bs-target="#viewChangesModal" onClick={() => viewChanges(item.TableMappingID)}>View Changes</a>
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={editedRow.validated === "true" || editedRow.validated === true}
                                            onChange={(e) => setEditedRow({ ...editedRow, validated: e.target.checked })}
                                        />
                                    </td>
                                    <td className={`fixed-column-cell grid-fixed-td-color-white`} style={index % 2 == 0 ? { backgroundColor: 'white !important' } : { backgroundColor: '#f5f5f5 !important' }}>
                                        <img
                                            src="images/right.svg"
                                            alt="edit-icon"
                                            className={`cust-cursor-pointer me-3 ${isFormFilled() ? '' : 'disabled'}`}
                                            onClick={() => isFormFilled()} // Only call handleTick if form is filled
                                            data-bs-toggle="modal" // Add this attribute to trigger the modal
                                            data-bs-target="#editRows" // Specify the target modal
                                            disabled={!isFormFilled()}
                                            title="update"
                                        />
                                        <img
                                            src="images/cross-red.svg"
                                            alt="cancel-icon"
                                            className="cust-cursor-pointer me-3"
                                            title="cancel"
                                            onClick={handleCancel}
                                        />
                                    </td>
                                </tr>
                            ) : (
                                <tr key={index} onDoubleClick={() => handleInlineEdit(index, item)}>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>{item?.SourceCatalogName || "NA"}</div>
                                        <div style={{ color: 'gray' }}>{item?.SourceSchemaName || "NA"}</div>
                                    </td>
                                    <td>{item.SourceTableName || "NA"}</td>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>{item?.SourceStorageType || "NA"}</div>
                                        <div style={{ color: 'gray' }}>{item?.SourceStoragePath || "NA"}</div>
                                    </td>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>{item?.NewCatalogName || "NA"}</div>
                                        <div style={{ color: 'gray' }}>{item?.NewSchemaName || "NA"}</div>
                                    </td>
                                    <td>{item?.NewTableName || "NA"}</td>
                                    <td>
                                        <div style={{ fontWeight: 'bold' }}>{item?.NewStorageType || "NA"}</div>
                                        <div style={{ color: 'gray' }}>{item?.NewStoragePath || "NA"}</div>
                                    </td>
                                    <td>{item?.VersionHistory || "No"}</td>
                                    <td>{item?.MigrationPattern || "NA"}</td>
                                    <td>{item?.LoadType || "NA"}</td>
                                    <td>{item?.InScopeOrOutScope || "NA"}</td>
                                    <td>
                                        <a href='#' data-bs-toggle="modal" data-bs-target="#viewChangesModal" onClick={() => viewChanges(item.TableMappingID)}>View Changes</a>
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={item.validated === "true" || item.validated === true}
                                            onChange={(e) => handleValidateChange(index, e.target.checked)}
                                        />
                                    </td>
                                    <td className={`fixed-column-cell ${index % 2 == 0 ? "grid-fixed-td-color-white" : "grid-fixed-td-color"}`}>
                                        <img
                                            src="images/edit-icon.svg"
                                            alt="edit-icon"
                                            className="cust-cursor-pointer me-3"
                                            title="Edit"
                                            data-bs-toggle="modal" // Add this attribute to trigger the modal
                                            data-bs-target="#SourceSys1"
                                            onClick={() => handleEditClick(item)} // Call the handleEditClick function
                                        />
                                        <img
                                            src="images/delete-icon.svg"
                                            alt="delete-icon"
                                            className="cust-cursor-pointer"
                                            title="Delete"
                                            data-bs-toggle="modal" // Add this attribute to trigger the modal
                                            data-bs-target="#editRowss" // Specify the target modal
                                            onClick={() => handleDelete(index, item)}
                                        />
                                    </td>
                                </tr>
                            )
                        ))}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td colSpan="30" className="text-center">
                            No Records Found
                        </td>
                    </tr>
                );
            }
        } catch (error) {
            console.error("Error in bindgrid:", error);
            return null;
        }
    };

    //  SQ_EX_145.0  to  SQ_EX 147.0   Define an async function to handle download operation 
    const handleDownloadClick = async () => {
        try {
            // Fetch JSON data from blob
            // const jsonData = await fetchJsonFromBlob(); // Ensure this function fetches your JSON data

            // Convert JSON to XLSX
            // Remove TableMappingID and rename InScopeOrOutScope header
            const dataToExport = jsonData.map(({ TableMappingID, InScopeOrOutScope, ...rest }) => ({
                ...rest,
                'InScopeOrOutScope': InScopeOrOutScope
            }));

            const worksheet = XLSX.utils.json_to_sheet(dataToExport);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'TableMappingDetails');

            // Generate buffer
            const xlsxBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Create a Blob from the buffer
            const blob = new Blob([xlsxBuffer], { type: 'application/octet-stream' });

            // Trigger download
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'TableMapping.xlsx'); // Set filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // Clean up URL object after download

        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    //  PS_TM_151   Trigger file import operation and open import pop-up 
    const handleImport = () => {
        console.log('tureeeeeeeeeeeee');

        setImportUpload(true);
        // <>
        //     <DynamicFileUploadPopup
        //         validationColumns={props.validationColumns}
        //         textData={props.textData}
        //         saveFunction={props.saveFunction}
        //         closePopup={props.closePopup}
        //         templateURL={props.templateURL}
        //     />
        // </>
    }
    const [isModalOpen, setModalOpen] = useState(false);

    //  PS_TM_152  Define a function to open a modal by setting isModalOpen to true 
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    //  PS_TM_153  Define a function to close a modal by setting isModalOpen to false 
    const handleCloseModal = () => {
        setModalOpen(false);
    };




    return (

        <div className="container-fluid px-md-4">
            <div class="overlay" id="pageLoader" style={{ display: "none" }}>
                <div class="position-absolute top-50 start-50  translate-middle">
                    <div class="d-flex align-items-center loader-bg">
                        <div
                            class="spinner-border Loader text-dark align-center "
                            role="status"
                        >
                            <span class="visually-hidden"></span>
                        </div>
                        <span class="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div style={{marginTop:'70px'}}>
            <HeaderCom value="1" />
            <div className="mt-3 px-2" >
                    <ul className="cust-breadcrumb mb-0">
                        <li className="font-16 font-medium">
                            <a>Manage Projects</a>
                        </li>
                        <li className="font-16 font-medium">
                            <a>{state.projectName}</a>
                        </li>
                        <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                    </ul>
                </div>
                </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-between pb-4">
                        <div className="d-flex align-items-center">
                            <a className="d-flex">
                                <img src="images/back-arrow.svg" alt="back-arrow" style ={{ cursor: "pointer"}} className="me-3 mb-2" onClick={() => {
                                    props.setShowCode();
                                    props.isEdit(whetherUpdated);
                                }} />
                            </a>
                            <h2 className="text-black font-bold font-22 mb-0">Table Mapping<button type="button" onClick={handleOpenModal} className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3">
                                <img src="images/info-icon-ETL.svg" className alt="info" />
                            </button></h2>
                            {isModalOpen && <ViewInstructions notebooktype='tableMapping' onClose={handleCloseModal} />}
                        </div>
                        <div className="d-flex">
                            <div className="input-group position-relative search-wid me-md-3">
                                <input
                                    type="search"
                                    className="form-control cust-input-sty font-14 rounded-3 font-regular"
                                    placeholder="Search"
                                    style={{ 'paddingRight': '30px' }}
                                    value={searchKeyword}
                                    onChange={handleSearchChange}
                                />
                                <span className="search-icon-sty">
                                    <a >
                                        <img src="images/search-icon.svg" alt="search icon" onClick={handleClearSearch} />
                                    </a>
                                </span>


                            </div>
                            <button type="button" className="btn cust-secondary-btn font-14 font-regular me-md-3" onClick={() => { handleImport() }}>
                                <span className="me-2"><img src="images/import-icon_ETL.svg" alt="add-icon" /></span> Import
                            </button>
                            <button type="button" className="btn cust-secondary-btn font-14 font-regular me-md-3" onClick={handleDownloadClick}>
                                <span className="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download
                            </button>
                            <button type="button" className="btn cust-primary-btn font-14 font-regular" data-bs-toggle="modal" data-bs-target="#SourceSys1">
                                <span className="me-2"><img src="images/Add-new.svg" alt="add-icon" /></span> Add New
                            </button>
                        </div>
                    </div>
                    <div className="rounded-3 px-3 py-3 custom-acc-border">
                        <div className="row">
                            <h2 className="font-16 font-bold mb-3">Filters</h2>

                            <div className="col-md-2 w-18 col-sm-12">
                                <label className="form-label text-black font-14 font-medium">Source Catalog Name</label>
                                <div className="dropdown">
                                    <select
                                        className="form-select cust-input-sty font-14 font-regular"
                                        aria-label="Default select example"
                                        value={filterCriteria.SourceCatalogName}
                                        onChange={(e) => { setFilterCriteria({ ...filterCriteria, SourceCatalogName: e.target.value }) }}
                                        style={{ zIndex: 1001 }}
                                    >
                                        <option value="" style={{ cursor: 'pointer' }}>Select</option>
                                        {uniqueValues('SourceCatalogName').map((value, index) => (
                                            <option key={index} value={value} style={{ cursor: 'pointer' }}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-2 w-18 col-sm-12">
                                <label className="form-label text-black font-14 font-medium">Source Schema Name</label>
                                <div className="dropdown">
                                    <select
                                        className="form-select cust-input-sty font-14 font-regular"
                                        aria-label="Default select example"
                                        value={filterCriteria.SourceSchemaName}
                                        onChange={(e) => { setFilterCriteria({ ...filterCriteria, SourceSchemaName: e.target.value }) }}
                                        style={{ zIndex: 1001 }}
                                    >
                                        <option value="" style={{ cursor: 'pointer' }}>Select</option>
                                        {uniqueValues('SourceSchemaName').map((value, index) => (
                                            <option key={index} value={value} style={{ cursor: 'pointer' }}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-2 w-18 col-sm-12">
                                <label className="form-label text-black font-14 font-medium">New Catalog Name</label>
                                <div className="dropdown">
                                    <select
                                        className="form-select cust-input-sty font-14 font-regular"
                                        aria-label="Default select example"
                                        value={filterCriteria.NewCatalogName}
                                        onChange={(e) => { setFilterCriteria({ ...filterCriteria, NewCatalogName: e.target.value }) }}
                                        style={{ zIndex: 1001 }}
                                    >
                                        <option value="" style={{ cursor: 'pointer' }}>Select</option>
                                        {uniqueValues('NewCatalogName').map((value, index) => (
                                            <option key={index} value={value} style={{ cursor: 'pointer' }}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-2 w-18 col-sm-12">
                                <label className="form-label text-black font-14 font-medium">New Schema Name</label>
                                <div className="dropdown">
                                    <select
                                        className="form-select cust-input-sty font-14 font-regular"
                                        aria-label="Default select example"
                                        value={filterCriteria.NewSchemaName}
                                        onChange={(e) => { setFilterCriteria({ ...filterCriteria, NewSchemaName: e.target.value }) }}
                                        style={{ zIndex: 1001 }}
                                    >
                                        <option value="" style={{ cursor: 'pointer' }}>Select</option>
                                        {uniqueValues('NewSchemaName').map((value, index) => (
                                            <option key={index} value={value} style={{ cursor: 'pointer' }}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-2 w-18 col-sm-12">
                                <label className="form-label text-black font-14 font-medium">Scope</label>
                                <div className="dropdown">
                                    <select
                                        className="form-select cust-input-sty font-14 font-regular"
                                        aria-label="Default select example"
                                        value={filterCriteria.InScopeOrOutScope}
                                        onChange={(e) => { setFilterCriteria({ ...filterCriteria, InScopeOrOutScope: e.target.value }) }}
                                        style={{ zIndex: 1001 }}
                                    >
                                        <option value="" style={{ cursor: 'pointer' }}>Select</option>
                                        {uniqueValues('InScopeOrOutScope').map((value, index) => (
                                            <option key={index} value={value} style={{ cursor: 'pointer' }}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-2 w-10 col-sm-12 pt-2">
                                <button
                                    type="button"
                                    className="btn cust-secondary-btn font-16 font-medium mt-4"
                                    // data-bs-dismiss="modal"
                                    onClick={handleClearFilters}
                                >
                                    <span className="me-2"><img src="images/clear-icon.svg" alt="add-icon" /></span> Clear
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-12 col-lg-12 col-sm-12 pt-4">
                        {/*Metadata Table starts here*/}
                        <div className="table-responsive rounded-3">
                            <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                <thead className="sticky-top-pos">
                                    <tr>
                                        <th >SourceCatalogSchemaName <span className="cursor-pointer"><img src={`images/${getSortIcon('SourceCatalogName')}`} alt="sort-down-icon" className="sort-icon" onClick={() => handleSort('SourceCatalogName')} /></span></th>
                                        <th>SourceTableName <span className="cursor-pointer"><img src={`images/${getSortIcon('SourceTableName')}`} alt="sort-down-icon" className="sort-icon" onClick={() => handleSort('SourceTableName')} /></span></th>
                                        <th>SourceStorageInfo <span className="cursor-pointer"><img src={`images/${getSortIcon('SourceStorageInfo')}`} alt="sort-down-icon" className="sort-icon" onClick={() => handleSort('SourceStorageInfo')} /></span></th>
                                        <th>NewSourceCatalogSchemaName <span className="cursor-pointer"><img src={`images/${getSortIcon('NewSourceCatalogSchemaName')}`} alt="sort-down-icon" className="sort-icon" onClick={() => handleSort('NewSourceCatalogSchemaName')} /></span></th>
                                        <th>NewTableName <span className="cursor-pointer"><img src={`images/${getSortIcon('NewTableName')}`} alt="sort-down-icon" className="sort-icon" onClick={() => handleSort('NewTableName')} /></span></th>
                                        <th>NewStorageInfo <span className="cursor-pointer"><img src={`images/${getSortIcon('NewStorageInfo')}`} alt="sort-down-icon" className="sort-icon" onClick={() => handleSort('NewStorageInfo')} /></span></th>
                                        <th>VersionHistory <span className="cursor-pointer"><img src={`images/${getSortIcon('VersionHistory')}`} alt="sort-down-icon" className="sort-icon" onClick={() => handleSort('VersionHistory')} /></span></th>
                                        <th>MigrationPattern <span className="cursor-pointer"><img src={`images/${getSortIcon('MigrationPattern')}`} alt="sort-down-icon" className="sort-icon" onClick={() => handleSort('MigrationPattern')} /></span></th>
                                        <th>LoadType <span className="cursor-pointer"><img src={`images/${getSortIcon('LoadType')}`} alt="sort-down-icon" className="sort-icon" onClick={() => handleSort('LoadType')} /></span></th>
                                        <th>InScopeOrOutScope <span className="cursor-pointer"><img src={`images/${getSortIcon('InScopeOrOutScope')}`} alt="sort-down-icon" className="sort-icon" onClick={() => handleSort('InScopeOrOutScope')} /></span></th>
                                        <th>Changes <span className="cursor-pointer"></span></th>
                                        <th className="text-center">Validated?</th>
                                        <th className="fixed-column-header">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>


                                    {bindgrid()}

                                </tbody>
                            </table>
                            {/*Metadata Table ends here*/}

                            {/* model */}
                            <div
                                className="modal fade "
                                id="editRows"
                                // data-bs-backdrop="static"
                                // data-bs-keyboard="false"
                                tabIndex={-1}
                                aria-labelledby="ConfrmLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header border-0">
                                            <h5
                                                className="modal-title d-flex align-items-center font-medium font-20"
                                                id="ConfrmLabel"
                                            >
                                                Edit Reason
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            />
                                        </div>
                                        <div className="modal-body py-0">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <label
                                                        htmlFor="User-Name"
                                                        className="form-label text-black font-14 font-medium"
                                                    >
                                                        Specify reason for manual edit
                                                        <span className="text-red"> *</span>
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control font-14 font-regular "
                                                        id="User-Name"
                                                        placeholder="Enter reason"
                                                        rows={5}
                                                        defaultValue={null}
                                                        value={reason}
                                                        onChange={handleReason}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer border-0 px-4 mt-4">
                                            <button
                                                type="button"
                                                className="btn cust-secondary-btn font-14 font-medium me-2"
                                                data-bs-dismiss="modal"
                                                onClick={() => setReason("")}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                className="btn cust-primary-btn font-14 font-medium"
                                                onClick={reason && handleSaveDetails}
                                                data-bs-dismiss="modal"
                                                disabled={!reason}

                                            >
                                                <span>Save Details</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* ADD MODEL */}
                            <div className="row">
                                {/* Button trigger modal */}
                                {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#SourceSys">
          Table Mapping
        </button> */}
                                {/* Modal */}
                                <div className="modal fade" id="SourceSys1" tabIndex={-1} aria-labelledby="SourceSysLabel" aria-hidden="true">
                                    <div className="modal-dialog add-new-table-mapping-popup">
                                        <div className="modal-content px-2">
                                            <div className="modal-header border-0 mt-3">
                                                <h5 className="modal-title d-flex align-items-center font-bold font-20" id="SourceSysLabel">
                                                    {popHeader === 'Edit' ? 'Edit Table Mapping' : 'Add New Table Mapping'}
                                                </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={handleCancelClick}
                                                />
                                            </div>

                                            <div className="modal-body">
                                                <div className="row">
                                                    {[
                                                        { label: "Source Catalog Name", name: "SourceCatalogName", type: 'inp' },
                                                        { label: "Source Schema Name", name: "SourceSchemaName", type: 'inp' },
                                                        { label: "Source Table Name", name: "SourceTableName", type: 'inp' },
                                                        { label: "Source Storage Type", name: "SourceStorageType", type: 'dd' },
                                                        { label: "Source Storage Path", name: "SourceStoragePath", type: 'inp' },
                                                        { label: "New Catalog Name", name: "NewCatalogName", type: 'inp' },
                                                        { label: "New Schema Name", name: "NewSchemaName", type: 'inp' },
                                                        { label: "New Table Name", name: "NewTableName", type: 'inp' },
                                                        { label: "New Storage Type", name: "NewStorageType", type: 'dd' },
                                                        { label: "New Storage Path", name: "NewStoragePath", type: 'inp' },
                                                        { label: "Version History", name: "VersionHistory", type: 'dd' },
                                                        { label: "Migration Pattern", name: "MigrationPattern", type: 'stat' },
                                                        { label: "Load Type", name: "LoadType", type: 'dd' },
                                                        { label: "InScopeOrOutScope", name: "InScopeOrOutScope", type: 'dd' }
                                                    ].map(field => (
                                                        <div className="col-md-6 col-sm-12 mb-4" key={field.name}>
                                                            <label className="form-label text-black font-14 font-medium">
                                                                {field.label}<span className="text-red">*</span>
                                                            </label>
                                                            {field.type === 'inp' && field.name === 'NewStoragePath' ? (
                                                                 <input
                                                                 type="text"
                                                                 className="form-control cust-input-sty font-14 font-regular"
                                                                 name={field.name}
                                                                 value={formValues[field.name]}
                                                                 onChange={handleFormChange}
                                                                 disabled={(formValues.SourceStorageType === 'MANAGED' && formValues.NewStorageType === 'MANAGED') ||
                                                                    (formValues.SourceStorageType === 'EXTERNAL' && formValues.NewStorageType === 'MANAGED') ||
                                                                    (formValues.SourceStorageType === 'MANAGED' && formValues.NewStorageType === 'SOURCETYPE')} // Disable if NewStorageType is 'EXTERNAL'
                                                             />
                                                            ) : field.type === 'inp' ? (
                                                                <input
                                                                    type="text"
                                                                    className="form-control cust-input-sty font-14 font-regular"
                                                                    name={field.name}
                                                                    value={formValues[field.name]}
                                                                    onChange={handleFormChange}
                                                                />
                                                            ) : field.type === 'stat' ? (
                                                                field.name === 'MigrationPattern' && (
                                                                    <div className="" key={field.name}>
                                                                        {formValues.SourceStorageType === 'EXTERNAL'  &&
                                                                            (formValues.NewStorageType === 'EXTERNAL' || formValues.NewStorageType === 'SOURCETYPE') ? (
                                                                            <input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 font-regular"
                                                                                name={field.name}
                                                                                aria-label="Default input example"
                                                                                value={"Configuration"} // Static value for this condition
                                                                                readOnly // Optional: make it read-only if you don't want user input
                                                                            />
                                                                        ) : (
                                                                            <input
                                                                                type="text"
                                                                                className="form-control cust-input-sty font-14 font-regular"
                                                                                name={field.name}
                                                                                aria-label="Default input example"
                                                                                value={"Migration"} // Static value for this condition
                                                                                readOnly // Optional: make it read-only if you don't want user input
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )
                                                            ) : (
                                                                <select
                                                                    className="form-select cust-input-sty font-14 font-regular"
                                                                    name={field.name}
                                                                    aria-label="Default select example"
                                                                    value={formValues[field.name]}
                                                                    onChange={handleFormChange}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {field.name === 'SourceStorageType' && (
                                                                        <>
                                                                            <option value="EXTERNAL">EXTERNAL</option>
                                                                            <option value="MANAGED">MANAGED</option>
                                                                        </>
                                                                    )}
                                                                    {field.name === 'NewStorageType' && (
                                                                        <>
                                                                            <option value="EXTERNAL">EXTERNAL</option>
                                                                            <option value="MANAGED">MANAGED</option>
                                                                            <option value="SOURCETYPE">SOURCETYPE</option>
                                                                        </>
                                                                    )}
                                                                    {field.name === 'VersionHistory' && (
                                                                        <>
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </>
                                                                    )}
                                                                    {field.name === 'LoadType' && (
                                                                        <>
                                                                            <option value="CTAS">CTAS</option>
                                                                            <option value="DeepClone">DeepClone</option>
                                                                            <option value="Config & Sync">Config & Sync</option>
                                                                        </>
                                                                    )}
                                                                    {field.name === 'InScopeOrOutScope' && (
                                                                        <>
                                                                            <option value="InScope">InScope</option>
                                                                            <option value="OutScope">OutScope</option>
                                                                        </>
                                                                    )}
                                                                </select>
                                                            )}
                                                            <p className="text-danger">&nbsp;{popupErrors?.[field.name]}</p>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="modal-footer border-0 mb-4 mt-4 d-flex p-0">
                                                    <button type="button" className="btn cust-secondary-btn font-14 font-medium me-3" data-bs-dismiss="modal" onClick={handleCancelClick}>
                                                        Cancel
                                                    </button>

                                                    <button
                                                        type="button"
                                                        disabled={!isPopFormFilled()}
                                                        className={`submit-button ${isPopFormFilled() ? 'enabled' : 'disabled'} btn cust-primary-btn font-14 font-medium`}
                                                        data-bs-dismiss="modal"
                                                        onClick={handleAddClick}
                                                    >
                                                        <span>{popHeader === 'Edit' ? "Save" : "Add"}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* {VIEW CHNANGES MODEL} */}

                            <div className="modal fade" id="viewChangesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="viewChangesModal" aria-hidden="true">
                                <div className="modal-dialog modal-xl">
                                    <div className="modal-content px-2">
                                        <div className="modal-header border-0">
                                            <h5 className="modal-title d-flex align-items-center font-medium font-20" id="viewChangesModal">
                                                View Changes
                                            </h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <div className="modal-body py-0 pb-4">
                                            <div className="table-responsive rounded-3 view-changes-table custom-scroll">
                                                <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                                    <thead className="sticky-top-pos">
                                                        <tr>
                                                            <th>ChangedColumn</th>
                                                            <th>PreviousValue</th>
                                                            <th>ChangedValue</th>
                                                            <th>ChangedBy</th>
                                                            <th>ChangedOn</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {changes?.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="5" className="text-center">No changes available</td>
                                                            </tr>
                                                        ) : (
                                                            changes?.map((change, index) => (
                                                                <tr key={index}>
                                                                    <td>{change.ChangedColumn}</td>
                                                                    <td>{change.previousValue}</td>
                                                                    <td>{change.ChangedValue}</td>
                                                                    <td>{change.CreatedBy}</td>
                                                                    <td>{change.CreatedOn}</td>
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Modal for delete confirmation */}
                            <div
                                className="modal fade"
                                id="editRowss"
                                tabIndex={-1}
                                aria-labelledby="ConfrmLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header border-0">
                                            <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">
                                                Specify reason for delete
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            />
                                        </div>
                                        <div className="modal-body py-0">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <label className="form-label text-black font-14 font-medium">
                                                        Specify reason for manual edit
                                                        <span className="text-red"> *</span>
                                                    </label>
                                                    <textarea
                                                        className="form-control font-14 font-regular"
                                                        placeholder="Enter reason"
                                                        rows={5}
                                                        value={reason}
                                                        onChange={(e) => setReason(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer border-0 px-4 mt-4">
                                            <button
                                                type="button"
                                                className="btn cust-secondary-btn font-14 font-medium me-2"
                                                data-bs-dismiss="modal"
                                                onClick={handleCancelDelete}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                className="btn cust-primary-btn font-14 font-medium"
                                                onClick={handleConfirmDelete}
                                                data-bs-dismiss="modal"
                                                disabled={!reason}
                                            >
                                                <span>Delete</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <p className="font-regular font-14 text-grey mt-3"># of Records: <span className="text-black font-medium">{filteredData.length} out of {jsonData.length}</span></p>
                    </div>
                </div>
            </div>
            <>
                {importUpload === true ? (

                    <DynamicFileUploadPopup
                        validationColumns={props.validationColumns}
                        text={props.textData}
                        saveFunction={props.saveFunction}
                        closePopup={props.closeTableMappingPopup}
                        closeTableMappingPopup={props.closeTableMappingPopup}
                        templateURL={props.templateURL}
                        setImportUpload={setImportUpload}
                    />
                ) : (
                    <>



                    </>
                )}
            </>
        </div>
    );
}

export default TableMapping;
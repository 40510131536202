// import all the necessary packages
//  PS_01 to 10          
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError,generateSasTokenAPI } from "../../../Service/Api.js";
import { HeaderCom } from "../../Header.jsx";
import DynamicGrid from './DynamicTable.jsx';
import SearchComponent from './SearchComponent.jsx';
import DownloadComponent from './DownloadComponent.jsx';
import ReasonComponent from './ReasonComponent.jsx';
import CatalogComponent from './CatalogComponent.jsx';
import ViewInstructions from "./viewInstructions.jsx";



function DDLScript(props) {
// Declare all the state variables needed

// PS_11 TO 29
    const { state } = useLocation();
    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);
    const [actualValue, setActualValue] = useState([]);
    const [dropDownValues, setDropDownValues] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [whetherUpdated, setWhetherUpdated] = useState(false);

    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [currentOperation, setCurrentOperation] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [codeSnippet, setCodeSnippet] = useState('');
    const [isNewCodeSnippet, setIsNewCodeSnippet] = useState(false);

    const [newRow, setNewRow] = useState({});
    const [scriptAdded, setScriptAdded] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    
    const [isModalOpen, setModalOpen] = useState(false);
    const [searchFilterTrigger, setSearchFilterTrigger] = useState(0);

    const [selectedCatalog, setSelectedCatalog] = useState(''); // State for selected catalog name
    const [catalogNames,setCatalogNames] = useState([]); // Sample catalog names (replace with actual data)
    // let blobUrl=''

    const [blobUrl,setBlobUrl]= useState([]);
const [settings, setSettings] = useState({
        TableConfig: [
            { key: 'SchemaName', header: 'SchemaName', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'TableName', header: 'TableName', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'DDLScripts', header: 'DDLScripts', sortable: true, type: 'normal', deFaultRow: 'viewcode' },
        ],
        ActionItems: { add: true, delete: true, edit: true },
        deFaultRow: [
            { key: 'DDLScripts', values: [] }
        ]
    });

    const searchColumns = ['SchemaName', 'TableName'];


    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    // In the useEffect call the fetchPseudo and fetchDropDownValues
    useEffect(() => {
        const fetchData = async () => {
            try {
                document.getElementById('pageLoader').style.display = "block";
                await Promise.all([
                    fetchDropDown()]);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                document.getElementById('pageLoader').style.display = "none";
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedCatalog) {
            fetchPseudo(selectedCatalog);
        }
    }, [selectedCatalog]);

    useEffect(() => {
        const setCatalogFromState = () => {
            let data;
            try {
                data = JSON.parse(state.newCatalogName);
            } catch (error) {
                console.error("Failed to parse newCatalogName:", error);
                data = state.newCatalogName;
            }
    
            if (Array.isArray(data)) {
                data = data.map(catalogName => catalogName.trim());
                setCatalogNames(data);
                
                // Choose first catalog if none selected
                if (!selectedCatalog) {
                    setSelectedCatalog(data[0]);
                }
            } else {
                console.log("Data is not an array:", data);
            }
        };
    
        setCatalogFromState();
    }, [state.newCatalogName]);

// This function is to fetch the deropDown values.
// PS_47 TO 51
    const fetchDropDown = async () => {

        const body = {
            projectId: state.projectId,
            type: "inventorydd",
            userId: state.userId,
            orgId: state.orgId,
            catalogName:selectedCatalog
        };

        try {
            const res = await fetchDropDownValues(body, state.jwt);
            document.getElementById('pageLoader').style.display = "block";
            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                console.log("The Response Is 200");
                // setCatalogNames(res?.data?.oldCatalogName)
                // const catalogToUse = selectedCatalog || res.data.oldCatalogName[0];
                // setSelectedCatalog(catalogToUse);
                // await fetchPseudo(catalogToUse)
            }
            document.getElementById('pageLoader').style.display = "none";
        } catch (e) {
            console.error('Error fetching Dropdown valuessss data:', e);
            handleError(e);
        }
    }

    // This function is to fetch the blob url and the bloc json of pseudocode
    // PS_36 TO 46
    const fetchPseudo = async (catalogName) => {
        debugger
        const body = {
            projectId: state.projectId,
            type: 'ucddlscripts',
            userId: state.userId,
            orgId: state.orgId,
            catalogName: catalogName
        };
        console.log(body, "current payload")

    try {
        document.getElementById('pageLoader').style.display = 'block';
        const res = await fetchPseudocodeData(body, state.jwt);

        if (res.statusCode === 403) {
            sessionExpired();
        } else if (res.statusCode !== 200) {
            console.error("Error fetching pseudocode data:", res);
        } else {
            console.log("The Response Is 200");
            let data = res?.data[0]?.DDLScriptsPseudoURL;
            setBlobUrl(data);
            console.log(data, "Blob URL");

            document.getElementById('pageLoader').style.display = 'none';

            try {
                const jsonData = await fetchJsonFromBlob(data);
                console.log(jsonData, "Data from blob");

                if (jsonData) {
                    let pseudo = jsonData.map((obj, index) => ({
                        ...obj,
                        index: index
                    }));
                    setGridData(pseudo);
                    setActualValue(pseudo);
                    console.log(pseudo, "Mapped data");
                } else {
                    throw new Error("Failed to fetch data from Azure Blob Storage");
                }
            } catch (e) {
                console.error('Error fetching JSON from blob:', e);
                handleError(e);
            }
        }
    } catch (e) {
        console.error('Error fetching pseudocode data:', e);
        handleError(e);
    }
};

// This function is to fetch the json from blob...
// PS_64 to 69
const fetchJsonFromBlob = async (blobUrl) => {
    try {
        console.log("fetchJsonFromBlobfetchJsonFromBlobfetchJsonFromBlobfetchJsonFromBlob")
        const sasToken = await generateSasTokenAPI();
        console.log(blobUrl, "Fetching JSON data from blob");

        let url = `${blobUrl}?${sasToken}`;
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        console.log(jsonData, 'Fetched JSON Data');
        return jsonData;
    } catch (error) {
        console.error('Error fetching JSON from blob:', error);
        throw error;
    }
};


//  PS_88
// This function is to clear the session
    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };

//  PS_70
    const handleViewCode = (code) => {
        setModalTitle('Code Snippet');
        setCodeSnippet(code);
        setShowModal(true);
        setIsNewCodeSnippet(false);
    };
    //  PS_75
    const handleAddCodeSnippet = () => {
        setModalTitle('Add Code Snippet');
        setCodeSnippet(); // Or any template you want to show
        setShowModal(true);
        setIsNewCodeSnippet(true);
    };
//  PS_80
    const handleSaveCodeSnippet = () => {
        if (isNewCodeSnippet) {
            // Update the newRow with the entered code snippet
            setNewRow(prevRow => ({ ...prevRow, DDLScripts: codeSnippet }));
            setScriptAdded(true); // Set the script as added
        } else {
            currentItem['DDLScripts'] = codeSnippet;
            handleEdit(currentItem);
        }
        setShowModal(false);
    };
//  PS_85
    const handleCancelScript = () => {
        setNewRow(prevRow => ({ ...prevRow, DDLScripts: '' }));
        setScriptAdded(false);
    };
//  PS_93
    const handleEdit = (item) => {
        setCurrentOperation('edit');
        setCurrentItem(item);
        setShowReasonModal(true);
    };
//  PS_97
    const handleAdd = (newItem) => {
        setCurrentOperation('add');
        setCurrentItem(newItem);
        setShowReasonModal(true);
    };
//  PS_101
    const handleDelete = (item) => {
        setCurrentOperation('delete');
        setCurrentItem(item);
        setShowReasonModal(true);
    };
//  PS_105
    // const handleReasonSave = async () => {
    //     try {
    //         let updatedData;
    //         switch (currentOperation) {
    //             case 'edit':
    //                 updatedData = gridData.map(item =>
    //                     item.index === currentItem.index ? { ...currentItem, reason } : item
    //                 );
    //                 break;
    //             case 'add':
    //                 updatedData = [...gridData, { ...currentItem, reason, index: gridData.length }];
    //                 break;
    //             case 'delete':
    //                 updatedData = gridData.filter(item => item.index !== currentItem.index);
    //                 break;
    //             default:
    //                 throw new Error('Invalid operation');
    //         }


    //         let body = {
    //             type: "DDLScriptsPseudo",
    //             projectId: state.projectId,
    //             userId: state.userId,
    //             orgId: state.orgId,
    //             comments: reason,
    //         }
    //         await updatePseudocodeData(body, state.jwt);
    //         await updateJsonInBlob(blobUrl, updatedData);

    //         // Update local state
    //         setGridData(updatedData);
    //         setActualValue(updatedData);
    //         setShowReasonModal(false);
    //         setScriptAdded(false)
    //         setReason('');
    //         setNewRow({});
    //         setCurrentOperation(null);
    //         setCurrentItem(null);
    //         setWhetherUpdated(true);
    //     } catch (error) {
    //         console.error('Error updating data:', error);
    //         // Handle error (show message to user, etc.)
    //     }
    // };
    const handleReasonSave = async () => {
           try {
                let updatedData;
                switch (currentOperation) {
                   case 'edit':
                       updatedData = actualValue.map(item =>
                           item.index === currentItem.index ? { ...item, ...currentItem, reason } : item
                       );
                       break;
                   case 'add':
                       updatedData = [...actualValue, { ...currentItem, reason, index: actualValue.length }];
                       break;
                   case 'delete':
                       updatedData = actualValue.filter(item => item.index !== currentItem.index);
                       break;
                   default:
                       throw new Error('Invalid operation');
               }
       let body={
                       type: "DDLScriptsPseudo", 
                       projectId: state.projectId, 
                       userId: state.userId,
                       orgId: state.orgId,
                       comments:reason,
       
       
       
                   }
                 let res=  await updatePseudocodeData(body,state.jwt)
                   await updateJsonInBlob(blobUrl, updatedData);
               await updateJsonInBlob(blobUrl, updatedData);
       
       
       
               // Update local state with original data and re-apply filter
               setActualValue(updatedData);
               setGridData(updatedData); // Optionally: apply previous filter/search to updatedData
        setSearchFilterTrigger(prev => prev + 1);
       
       
               setShowReasonModal(false);
               setReason('');
               setCurrentOperation(null);
               setCurrentItem(null);
               setWhetherUpdated(true);
           } catch (error) {
               console.error('Error updating data:', error);
           }
       };

    
//  PS_112
    const updateJsonInBlob = async (blobUrl, updatedData) => {

        console.log (blobUrl,"blobUrlblobUrlblobUrlblobUrl",updatedData,"updateddddddDaatattatata")
        try {
            const sasToken = await generateSasTokenAPI();
         
            const url = `${blobUrl}?${sasToken}`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Data updated successfully in Azure Blob');
        } catch (error) {
            console.error('Error updating JSON in blob:', error);
            throw error;
        }
    };
//  PS_116
    const handleError = (e) => {
        insertError({
            errorMessage: e.message,
            serviceName: "frontend",
            module: "DDLScripts",
            functionName: "DDlScriptTable",
            userId: state.userId
        });
        console.log("error", e);
        setError(e.message);
    }

    const handleCopy= () => {
        try {
            // const concatenatedScripts = filteredScripts?.map(scriptObj => scriptObj.scripts).join("\n");
            navigator.clipboard.writeText(codeSnippet)
            setIsCopied(true);
            setTimeout(() => { setIsCopied(false) }, 2000);
        } catch (err) {
            console.error('Error while copying to clipboard:', err);
        }
    };

    return (
        <div className="container-fluid">
             <div className="row " >
             <div className="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                    <div className="d-flex">
                        <div className="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                            </span>
                            <span className="mx-3 font-14 loader-text">Session has expired. Please login Again</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center " role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                Oops! Something went wrong
            </button>
            <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                <p className="font-14 font-regular text-black">Please try again later</p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <HeaderCom value="1" />
            <div className="col-md-12 pt-4 mt-5">
            <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a >Manage Projects</a></li>
                            <li className="font-16 font-medium"><a>{state.projectName}</a></li>
                            <li className="font-16 font-medium active">DDL Scripts</li>
                        </ul>
                    </div>
                <div className="d-flex align-items-center justify-content-between p-4">
                    <div className="d-flex">
                        <a className="d-flex">
                            <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '35px', cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
                        </a>
                        <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}
                            <button type="button" onClick={handleOpenModal} className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3">
                                <img src="images/info-icon-ETL.svg" className alt="info" />
                            </button></h2>
                        {isModalOpen && <ViewInstructions notebooktype='ddlPseudo' onClose={handleCloseModal} />}
                    </div>
                    <div className="d-flex">
                        <SearchComponent
                            columns={searchColumns}
                            actualValue={actualValue}
                            setGridData={setGridData}
                            searchFilterTrigger={searchFilterTrigger}
                        />

                        <CatalogComponent
                            catalogNames={catalogNames}
                            selectedCatalog={selectedCatalog}
                            setSelectedCatalog={setSelectedCatalog}
                        />

                        <DownloadComponent
                            type="excel"
                            data={actualValue.map(item => ({
                                SourceCatalogName: selectedCatalog,
                                SchemaName: item.SchemaName,
                                TableName: item.TableName,
                                DDLScripts: item.DDLScripts
                            }))}
                            fileName="DDLScripts"
                        />
                    </div>
                </div>
                <DynamicGrid
                    data={gridData}
                    settings={settings}
                    setGridData={setGridData}
                    actualValue={actualValue}
                    setWhetherUpdated={setWhetherUpdated}
                    onEdit={handleEdit}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    onViewCode={handleViewCode} 
                    onAddCodeSnippet={handleAddCodeSnippet}
                    newRow={newRow}
                    setNewRow={setNewRow}
                    scriptAdded={scriptAdded}
                    handleCancelScript={handleCancelScript} // New prop
                />
                <ReasonComponent
                    isOpen={showReasonModal}
                    onClose={() => setShowReasonModal(false)}
                    onSave={handleReasonSave} 
                    reason={reason}
                    setReason={setReason}
                />


                {/* Modal */}
                {showModal && (
                    <div className="modal show d-block" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header border-0 px-4">
                                    <h5 className="modal-title d-flex align-items-center font-medium font-20">{modalTitle}</h5>
                                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowModal(false)}></button>
                                </div>
                                <div className="modal-body py-0">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-10">
                                        <textarea
                                                className="form-control custom-textarea logger-textarea-scroll add-code-snippet no-word-wrap"
                                                id="exampleFormControlInput1"
                                                value={codeSnippet}
                                                onChange={(e) => setCodeSnippet(e.target.value)}
                                                readOnly={!isNewCodeSnippet}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 px-4 mx-5 mt-3">
                                   
                                {isNewCodeSnippet ? (
                                            <>
                                                <button type="button" className="btn cust-primary-btn font-14 font-medium me-2"
                                                    onClick={() => setShowModal(false)}>
                                                    Cancel
                                                </button>   
                                                <button type="button" className="btn cust-primary-btn font-14 font-medium me-2"
                                                    onClick={handleSaveCodeSnippet}>
                                                    Add          
                                                </button>
                                            </>
                                        ) : (
                                            <button type="button" className="btn cust-primary-btn font-14 font-medium me-2"
                                            onClick={handleCopy}
                                            >
                                                <img src="images/copy-code-icon.svg" alt="copy" className="me-2" />
                                                <span>{isCopied ? "Copied" : "Copy Code"}</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DDLScript;
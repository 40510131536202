// mockData.js

export const mockFetchCatalogSchemaResponse = {
    status: 200,
    data: [
        { catalog: 'alpha', schema: 'bronze', statuses: "APPROVED" },
        { catalog: 'alpha', schema: 'silver', statuses: "APPROVED" },
        { catalog: 'yesss', schema: 'gold', statuses: "APPROVED" },
        { catalog: 'beta', schema: 'bronze', statuses: "SKIP" },
        { catalog: 'noo', schema: 'ooOoo', statuses: "APPROVED" },
        { catalog: 'beta', schema: 'gold', statuses: "SKIP" },
        { catalog: 'nooo', schema: 'eee', statuses: "SKIP" },
        { catalog: 'nooo', schema: 'silver', statuses: "SKIP" },
        { catalog: 'gamma', schema: 'gold', statuses: "SKIP" },
    ]
};


export const mockFetchSourceToTargetFlowResponse = {
    status: 200,
    data: {
        umlCatalogCode: `@startuml source2targetSample

package "Inventory_Catalog_C" {
  
}

package "Delivery_Catalog_D" {
  
}

package "Warehouse_Catalog_A" {
  
}

package "Shipping_Catalog_B" {
  
}

Warehouse_Catalog_A -[dashed]-> Shipping_Catalog_B : migrated to
Inventory_Catalog_C -[dashed]-> Delivery_Catalog_D : migrated to

@enduml`,

        umlSchemaCode: `@startuml source2targetSample

package "Inventory_Catalog_C" {
  entity "bronze" {
  }
  entity "gold"{
  }
}

package "Delivery_Catalog_D" {
  entity "bronze" {
  }
  entity "gold" {
  }
}

package "Warehouse_Catalog_A" {
  entity "bronze" { 
  }
  entity "silver" {
  }
}

package "Shipping_Catalog_B" {
  entity "silver" {
  }
}

Warehouse_Catalog_A -[dashed]-> Shipping_Catalog_B : migrated to
Inventory_Catalog_C -[dashed]-> Delivery_Catalog_D : migrated to

@enduml`,

        umlTableCode: `@startuml source2targetSample

package "Inventory_Catalog_C" {
  entity "bronze" {
    product_inventory
  }
  entity "gold"{
    product_inventory_history
  }
}

package "Delivery_Catalog_D" {
  entity "bronze" {
    delivery_schedules
  }
  entity "gold" {
    delivery_performance
  }
}

package "Warehouse_Catalog_A" {
  entity "bronze" {
    warehouse_locations
  }
  entity "silver" {
    shipment_routes
  }
}

package "Shipping_Catalog_B" {
  entity "silver" {
    shipment_routes_shipping
  }
}

Warehouse_Catalog_A -[dashed]-> Shipping_Catalog_B : migrated to
Inventory_Catalog_C -[dashed]-> Delivery_Catalog_D : migrated to

@enduml`
    }
};
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { generateSasTokenAPI, postTwbxFile } from "../../Service/Api";

function TwbxPopup(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const accountName = 'avaeusgenetlsametadev';
    const containerName = 'uc-migration';

    const [editHTML, seteditHTML] = useState(true);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [futureState, setFutureState] = useState("");
    const [data, setData] = useState({});
    const [show, setShow] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [enable, setEnable] = useState(true);

    const { state } = useLocation();
    if (state == null) {
        window.location.href = "/";
    }

    useEffect(() => {
        setShow(true);
       
        if (props.fileUploaded && props.fileUploaded !== '') {
            setFormValid(false);
            setFileName(props.fileUploaded)
            setEnable(false);
        } else {
            setFormValid(true);
            setEnable(true);
        }
    }, [props.fileUploaded]);


    



    const putFileToBlob = async (fileData) => {
        try {
            const sasToken = await generateSasTokenAPI();
            const blobPath = `organizations/${state.orgId}/${state.projectId}/files/twbxFile.twbx`;
            const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
        
            const contentType = fileData.type || 'application/octet-stream';

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': contentType,
                    'x-ms-blob-type': 'BlockBlob'
                },
                body: fileData
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const fileUrl = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`;
            console.log('File uploaded successfully to ', fileUrl);
            return fileUrl;

        } catch (error) {
            console.error('Error putting file to blob:', error);
            throw error;
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            setMessage('Please select a file to upload');
            return;
        }
        document.getElementById("pageLoader").style.display = "block";

        setIsLoading(true);
        setMessage('');

        try {
            const fileUrl = await putFileToBlob(file);
            console.log('File has been uploaded to:', fileUrl);
            
            const body = {
                "blobUrl":fileUrl ,
                twbxFileName: fileName,
                userId: state.userId,
                projectId: state.projectId,
                orgId:state.orgId
            };
            console.log("body", body);
            const responseData = await postTwbxFile(body)
            setShow(() => false);
            document.getElementById("pageLoader").style.display = "none";
            console.log("responseData", responseData);

            if (responseData.status == "success") {
                setMessage('File uploaded successfully!');
                props.closePopup();
                props.saveFunction(fileName);
            } else {
                props.closePopup();
                props.onChange({ status: 400 });
            }

           


        } catch (error) {
            setMessage('Error uploading file: ' + error.message);
        } finally {
            document.getElementById("pageLoader").style.display = "none"; 
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setFile(null);  // Clear the selected file
        setFileName('');  // Clear the file name
        props.closePopup();  // Call the prop function to close the popup
    };

    return (
        <div className="container-fluid">
            <div style={editHTML === false ? { display: "none" } : { display: "block" }}></div>
            <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Confrm" hidden>
                    Upload Tableau File*
                </button>
                <div className={`modal fade ${show ? "show" : ""}`} id="Confrm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ConfrmLabel" aria-hidden="true" style={show ? { 'display': "block", "paddingLeft": '0px' } : {}}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">Upload Files</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { handleCancel(); setShow(false); }}></button>
                            </div>
                            <div className="modal-body py-0">
                                <div className="row mt-3">
                                    <div className="col-md-12 col-sm-12 mb-3">
                                        <label className="form-label text-black font-14 font-medium">Upload Tableau File</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="mb-5">
                                            <span className="upload-btn-wrapper d-block">
                                                {!fileName ? (
                                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                        <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                                        <span className="font-medium font-13 d-block text-grey mb-2">Browse or drag and drop file</span>
                                                    </button>
                                                ) : (
                                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                        <img src="images/sample-logo.svg" alt="sample-logo" className="mb-2" />
                                                        <a className="font-medium font-12 d-block">{fileName}</a>
                                                        <a
                                                            style={{ position: "relative", zIndex: "100" }}
                                                            onClick={() => { handleCancel() }}
                                                        >
                                                            <span className="ms-2">
                                                                <img src="images/link-close-icon.svg" alt="link-close-icon" />
                                                            </span>
                                                        </a>
                                                    </button>
                                                )}
                                                <input type="file" name="myfile" className="cursor-pointer cust-file" accept=".twbx" id="definedocumentid" onChange={handleFileChange} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0 px-4">
                                <button type="button" className="btn cust-secondary-btn font-14 font-medium me-2" data-bs-dismiss="modal"
                                    onClick={() => {
                                        setShow(false);
                                        handleCancel()
                                        setValidationErrors([]);
                                    }}>
                                    Cancel
                                </button>
                                <button type="button" data-bs-dismiss="modal" disabled={!formValid || isLoading}
                                    className="btn cust-primary-btn font-14 font-medium"
                                    onClick={handleUpload}>
                                    <span>Submit</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {show && <div className="modal-backdrop show"></div>}
            </div>
        </div>
    );
}

export default TwbxPopup;
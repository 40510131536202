// Import all the necessary modules
//  PS_01  to  PS_09
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError,generateSasTokenAPI } from "../../../Service/Api.js";
import { HeaderCom } from "../../Header.jsx";
import DynamicGrid from './DynamicTable.jsx';
import SearchComponent from './SearchComponent.jsx';
import FilterComponent from './FilterComponent.jsx';
import DownloadComponent from './DownloadComponent.jsx';
import ReasonComponent from './ReasonComponent.jsx';
import ViewInstructions from "./viewInstructions.jsx";



function CopyClone(props) {
// Declare all the state variables 
//  PS_11 to 24
  



    const { state } = useLocation();
    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);
    const [actualValue, setActualValue] = useState([]);
    const [dropDownValues, setDropDownValues] = useState({});
    const [error, setError] = useState(null);
    const [whetherUpdated, setWhetherUpdated] = useState(false);

    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [currentOperation, setCurrentOperation] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [newRow, setNewRow] = useState({});
    const [blobUrl, setblobUrl] = useState('');
    const [searchFilterTrigger, setSearchFilterTrigger] = useState(0);
    const [settings, setSettings] = useState({
        TableConfig: [
            { key: 'NotebookName', header: 'NotebookName', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'RequirementID', header: 'RequirementID', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'DesignID', header: 'DesignID', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'CellName', header: 'CellName', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'Steps', header: 'Steps', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'Flow', header: 'Flow', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'OperationType', header: 'OperationsType', sortable: true, type: 'normal', deFaultRow: 'input' },
            { key: 'OperationDescription', header: 'OperationDescription', sortable: true, type: 'normal', deFaultRow: 'input' },
            {key:'InternalReviewComments',header:'InternalReviewComments',sortable: true, type: 'normal', deFaultRow: 'input'},
            {key:'ExternalReviewComments',header:'ExternalReviewComments', sortable: true, type: 'normal', deFaultRow: 'input'}

        ],
        ActionItems: { add: true, delete: true, edit: true },
        deFaultRow: []


    });

    const searchColumns = ['NoteBookName', 'OperationType', 'CellName']
    const [isModalOpen, setModalOpen] = useState(false);
            
    const handleOpenModal = () => {
        setModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setModalOpen(false);
      };


 // In the useEffect call the fetchPsuedocode function
    //  PS_25 to  PS_26
    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([ fetchPseudo()]);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            }
        };
        fetchData();
    }, []);

   // This function is to fetching the blob url from the Database.
//   PS_27 to  PS_54
    const fetchPseudo = async () => {
        document.getElementById('pageLoader').style.display = "block";
        const body = {
            projectId: state.projectId,
            type: "copyclonepseudocode",
            userId: state.userId,
            orgId: state.orgId,
        };

        try {
            const res = await fetchPseudocodeData(body, state.jwt);
            console.log(res, "response from DB")
            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                console.log("The Response Is 200");
                let blobURL = res?.data[0]?.copyClonePseudocodeURL;
                setblobUrl(blobURL)
                console.log(blobURL, 'fetchJsonFromBlobfetchJsonFromBlob');
                try {
                    const jsonData = await fetchJsonFromBlob(blobURL);
                    document.getElementById('pageLoader').style.display = "none";
                    console.log(jsonData, "Data from blob");

                    if (jsonData) {
                        let pseudo = jsonData.map((obj, index) => ({
                            ...obj,
                            index: index
                        }));
                        setGridData(pseudo);
                        setActualValue(pseudo);
                        console.log(pseudo, "Mapped data");
                    } else {
                        throw new Error("Failed to fetch data from Azure Blob Storage");
                    }
                } catch (e) {
                    console.error('Error fetching JSON from blob:', e);
                    handleError(e);
                }
            }
        } catch (e) {
            console.error('Error fetching pseudocode data:', e);
            handleError(e);
        }
    }
//  PS_64 to 68
// This function is to fetch Json From Blob
    
    const fetchJsonFromBlob = async (blobURL) => {
        try {
            const sasToken = await generateSasTokenAPI();
         
            let url = `${blobURL}?${sasToken}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonData = await response.json();
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };


        // This function is to  clear the local storage
//  PS_69 to  PS_73
    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };



     // This function is to edit the pseudocode
    //   PS_74 to   PS_77               
    const handleEdit = (item) => {
        setCurrentOperation('edit');
        setCurrentItem(item);
        setShowReasonModal(true);
    };
// This function will be triggered while adding data to the pseudocode
//   PS_78 to  PS_81             
    const handleAdd = (newItem) => {
        setCurrentOperation('add');
        setCurrentItem(newItem);
        setShowReasonModal(true);
    };
// This function will be triggered while deleting any data on the pseudocode
//   PS_82 to  PS_85
    const handleDelete = (item) => {
        setCurrentOperation('delete');
        setCurrentItem(item);
        setShowReasonModal(true);
    };


    //  PS_86 to  PS_95
//     // This function is to handle the reason pop up and the crud operations.

//     const handleReasonSave = async () => {
//         try {
//             let updatedData;
//             switch (currentOperation) {
//                 case 'edit':
//                     updatedData = gridData.map(item =>
//                         item.index === currentItem.index ? { ...currentItem, reason } : item
//                     );
//                     break;
//                 case 'add':
//                     updatedData = [...gridData, { ...currentItem, reason, index: gridData.length }];
//                     break;
//                 case 'delete':
//                     updatedData = gridData.filter(item => item.index !== currentItem.index);
//                     break;
//                 default:
//                     throw new Error('Invalid operation');
//             }
// let body={
//                 type: "copyClonePseudocode", 
//                 projectId: state.projectId, 
//                 userId: state.userId,
//                 orgId: state.orgId,
//                 comments:reason,

//             }
//           let res=  await updatePseudocodeData(body,state.jwt)
//             await updateJsonInBlob(blobUrl, updatedData);

//             // Update local state
//             setGridData(updatedData);
//             setActualValue(updatedData);
//             setShowReasonModal(false);
//             setReason('');
//             setCurrentOperation(null);
//             setCurrentItem(null);
//             setWhetherUpdated(true);
//         } catch (error) {
//             console.error('Error updating data:', error);
//             // Handle error (show message to user, etc.)
//         }
//     };


const handleReasonSave = async () => {
       try {
            let updatedData;
            switch (currentOperation) {
               case 'edit':
                   updatedData = actualValue.map(item =>
                       item.index === currentItem.index ? { ...item, ...currentItem, reason } : item
                   );
                   break;
               case 'add':
                   updatedData = [...actualValue, { ...currentItem, reason, index: actualValue.length }];
                   break;
               case 'delete':
                   updatedData = actualValue.filter(item => item.index !== currentItem.index);
                   break;
               default:
                   throw new Error('Invalid operation');
           }
   let body={
                   type: "copyClonePseudocode", 
                   projectId: state.projectId, 
                   userId: state.userId,
                   orgId: state.orgId,
                   comments:reason,
   
   
   
               }
             let res=  await updatePseudocodeData(body,state.jwt)
               await updateJsonInBlob(blobUrl, updatedData);
           await updateJsonInBlob(blobUrl, updatedData);
   
   
   
           // Update local state with original data and re-apply filter
           setActualValue(updatedData);
           setGridData(updatedData); // Optionally: apply previous filter/search to updatedData
   
    setSearchFilterTrigger(prev => prev + 1);
   
           setShowReasonModal(false);
           setReason('');
           setCurrentOperation(null);
           setCurrentItem(null);
           setWhetherUpdated(true);
       } catch (error) {
           console.error('Error updating data:', error);
       }
   };


    // This function is to update the pseudocode json in Blob.
//  PS_96 to  PS_98
    const updateJsonInBlob = async (blobURL, updatedData) => {
        try {
            const sasToken = await generateSasTokenAPI();
    
            const url = `${blobURL}?${sasToken}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Data updated successfully in Azure Blob');
        } catch (error) {
            console.error('Error updating JSON in blob:', error);
            throw error;
        }
    };


    //  PS_99 to  PS_101
// This function is to store the error logs in the database.
    const handleError = (e) => {
        insertError({
            errorMessage: e.message,
            serviceName: "frontend",
            module: "Datadictionary",
            functionName: "DataDictionaryTable",
            userId: state.userId
        });
        console.log("error", e);
        setError(e.message);
    }




    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                    <div className="d-flex">
                        <div className="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                            </span>
                            <span className="mx-3 font-14 loader-text">Session has expired. Please login Again</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center " role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                Oops! Something went wrong
            </button>
            <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                <p className="font-14 font-regular text-black">Please try again later</p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <HeaderCom value="1" />
            <div className="col-md-12 pt-4 mt-5">
                <div className="mt-3 px-2">
                    <ul className="cust-breadcrumb mb-0">
                        <li className="font-16 font-medium">
                            <a>Manage Projects</a>
                        </li>
                        <li className="font-16 font-medium">
                            <a>{state.projectName}</a>
                        </li>
                        <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                    </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between p-4">
                    <div className="d-flex">
                        <a className="d-flex">
                            <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '35px', cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
                        </a>
                        <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}
                        <button type="button" onClick={handleOpenModal} className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3">
                                <img src="images/info-icon-ETL.svg" className alt="info" />
                            </button></h2>
                            {isModalOpen && <ViewInstructions notebooktype='copyClonePseudo' onClose={handleCloseModal} />}
                    </div>
                    <div className="d-flex">
                        <SearchComponent
                            columns={searchColumns}
                            actualValue={actualValue}
                            setGridData={setGridData}
                            searchFilterTrigger={searchFilterTrigger}
                        />
                        {/* <FilterComponent
                            columns={settings.TableConfig.map(config => config.key)}
                            actualValue={actualValue}
                            setGridData={setGridData}
                        /> */}
                        <DownloadComponent
                            type="excel"
                            data={actualValue}
                            fileName="CopyClone"
                        />
                    </div>
                </div>
                <DynamicGrid
                    data={gridData}
                    settings={settings}
                    setGridData={setGridData}
                    actualValue={actualValue}
                    setWhetherUpdated={setWhetherUpdated}
                    onEdit={handleEdit}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    newRow={newRow}
                    setNewRow={setNewRow}
                />
                <ReasonComponent
                    isOpen={showReasonModal}
                    onClose={() => setShowReasonModal(false)}
                    onSave={handleReasonSave}
                    reason={reason}
                    setReason={setReason}
                />
            </div>
        </div>
    );
}

export default CopyClone;
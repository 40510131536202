
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ACDynamicGrid from './Integrations/ACDynamicTable';
import { fetchUserDetail } from '../../Service/Api';
import SearchComponent from './Integrations/SearchComponent';
import { HeaderCom } from '../Header';
import ReasonComponent from './Integrations/ReasonComponent';  // Import ReasonComponent

const Roles = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    const [roles, setRoles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [actualValue, setActualValue] = useState([]);
    const [showReasonModal, setShowReasonModal] = useState(false);  // Modal visibility state
    const [reason, setReason] = useState('');  // Reason text state
    const [currentOperation, setCurrentOperation] = useState('');  // Track current operation (add, edit, delete)
    const [currentItem, setCurrentItem] = useState(null);  // Track current item being operated on
    const stateValue = { ...state };

    useEffect(() => {
        fetchRoles();
    }, []);

    const searchColumns = ['name'];

    // Fetch roles from API
    const fetchRoles = async () => {
        const body = {
            projectId: state.projectId,
            generationtype: "FetchRoles",
            jwt_token: state.jwt,
            userId: state.userId
        };
        document.getElementById('pageLoader').style.display = 'block';

        const data = await fetchUserDetail(body, state.jwt);
        setRoles(data.roles.map(role => ({
            ...role,
            privilege: Array.isArray(role.privilege) ? role.privilege.join(', ') : role.privilege,
        })));
        setActualValue(data.roles.map(role => ({
            ...role,
            privilege: Array.isArray(role.privilege) ? role.privilege.join(', ') : role.privilege,
        })));
        document.getElementById('pageLoader').style.display = 'none';
    };

    // Handle add, edit, delete operations with reason modal

    const handleAddRole = (newRow) => {
        setShowReasonModal(true);
        setCurrentOperation('add');
        setCurrentItem(newRow);
    };

    const handleEditRole = (updatedRow) => {
        setShowReasonModal(true);
        setCurrentOperation('edit');
        setCurrentItem(updatedRow);
    };

    const handleDeleteRole = (row) => {
        setShowReasonModal(true);
        setCurrentOperation('delete');
        setCurrentItem(row);
    };

    const handleReasonSave = async () => {
        let updatedRoles = [];

        try {
            if (currentOperation === 'edit') {
                const index = roles.findIndex((role) => role.name === currentItem.name);
                updatedRoles = [...roles];
                updatedRoles[index] = { ...currentItem, reason };
            } else if (currentOperation === 'delete') {
                updatedRoles = roles.filter((role) => role.name !== currentItem.name);
            } else if (currentOperation === 'add') {
                updatedRoles = [...roles, { ...currentItem, reason }];
            }

            const body = {
                projectId: state.projectId,
                generationtype: "RoleUpdate",
                roles: updatedRoles,
                jwt_token: state.jwt,
                userId: state.userId
            };

            await fetchUserDetail(body, state.jwt);

            setRoles(updatedRoles);
            setActualValue(updatedRoles);
            setShowReasonModal(false);
            setReason('');
            setCurrentOperation('');
            setCurrentItem(null);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const settings = {
        TableConfig: [
            {
                header: 'Role Name',
                key: 'name',
                sortable: true,
                deFaultRow: 'input'
            },
            {
                header: 'privilege',
                key: 'privilege',
                sortable: false,
                deFaultRow: 'dropdown',
                dropdownOptions: [
                    'USE CATALOG', 'USE SCHEMA', 'APPLY TAG', 'CREATE TABLE', 'DROP TABLE', 'ALTER TABLE', 'SELECT', 'INSERT', 'UPDATE', 'DELETE',
                    'BROWSE', 'EXECUTE', 'EXTERNAL USE SCHEMA', 'MODIFY', 'READ VOLUME', 'REFRESH', 'WRITE VOLUME', 'CREATE FUNCTION',
                    'CREATE MATERIALIZED VIEW', 'CREATE MODEL', 'CREATE SCHEMA', 'CREATE VOLUME', 'ALL privilege'
                ]
            }
        ],
        deFaultRow: [
            { key: 'privilege', values: ['USE CATALOG', 'USE SCHEMA', 'APPLY TAG', 'BROWSE', 'EXECUTE', 'EXTERNAL USE SCHEMA', 'MODIFY', 'READ VOLUME', 'REFRESH', 'SELECT', 'WRITE VOLUME', 'CREATE FUNCTION', 'CREATE MATERIALIZED VIEW', 'CREATE MODEL', 'CREATE SCHEMA', 'CREATE TABLE', 'CREATE VOLUME', 'ALL privilege'] }
        ]
    };

    return (
        <div className="container-fluid">
           <HeaderCom value='1' />
            <div className="col-md-12 pt-4 mt-5">
                <div className="mt-3">
                    <ul className="cust-breadcrumb mb-0">
                        <li className="font-12 font-medium"><a>Manage Projects</a></li>
                        <li className="font-12 font-medium active">{state.projectName}</li>
                    </ul>
                </div>
                <div className="row p-4">
                    <div className="d-flex mb-4 justify-content-between align-items-center">
                        <div className="d-flex align-items-center mb-xl-0">
                            <a className="d-flex" onClick={() => navigate('/User', { state: stateValue })}>
                                <img src="images/back-arrow.svg" alt="back-arrow" />
                            </a>
                            <h2 className="text-black font-bold font-22 mb-0 ms-3">{state.projectName}</h2>
                        </div>
                        <div className="d-lg-flex d-md-block">
                            <div className="d-flex mb-md-6 mb-sm-6">
                                <div className="cust-input-sty font-11 rounded-2 font-medium text-grey-v7 px-3 me-3 d-flex align-items-center">
                                    <span className="text-nowrap">Organization :</span>
                                    <span><img src={state.orgLogo} alt="hubspot-logo"  className="figma-logo"/></span>
                                    <span className="me-2 text-nowrap font-medium font-12 text-black">{state.orgName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="code-review-tab-width">
                        <div className="code-review-page tabs nav">
                        <div className="tab nav-link font-bold" data-bs-toggle="pill" >
                                <a
                                        onClick={() => {
                                            navigate("/ProjectInfo", {
                                                state: stateValue,
                                            });
                                        }}
                                    >
                                    Project Info
                                    </a>
                                    </div>
                                <div className="tab nav-link font-bold" data-bs-toggle="pill" onClick={() => {
                                            navigate("/uc-chat", {
                                                state: stateValue,// Corrected the syntax here
                                            });
                                        }}>
                                    <a
                                        
                                    >
                                        Ask GenETL
                                    </a>
                                </div>
                            <div className="tab nav-link font-bold active" data-bs-toggle="pill" >Access Control</div>
                        </div>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div id="acc-control" className="tab-pane fade show active code-review-tab tab-content">
                            <div className="row mt-5">
                                <div className="col-md-2">
                                    <div className="tab-container">
                                        <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow">
                                            <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/User', { state: stateValue })}>
                                                <span>User Details</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center active" onClick={() => navigate('/Roles', { state: stateValue })}>
                                                <span>Roles</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/GroupList', { state: stateValue })}>
                                                <span>Groups</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/Script', { state: stateValue })}>
                                                <span>Scripts</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="font-20 font-bold text-black mb-0">Role Details</h4>
                                        <div className="d-flex gap-3">
                                            <SearchComponent
                                            columns={searchColumns}
                                            actualValue={actualValue}
                                            setGridData={setRoles}
                                        />
                                        </div>
                                        
                                    </div>
                                    <ACDynamicGrid 
                                        data={roles}
                                        settings={settings}
                                        setGridData={setRoles}
                                        actualValue={roles}
                                        setWhetherUpdated={() => {}}
                                        onEdit={handleEditRole}
                                        onAdd={handleAddRole}
                                        onDelete={handleDeleteRole}
                                        view={1}
                                    />
                                    <ReasonComponent
                                        isOpen={showReasonModal}
                                        onClose={() => setShowReasonModal(false)}
                                        onSave={handleReasonSave}
                                        reason={reason}
                                        setReason={setReason}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="toastMessage" className="toast-message" style={{ display: 'none' }}>
                Session expired. Redirecting to login...
            </div>
            <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roles;
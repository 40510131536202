import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import PlantUML from 'react-plantuml';
import {generateApproachDiagram,generateSasTokenAPI} from '../../../Service/Api'
import ViewInstructions from './viewInstructions';


// Code Snippet Modal Component
const CodeSnippetModal = ({ code, onClose }) => (
  <div className="modal fade show" id="Confrm" tabIndex="-1" aria-labelledby="ConfrmLabel" aria-hidden="false" style={{ display: 'block' }}>
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header border-0 px-4">
          <h5 className="modal-title d-flex align-items-center font-medium font-20" id="ConfrmLabel">Code Snippet</h5>
          <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
        </div>
        <div className="modal-body py-0">
          <div className="row d-flex justify-content-center">
            <div className="col-10">
              <div>
                <textarea className="form-control custom-textarea logger-textarea-scroll add-code-snippet no-word-wrap" value={code} readOnly />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer border-0 px-4 mx-5 mt-3">
          <button type="button" className="btn cust-primary-btn font-14 font-medium me-2" onClick={() => navigator.clipboard.writeText(code)}>
            <img src="images/copy-code-icon.svg" alt="copy" className="me-2" />
            <span>Copy Code</span>
          </button>
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show" onClick={onClose}></div>
  </div>
);

const ApproachDiagram = () => {
  let { state } = useLocation();
  const [beforeDiagramCode, setBeforeDiagramCode] = useState('');
  const [afterDiagramCode, setAfterDiagramCode] = useState('');
  const [zoom, setZoom] = useState(1);
  const [modalCode, setModalCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  


  const nav = useNavigate();
  const handleOpenModal = () => {
    setIsModalOpen(true);
};
const handleCloseModal = () => {
  setIsModalOpen(false);
};
  useEffect(() => {
    // if (!state) {
    //   nav('/');
    //   return;
    // }

    const fetchData = async () => {
      try {
        const pseudoData = await fetchPseudo();
        if (pseudoData.statusCode === 200) {
          const beforeDiagramUrl = pseudoData.BeforeApproachDiagramUrl;
          const afterDiagramUrl = pseudoData.AfterApproachDiagramUrl;
          const jsonData = await streamBlobData(beforeDiagramUrl, afterDiagramUrl);
          setBeforeDiagramCode(jsonData.BeforeDiagram);
          setAfterDiagramCode(jsonData.AfterDiagram);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        document.getElementById('pageLoader').style.display = 'none';
      }
    };

    fetchData();
  }, [state, nav]);

  const fetchPseudo = async () => {
    // let body={
    //   "projectId":state.projectId,
    //   "projectName":state.projectName,
    //   "orgName":state.orgName,
    //   "orgId":state.orgId,
    //   "userId":state.userId
    // }
    // let data= await generateApproachDiagram(body)
    // return {
    //   statusCode: data.status,
    //   BeforeApproachDiagramUrl: data,diagram1,
    //   AfterApproachDiagramUrl: data.diagram2
    // };
    return {
      statusCode: 200,
      BeforeApproachDiagramUrl: '/dummy-url-before',
      AfterApproachDiagramUrl: '/dummy-url-after'
    };
  };

  const streamBlobData = async (beforeBlobUrl, afterBlobUrl) => {
    try {
      let beforeBlobData = await fetchJsonFromBlob(beforeBlobUrl);
      let afterBlobData = await fetchJsonFromBlob(afterBlobUrl);
      return { BeforeDiagram: beforeBlobData, AfterDiagram: afterBlobData };
    } catch (error) {
      console.error('Error streaming blob data:', error);
      throw error;
    }
  };

  const fetchJsonFromBlob = async (inputURL) => {
    try {
        console.log(inputURL, "inputURL");

        const sasToken = await generateSasTokenAPI();
        console.log(sasToken, "Generated SAS Token");
        // inputURL = inputURL;
        let url = `${inputURL}?${sasToken}`;
        console.log(url, "Final URL");

        let response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        console.log(jsonData, 'Fetched JSON Data');
        return jsonData;
    } catch (error) {
        console.error('Error fetching JSON from blob:', error);
        throw error;
    }
};

  const handleViewCode = (code) => {
    setModalCode(code);
    setIsModalOpen(true);
  };

  const handleCaptureClick = async () => {
    try {
      const downloadImage = async (elementId, fileName) => {
        const imgElement = document.querySelector(`#${elementId} img`);
        if (!imgElement) {
          console.error(`Image element with id ${elementId} not found!`);
          return;
        }
        let srcValue = imgElement.getAttribute('src');
        
        if (srcValue.startsWith('http://')) {
          srcValue = srcValue.replace('http://', 'https://');
        }
        
        const response = await fetch(srcValue);
        const blob = await response.blob();
        saveAs(blob, fileName);
      };

      // Download Before Diagram
      await downloadImage('Approach diagram before', 'Approach diagram before.png');

      // Download After Diagram
      await downloadImage('Approach diagram after', 'Approach diagram after.png');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-fluid px-md-4">
      <div className="row">
        <nav className="navbar cus-boxshadow d-block fixed-top bg-white py-2">
          <div className="d-flex align-items-center py-1">
            <a className="navbar-brand" href="#"><img src="images/codegen-logo.svg" alt="codegen-logo" className="logo-sty" /></a>
            <div className="ms-5">
              <button className="header-button-active fw-bold">Organization</button>
              <button className="header-button ms-2">Activity Logs</button>
            </div>
            <div className="dropdown me-lg-3 ms-auto">
              <img src="images/user-profile.svg" alt="user-profile" className="profile-sty cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false" />
              <ul className="dropdown-menu dropdown-menu-end shadow-sm border-0">
                <li><a className="dropdown-item text-black font-16 user-dd-sty font-regular d-flex align-items-center" href="#"><span className="me-2"><img src="images/logout.svg" alt="profile-icon" /></span>Logout</a></li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="col-md-12 pt-4 mt-5">
          <div className="mt-3 px-2">
            <ul className="cust-breadcrumb mb-0">
              <li className="font-16 font-medium"><a href="#">Manage Projects</a></li>
              <li className="font-16 font-medium"><a href="#">{state.projectName}</a></li>
              <li className="font-16 font-medium active">Approach Diagram</li>
            </ul>
          </div>
          <div className="d-flex align-items-center justify-content-between p-4 pb-3">
            <div className="d-flex align-items-center">
              <a href="#"><img src="images/back-arrow-new.svg" alt="back-arrow" className="me-3 mb-2"  style={{ cursor: 'pointer' }} /></a>
              <h2 className="text-black font-bold font-22 mb-0">Approach Diagram
              <button type="button" className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3" onClick={handleOpenModal}>
                                            <img src="images/info-icon-ETL.svg" class="" alt="info" />
                                        </button></h2>
              {isModalOpen && <ViewInstructions notebooktype='approachDiagram' onClose={handleCloseModal} />}
              
              
            </div>
            <div className="d-flex">
              <button type="button" className="btn cust-secondary-btn font-14 font-regular" onClick={handleCaptureClick}>
                <span className="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download
              </button>
            </div>
          </div>
          <div className="col-md-12 px-4">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12 pt-2 mb-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h2 className="text-black font-bold font-22 mb-0">Before</h2>
                  <a href="#" onClick={() => handleViewCode(beforeDiagramCode)} className="font-14 font-medium text-decoration-none">View Code</a>
                </div>
                <div className="position-relative" id="beforeDiagram">
                  <div className="position-absolute custom-btn-position bg-white rounded-3">
                    <button type="button" className="btn btn-common-style btn-zoom-in custom-bottom-brd" onClick={() => setZoom(zoom + 0.1)}><img src="images/zoomin-icon.svg" alt="zoom-in" /></button>
                    <button type="button" className="btn btn-common-style btn-zoom-out" onClick={() => setZoom(zoom - 0.1)}><img src="images/zoomout-icon.svg" alt="zoom-out" /></button>
                  </div>
                  <div className="form-control custom-textarea logger-textarea-scroll add-code-snippet">
                    {beforeDiagramCode && <PlantUML id="daimage123-before" zoom={zoom} code={beforeDiagramCode} />}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 pt-2 mb-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h2 className="text-black font-bold font-22 mb-0">After</h2>
                  <a href="#" onClick={() => handleViewCode(afterDiagramCode)} className="font-14 font-medium text-decoration-none">View Code</a>
                </div>
                <div className="position-relative" id="afterDiagram">
                  <div className="position-absolute custom-btn-position bg-white rounded-3">
                    <button type="button" className="btn btn-common-style btn-zoom-in custom-bottom-brd" onClick={() => setZoom(zoom + 0.1)}><img src="images/zoomin-icon.svg" alt="zoom-in" /></button>
                    <button type="button" className="btn btn-common-style btn-zoom-out" onClick={() => setZoom(zoom - 0.1)}><img src="images/zoomout-icon.svg" alt="zoom-out" /></button>
                  </div>
                  <div className="form-control custom-textarea logger-textarea-scroll add-code-snippet">
                    {afterDiagramCode && <PlantUML id="daimage123-after" zoom={zoom} code={afterDiagramCode} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <CodeSnippetModal code={modalCode} onClose={() => setIsModalOpen(false)} />}
    </div>
  );
}

export default ApproachDiagram;
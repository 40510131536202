import React, { useState, useEffect, useRef } from 'react';
import { postDWHConnectionInfo, getConnectionInfo } from '../../Service/Api';
import { useLocation, useNavigate } from "react-router-dom";
import { read, utils as XLSXUtils, write, writeFile } from 'xlsx';
import { saveAs } from 'file-saver';
import { generateSasTokenAPI, fetchDropDownValues } from '../../Service/Api';
import { inventoryTemplate, tableTypeTemplate, inventoryDownloadScripts } from '../new/ucMigrationConstants'


import * as XLSX from 'xlsx';
import { findAllByTestId } from '@testing-library/react';


const InventoryUploadPopup = (props) => {
    const [username, setUsername] = useState('');
    const [url, setUrl] = useState('');
    const [port, setPort] = useState('');
    const [categorizeSubjectArea, setCategorizeSubjectArea] = useState('no');
    const [verticalIndustry, setVerticalIndustry] = useState('');
    const [subjectAreas, setSubjectAreas] = useState([]);  // Initialize as an array
    const [inventoryData, setInventoryData] = useState([]);
    const [tableSheetData, setTableSheetData] = useState([]);
    const [inventoryUrl, setInventoryUrl] = useState('');
    const [tableSheetUrl, setTableSheetUrl] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState([]);
    const [fileName, setFileName] = useState('');
    const [subjectAreaInput, setSubjectAreaInput] = useState('');
    const [isInventoryUploaded, setIsInventoryUploaded] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);


    const fileInputRef = useRef(null);
    let { state } = useLocation();

    const accountName = 'avaeusgenetlsametaprod';
    const containerName = 'uc-migration';


    const putJsonToBlob = async (orgName, projectName, jsonData, fileName, phase) => {
        try {
            const sasToken = await generateSasTokenAPI();
            const blobPath = `organizations/${orgName}/${projectName}/${phase}/${fileName}`;
            const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;
            const returnURL = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob'
                },
                body: JSON.stringify(jsonData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log(`JSON data uploaded successfully to ${blobPath}`);
            return returnURL;
        } catch (error) {
            console.error('Error putting JSON to blob:', error);
            throw error;
        }
    };

    useEffect(() => {
        const isDocumentUploaded = props.documentUploaded(); // Call the function to get the value
        if (isDocumentUploaded === true || isInventoryUploaded) {
            getConnection();
            setIsInventoryUploaded(true);
            // setIsReadOnly(true);
        }
        getDropdownValues()
    }, [props.documentUploaded, isInventoryUploaded]);

    useEffect(() => {
        // document.getElementById('pageLoader').style.display = 'block';
        getConnection()
    }, [])

    const getConnection = async () => {
        try {
            // document.getElementById('loader').style.display = 'block';
            const requestBody = {
                userId: state.userId,
                projectId: state.projectId,
                jwt_token: state.jwt,  // Add jwt_token as part of the payload
            };

            const response = await getConnectionInfo(requestBody);
            console.log(response, "fetchRessresponseresponseresponse");
            if (response?.status === 403) {
                sessionExpired();
            } else if (response.status !== 200) {
                console.error(['Failed to fetch connection details']);
                // document.getElementById('protoastMessageSomething').style.display = 'block';
            } else {
                if (response?.data?.status) {
                    return;
                }
                const data = response.data;
                console.log(data, 'this is the data');
                setUsername(data.userName);
                setUrl(data.url);
                setPort(data.Port);
                setCategorizeSubjectArea(data.isSubjectAreaRequired === "False" ? 'no' : 'yes');
                props.setIsSubjectAreaRequired(data.isSubjectAreaRequired === "False" ? false : true)
                setVerticalIndustry(data.verticalIndustryId);
                const subjects = data.subjectArea ? data.subjectArea.split(',').map((area) => area.trim()) : [];
                setSubjectAreas(subjects);
                setFileName(data.inventoryFileName)
                console.log(data.inventoryFileName, '........................>>>>>>>>>>>>>>>>>>>>');

                if (data.userName !== '' || data.userName == undefined) {
                    setIsReadOnly(true)
                }

            }
            // document.getElementById('pageLoader').style.display = 'none';


            // document.getElementById('loader').style.display = 'none';
        } catch (error) {
            console.error('Error fetching connection details:', error);
            // setError(['Error fetching connection details']);
            // document.getElementById('loader').style.display = 'none';
        }
    };


    const [verticalIndustryOptions, setVerticalIndustryOptions] = useState([]); // Dropdown options
    const [allSubjectAreas, setAllSubjectAreas] = useState({}); // All subject areas grouped by vertical industry

    const getDropdownValues = async () => {
        try {
            const requestBody = {
                userId: state.userId,
                projectId: state.projectId,
                jwt_token: state.jwt,
                type: 'inventorysubjectarea'
            };

            let dropDownValues = await fetchDropDownValues(requestBody, state.jwt);

            if (dropDownValues.statusCode === 403) {
                sessionExpired();
            } else if (dropDownValues.statusCode !== 200) {
                console.error(['Failed to fetch dropdown values']);
            } else {
                const data = dropDownValues.data.VerticalIndustrySubjectAreas;

                // Populate vertical industry dropdown options
                const industries = Object.keys(data);
                setVerticalIndustryOptions(industries);

                // Store all subject areas grouped by vertical industry
                setAllSubjectAreas(data);
            }
        } catch (error) {
            console.error('Error fetching dropdown values:', error);
            console.error(['Error fetching dropdown values']);
        }
    };


    const sessionExpired = () => {
        localStorage.clear();
        alert('Session expired. Redirecting to login...');
        setTimeout(() => {
            window.location.href = '/';
        }, 3000);
    };
    const handleInventoryUploaded = () => {
        setIsInventoryUploaded(true);
        props.uploaded(); // Assuming the `uploaded` prop function takes a boolean
    };

    const updateState = () => {
        return {
            ...state,
            isSubjectArea: categorizeSubjectArea
        };
    };


    const handleVerticalIndustryChange = (selectedIndustry) => {
        setVerticalIndustry(selectedIndustry);

        // Set subject areas based on the selected vertical industry
        if (selectedIndustry && allSubjectAreas[selectedIndustry]) {
            setSubjectAreas(allSubjectAreas[selectedIndustry]);
        } else {
            setSubjectAreas([]); // Reset if no subject areas exist
        }
    };

    const addSubjectArea = (subjectArea) => {
        if (subjectArea && !subjectAreas.includes(subjectArea)) {
            setSubjectAreas([...subjectAreas, subjectArea]);
        }
    };

    const removeSubjectArea = (subjectArea) => {
        setSubjectAreas(subjectAreas.filter(area => area !== subjectArea));
    };




    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.onerror = (e) => reject(e);
            reader.readAsBinaryString(file);
        });
    };


    const validateData = (inventoryData, tableSheetData) => {
        const errors = [];

        // DataDictionary sheet validation
        const dataDictionaryRequiredFields = [
            { name: 'SourceCatalogName', allowNA: false, allowNull: false },
            { name: 'SourceSchemaName', allowNA: false, allowNull: false },
            { name: 'SourceTableName', allowNA: false, allowNull: false },
            { name: 'SourceColumnName', allowNA: false, allowNull: false },
            { name: 'SourceDataType', allowNA: false, allowNull: false },
            { name: 'SourceConstraints', allowNA: false, allowNull: false },
            { name: 'Description', allowNA: true, allowNull: true },
        ];

        // TableType sheet validation
        const tableTypeRequiredFields = [
            { name: 'TableName', allowNA: false, allowNull: false },
            { name: 'Type', allowNA: false, allowNull: false },
            { name: 'Format', allowNA: false, allowNull: false },
            { name: 'Storage', allowNA: false, allowNull: false },
            { name: 'IsDelta', allowNA: false, allowNull: false },
            { name: 'Location', allowNA: false, allowNull: true },
            { name: 'Size', allowNA: false, allowNull: false },
            { name: 'VersionHistory', allowNA: false, allowNull: false },
            { name: 'InScopeOrOutScope', allowNA: false, allowNull: false },
        ];

        const getHeaders = (data) => {
            return Object.keys(data[0] || {});
        };

        const checkFields = (data, fields, sheetName) => {
            const headers = getHeaders(data);
            const requiredFieldNames = fields.map(field => field.name);

            // Check for missing columns
            const missingColumns = requiredFieldNames.filter(field => !headers.includes(field));
            if (missingColumns.length) {
                missingColumns.forEach(column => {
                    errors.push(`${sheetName} sheet: Missing required column '${column}'.`);
                });
            }

            // Check for additional columns
            const extraColumns = headers.filter(header => !requiredFieldNames.includes(header));
            if (extraColumns.length) {
                extraColumns.forEach(column => {
                    errors.push(`${sheetName} sheet: Unexpected column '${column}' found.`);
                });
            }

            // Determine the format of oldCatalogName
            let oldCatalogNames;

            if (Array.isArray(state.oldCatalogName)) {
                // Case 1: oldCatalogName is already an array
                oldCatalogNames = state.oldCatalogName;
            } else {
                // Case 2: oldCatalogName is a JSON string
                try {
                    oldCatalogNames = JSON.parse(state.oldCatalogName);
                    if (!Array.isArray(oldCatalogNames)) {
                        throw new Error("Parsed value is not an array");
                    }
                } catch (e) {
                    errors.push(`${sheetName} sheet: Invalid format for oldCatalogName. Expected a JSON array.`);
                    return; // Exit the function if parsing fails
                }
            }

            // Check if SourceCatalogName column exists
            if (headers.includes('SourceCatalogName')) {
                // Collect all values from SourceCatalogName
                const sourceCatalogValues = new Set();

                // Check each row for invalid data
                data.forEach((row, rowIndex) => {
                    fields.forEach(({ name, allowNA, allowNull }) => {
                        const value = row[name];

                        if (name === 'SourceCatalogName') {
                            // Collect values from SourceCatalogName
                            if (value) {
                                sourceCatalogValues.add(value);
                            }

                            // Check if SourceCatalogName contains only the values in oldCatalogNames array
                            if (!oldCatalogNames.includes(value)) {
                                errors.push(`${sheetName} sheet: Invalid value '${value}' in 'SourceCatalogName' field. Allowed values are: ${oldCatalogNames.join(", ")}. Error at row ${rowIndex + 2}`);
                            }
                        }

                        if (value == null || (typeof value === 'string' && value.trim() === '')) {
                            if (!allowNull) {
                                errors.push(`${sheetName} sheet: '${name}' field cannot be NULL. Error at row ${rowIndex + 2}`);
                            }
                        } else if (value === 'NA' && !allowNA) {
                            errors.push(`${sheetName} sheet: '${name}' field cannot be 'NA'. Error at row ${rowIndex + 2}`);
                        }
                    });
                });

                // Convert oldCatalogNames to a Set for easier checking
                const oldCatalogSet = new Set(oldCatalogNames);

                // Check for discrepancies between SourceCatalogName and oldCatalogNames
                sourceCatalogValues.forEach(value => {
                    if (!oldCatalogSet.has(value)) {
                        errors.push(`${sheetName} sheet: '${value}' is Not Mentioned as 'oldCatalogName' but is present in 'SourceCatalogName' Column.`);
                    }
                });

                oldCatalogSet.forEach(oldValue => {
                    if (!sourceCatalogValues.has(oldValue)) {
                        errors.push(`${sheetName} sheet: '${oldValue}' is listed as an OldCatalogName but is missing from 'SourceCatalogName' column.`);
                    }
                });
            }
        };

        checkFields(inventoryData, dataDictionaryRequiredFields, 'DataDictionary');
        checkFields(tableSheetData, tableTypeRequiredFields, 'TableType');

        return errors;
    };

    const handleSave = async () => {
        // if (inventoryData.length === 0 || tableSheetData.length === 0) {
        //     setError('DataDictionary or TableType sheets are required.');
        //     return;
        // }

        try {
            // Fetch URLs after uploading files
            const inventoryUrl = await putJsonToBlob(state.orgId, state.projectId, inventoryData, 'InventoryDocument.json', 'define');
            const tableSheetUrl = await putJsonToBlob(state.orgId, state.projectId, tableSheetData, 'TabletypeDocument.json', 'define');

            props.setIsSubjectAreaRequired(categorizeSubjectArea)
            
            const requestBody = {
                connectionDetails: {
                    username,
                    url,
                    port,
                    password: '',
                },
                catagorize: {
                    isSubjectAreaRequired: categorizeSubjectArea === 'yes'
                },
                verticalIndustryId: verticalIndustry,
                subjectArea: subjectAreas.join(','),
                inventoryFileName: fileName,
                inventoryDDURL: inventoryUrl,
                tableTypeURL: tableSheetUrl,
                userId: state.userId,
                ProjectId: state.projectId,
                jwt_token: state.jwt,  // Add jwt_token as part of the payload
            };
            // Acquire JWT token
            console.log(requestBody, 'requestBodyrequestBodyrequestBodyrequestBodyrequestBody');


            // // Post the request to the backend
            const response = await postDWHConnectionInfo(requestBody);
            state = { ...state, isSubjectAreaRequired: categorizeSubjectArea === 'yes' }
            // const data = await response.json();
            console.log(response, "req");

            if (response.status === 200) {
                // alert('Files uploaded successfully');
                props.handleConnectionDetails();
                handleInventoryUploaded();
                updateState();


                // Close the modal
                const modalElement = document.getElementById('SourceSys');
                if (modalElement) {
                    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
                    if (modalInstance) {
                        modalInstance.hide();
                    }
                }
                props.close()


            } else {
                console.log('Error Uploading Files');
            }
        } catch (error) {
            console.log('Error catch');
        }
    };

    const handleReset = () => {
        setUsername('');
        setUrl('');
        setPort('');
        setCategorizeSubjectArea('no');
        setVerticalIndustry('');
        setSubjectAreas([]);
        setInventoryData([]);
        setTableSheetData([]);
        setInventoryUrl('');
        setTableSheetUrl('');
        setFileName('');
        setError([]);
        props.close()
    };

    const areFieldsFilled = () => {
        console.log(categorizeSubjectArea, isEditing, 'this is the subjectArea noww')
        if (categorizeSubjectArea === 'no') {
            return (
                username &&
                url &&
                fileName
            );
        } else {
            return (
                username &&
                url &&
                verticalIndustry &&
                (categorizeSubjectArea === 'yes' && subjectAreas.length > 0) &&
                fileName
            );
        }
    };

    async function fetchFromBlobInventory(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const jsonData = await response.json();
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData;
        } catch (error) {
            console.error("Error fetching from blob:", error);
            return null;
        }
    }



    const exportToExcel = async () => {
        if (isDownloading) return; // Prevent multiple clicks
        setIsDownloading(true); // Set downloading state

        try {
            const inventoryTemplateURL = inventoryTemplate;
            const tableTypeTemplateURL = tableTypeTemplate;
            const sasToken = await generateSasTokenAPI();
            console.log(sasToken, 'Generated SAS Token');

            let url = `${inventoryTemplateURL}?${sasToken}`;
            const dataDictionaryResponse = await fetchFromBlobInventory(url);
            console.log('Data Dictionary Response:', dataDictionaryResponse);

            url = `${tableTypeTemplateURL}?${sasToken}`;
            let tableTypeResponse = await fetchFromBlobInventory(url);
            console.log('Table Type Response:', typeof (tableTypeResponse));
            tableTypeResponse = tableTypeResponse;

            const wsDataDictionary = XLSXUtils.json_to_sheet(dataDictionaryResponse);
            const wsTableType = XLSXUtils.json_to_sheet(tableTypeResponse);

            const wb = {
                Sheets: {
                    DataDictionary: wsDataDictionary,
                    TableType: wsTableType,
                },
                SheetNames: ['DataDictionary', 'TableType'],
            };

            // Create Excel file buffer
            const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(data, "InventoryTemplate.xlsx");

        } catch (error) {
            console.error("Error exporting to Excel:", error);
        } finally {
            setIsDownloading(false); // Reset downloading state
        }
    };

    async function fetchFromBlobScripts(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            // Assuming the response is a Blob or text (for a Python script)
            const blob = await response.blob();
            console.log(blob, 'Fetched Blob Data');
            return blob; // Return the Blob data
        } catch (error) {
            console.error("Error fetching from blob:", error);
            return null;
        }
    }


    const downloadScript = async () => {
        try {
            // Step 1: Generate SAS Token
            const sasToken = await generateSasTokenAPI();
            console.log(sasToken, 'Generated SAS Token');

            // Step 2: Define the URL for the Python script (replace with actual URL)
            const scriptUrl = `${inventoryDownloadScripts}?${sasToken}`;

            // Step 3: Fetch the Python script as a Blob
            const scriptBlob = await fetchFromBlobScripts(scriptUrl);
            if (scriptBlob) {
                // Step 4: Create a link to download the Python file
                const link = document.createElement('a');
                link.href = URL.createObjectURL(scriptBlob); // Create an object URL for the Blob
                link.download = 'script.py'; // Set the file name
                document.body.appendChild(link); // Append the link to the DOM (required for triggering the download)
                link.click(); // Trigger the download
                document.body.removeChild(link); // Remove the link after the download
            } else {
                console.error("Failed to fetch the script");
            }
        } catch (error) {
            console.error("Error downloading script:", error);
        }
    };


    const handleFileUpload = async (event) => {
        if (isReadOnly) return;
        const file = event.target.files[0];
        setFileName(file.name);
        if (file && file.name.endsWith(".xlsx")) {
            try {
                const data = await readFile(file);
                const workbook = read(data, { type: 'binary' });
                const sheets = workbook.SheetNames;
                if (sheets.includes('DataDictionary') && sheets.includes('TableType')) {
                    // Convert to JSON
                    const inventoryData = XLSXUtils.sheet_to_json(workbook.Sheets['DataDictionary']);
                    const tableSheetData = XLSXUtils.sheet_to_json(workbook.Sheets['TableType']);
                    // Validate data
                    const validationErrors = validateData(inventoryData, tableSheetData);
                    if (validationErrors.length > 0) {
                        setError(validationErrors);
                        return;
                    }

                    // Transform inventoryData to change 'DataType' to 'SourceDataType'
                    const transformedInventoryData = inventoryData.map(item => ({
                        ...item,
                        DataType: item.SourceDataType,
                        Constraints: item.SourceConstraints
                    }));

                    // Remove original DataType key if neededa
                    transformedInventoryData.forEach(item => {
                        delete item.SourceDataType;
                        delete item.SourceConstraints;
                    });

                    setInventoryData(transformedInventoryData);
                    setTableSheetData(tableSheetData);
                } else {
                    setError(['Required sheets missing']);
                }
            } catch (error) {
                setError(['Error reading file']);
            }
        } else {
            setError(['Invalid file type']);
        }
    };


    const handleCancelUpload = () => {
        // Reset file input value and clear state
        setFileName('');
        setInventoryData([]);
        setTableSheetData([]);
        setError([]);
        if (fileInputRef.current) {
            fileInputRef.current.value = null; // Reset the file input field
        }
    };

    const handleBlur = () => {
        // If there is some input, add it as a subject area bubble
        if (subjectAreaInput.trim() !== '') {
            addSubjectArea(subjectAreaInput.trim());
            setSubjectAreaInput('');  // Clear the input field
        }
        // setIsEditing(false);  // Reset editing state
    };





    return (
        <>
            <div className="modal-backdrop fade show"></div>
            <div
                className="modal fade show d-block"
                id="inventory" style={{
                    overflow: "auto",
                    maxHeight: "112vh",
                    margin: "auto",
                    scrollBehavior: "smooth"
                }}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="InventoryLabel"
                aria-hidden="true"
            >

                <div className="modal-dialog modal-xl">
                    <div className="modal-content px-2">
                        <div className="modal-header border-0">
                            <h5 className="modal-title d-flex align-items-center font-medium font-20" id="SourceSysLabel">Connection Details and Inventory Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.close}></button>
                        </div>
                        <div className="modal-body py-0 my-3 mx-5">
                            <div className="row">
                                <div className="rounded-3 font-20 custom-acc-border font-bold">
                                    <div className="row">
                                        <div className="collapse show">
                                            <div className="custom-accordian-body color-grey-bg py-2 px-3">
                                                <div className="row pb-3 mb-3 border-bottom pt-3">
                                                    <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                                        <label htmlFor="connection-Name" className="form-label text-black font-14 font-medium">Username<span className="text-red">*</span></label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="connection-Name" placeholder="Enter User name" value={username ? username : ''} onChange={(e) => !isReadOnly && setUsername(e.target.value)}
                                                            disabled={isReadOnly} />
                                                    </div>
                                                    <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                                        <label htmlFor="host-Name" className="form-label text-black font-14 font-medium">URL<span className="text-red">*</span></label>
                                                        <input type="url" className="form-control cust-input-sty font-14 font-regular" id="host-Name" value={url || ''} onChange={(e) => !isReadOnly && setUrl(e.target.value)}
                                                            disabled={isReadOnly}placeholder="Enter URL"  />
                                                    </div>
                                                    <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                                        <label htmlFor="db-Name" className="form-label text-black font-14 font-medium">Port</label>
                                                        <input type="text" className="form-control cust-input-sty font-14 font-regular" id="db-Name" value={port || ''} onChange={(e) => !isReadOnly && setPort(e.target.value)}
                                                            disabled={isReadOnly} placeholder="Enter Port" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-lg-12 mt-3 mt-lg-0 mb-4">
                                                        <label className="form-check-label font-14 font-medium text-grey">Do you need to categorize subject area?<span className="text-red">*</span></label>
                                                        <div className="d-flex gap-4">
                                                            <div className="form-check mt-1">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="categorizeSubjectArea" // Ensure the group name is consistent
                                                                    id="yes"
                                                                    checked={categorizeSubjectArea === 'yes'} // Controlled component logic
                                                                    onChange={() => !isReadOnly && setCategorizeSubjectArea('yes')} // Event handler for state change
                                                                />
                                                                <label className="form-check-label font-14 font-medium text-grey" htmlFor="yes">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check mt-1">
                                                                <input
                                                                    className="form-check-input "
                                                                    type="radio"
                                                                    name="categorizeSubjectArea" // Ensure the group name is consistent
                                                                    id="no"
                                                                    checked={categorizeSubjectArea === 'no'} // Controlled component logic
                                                                    onChange={() => !isReadOnly && setCategorizeSubjectArea('no')} // Event handler for state change
                                                                />
                                                                <label className="form-check-label font-14 font-medium text-grey" htmlFor="no">
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {categorizeSubjectArea === 'yes' && (
                                                    <div className="row">

                                                        <div className="col-md-4 col-sm-4 mb-4">
                                                            <label className="form-label text-black font-14 font-medium">Vertical Industry</label><span className="text-red">*</span>
                                                            <div className="custom-select">
                                                                <select
                                                                    className="form-control custom-dropdown-toggle custom-dropdown-selecttoggle cust-input-sty font-14 font-regular"
                                                                    value={verticalIndustry}
                                                                    onChange={(e) => { handleVerticalIndustryChange(e.target.value); setIsEditing(true) }}
                                                                    disabled={isReadOnly}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {verticalIndustryOptions.map((industry, index) => (
                                                                        <option key={index} value={industry}>
                                                                            {industry}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4 col-sm-4 mb-4">
                                                            <label className="form-label text-black font-14 font-medium">
                                                                Subject Area
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            <div className="position-relative">
                                                                <div className="multi-select">
                                                                    {/* Render existing subject areas as bubbles */}
                                                                    {Array.isArray(subjectAreas) && subjectAreas.map((area, index) => (
                                                                        <span className="multi-select-bubble subject-area font-12 font-medium" key={index}>
                                                                            {area}
                                                                            {!isReadOnly && (
                                                                                <img
                                                                                    className="ms-2 cursor-pointer"
                                                                                    src="images/cross-red.svg"
                                                                                    alt="remove"
                                                                                    onClick={() => removeSubjectArea(area)}
                                                                                />
                                                                            )}
                                                                        </span>
                                                                    ))}

                                                                    {/* Render the input field */}
                                                                    {isEditing ? (
                                                                        <input
                                                                            type="text"
                                                                            className="font-14 font-regular"
                                                                            value={subjectAreaInput}
                                                                            onChange={(e) => !isReadOnly && setSubjectAreaInput(e.target.value)}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter' && subjectAreaInput.trim() !== '' && !isReadOnly) {
                                                                                    addSubjectArea(subjectAreaInput.trim());
                                                                                    setSubjectAreaInput('');
                                                                                }
                                                                            }}
                                                                            onBlur={handleBlur}  // When focus is lost, change the input to bubble
                                                                            autoFocus
                                                                            readOnly={isReadOnly}
                                                                        />
                                                                    ) : (
                                                                        <span
                                                                            className="font-14 font-regular"
                                                                            onClick={() => !isReadOnly && setIsEditing(true)} // Enable editing when clicked
                                                                        >
                                                                            {subjectAreas.length > 0 ? subjectAreaInput : 'Enter Subject Area'}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row px-4">
                                        <div className="col-md-12 col-sm-12 mb-3">
                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <label className="form-label text-black font-14 font-medium">Upload Data<span className="text-red"> *</span></label>
                                                <button
                                                    type="button"
                                                    className="btn cust-secondary-btn font-14 font-regular d-flex align-items-center"
                                                    onClick={downloadScript} // Trigger the download script function
                                                >
                                                    <span className="me-2"><img src="images/download-icon.svg" alt="add-icon" /></span> Download Script
                                                </button>
                                            </div>
                                            <span className="d-block font-12 info-message-section text-black font-medium">Please ensure you included the Inventory and Table Type Sheet</span>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
                                            <div className="upload-btn-wrapper d-block">
                                                {fileName ? (
                                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                        <img src="images/sample-logo.svg" alt="sample-logo" className="mb-2" />
                                                        <a className="font-medium font-12 d-block">{fileName}</a>
                                                        <a
                                                            style={{ position: "relative", zIndex: "100", pointerEvents: props.documentUploaded() === true ? "none" : "auto", opacity: props.documentUploaded() === true ? 0.5 : 1 }}
                                                            onClick={props.documentUploaded() === true ? null : handleCancelUpload} // Conditionally attaching the onClick handler
                                                        >
                                                            <span className="ms-2">
                                                                <img src="images/link-close-icon.svg" alt="link-close-icon" />
                                                            </span>
                                                        </a>
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="upload-btn-sty shadow-none cursor-pointer text-center"
                                                        onClick={() => fileInputRef.current.click()}
                                                    >
                                                        <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                                        <span className="font-medium font-13 d-block text-grey mb-2">
                                                            Browse or drag and drop file
                                                        </span>
                                                        <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v5">
                                                            Use this template to add info -
                                                            <a
                                                                className={`popup-anchor-color custom-tableHeader download-link ${isDownloading ? 'disabled' : ''}`}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    exportToExcel();
                                                                }}
                                                                style={{ pointerEvents: isDownloading ? 'none' : 'auto', opacity: isDownloading ? 0.5 : 1 }}
                                                            >
                                                                {isDownloading ? 'Downloading...' : 'Download Template'}
                                                            </a>
                                                        </span>
                                                    </button>
                                                )}
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    className="cursor-pointer cust-file"
                                                    onChange={handleFileUpload}
                                                    style={{ display: 'none' }}
                                                    disabled={isReadOnly}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {error.length > 0 && (
                            <div className="alert alert-danger">
                                {error.map((err, index) => (
                                    <div key={index}>{err}</div>
                                ))}
                            </div>
                        )} */}

                            {error.length > 0 ? (
                                <div className="code-doc-error">
                                    {error.map((data, index) => (
                                        <span className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section" key={index}>
                                            <img src="images/warning-red-icon.svg" alt="Warning icon" />
                                            <span className="ms-2">{data}</span>
                                        </span>
                                    ))}
                                </div>
                            ) : null}
                            <div className="modal-footer border-0 mb-4 mt-4 mx-5 d-flex p-0">
                                <button type="button" className="btn cust-secondary-btn font-14 font-medium me-3" data-bs-dismiss="modal" onClick={handleReset}>Cancel</button>
                                <button
                                    type="button"
                                    className="btn cust-primary-btn font-14 font-medium"
                                    data-bs-dismiss="modal"
                                    onClick={handleSave}
                                    disabled={!areFieldsFilled() || isInventoryUploaded || error.length !== 0}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="overlay"
                id="pageLoader"
                style={{ display: "none" }}
            >
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div
                            className="spinner-border Loader text-dark align-center"
                            role="status"
                        >
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InventoryUploadPopup;
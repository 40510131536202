import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, fetchDropDownValues, insertError, generateSasTokenAPI, updatePseudocodeData } from "../../../Service/Api.js";
import { HeaderCom } from "../../Header.jsx";
import DynamicGrid from './DynamicTable.jsx';
import SearchComponent from './SearchComponent.jsx';
import FilterComponent from './FilterComponent.jsx';
import DownloadComponent from './DownloadComponent.jsx';
import ReasonComponent from './ReasonComponent.jsx';
import Editor from '@monaco-editor/react';
import { CopyToClipboard } from "react-copy-to-clipboard";

function DataQualityResult(props) {
    const accountName = 'avaeusgenetlsametaprod';
    const containerName = 'uc-migration';


    const fetchJsonFromBlob = async (blobURL) => {
        try {
            const sasToken = await generateSasTokenAPI();
            let url = `${blobURL}?${sasToken}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const jsonData = await response.json();
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };

    const updateJsonInBlob = async (updatedData) => {
        try {
            const sasToken = await generateSasTokenAPI();

            console.log("blobUrlblobUrlblobUrlblobUrlblobUrlblobUrlblobUrlblobUrlblobUrlblobUrl",blobUrl)
            const url = `${blobUrl}?${sasToken}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Data updated successfully in Azure Blob');
        } catch (error) {
            console.error('Error updating JSON in blob:', error);
            throw error;
        }
    };

    const { state } = useLocation();
    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);
    const [actualValue, setActualValue] = useState([]);
    const [dropDownValues, setDropDownValues] = useState({});
    const [error, setError] = useState(null);
    const [whetherUpdated, setWhetherUpdated] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [currentOperation, setCurrentOperation] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [newRow, setNewRow] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [searchFilterTrigger, setSearchFilterTrigger] = useState(0);
    const [codeSnippet, setCodeSnippet] = useState('');
    const [blobUrl, setBlobUrl] = useState('');
    const [copiedText, setCopiedText] = useState(false)
    const handleEdit = (item) => {
        setCurrentOperation('edit');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    const handleAdd = (newItem) => {
        setCurrentOperation('add');
        setCurrentItem(newItem);
        setShowReasonModal(true);
    };

    const handleDelete = (item) => {
        setCurrentOperation('delete');
        setCurrentItem(item);
        setShowReasonModal(true);
    };
    const handleCopyText = () => {
        setCopiedText(true);
        setTimeout(() => setCopiedText(false), 2000);
    };

    const onViewCode = (code) => {
        setCodeSnippet(code);
        setShowModal(true);
    };

    // const handleReasonSave = async () => {
    //     try {
    //         let updatedData;
    //         switch (currentOperation) {
    //             case 'edit':
    //                 updatedData = gridData.map(item =>
    //                     item.index === currentItem.index ? { ...currentItem, reason } : item
    //                 );
    //                 break;
    //             case 'add':
    //                 updatedData = [...gridData, { ...currentItem, reason, index: gridData.length }];
    //                 break;
    //             case 'delete':
    //                 updatedData = gridData.filter(item => item.index !== currentItem.index);
    //                 break;
    //             default:
    //                 throw new Error('Invalid operation');
    //         }
    //         let body = {
    //             type: "DQResult",
    //             projectId: state.projectId,
    //             userId: state.userId,
    //             orgId: state.orgId,
    //             comments: reason,

    //         }
    //         let res = await updatePseudocodeData(body, state.jwt)
    //         await saveRowUpdate(currentItem); // Save the updated row to blob

    //         // Update local state
    //         setGridData(updatedData);
    //         setActualValue(updatedData);
    //         setShowReasonModal(false);
    //         setReason('');
    //         setCurrentOperation(null);
    //         setCurrentItem(null);
    //         setWhetherUpdated(true);
    //     } catch (error) {
    //         console.error('Error updating data:', error);
    //     }
    // };

    const handleReasonSave = async () => {
           try {
                let updatedData;
                switch (currentOperation) {
                   case 'edit':
                       updatedData = actualValue.map(item =>
                           item.index === currentItem.index ? { ...item, ...currentItem, reason } : item
                       );
                       break;
                   case 'add':
                       updatedData = [...actualValue, { ...currentItem, reason, index: actualValue.length }];
                       break;
                   case 'delete':
                       updatedData = actualValue.filter(item => item.index !== currentItem.index);
                       break;
                   default:
                       throw new Error('Invalid operation');
               }
       let body={
                       type: "DQResult", 
                       projectId: state.projectId, 
                       userId: state.userId,
                       orgId: state.orgId,
                       comments:reason,
       
       
       
                   }
                 let res=  await updatePseudocodeData(body,state.jwt)
                   await updateJsonInBlob(blobUrl, updatedData);
               await updateJsonInBlob(blobUrl, updatedData);
       
       
       
               // Update local state with original data and re-apply filter
               setActualValue(updatedData);
               setGridData(updatedData); // Optionally: apply previous filter/search to updatedData
       
        setSearchFilterTrigger(prev => prev + 1);
       
               setShowReasonModal(false);
               setReason('');
               setCurrentOperation(null);
               setCurrentItem(null);
               setWhetherUpdated(true);
           } catch (error) {
               console.error('Error updating data:', error);
           }
       };

    const saveRowUpdate = async (updatedRow) => {
        debugger;
        try {
            // Fetch existing JSON data
            console.log(blobUrl,"blobUrlblobUrlblobUrlblobUrl")
            const rawJsonData = await fetchJsonFromBlob(blobUrl);

            // Parse the JSON string to a JavaScript object
            const jsonData = rawJsonData

            // Check if jsonData and jsonData.RowCount are initialized correctly
            if (!jsonData) {
                throw new Error('jsonData is undefined');
            }

            if (!Array.isArray(jsonData.RowCount)) {
                jsonData.RowCount = [];
            }

            // Logging to verify the contents of jsonData and updatedRow
            console.log('Fetched jsonData:', JSON.stringify(jsonData, null, 2));
            console.log('Updated Row:', JSON.stringify(updatedRow, null, 2));

            // Ensure types match
            const rowIndex = Number(updatedRow.index);
            console.log('Row Index (converted to number):', rowIndex);

            // Identify the row to update in the RowCount array
            const updatedRowIndex = jsonData.RowCount.findIndex(row => Number(row['S.No']) === rowIndex);
            console.log('Updated Row Index:', updatedRowIndex);

            if (updatedRowIndex === -1) {
                // Row not found, push new entry
                console.log('Row not found, adding new entry');
                jsonData.RowCount.push({
                    "S.No": rowIndex,
                    "Scripts": updatedRow.DDLScripts,
                    "SourceRowCount": updatedRow.SourceRowCount,
                    "DestinationRowCount": updatedRow.DestinationRowCount,
                    "ValidationStatus": updatedRow.Status,
                });
            } else {
                // Update the specific row in the RowCount array
                console.log('Row found, updating entry');
                jsonData.RowCount[updatedRowIndex] = {
                    ...jsonData.RowCount[updatedRowIndex],
                    Scripts: updatedRow.DDLScripts,
                    SourceRowCount: updatedRow.SourceRowCount,
                    DestinationRowCount: updatedRow.DestinationRowCount,
                    ValidationStatus: updatedRow.Status,
                };
            }

            // Log the updated jsonData to verify changes
            console.log('Updated jsonData:', JSON.stringify(jsonData, null, 2));

            // Save jsonData back to the blob storage or corresponding state
            await updateJsonInBlob(jsonData); // Convert back to JSON string before saving

        } catch (error) {
            console.error('Error updating data:', error);
        }
    };


    const [settings, setSettings] = useState({
        TableConfig: [
            { key: 'DDLScripts', header: 'DDLScripts', sortable: true, type: 'normal', deFaultRow: 'viewcode' },
            { key: 'SourceRowCount', header: 'SourceRowCount', sortable: true, type: 'normal' },
            { key: 'DestinationRowCount', header: 'DestinationRowCount', sortable: true, type: 'normal' },
            { key: 'Status', header: 'Status', sortable: true, type: 'normal' },

        ],
        ActionItems: { delete: true, edit: true },
        deFaultRow: [],
        type: "NoDefaultRow"


    });

    const searchColumns = ['DDLScripts'];
    const FilterColumns = ['DDLScripts'];


    useEffect(() => {
        const fetchData = async () => {
            document.getElementById('pageLoader').style.display = "block";
            try {
                await fetchPseudo();
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                document.getElementById('pageLoader').style.display = "none";
            }
        };
        fetchData();
    }, []);

    const fetchDropDown = async () => {
        try {
            let data = { type: props.data.hyperlinkText, projectId: state.projectId, userId: state.userId }
            let dropDownValues = await fetchDropDownValues(data, state.jwt)
            if (dropDownValues.data) {
                setDropDownValues(dropDownValues.data);
                updateSettingsWithDropdownValues(dropDownValues.data);
                console.log(dropDownValues, "v1")
            }
        } catch (e) {
            handleError(e);
        }
    }

    const updateSettingsWithDropdownValues = (data) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            deFaultRow: prevSettings.deFaultRow.map(row => {
                if (row.key === 'SubjectArea') {
                    return { ...row, values: data.DDuniqueSubjectArea || [] };
                }
                if (row.key === 'SchemaName') {
                    return { ...row, values: data.uniqueSchemaName || [] };
                }
                if (row.key === 'TableName') {
                    return { ...row, values: data.uniqueTableName || [] };
                }
                return row;
            })
        }));
    }

    const fetchPseudo = async () => {
        const body = {
            projectId: state.projectId,
            type: "dqresult",
            userId: state.userId,
            orgId: state.orgId,
        };

        try {
            const res = await fetchPseudocodeData(body, state.jwt);
            console.log(res, 'resresresresres');

            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                document.getElementById('openErrorModal').click();
            } else {
                console.log("The Response Is 200");
                const blobURL = res?.data[0]?.dataQualityResultURL;
                setBlobUrl(blobURL);
                console.log(blobURL, 'fetchJsonFromBlobfetchJsonFromBlob');

                try {
                    const jsonData = await fetchJsonFromBlob(blobURL);

                    let parsedData;
                    if (typeof jsonData === 'string') {
                        try {
                            parsedData = JSON.parse(jsonData);
                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                            parsedData = jsonData;
                        }
                    } else {
                        parsedData = jsonData;
                    }
                    console.log(parsedData, "Data from blob");


                    if (parsedData && parsedData.RowCount) {
                        let pseudo = parsedData.RowCount.map(item => ({
                            index: item['S.No'],
                            DDLScripts: item.Scripts,
                            SourceRowCount: item.SourceRowCount,
                            DestinationRowCount: item.DestinationRowCount,
                            Status: item.ValidationStatus
                        }));
                        setGridData(pseudo);
                        setActualValue(pseudo);
                        console.log(pseudo, "Mapped data");
                    } else {
                        throw new Error("Failed to fetch data from Azure Blob Storage or RowCount array is missing");
                    }
                } catch (e) {
                    console.error('Error fetching JSON from blob:', e);
                    handleError(e);
                }
            }
        } catch (e) {
            console.error('Error fetching pseudocode data:', e);
            handleError(e);
        }
    }

    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };

    const handleError = (e) => {
        insertError({
            errorMessage: e.message,
            serviceName: "frontend",
            module: "Datadictionary",
            functionName: "DataDictionaryTable",
            userId: state.userId
        });
        console.log("error", e);
        setError(e.message);
    }

    const passCount = gridData.filter(item => item.Status.toLowerCase().includes('pass')).length;
    const failCount = gridData.filter(item => item.Status.toLowerCase().includes('fail')).length;
    const totalCount = gridData.length;

    if (error) return <div>Error: {error}</div>;

    return (
        <div className="container-fluid">
            <HeaderCom value="1" />
            <div className="col-md-12 pt-4 mt-5">
            <div className="mt-3 px-2">
                    <ul className="cust-breadcrumb mb-0">
                        <li className="font-16 font-medium">
                            <a>Manage Projects</a>
                        </li>
                        <li className="font-16 font-medium">
                            <a>{state.projectName}</a>
                        </li>
                        <li className="font-16 font-medium active">{props.data.hyperlinkText}</li>
                    </ul>
                </div>
 
                <div className="d-flex align-items-center justify-content-between p-4">
                    <div className="d-flex">
                        <a className="d-flex">
                            <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '35px', cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
                        </a>
                        <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}</h2>
                    </div>
                    <div className="d-flex">
                        <div className="cust-input-sty font-14 rounded-2 font-regular text-grey px-3 py-2 me-3 d-flex align-items-center">
                            <span className="border-end d-flex align-items-center pe-3">
                                <img src="images/verified-icon.svg" alt="verified-icon" className="verified-logo-sty me-2" />
                                <span className="text-nowrap font-regular">Pass - <span className="font-medium">{passCount}</span></span>
                            </span>
                            <span className="border-end d-flex align-items-center px-3">
                                <img src="images/not-verified-icon.svg" alt="not-verified-icon" className="verified-logo-sty me-2" />
                                <span className="text-nowrap font-regular">Fail - <span className="font-medium">{failCount}</span></span>
                            </span>
                            <span className="ps-3">
                                <span className="text-nowrap font-regular">Total - <span className="font-medium">{totalCount}</span></span>
                            </span>
                        </div>
                        <SearchComponent columns={searchColumns} actualValue={actualValue} setGridData={setGridData} searchFilterTrigger={searchFilterTrigger} />
                        <FilterComponent columns={FilterColumns} actualValue={actualValue} setGridData={setGridData} searchFilterTrigger={searchFilterTrigger} />
                        <DownloadComponent type="excel" data={actualValue} fileName="DataQualityResult" />
                    </div>
                </div>
                <DynamicGrid
                    data={gridData}
                    settings={settings}
                    setGridData={setGridData}
                    actualValue={actualValue}
                    setWhetherUpdated={setWhetherUpdated}
                    onEdit={handleEdit}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    onViewCode={onViewCode}
                    newRow={newRow}
                    setNewRow={setNewRow}
                />
                <ReasonComponent
                    isOpen={showReasonModal}
                    onClose={() => setShowReasonModal(false)}
                    onSave={handleReasonSave}
                    reason={reason}
                    setReason={setReason}
                />
                {showModal && (
                    <div className="modal show d-block" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header border-0 px-4">
                                    <h5 className="modal-title d-flex align-items-center font-medium font-20">Code Snippet</h5>
                                    <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                                </div>
                                <div className="modal-body py-0">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-10">
                                            <div>
                                                <Editor  defaultLanguage="javascript"
                                                    className="form-control custom-textarea logger-textarea-scroll add-code-snippet"
                                                    value={codeSnippet}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 px-4">
                                    <button
                                        type="button"
                                        className="btn cust-primary-btn font-14 font-medium"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Cancel
                                    </button>
                                    <CopyToClipboard text={codeSnippet} onCopy={handleCopyText}>
                                        <button
                                            type="button"
                                            className="btn cust-primary-btn font-14 font-medium"
                                        >
                                            {copiedText ? "Copied!" : "Copy Code"}
                                        </button>
                                    </CopyToClipboard>

                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div
                className="overlay"
                id="pageLoader"
                style={{ display: "none" }}
            >
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div
                            className="spinner-border Loader text-dark align-center"
                            role="status"
                        >
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataQualityResult;
import React from 'react';

const ReasonComponent = ({ isOpen, onClose, onSave, reason, setReason,setNewRow }) => {
  if (!isOpen) return null;

  return (
    <div className="container-fluid">
      <div className="modal-backdrop fade show"></div>
      <div className="row">
        
        <div
          className="modal fade show"
          id="editRows"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="ConfrmLabel"
          aria-hidden="true"
          style={{ display: 'block' }}
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{padding:"10px"}}>
              <div className="modal-header border-0">
                <h5
                  className="modal-title d-flex align-items-center font-medium font-20"
                  id="ConfrmLabel"
                >
                  Edit Reason
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={onClose}
                />
              </div>
              <div className="modal-body py-0">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <label
                      htmlFor="User-Name"
                      className="form-label text-black font-14 font-medium"
                    >
                      Specify reason for manual edit
                      <span className="text-red"> *</span>
                    </label>
                    <textarea
                      type="text"
                      className="form-control font-14 font-regular"
                      id="User-Name"
                      placeholder="Enter your reason here..."
                      rows={5}
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 px-4 mt-4">
                <button
                  type="button"
                  className="btn cust-secondary-btn font-14 font-medium me-2"
                  data-bs-dismiss="modal"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn cust-primary-btn font-14 font-medium"
                  onClick={onSave}
                  disabled={!reason.trim()}
                >
                  <span>Save Details</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReasonComponent;
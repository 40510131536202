import React, { useState, useEffect, useRef } from "react";
import { CopyToClipboard, ClipboardItem } from "react-copy-to-clipboard";
import Editor from "@monaco-editor/react";
import axios from "axios";
import { saveAs } from 'file-saver';
import { putPythonToBlob, updateJsonInBlob } from "./BlobStorageService";
import { useLocation } from "react-router-dom";

const PlantUMLPreview = (props) => {
    const [code, setCode] = useState("");
    const [diagramSrc, setDiagramSrc] = useState("");
    const [zoom, setZoom] = useState(1);
    const [copiedText, setCopiedText] = useState(false);
    const [copiedImage, setCopiedImage] = useState(false);
    const [chatId, setChatId] = useState(null);
    const [currentService, setCurrentService] = useState("PlantUML");
    const svgRef = useRef(null);

    var { state } = useLocation()

    useEffect(() => {
        fetchData();
    }, []);

    const generateSasToken = async () => {
        const response = await fetch('http://localhost:3001/generate-sas');
        if (!response.ok) throw new Error('Failed to fetch SAS token');
        const { sasToken } = await response.json();
        return sasToken;
    };

    const fetchJsonFromBlob = async () => {
        try {
            const sasToken = await generateSasToken();
            const url = `https://avaeusgenetlsametaprod.blob.core.windows.net/chatmainsample/GenETL KT New/New Clark/json/alphabronze.txt?${sasToken}`;
            console.log(url);
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const jsonData = await response.text();
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };

    const fetchData = async () => {
        try {
            const jsonData = await fetchJsonFromBlob();
            console.log(jsonData, 'Fetched data from blob');
            setCode(jsonData);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const saveCodeDetails = async () => {
        try {
            const data = code
            await putPythonToBlob(state.orgId, state.projectId, data, 'python.py');

            console.log(props.chatHistory, 'props.chatHistoryprops.chatHistoryprops.chatHistory');

            let chatData = props.chatHistory;
            chatData[chatData.length - 1].show = true;
            await updateJsonInBlob(state.orgId, state.projectId, "chatHistory.json", chatData);
            console.log('cominggggggg');


            props.setShowCode();
        } catch (error) {
            console.error('Error saving code details:', error);
        }
    };

    useEffect(() => {
        if (code) {
            const fetchDiagram = async () => {
                try {
                    const { diagramSvg } = await fetchPlantUMLDiagram(code);
                    setDiagramSrc(diagramSvg);
                    setCurrentService("Kroki");
                    console.log("Utilizing Kroki service");
                } catch (krokiError) {
                    console.error("Kroki service failed:", krokiError);
                    setCurrentService("None");
                    setDiagramSrc("<div>Error displaying diagram</div>");
                }
            };
            fetchDiagram();
        }
    }, [code]);

    const handleZoomIn = () => setZoom(zoom + 0.1);
    const handleZoomOut = () => zoom > 0.2 && setZoom(zoom - 0.1);

    const handleCopyText = () => {
        setCopiedText(true);
        setTimeout(() => setCopiedText(false), 2000);
    };

    const handleCopyDiagram = async () => {
        if (svgRef.current) {
            const svgElement = svgRef.current.querySelector("svg");
            if (svgElement) {
                const svgData = new XMLSerializer().serializeToString(svgElement);
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                const img = new Image();
                img.onload = async () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    try {
                        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
                        await navigator.clipboard.write([
                            new ClipboardItem({ "image/png": blob }),
                        ]);
                        setCopiedImage(true);
                        setTimeout(() => setCopiedImage(false), 2000);
                    } catch (err) {
                        console.error("Failed to copy image: ", err);
                    }
                };
                img.src =
                    "data:image/svg+xml;base64," +
                    btoa(unescape(encodeURIComponent(svgData)));
            }
        }
    };

    const handleDownloadPng = async () => {
        if (svgRef.current) {
            const svgElement = svgRef.current.querySelector("svg");
            if (svgElement) {
                const svgData = new XMLSerializer().serializeToString(svgElement);
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                const img = new Image();
                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob((blob) => {
                        saveAs(blob, 'diagram.png');
                    });
                };
                img.src =
                    "data:image/svg+xml;base64," +
                    btoa(unescape(encodeURIComponent(svgData)));
            }
        }
    };

    async function fetchPlantUMLDiagram(code) {
        try {
            const response = await client(
                "https://kroki.io/plantuml/svg",
                code,
                "post"
            );
            return { diagramSvg: response.data, error: null };
        } catch (error) {
            console.error("Error fetching diagram:", error);
            return {
                diagramSvg: null,
                error: error.message || "Failed to generate diagram",
            };
        }
    }

    async function client(endpoint, body, requestType, cancelToken) {
        let headers = {
            "Access-Control-Allow-Origin": "*",
        };

        if (endpoint.includes("kroki.io")) {
            headers = {
                "Content-Type": "text/plain",
            };
        } else {
            if (body instanceof FormData) {
                headers["Content-Type"] = "multipart/form-data";
            } else {
                headers["Content-Type"] = "application/json";
                body = { ...body };
            }
        }

        let url = `${endpoint}`;
        let configreq = {
            method: requestType,
            url: url,
            data: body,
            headers: headers,
        };

        if (cancelToken && typeof cancelToken.throwIfRequested === "function") {
            configreq.cancelToken = cancelToken;
        }

        const results = await axios(configreq);
        return results;
    }

    return (
        <div className="container-fluid px-md-4">
            <div className="row">
                {/* header style starts here */}
                <nav className="navbar cus-boxshadow d-block fixed-top bg-white py-2">
                    <div className="d-flex  align-items-center py-1">
                        <a className="navbar-brand" href="#">
                            <img
                                src="images/codegen-logo.svg"
                                alt="codegen-logo"
                                className="logo-sty"
                            />
                        </a>
                        <div className="ms-5">
                            <button className="header-button-active fw-bold">Organization</button>
                            <button className="header-button ms-2">Activity Logs</button>
                        </div>
                        <div className="dropdown me-lg-3 ms-auto">
                            <img
                                src="images/user-profile.svg"
                                alt="user-profile"
                                className="profile-sty cursor-pointer"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            />
                            <ul className="dropdown-menu dropdown-menu-end shadow-sm border-0">
                                <li>
                                    <a
                                        className="dropdown-item text-black font-16 user-dd-sty font-regular d-flex align-items-center"
                                        href="#"
                                    >
                                        <span className="me-2">
                                            <img src="images/logout.svg" alt="profile-icon" />
                                        </span>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {/* header style ends here */}

                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0">
                            <li className="font-16 font-medium">
                                <a href="#">Manage Projects</a>
                            </li>
                            <li className="font-16 font-medium">
                                <a href="#">{state.projectName}</a>
                            </li>
                            <li className="font-16 font-medium active">Inventory Data Model</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4 pb-3">
                        <div className="d-flex align-items-center">
                            <a>
                                <img
                                    src="images/back-arrow-new.svg"
                                    alt="back-arrow"
                                    className="me-3 mb-2"
                                    onClick={() => { props.setShowCode(); }}
                                />
                            </a>
                            <h2 className="text-black font-bold font-22 mb-0">
                                Inventory Data Model
                                <button
                                    type="button"
                                    className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3"
                                >
                                    <img src="images/info-icon-ETL.svg" className="" alt="info" />
                                </button>
                            </h2>
                        </div>
                        <div className="d-flex">
                            <div className="me-2 dropdown-width">
                                <div className="dropdown">
                                    <a
                                        className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center"
                                        href="#"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        style={{ zIndex: 1001 }}
                                    >
                                        Gold
                                    </a>
                                    <ul
                                        className="dropdown-menu w-100 cust-drop-menu"
                                        aria-labelledby="dropdownMenuLink"
                                    >
                                        <li>
                                            <a className="dropdown-item font-12" href="#">
                                                Gold
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item font-12" href="#">
                                                Bronz
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item font-12" href="#">
                                                Silver
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn cust-secondary-btn font-14 font-regular me-md-2"
                                onClick={handleDownloadPng}
                            >
                                <span className="me-2">
                                    <img src="images/download-icon.svg" alt="download-icon" />
                                </span>
                                Download
                            </button>
                            <CopyToClipboard text={code} onCopy={handleCopyText}>
                                <button
                                    type="button"
                                    className="btn cust-primary-btn font-14 font-regular"
                                >
                                    <span className="me-2">
                                        <img src="images/copy-code-icon.svg" alt="copy-icon" />
                                    </span>
                                    {copiedText ? "Copied!" : "Copy Code"}
                                </button>
                            </CopyToClipboard>
                        </div>
                    </div>

                    <div className="col-md-12 px-4">
                        {/* Error logger textarea starts here */}
                        <div className="row">
                            {/* Editor Section */}
                            <div className="col-md-6 pt-2 mb-5">
                                <div>
                                    <Editor
                                        height="400px"
                                        language="plantuml"
                                        theme="vs-light"
                                        value={code}
                                        onChange={setCode}
                                        className="form-control custom-textarea logger-textarea-scroll add-code-snippet"
                                    />
                                </div>
                            </div>

                            {/* Diagram Display Section */}
                            <div className="col-md-6 pt-2 mb-5">
                                <div>
                                    <div className="position-relative">
                                        <div className="position-absolute custom-btn-position bg-white rounded-3">
                                            <button
                                                type="button"
                                                className="btn btn-common-style btn-zoom-in custom-bottom-brd"
                                                onClick={handleZoomIn}
                                            >
                                                <img src="images/zoomin-icon.svg" alt="zoom-in" />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-common-style btn-zoom-out"
                                                onClick={handleZoomOut}
                                            >
                                                <img src="images/zoomout-icon.svg" alt="zoom-out" />
                                            </button>
                                        </div>
                                        <div
                                            className="custom-inventory-textarea logger-textarea-scroll"
                                            ref={svgRef}
                                            style={{
                                                transform: `scale(${zoom})`,
                                                transformOrigin: "top left",
                                            }}
                                        >
                                            {diagramSrc && (
                                                <div dangerouslySetInnerHTML={{ __html: diagramSrc }} />
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <button
                                            className="btn cust-primary-btn font-14 font-regular"
                                            onClick={handleCopyDiagram}
                                        >
                                            <span className="me-2">
                                                <img src="images/copy-code-icon.svg" alt="copy-icon" />
                                            </span>
                                            {copiedImage ? "Copied!" : "Copy Diagram"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Service Information */}
                    <div className="row mt-3">
                        <div className="col-12">
                            <p>Current Service: {currentService}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlantUMLPreview;
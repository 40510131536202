import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { generateSasTokenAPI, getCodePageDataApi, insertError } from '../../Service/Api.js';
import Editor from '@monaco-editor/react';
import { HeaderCom } from '../Header.jsx';

export default function RelationShipViewPage(props) {
    const [powerBICode, setPowerBICode] = useState("");
    const [blobUrl, setBlobUrl] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { state = {} } = useLocation();

    useEffect(() => {
        fetchCodePageData();
    }, []);

    useEffect(() => {
        if (blobUrl) {
            fetchDataFromBlob();
        }
    }, [blobUrl]);

    const fetchJsonFromBlob = async (blobURL) => {
        try {
            const sasToken = await generateSasTokenAPI();
            const url = `${blobURL}?${sasToken}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const textData = await response.text();
            return textData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            handleError(error);
            throw error;
        }
    };

    
const accountName = 'avaeusgenetlsametadev';
const containerName = 'uc-migration';

 const putPythonToBlob = async (orgName, projectName, jsonData, fileName, phase) => {
    try {
        const sasToken = await generateSasTokenAPI();
        const blobPath = `organizations/${orgName}/${projectName}/development/relationship.tmdl`;
        // const url = `https://avaeusgenetlsametadev.blob.core.windows.net/uc-migration/organizations/B2CFE45E-B852-463D-8FCD-C305FEFC4628/0B9C7F71-FDE5-4192-9F93-A0B1ABEC4FE8/development/relationship.tmdl?${sasToken}`;
        const url = `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}?${sasToken}`;

        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-ms-blob-type': 'BlockBlob'
            },
            body: jsonData
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('JSON data uploaded successfully to ', `https://${accountName}.blob.core.windows.net/${containerName}/${blobPath}`);
        return url;

    } catch (error) {
        console.error('Error putting JSON to blob:', error);
        throw error;
    }
};

    const fetchCodePageData = async () => {


        const body = {
            projectId: state.projectId,
            type: "tableauCode",
            userId: state.userId,
            orgId: state.orgId,
            jwt: state.jwt,
        };

        try {
            setError(null);
            const res = await getCodePageDataApi(body, state.jwt);
            if (res.status === 403) {
                sessionExpired();
                return;
            } else if (res.status !== 200) {
                throw new Error("Failed to fetch blob URL from Server");
            }

            const blobUrl = res.data[0]?.powerBIRelationshipUrl;
            if (blobUrl) {
                setBlobUrl(blobUrl);
            } else {
                throw new Error("No blob URL found");
            }
        } catch (error) {
            console.error("Error fetching code page data:", error);
            handleError(error);
        }
    };

    const fetchDataFromBlob = async () => {
        try {
            document.getElementById("pageLoader").style.display = "block";

            const data = await fetchJsonFromBlob(blobUrl);
            const parsedData = data
            setPowerBICode(parsedData); 
            document.getElementById("pageLoader").style.display = "none";
        } catch (error) {
            console.error("Error fetching code from blob:", error);
            handleError(error);
            document.getElementById("pageLoader").style.display = "none";
        }
    };

    const handleError = (error) => {
        insertError({
            errorMessage: error.message,
            serviceName: "frontend",
            module: "sourceProgressCode",
            functionName: "sourceProgressCode",
            userId: state.userId
        });
        console.log("error", error);
        setError(error.message);
    };

    const download = (filename, textInput) => {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(textInput));
        element.setAttribute('download', filename);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

      const saveCodeDetails = async () => {
        
            try {
                const data = JSON.stringify(powerBICode)
                const nametoSave = props.data.notebookName;
                // await putPythonToBlob(
                //     state.orgId,
                //     state.projectId,
                //     data,
                //     nametoSave,
                //     "define"
                // );
                console.log('Save operation completed.');
                props.closeSourceCodePage();
            } catch (error) {
                console.error("Error saving code details:", error);
                handleError(error);
            }
        };
    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };

    const handleCopy = () => {
        if (!powerBICode) {
            console.error('No code to copy.');
            return;
        }

        navigator.clipboard.writeText(powerBICode)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
            })
            .catch(err => {
                console.error('Error while copying to clipboard:', err);
            });
    };

    return (
        <div className="container-fluid px-md-4">
            {/* Session Expired Toast */}
            <div className="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                    <div className="d-flex">
                        <div className="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                            </span>
                            <span className="mx-3 font-14 loader-text">Session has expired. Please login Again</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Loader */}
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>

            {/* Error Modal */}
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                Oops! Something went wrong
            </button>
            <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="success-icon-sty" /></span>
                                <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                <p className="font-14 font-regular text-black">Please try again later</p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="row">
                <HeaderCom value='1' />
                <div className="col-md-12 pt-4 mt-5">
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0">
                            <li className="font-16 font-medium" key="manage-projects">
                                <a>Manage Projects</a>
                            </li>
                            <li className="font-16 font-medium" key={state.projectName}>
                                <a>{state.projectName}</a>
                            </li>
                            <li className="font-16 font-medium active" key={props.data.hyperlinkText}>{props.data.hyperlinkText}</li>
                        </ul>
                    </div>
                    <div className="d-flex align-items-center justify-content-between p-4">
                        <div className="d-flex align-items-center">
                            <a className="d-flex" onClick={saveCodeDetails} style={{ cursor: 'pointer' }}>
                                <img src="images/back-arrow.svg" alt="back-arrow" />
                            </a>
                            <h2 className="text-black font-bold font-22 mb-0 ms-3">
                                {props.data.hyperlinkText}
                            </h2>
                            <button
                                className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3"
                                data-bs-target="#exampleModalToggle"
                                data-bs-toggle="modal"
                            >
                                <img src="images/info-icon-ETL.svg" alt="info" />
                            </button>
                            <div
                                className="modal fade"
                                id="exampleModalToggle"
                                aria-hidden="true"
                                aria-labelledby="exampleModalToggleLabel"
                                tabIndex={-1}
                            >
                                <div className="modal-dialog view-instructions-popup modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="view-instructions-popup-left-section">
                                                        <img
                                                            src="images/view-instructions-popup-banner-img.png"
                                                            alt="view-instructions-popup-banner-img"
                                                            className="popup-banner-img"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="view-instructions-popup-right-section">
                                                        <div className="d-flex justify-content-between">
                                                            <h1 className="font-bold font-20 m-0">View Instructions</h1>
                                                            <button
                                                                type="button"
                                                                className="btn-close"
                                                                data-bs-dismiss="modal"
                                                                aria-label="Close"
                                                            />
                                                        </div>
                                                        <ul className="mt-4 view-instructions-list-items">
                                                            <li>
                                                                Please validate whether the data's are correct
                                                            </li>
                                                            <li>
                                                                If things are fine just click 'back' then on 'Looks good' and proceed to next steps
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <button
                                type="button"
                                className="btn cust-primary-btn font-14 font-medium me-2"
                                onClick={handleCopy}
                            >
                                <img src="images/copy-code-icon.svg" alt="copy" className="me-2" />
                                <span>{isCopied ? "Copied" : "Copy Code"}</span>
                            </button>
                            <button
                                type="button"
                                className="btn cust-secondary-btn font-16 font-medium"
                                onClick={() => { download(`${props.data?.hyperlinkText}.py`, powerBICode) }}
                            >
                                <span className="me-2">
                                    <img src="images/download-icon.svg" alt="add-icon" />
                                </span>
                                Download
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 px-4">
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-12 col-lg-9 col-sm-12 pt-4 mb-5">
                                <div className="form-control custom-textarea logger-textarea-scroll" id="exampleFormControlInput1">
                                    <Editor height="50.5vh" defaultLanguage="python" value={powerBICode} options={{ minimap: { enabled: false } }} onChange={setPowerBICode} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
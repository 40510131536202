import React, { useState, useEffect } from 'react';

const SearchComponent = ({ columns, actualValue, setGridData, searchFilterTrigger }) => {
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Reset search when the trigger changes
        setSearchTerm('');
        setGridData(actualValue);
    }, [searchFilterTrigger]);

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (term.length >= 3) {
            const filteredData = actualValue.filter(item =>
                columns.some(column => 
                    item[column] && item[column].toLowerCase().includes(term.toLowerCase())
                )
            );
            setGridData(filteredData);
        } else {
            setGridData(actualValue);
        }
    };
    return (
  

      <div className="input-group position-relative w-50 search-wid me-3 pb-1">
                                          <input type="search" className="form-control cust-input-sty font-14 rounded-2 font-regular" placeholder="Search" style={{ 'paddingRight': '30px' }} value={searchTerm}
          onChange={handleSearch}/>
                                          <span className="search-icon-sty"><a ><img src="images/search-icon.svg" alt="search icon" /></a></span>
                                      </div>
    );
};

export default SearchComponent;
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError, generateSasTokenAPI } from "../../../Service/Api.js";
import { HeaderCom } from "../../Header.jsx";
import DynamicGrid from './DynamicTable.jsx';
import SearchComponent from './SearchComponent.jsx';
import FilterComponent from './FilterComponent.jsx';
import DownloadComponent from './DownloadComponent.jsx';
import ReasonComponent from './ReasonComponent.jsx';
import ViewInstructions from './viewInstructions.jsx'



function InventoryDocument(props) {
    const { state } = useLocation();
    const location = useLocation();
    // const navigate = useNavigate();

    // Assume location.state.isSubjectArea is provided in the route state
    const isSubjectArea = state?.isSubjectArea ?? false;  // Fallback to false if undefined

    const [settings, setSettings] = useState(() => {
        return {
            TableConfig: [
                { key: 'SubjectArea', header: 'SubjectArea', sortable: true, type: 'normal', deFaultRow: 'dropdown' },
                { key: 'SourceCatalogName', header: 'SourceCatalogName', sortable: true, type: 'normal', deFaultRow: 'input' },
                { key: 'SourceSchemaName', header: 'SourceSchemaName', sortable: true, type: 'normal', deFaultRow: 'input' },
                { key: 'SourceTableName', header: 'SourceTableName', sortable: true, type: 'normal', deFaultRow: 'input' },
                { key: 'SourceColumnName', header: 'SourceColumnName', sortable: true, type: 'normal', deFaultRow: 'input' },
                { key: 'Constraints', header: 'SourceConstraints', sortable: true, type: 'normal', deFaultRow: 'input' },
                { key: 'DataType', header: 'DataType', sortable: true, type: 'normal', deFaultRow: 'input' },
                { key: 'Description', header: 'Description', sortable: true, type: 'normal', deFaultRow: 'input' }
                

            ],
            ActionItems: { add: true, delete: true, edit: true },
            deFaultRow: [
                { key: 'SubjectArea', values: [] },
                { key: 'SchemaName', values: [] },
                { key: 'TableName', values: [] },

            ]
        };
    });

    const accountName = 'avaeusgenetlsametaprod';
    const containerName = 'uc-migration';



    const fetchJsonFromBlob = async (inventoryDDURL) => {
        try {
            console.log(inventoryDDURL, "inventoryDDURL");

            const sasToken = await generateSasTokenAPI();
            console.log(sasToken, "Generated SAS Token");
            // inventoryDDURL = inventoryDDURL;
            let url = `${inventoryDDURL}?${sasToken}`;
            console.log(url, "Final URL");

            let response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonData = await response.json();
            console.log(jsonData, 'Fetched JSON Data');
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };



    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);
    const [actualValue, setActualValue] = useState([]);
    const [dropDownValues, setDropDownValues] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [whetherUpdated, setWhetherUpdated] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [currentOperation, setCurrentOperation] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [searchFilterTrigger, setSearchFilterTrigger] = useState(0);
    const [newRow, setNewRow] = useState({
        SourceCatalogName: '',
        SourceSchemaName: '',
        SourceTableName: '',
        SourceColumnName: '',
        Constraints: '',
        DataType: '',
        Description:''

    });
    const [blobUrl, setblobUrl] = useState('');

    const handleEdit = (item) => {
        setCurrentOperation('edit');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };



    const handleAdd = () => {
        console.log("I am in add function");
        console.log(newRow, "New item to be added");

        // Check if all fields in newRow are filled
        const allFieldsFilled = Object.values(newRow).every(value => value !== '' && value !== null && value !== undefined);

        if (allFieldsFilled) {
            // Proceed to add the new row
            setCurrentOperation('add');
            setCurrentItem(newRow);
            // Here you might want to show a modal or perform further actions
            setShowReasonModal(true);
            // Optionally reset newRow after adding
            // setNewRow({
            //     SourceCatalogName: '',
            //     SourceSchemaName: '',
            //     SourceTableName: '',
            //     SourceColumnName: '',
            //     // Reset other keys as necessary
            // });
        } else {
            console.log('Please ensure that all fields are filled before adding.');
        }
    };

    const handleDelete = (item) => {
        setCurrentOperation('delete');
        setCurrentItem(item);
        setShowReasonModal(true);
    };

    

    const handleReasonSave = async () => {
        try {
            let updatedData;
            switch (currentOperation) {
                case 'edit':
                    updatedData = actualValue.map(item =>
                        item.index === currentItem.index ? { ...item, ...currentItem, reason } : item
                    );
                    break;
                case 'add':
                    updatedData = [...actualValue, { ...currentItem, reason, index: actualValue.length }];
                    break;
                case 'delete':
                    updatedData = actualValue.filter(item => item.index !== currentItem.index);
                    break;
                default:
                    throw new Error('Invalid operation');
            }
            let body = {
                type: "inventoryDocument",
                projectId: state.projectId,
                userId: state.userId,
                orgId: state.orgId,
                comments:reason,
            }
            let res = await updatePseudocodeData(body,state.jwt)
            await updateJsonInBlob(blobUrl, updatedData);
    
            // Update local state with original data and re-apply filter
            setActualValue(updatedData);
            
            // Reset gridData to actualValue
            setGridData(updatedData);
    
            // Trigger re-application or reset logic in search and filter components
            setSearchFilterTrigger(prev => prev + 1);
    
            setShowReasonModal(false);
            setReason('');
            setCurrentOperation(null);
            setCurrentItem(null);
            setWhetherUpdated(true);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const updateJsonInBlob = async (blobUrl, updatedData) => {
        try {
            const sasToken = await generateSasTokenAPI();
            let url = `${blobUrl}?${sasToken}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Data updated successfully in Azure Blob');
        } catch (error) {
            console.error('Error updating JSON in blob:', error);
            throw error;
        }
    };

    const searchColumns = ['SourceCatalogName', 'SourceSchemaName'];
    const FilterColumns = ['SourceCatalogName', 'SourceSchemaName', 'SourceTableName', 'SourceColumnName'];

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchPseudo()

                ]);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const fetchDropDown = async () => {
        try {
            let data = { type: "subjectarea", projectId: state.projectId, userId:state.userId }
            document.getElementById('pageLoader').style.display = "block";
            let dropDownValues1 = await fetchDropDownValues(data, state.jwt);
            if (dropDownValues1.data) {
                setDropDownValues(dropDownValues1.data.subjectArea);
                updateSettingsWithDropdownValues(dropDownValues1.data.subjectArea);
                console.log(dropDownValues1.data.subjectArea, "0000000000000000000000000000")
                console.log(dropDownValues, "v1222222222");
            }
            document.getElementById('pageLoader').style.display = "none";

        } catch (e) {
            handleError(e);
        }
    };

    useEffect(() => {
        fetchDropDown();
    }, []);

    const updateSettingsWithDropdownValues = (data) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            deFaultRow: prevSettings.deFaultRow.map(row => {
                if (row.key === 'SubjectArea') {
                    return { ...row, values: data || [] };
                }
                if (row.key === 'SchemaName') {
                    return { ...row, values: data || [] };
                }
                if (row.key === 'TableName') {
                    return { ...row, values: data || [] };
                }
                return row;
            })
        }));
    }

    const fetchPseudo = async () => {
        const body = {
            projectId: state.projectId,
            type: "inventorydocument",
            userId: state.userId,
            orgId: state.orgId,
        };

        try {
            document.getElementById('pageLoader').style.display = "block";

            const res = await fetchPseudocodeData(body, state.jwt);
            if (res.statusCode === 403) {
                sessionExpired();
            } else if (res.statusCode !== 200) {
                // document.getElementById('openErrorModal').click();
            } else {
                console.log("The Response Is 200");
                let inventoryDDURL = res?.data[0]?.inventoryDDURL;
                setblobUrl(inventoryDDURL)
                console.log(inventoryDDURL, 'fetchJsonFromBlobfetchJsonFromBlob');
                try {
                    const jsonData = await fetchJsonFromBlob(inventoryDDURL);
                    console.log(jsonData, "Data from blob");

                    if (jsonData) {
                        let pseudo = jsonData.map((obj, index) => ({
                            ...obj,
                            index: index
                        }));
                        setGridData(pseudo);
                        setActualValue(pseudo);
                        console.log(pseudo, "Mapped data");
                    } else {
                        throw new Error("Failed to fetch data from Azure Blob Storage");
                    }
                    document.getElementById('pageLoader').style.display = "none";

                } catch (e) {
                    console.error('Error fetching JSON from blob:', e);
                    handleError(e);
                }
            }
        } catch (e) {
            console.error('Error fetching pseudocode data:', e);
            handleError(e);
        }
    }

    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };

    const handleError = (e) => {
        insertError({
            errorMessage: e.message,
            serviceName: "frontend",
            module: "Datadictionary",
            functionName: "DataDictionaryTable",
            userId: state.userId
        });
        console.log("error", e);
        setError(e.message);
    }

    // if (isLoading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error}</div>;



    return (
        <div className="container-fluid">
            <div className="row " >
            <div className="d-flex justify-content-center align-items-center w-100" style={{ position: 'absolute', zIndex: '100000' }}>
                <div id="toastMessage" className="toast custom-toast align-items-center mt-5" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000" style={{ display: "none" }}>
                    <div className="d-flex">
                        <div className="toast-body">
                            <span>
                                <img src="images/sessionIcon.svg" style={{ height: '22px' }} />
                            </span>
                            <span className="mx-3 font-14 loader-text">Session has expired. Please login Again</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay" id='pageLoader' style={{ display: 'none' }}>
                <div className="position-absolute top-50 start-50  translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center " role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" id="openErrorModal" data-bs-target="#Something-wrong" hidden>
                Oops! Something went wrong
            </button>
            <div className="modal fade" id="Something-wrong" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-4 pb-0">
                            <div className="row text-center">
                                <span><img src="images/went-wrong-icon.svg" alt="went-wrong-icon" className="sucess-icon-sty" /></span>
                                <h3 className="font-16 font-bold text-black mt-4">Oops! Something went wrong</h3>
                                <p className="font-14 font-regular text-black">Please try again later</p>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 pb-4 pt-2 justify-content-center">
                            <button type="button" className="btn cust-primary-btn font-14 font-medium" data-bs-dismiss="modal" aria-label="Close">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
                <HeaderCom value="1" />
                <div className="col-md-12 pt-4 mt-5">
                    {/* Breadcrumbs starts here */}
                    <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a >Manage Projects</a></li>
                            <li className="font-16 font-medium"><a>{state.projectName}</a></li>
                            <li className="font-16 font-medium active">Inventory Document</li>
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
                    <div className="d-flex align-items-center justify-content-between p-4">
                    <div className="d-flex align-items-center">
                            <a className="d-flex">
                            <img src="images/back-arrow.svg" alt="back-arrow" style={{ width: '24px', height: '24px', cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => { props.setShowCode(); props.isEdit(whetherUpdated) }} />
                            </a>
                            <h2 className="text-black font-bold font-22 mb-0 ms-3">{props.data.hyperlinkText}
                                <button type="button" className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3" onClick={handleOpenModal}>
                                    <img src="images/info-icon-ETL.svg" alt="info" />
                                </button>
                            </h2>
                            {isModalOpen && <ViewInstructions notebooktype='inventoryDocument' onClose={handleCloseModal} />}
                        </div>
                        <div className="d-flex">
                        <SearchComponent
    columns={searchColumns}
    actualValue={actualValue}
    setGridData={setGridData}
    searchFilterTrigger={searchFilterTrigger}
/>
<FilterComponent
    columns={FilterColumns}
    actualValue={actualValue}
    setGridData={setGridData}
    searchFilterTrigger={searchFilterTrigger}
/>
                            <DownloadComponent
                                type="excel"
                                data={actualValue}
                                fileName="InventoryDocument"
                            />
                        </div>
                    </div>

                    <DynamicGrid
                        data={gridData}
                        settings={settings}
                        setGridData={setGridData}
                        actualValue={actualValue}
                        setWhetherUpdated={setWhetherUpdated}
                        onEdit={handleEdit}
                        onAdd={handleAdd} // Pass handleAdd function
                        onDelete={handleDelete} // Pass handleDelete function
                        newRow={newRow} // Pass newRow for adding new items
                        setNewRow={setNewRow} // Function to update newRow state
                    />
                    <ReasonComponent
                        isOpen={showReasonModal}
                        onClose={() => setShowReasonModal(false)}
                        onSave={handleReasonSave}
                        reason={reason}
                        setReason={setReason}
                    />
                </div>
            </div>
        </div>
    );
}

export default InventoryDocument;
var inventoryTemplate="https://avaeusgenetlsametaprod.blob.core.windows.net/uc-migration/TemplateData/inventoryDDURL.json"


var tableTypeTemplate="https://avaeusgenetlsametaprod.blob.core.windows.net/uc-migration/TemplateData/tableTypeURL.json"


var inventoryDownloadScripts="https://avaeusgenetlsametaprod.blob.core.windows.net/uc-migration/TemplateData/DownloadScripts.py"


var existingNotebookSOPurl="https://avaeusgenetlsametaprod.blob.core.windows.net/uc-migration/TemplateData/existingDownloadSOP.pdf"

export {inventoryTemplate,tableTypeTemplate,inventoryDownloadScripts,existingNotebookSOPurl}
import React, { useState, useEffect, useRef } from 'react';
import { diffLines } from 'diff';

const CodeComparisonComponent = ({ oldCode, newCode, notebookName, isOpen, onClose }) => {
  const [diff, setDiff] = useState([]);
  const leftPanelRef = useRef(null);
  const rightPanelRef = useRef(null);

  useEffect(() => {
    const computeDiff = () => {
      const differences = diffLines(oldCode, newCode);
      setDiff(differences);
    };

    computeDiff();
  }, [oldCode, newCode]);

  useEffect(() => {
    const handleScroll = (source, target) => {
      target.scrollTop = source.scrollTop;
    };

    const leftPanel = leftPanelRef.current;
    const rightPanel = rightPanelRef.current;

    if (leftPanel && rightPanel) {
      const syncScrollLeft = () => handleScroll(leftPanel, rightPanel);
      const syncScrollRight = () => handleScroll(rightPanel, leftPanel);

      leftPanel.addEventListener('scroll', syncScrollLeft);
      rightPanel.addEventListener('scroll', syncScrollRight);

      return () => {
        leftPanel.removeEventListener('scroll', syncScrollLeft);
        rightPanel.removeEventListener('scroll', syncScrollRight);
      };
    }
  }, [leftPanelRef, rightPanelRef]);

  const renderSideBySide = () => {
    let leftLines = [];
    let rightLines = [];
    let leftLineNumber = 1;
    let rightLineNumber = 1;

    diff.forEach((part) => {
      const lines = part.value.split('\n').slice(0, -1);

      if (part.added) {
        leftLines.push(...Array(lines.length).fill(<span className="empty-line">&nbsp;</span>));
        rightLines.push(...lines.map(line => (
          <span key={`rline-${rightLineNumber}`} className="added-line">+ {line}</span>
        )));
        rightLineNumber += lines.length;
      } else if (part.removed) {
        leftLines.push(...lines.map(line => (
          <span key={`lline-${leftLineNumber}`} className="removed-line">- {line}</span>
        )));
        rightLines.push(...Array(lines.length).fill(<span className="empty-line">&nbsp;</span>));
        leftLineNumber += lines.length;
      } else {
        leftLines.push(...lines.map(line => (
          <span key={`lline-${leftLineNumber}`}>{line}</span>
        )));
        rightLines.push(...lines.map(line => (
          <span key={`rline-${rightLineNumber}`}>{line}</span>
        )));
        leftLineNumber += lines.length;
        rightLineNumber += lines.length;
      }
    }); 

    
return (
  <div className="row">
    <div className="col-md-6 col-lg-6 col-sm-12 pt-2 mb-2">
      <h2 className="text-black font-bold font-16 mb-2">Old Version</h2>
      <pre ref={leftPanelRef} className="form-control custom-textarea logger-textarea-scroll add-code-snippet border-0" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
        {leftLines.map((line, index) => (
          <div key={`left-${index}`} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <span className="line-number" style={{ width: 40, display: 'inline-block', textAlign: 'right' }}>
              {index + 1}
            </span>
            <span>&nbsp;{line}</span>
          </div>
        ))}
      </pre>
    </div>
    <div className="col-md-6 col-lg-6 col-sm-12 pt-2 mb-2">
      <h2 className="text-black font-bold font-16 mb-2">New Version</h2>
      <pre ref={rightPanelRef} className="form-control custom-textarea logger-textarea-scroll add-code-snippet border-0" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
        {rightLines.map((line, index) => (
          <div key={`right-${index}`} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <span className="line-number" style={{ width: 40, display: 'inline-block', textAlign: 'right' }}>
              {index + 1}
            </span>
            <span>&nbsp;{line}</span>
          </div>
        ))}
      </pre>
    </div>
  </div>
);
  };

  return (
    <div className="container-fluid">
      <div className="modal-backdrop fade show"></div>
      <div className="row">
        <div className={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="SourceSysLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content px-2">
              <div className="modal-header border-0">
                <h5 className="modal-title d-flex align-items-center font-bold font-20" id="SourceSysLabel">
                  {notebookName}
                </h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
              </div>
              <div className="modal-body py-0 pb-4">
                {renderSideBySide()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeComparisonComponent;
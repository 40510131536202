//  PS_CTM_01 to  PS_CTM_02  Import necessary React hooks and functions ,  Import required components and API functions 
import React, { useState, useEffect, useDeferredValue } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { fetchPseudocodeData, generateSasTokenAPI } from '../../../Service/Api';

//  PS_CTM_03   Define constants for Azure storage account and container 
const accountName = 'avaeusgenetlsametaprod';
const containerName = 'uc-migration';

//  PS_CTM_04  Define the ConfirmationTMU functional component 
const ConfirmationTMU = (props) => {
    console.log(props, 'propsssss');
    //  PS_CTM_05  to  PS_CTM_07  Parse uploadedData from props and transform it 
    var newData
    try {
        newData = JSON.parse(props.uploadedData);
    } catch (error) {
        newData = props.uploadedData
    }
    newData = newData?.TableMappingDetails.map(item => {
        return {
            ...item,
            InScopeOrOutScope: item['InScopeOrOutScope']
        }
    });
    console.log(newData, "uploadedNewDatauploadedNewData");

    //   PS_CTM_08 to   PS_CTM_15 state variable declaration and  Initialize state for initialization flag 
    const navigate = useNavigate();

    const [uploadedTMDataJson, setUploadedTMDataJson] = useState(newData);
    const [existingTMDataJson, setExistingTMDataJson] = useState([]);
    const [differences, setDifferences] = useState([]);
    const [blobUrl, setBlobUrl] = useState('')
    const [selectedRows, setSelectedRows] = useState([]); // State to keep track of selected rows
    const { state } = useLocation()
    console.log(state, 'state');
    const [init, setinit] = useState(0)

    //  PS_CTM_16  Set up useEffect hook to call fetchPseudo on component mount 
    useEffect(() => {
        fetchPseudo().catch(err => console.error(err));
    }, []);

    //  PS_CTM_17 to PS_CTM_18   Set up useEffect hook to check differences and close popup if needed 
    useEffect(() => {
        if (init == 1 && differences.length == 0) {
            props.closepop()
        } else {
            setinit(1)
        }
    }, [differences])

    //  PS_CTM_19 to   PS_CTM_24  Set up useEffect hook to compare data when existingTMDataJson is updated 
    useEffect(() => {
        if (existingTMDataJson.length > 0) { // Only run if existingTMDataJson is updated
            console.log(existingTMDataJson, 'Existing TM Data Updated');

            const diffs = compareData(newData, existingTMDataJson);
            setDifferences(diffs);
            console.log(diffs, 'differences');
        }
    }, [existingTMDataJson]);
    // useEffect(() => {

    // }, [differences])

    // const generateSasToken = async () => {
    //     const response = await fetch('http://localhost:3001/generate-sas');
    //     if (!response.ok) throw new Error('Failed to fetch SAS token');
    //     const { sasToken } = await response.json();
    //     return sasToken;
    // };

    //  PS_CTM_25 to   PS_CTM_35  Define fetchJsonFromBlob async function to fetch the json from the blob using the url
    const fetchJsonFromBlob = async (tableMappingURL) => {
        try {
            const sasToken = await generateSasTokenAPI();
            const url = `${tableMappingURL}?${sasToken}`;
            const response = await fetch(url);
            console.log(response, 'responseee')
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const jsonData = await response.json();
            console.log(jsonData)
            return jsonData;
        } catch (error) {
            console.error('Error fetching JSON from blob:', error);
            throw error;
        }
    };

    //  PS_CTM_36 to  PS_CTM_48  Define compareData function to find differences between uploaded and existing data 
    const compareData = (uploadedData, existingData) => {
        const differences = [];

        uploadedData.forEach((uploadedItem) => {
            const existingItem = existingData.find(item =>
                item.NewTableName === uploadedItem.NewTableName &&
                item.SourceCatalogName === uploadedItem.SourceCatalogName &&
                item.SourceSchemaName === uploadedItem.SourceSchemaName &&
                item.SourceTableName === uploadedItem.SourceTableName &&
                item.SourceStorageType === uploadedItem.SourceStorageType &&
                item.SourceStoragePath === uploadedItem.SourceStoragePath
            );

            if (existingItem) {
                let allChanged = true;

                Object.keys(uploadedItem).forEach(key => {
                    if (uploadedItem[key] === existingItem[key]) {
                        allChanged = false;  // Found at least one key that hasn't changed
                    }
                });

                if (allChanged) {
                    // All values have changed; consider it a new record
                    differences.push({
                        SchemaName: uploadedItem.sourceSchemaName,
                        TableName: uploadedItem.sourceTableName,
                        ChangedColumn: 'New record',
                        PreviousValue: 'NA',
                        ChangedValue: JSON.stringify(uploadedItem)
                    });
                } else {
                    // Compare individual fields for changes
                    Object.keys(uploadedItem).forEach(key => {
                        if (uploadedItem[key] !== existingItem[key]) {
                            differences.push({
                                SchemaName: uploadedItem.NewSchemaName,
                                TableName: uploadedItem.NewTableName,
                                ChangedColumn: key,
                                PreviousValue: existingItem[key],
                                ChangedValue: uploadedItem[key]
                            });
                        }
                    });
                }
            } else {
                // If no existing item is found, consider it as a new record
                differences.push({
                    SchemaName: uploadedItem.SourceSchemaName,
                    TableName: uploadedItem.SourceTableName,
                    ChangedColumn: 'New record',
                    PreviousValue: 'NA',
                    ChangedValue: JSON.stringify(uploadedItem)
                });
            }
        });

        return differences;
    };

    //  PS_CTM_49 to  PS_CTM_61  Define the asynchronous `fetchPseudo` function  to fetch the blob url from the database
    const fetchPseudo = async () => {
        try {
            let data = { type: "tablemapping", projectId: state.projectId, userId: state.userId, orgId: state.orgId, jwt: state.jwt }
            console.log(data, "payload")
            // document.getElementById('pageLoader').style.display = 'block';
            let urlData = await fetchPseudocodeData(data, state.jwt)
            console.log(urlData, "tata")

            if (urlData.statusCode === 200) {
                let url = urlData.data[0].UCTableMappingURL

                setBlobUrl(urlData.data[0].UCTableMappingURL)

                const jsonData = await fetchJsonFromBlob(url);
                console.log(jsonData, '11111111');

                setExistingTMDataJson(jsonData);
                setUploadedTMDataJson(newData);  // Assuming `data` is your uploaded data
            }
        } catch (error) {
            console.error(error);
            alert('Something went wrong');
        }
    };
    //  PS_CTM_62  to PS_CTM_79  Define the `handleTick` function that receives a list of rows as an argument  to change it as approved 
    const handleTick = (rows) => {
        debugger
        console.log('Existing TM Data JSON initially:', existingTMDataJson);

        const updatedData = [...existingTMDataJson];
        rows.forEach(row => {
            const index = updatedData.findIndex(item => item.NewTableName === row.TableName);
            console.log('Index found:', index); // Log index for debugging

            if (index > -1) {
                // Update existing record
                const existingRecord = updatedData[index];
                updatedData[index][row.ChangedColumn] = row.ChangedValue;

                const changesPayload = {
                    TableMappingID: existingRecord.TableMappingID,
                    changes: {}
                };

                for (const key in row) {
                    if (row[key] !== existingRecord[key]) {
                        changesPayload.changes[key] = {
                            previous: existingRecord[key],
                            current: row[key]
                        };
                    }
                }

                console.log('Changes Payload:', changesPayload);

                try {
                    // Uncomment this line when ready to make API call
                    // postTableMapping({ projectId, userId, changesPayload });
                    putJsonToBlob(updatedData);

                    // Remove the updated record from differences
                    setDifferences(prevDifferences =>
                        prevDifferences.filter(diff =>
                            !(diff.SchemaName === row.SchemaName &&
                                diff.TableName === row.TableName &&
                                diff.ChangedColumn === row.ChangedColumn)
                        )
                    );


                } catch (error) {
                    console.error('Error updating data in blob:', error);
                }

            } else {
                // New record case
                try {
                    // Parse the ChangedValue JSON string
                    console.log('Existing TM Data JSON before adding new record:', existingTMDataJson);

                    const newRecordDetails = JSON.parse(row.ChangedValue);

                    // Generate new UUID for the new record
                    const TableMappingID = uuidv4();
                    console.log('Generating new UUID:', TableMappingID);

                    // Form the new record object
                    const newRecord = {
                        ...newRecordDetails,
                        TableMappingID
                    };

                    // Append the new record to updatedData array
                    updatedData.push(newRecord);

                    // Set the updated data in state
                    setExistingTMDataJson(updatedData);

                    console.log('Combined TM Data JSON after adding new record:', updatedData);

                    try {
                        // Uncomment this line when ready to update the blob storage
                        putJsonToBlob(updatedData);

                        // Remove the new record from differences if it was added as a difference
                        setDifferences(prevDifferences =>
                            prevDifferences.filter(diff =>
                                !(diff.SchemaName === row.SchemaName &&
                                    diff.TableName === row.TableName &&
                                    diff.ChangedColumn === 'New record')
                            )
                        );

                    } catch (error) {
                        console.error('Error updating data in blob:', error);
                    }
                } catch (error) {
                    console.error('Error parsing ChangedValue or updating data:', error);
                }

            }

            if (differences.length === 0) {
                // alert('All differences are resolved');
                props.closepop()
            }
        });
    };

    //  PS_CTM_81 to  PS_CTM_87  Define the `handleCancel` function that receives a list of rows as an argument   and neglect the row from adding in the blob 
    
    // Define the `handleCancel` function to cancel changes for the selected rows
    const handleCancel = (rows) => {
        const updatedDifferences = differences.filter(diff => {
            // Check if the current diff is included in the rows to cancel
            return !rows.some(row => {
                return (
                    diff.SchemaName === row.SchemaName &&
                    diff.TableName === row.TableName &&
                    diff.ChangedColumn === row.ChangedColumn
                );
            });
        });
    
        // Update the `differences` state with the remaining differences
        setDifferences(updatedDifferences);
    
        // Check if all differences are resolved (empty array) and close the popup
        if (updatedDifferences.length === 0) {
            // alert('All differences are resolved');
            props.closepop();
        }
    };

    // PS_CTM_88 to  PS_CTM_95  Define the `putJsonToBlob` async function that takes `jsonData` as input and update in the blob
    const putJsonToBlob = async (jsonData) => {
        try {
            const sasToken = await generateSasTokenAPI();
            const url = `${blobUrl}?${sasToken}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-ms-blob-type': 'BlockBlob'
                },
                body: JSON.stringify(jsonData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error putting JSON to blob:', error);
            throw error;
        }
    };

    return (
        <div className="container-fluid">
            {differences.length>0?<div className='modal-backdrop'></div>:<></>}
            <div className="row">

                <div
                    className="modal fade show d-block"
                    id="SourceSys"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="SourceSysLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content px-2">
                            <div className="modal-header border-0">
                                <h5
                                    className="modal-title d-flex align-items-center font-medium font-20"
                                    id="SourceSysLabel"
                                >
                                    Upload Confirmed Table Mapping Sheet
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => props.closepop()}
                                />
                            </div>
                            <div className="modal-body py-0 pb-4">
                                <div className="table-responsive rounded-3 upload-confimred-table custom-scroll" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <table className="table w-100 table-borderless rounded custom-grid custom-metadata-table mb-0">
                                        <thead className="sticky-top-pos">
                                            <tr>
                                                <th>
                                                    <input
                                                        className="form-check-input custom-checkbox mt-0 me-2"
                                                        type="checkbox"
                                                        onChange={(e) => setSelectedRows(e.target.checked ? differences.map((_, index) => index) : [])}
                                                        id="radio5"
                                                    />
                                                </th>
                                                <th>SchemaName</th>
                                                <th>TableName</th>
                                                <th>ChangedColumn</th>
                                                <th>PreviousValue </th>
                                                <th>ChangedValue </th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {differences.length === 0 ? (
                                                <tr>
                                                    <td colSpan={7} className="text-center">No records found</td>
                                                </tr>
                                            ) : differences.map((diff, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            className="form-check-input custom-checkbox mt-0 me-2"
                                                            type="checkbox"
                                                            checked={selectedRows.includes(index)}
                                                            onChange={(e) => setSelectedRows(e.target.checked ? [...selectedRows, index] : selectedRows.filter(idx => idx !== index))}
                                                        />
                                                    </td>
                                                    <td>{diff.SchemaName}</td>
                                                    <td>{diff.TableName}</td>
                                                    <td>{diff.ChangedColumn}</td>
                                                    <td>{diff.PreviousValue}</td>
                                                    <td>{diff.ChangedValue}</td>
                                                    <td className="text-center">
                                                        <img
                                                            src="images/tick-icon.svg"
                                                            alt="tick-icon"
                                                            className="cust-cursor-pointer me-3"
                                                            title="tick"
                                                            onClick={() => handleTick(selectedRows.length > 0 ? selectedRows.map(idx => differences[idx]) : [diff])}
                                                        />
                                                        <img
                                                            src="images/cross-red.svg"
                                                            alt="edit-icon"
                                                            className="cust-cursor-pointer"
                                                            title="cross"
                                                            onClick={() => handleCancel(selectedRows.length > 0 ? selectedRows.map(idx => differences[idx]) : [diff])}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <p className="font-regular font-14 text-grey mt-3">
                                    # of Records:{differences.length}
                                    <span className="text-black font-medium"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ConfirmationTMU;
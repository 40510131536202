import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchPseudocodeData, fetchDropDownValues, updatePseudocodeData, insertError, generateSasTokenAPI } from "../../Service/Api";
import { saveAs } from 'file-saver';
import Editor from '@monaco-editor/react';
import axios from 'axios';
import ViewInstructions from './Integrations/viewInstructions';
import { HeaderCom } from '../Header';

// Code Snippet Modal Component
const CodeSnippetModal = ({ code, onClose }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code).then(() => {
      setIsCopied(true);

      // Reset the button text back after 2 seconds
      setTimeout(() => setIsCopied(false), 2000);
    }).catch(error => {
      console.error('Copy failed', error);
    });
  };

  return (
    <div className="modal show d-block" tabIndex={-1} role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header border-0 px-4">
            <h5
              className="modal-title d-flex align-items-center font-medium font-20"
              id="ConfrmLabel"
            >
              Code Snippet
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            />
          </div>
          <div className="modal-body py-0">
            <div className="row d-flex justify-content-center">
              <div className="col-10">
                <Editor
                  height="400px"
                  defaultLanguage="plantuml"
                  value={code}
                  options={{
                    readOnly: true,
                    wordWrap: "on",
                    minimap: { enabled: false },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer border-0 px-4 mx-5 mt-3">
            <button
              type="button"
              className="btn cust-primary-btn font-14 font-medium"
              onClick={onClose}
              aria-label="Close"
            // onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn cust-primary-btn font-14 font-medium me-2"
              onClick={handleCopy}
            >
              <img
                src="images/copy-code-icon.svg"
                alt="copy"
                className="me-2"
              />
              <span>{isCopied ? 'Copied' : 'Copy Code'}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};



const ApproachDiagram = (props) => {
  let { state } = useLocation();
  const navigate = useNavigate();
  const [beforeDiagramCode, setBeforeDiagramCode] = useState('');
  const [afterDiagramCode, setAfterDiagramCode] = useState('');
  const [beforeDiagramSVG, setBeforeDiagramSVG] = useState('');
  const [afterDiagramSVG, setAfterDiagramSVG] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [translateBefore, setTranslateBefore] = useState({ x: 0, y: 0 });
  const [translateAfter, setTranslateAfter] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [zoomBefore, setZoomBefore] = useState(1);
  const [zoomAfter, setZoomAfter] = useState(1);
  const [modalCode, setModalCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [beforeZoom, setBeforeZoom] = useState(1);
  const [afterZoom, setAfterZoom] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [issModalOpen, setIssModalOpen] = useState(false);



  const handleZoomInBefore = () => setZoomBefore(zoomBefore + 0.1);
  const handleZoomInAfter = () => setZoomAfter(zoomAfter + 0.1);

  const handleZoomOutBefore = () => setZoomBefore(zoomBefore - 0.1);
  const handleZoomOutAfter = () => setZoomAfter(zoomAfter - 0.1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchPseudo()]);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleOpenModal = () => {
    setIssModalOpen(true);
  };
  const handleCloseModal = () => {
    setIssModalOpen(false);
  };
  const fetchPseudo = async () => {
    const body = {
      projectId: state.projectId,
      type: "approach",
      userId: state.userId,
      orgId: state.orgId,
    };

    try {
      document.getElementById('pageLoader').style.display = "block";
      const res = await fetchPseudocodeData(body, state.jwt);
      console.log(res, "ApproachDiagram")
      if (res.statusCode === 403) {
        sessionExpired();
      } else if (res.statusCode !== 200) {
        // document.getElementById('openErrorModal').click();
      } else {
        console.log("The Response Is 200");
        let afterURL = res?.data[0].approachDiagramAfterURL;
        let beforeURL = res?.data[0].approachDiagramBeforeURL;
        console.log(afterURL, beforeURL, 'fetchJsonFromBlobfetchJsonFromBlob');
        try {
          const afterDiagramResponse = await fetchJsonFromBlob(afterURL);
          const beforeDiagramResponse = await fetchJsonFromBlob(beforeURL);

          console.log(afterDiagramResponse, "Data from after blob");
          console.log(beforeDiagramResponse, "Data from Before blob");

          // Format the fetched content before setting it
          const afterDiagramFormatted = formatPlantUMLWBS(afterDiagramResponse);
          const beforeDiagramFormatted = formatPlantUMLWBS(beforeDiagramResponse);
          console.log(afterDiagramFormatted, "after formated data");
          console.log(beforeDiagramFormatted, "before formated data");
          setAfterDiagramCode(afterDiagramFormatted);
          setBeforeDiagramCode(beforeDiagramFormatted);

          document.getElementById('pageLoader').style.display = "none";
        } catch (e) {
          console.error('Error fetching JSON from blob:', e);
          handleError(e);
        }
      }
    } catch (e) {
      console.error('Error fetching pseudocode data:', e);
      handleError(e);
    }
  }

  const formatPlantUMLWBS = (wbsText) => {
    // Replace escape characters with actual newline characters
    const lines = wbsText.replace(/\\n/g, '\n').split('\n');

    // Filter out empty lines and unnecessary spaces
    const formattedLines = lines
      .filter(line => line.trim().length > 0)
      .map(line => line.trim());

    // Join the lines back into a single formatted string
    return formattedLines.join('\n');
  };




  const fetchJsonFromBlob = async (inventoryDDURL) => {
    try {
      const sasToken = await generateSasTokenAPI();
      let url = `${inventoryDDURL}?${sasToken}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const jsonData = await response.text();
      console.log(jsonData, 'Fetched JSON Data');
      return jsonData;
    } catch (error) {
      console.error('Error fetching JSON from blob:', error);
      throw error;
    }
  };


  const sessionExpired = () => {
    localStorage.clear();
    document.getElementById('toastMessage').style.display = "block";

    setTimeout(() => {
      document.getElementById('toastMessage').style.display = "none";
      navigate('/');
    }, 3000);
  };

  const handleError = (e) => {
    insertError({
      errorMessage: e.message,
      serviceName: "frontend",
      module: "ApproachDiagram",
      functionName: "ApproachDiagram",
      userId: state.userId
    });
    console.log("error", e);
    setError(e.message);
  }


  useEffect(() => {
    const fetchSVGs = async () => {
      const beforeSVG = await generateDiagramWithKroki(beforeDiagramCode);
      console.log('Before SVG:', beforeSVG);  // Log for checking
      const afterSVG = await generateDiagramWithKroki(afterDiagramCode);
      console.log('After SVG:', afterSVG);  // Log for checking
      setBeforeDiagramSVG(beforeSVG);
      setAfterDiagramSVG(afterSVG);
    };

    fetchSVGs();
  }, [beforeDiagramCode, afterDiagramCode]);

  const handleMouseDown = (e, type) => {
    setIsDragging(true);
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e, type) => {
    if (!isDragging) return;

    const dx = e.clientX - dragStart.x;
    const dy = e.clientY - dragStart.y;

    if (type === "before") {
      setTranslateBefore({
        x: translateBefore.x + dx,
        y: translateBefore.y + dy,
      });
    } else if (type === "after") {
      setTranslateAfter({
        x: translateAfter.x + dx,
        y: translateAfter.y + dy,
      });
    }

    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const nav = useNavigate();

  useEffect(() => {
    const fetchSVGs = async () => {
      const beforeSVG = await generateDiagramWithKroki(beforeDiagramCode);
      const afterSVG = await generateDiagramWithKroki(afterDiagramCode);
      setBeforeDiagramSVG(beforeSVG);
      setAfterDiagramSVG(afterSVG);
    };

    fetchSVGs();
  }, [beforeDiagramCode, afterDiagramCode]);


  const handleViewCode = (code) => {
    setModalCode(code);
    setIsModalOpen(true);
  };

  const handleCaptureClick = async () => {
    const downloadImage = async (svgContent, fileName) => {
      const blob = new Blob([svgContent], { type: 'image/svg+xml' });
      saveAs(blob, fileName);
    };

    await downloadImage(beforeDiagramSVG, 'ApproachDiagram_Before.svg');
    await downloadImage(afterDiagramSVG, 'ApproachDiagram_After.svg');
  };

  const generateDiagramWithKroki = async (umlCode) => {
    try {
      const response = await axios.post('https://kroki.io/plantuml/svg', umlCode, {
        headers: { 'Content-Type': 'text/plain' },
      });
      return response.data; // SVG content
    } catch (error) {
      console.error('Error generating diagram with Kroki:', error);
      return '<div>Error generating diagram</div>';
    }
  };

  return (
    <div className="container-fluid px-md-4">
      <div className="row">
        {/* Header Section */}
        <div className="overlay" id="pageLoader" style={{ display: "none" }}>
          <div className="position-absolute top-50 start-50  translate-middle">
            <div className="d-flex align-items-center loader-bg">
              <div
                className="spinner-border Loader text-dark align-center"
                role="status"
              >
                <span className="visually-hidden"></span>
              </div>
              <span className="ms-3 font-18 loader-text mt-2 font-medium">
                Loading...
              </span>
            </div>
          </div>
        </div>
        <HeaderCom value='1' />

        {/* Main Content */}
        <div className="col-md-12 pt-4 mt-5">
          <div className="mt-3 px-2">
            <ul className="cust-breadcrumb mb-0">
              <li className="font-16 font-medium">
                <a >Manage Projects</a>
              </li>
              <li className="font-16 font-medium">
                <a >{state.projectName}</a>
              </li>
              <li className="font-16 font-medium active">Approach Diagram</li>
            </ul>
          </div>
          <div className="d-flex align-items-center justify-content-between p-4 pb-3">
            <div className="d-flex align-items-center">
              <a className="d-flex">
                <img src="images/back-arrow-new.svg" alt="back-arrow" className="me-3 mb-2" style={{ cursor: 'pointer' }} onClick={() => { props.setShowCode(); props.isEdit(false) }} />
              </a>
              <h2 className="text-black font-bold font-22 mb-0">
                Approach Diagram
                <button
                  type="button"
                  className="btn cust-secondary-btn info-icon-btn font-14 font-medium ms-3"
                  onClick={handleOpenModal}
                >
                  <img src="images/info-icon-ETL.svg" className="" alt="info" />
                </button>
              </h2>
              {issModalOpen && <ViewInstructions notebooktype='approachDiagram' onClose={handleCloseModal} />}

            </div>
            <div className="d-flex">
              <button
                type="button"
                className="btn cust-secondary-btn font-14 font-regular"
                onClick={handleCaptureClick}
              >
                <span className="me-2">
                  <img src="images/download-icon.svg" alt="download-icon" />
                </span>
                Download
              </button>
            </div>
          </div>
          {/* Diagram Sections */}
          <div className="row">
            {/* Before Diagram */}
            <>
              <div className="col-md-6 col-lg-6 col-sm-12 pt-2 mb-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h2 className="text-black font-bold font-22 mb-0">Before</h2>
                  <button
                    onClick={() => handleViewCode(beforeDiagramCode)}
                    className="font-14 font-medium text-decoration-none btn btn-link"
                  >
                    View Code
                  </button>
                </div>
                <div>
                  <div className="position-relative">
                    <div className="position-absolute custom-btn-position bg-white rounded-3">
                      <button type="button" className="btn btn-common-style btn-zoom-in custom-bottom-brd" onClick={handleZoomInBefore}>
                        <img className="" src="images/zoomin-icon.svg" alt="zoom-in" />
                      </button>
                      <button type="button" className="btn btn-common-style btn-zoom-out" onClick={handleZoomOutBefore}>
                        <img className="" src="images/zoomout-icon.svg" alt="zoom-out" />
                      </button>
                    </div>
                    <div className="custom-inventory-textarea logger-textarea-scroll" id="uml-image">
                      <div style={{ transform: `scale(${zoomBefore})`, transformOrigin: 'top left' }}>
                        {beforeDiagramCode && (
                          <img
                            src={`data:image/svg+xml;base64,${btoa(beforeDiagramSVG)}`}
                            alt="Before Diagram"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>

            {/* After Diagram */}
            <div className="col-md-6 col-lg-6 col-sm-12 pt-2 mb-3">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h2 className="text-black font-bold font-22 mb-0">After</h2>
                <button
                  onClick={() => handleViewCode(afterDiagramCode)}
                  className="font-14 font-medium text-decoration-none btn btn-link"
                >
                  View Code
                </button>
              </div>

              <>
                <div className="position-relative">
                  <div className="position-absolute custom-btn-position bg-white rounded-3">
                    <button type="button" className="btn btn-common-style btn-zoom-in custom-bottom-brd" onClick={handleZoomInAfter}>
                      <img className="" src="images/zoomin-icon.svg" alt="zoom-in" />
                    </button>
                    <button type="button" className="btn btn-common-style btn-zoom-out" onClick={handleZoomOutAfter}>
                      <img className="" src="images/zoomout-icon.svg" alt="zoom-out" />
                    </button>
                  </div>
                  <div className="custom-inventory-textarea logger-textarea-scroll" id="uml-image">
                    <div style={{ transform: `scale(${zoomAfter})`, transformOrigin: 'top left' }}>
                      {afterDiagramCode && (
                        <img
                          src={`data:image/svg+xml;base64,${btoa(afterDiagramSVG)}`}
                          alt="After Diagram"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>

      {/* Code Snippet Modal */}
      {isModalOpen && (
        <CodeSnippetModal code={modalCode} onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );

};

export default ApproachDiagram;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {fetchUserDetail} from '../../Service/Api'
import { HeaderCom } from '../Header';

// Define initial state values
const initialScripts = []; // Initial value for the script state

const ScriptComponent = () => {
    const navigate = useNavigate();
    const [scripts, setScripts] = useState(initialScripts);
    const [isLoading, setIsLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(localStorage.getItem('selectedGroup') ? JSON.parse(localStorage.getItem('selectedGroup')) : null);
    const [filteredScripts, setFilteredScripts] = useState([]);
    const location = useLocation();
    const { state } = useLocation();
    console.log(state, 'state');
    let stateValue={...state}
    console.log(stateValue,'stateValue')

    useEffect(() => {
        async function fetchData() {
            await fetchScripts(); // Fetch scripts first
            await fetchGroups(); // Then fetch groups
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedGroup) {
            const scriptsForGroup = scripts.filter(scriptObj => scriptObj.groupName === selectedGroup.name); // Assuming each script has a groupName
            setFilteredScripts(scriptsForGroup);
        } else {
            setFilteredScripts(scripts); // Initially bind all scripts
        }
        localStorage.setItem('selectedGroup', JSON.stringify(selectedGroup)); // Store the selected group in local storage
    }, [scripts, selectedGroup]);

    const fetchGroups = async () => {
        const body = {
            projectId: state.projectId,
            generationtype: "FetchGroup",
            userId: state.userId,
            jwt_token:state.jwt
        };

        try {
            document.getElementById('pageLoader').style.display = 'block';
            const res = await fetchUserDetail(body,state.jwt);
            console.log("res", res, "Response from groups");
            console.log("res", typeof res, "Response from groups");

            if (Array.isArray(res.groups)) {
                console.log(res.groups, 'Groups - already an array');
                setGroups(res.groups);
            } else {
                console.error('Unexpected format for groups:', res.groups);
                setGroups([]);
            }
            document.getElementById('pageLoader').style.display = 'none';

        } catch (error) {
            console.error("An error occurred while fetching groups:", error);
            setGroups([]);
        }
    };

    const fetchScripts = async () => {
        const body = {
            projectId: state.projectId,
            generationtype: "FetchScript",
            userId: state.userId,
            jwt_token:state.jwt
        }

        try {
            document.getElementById('pageLoader').style.display = 'block';
            setIsLoading(true); // Show loading indicator
            const res = await fetchUserDetail(body,state.jwt);
            // setIsLoading(false); // Hide loading indicator

            console.log("res", res, "Response from Scripts");

            if (Array.isArray(res)) {
                console.log(res, 'Scripts - already an array');
                setScripts(res);
                setFilteredScripts(res); // Initially set all scripts
            } else if (typeof res === 'object' && res !== null) {
                console.log(res, 'Response is an object');
                setScripts(res.scripts); // Extract scripts from the object
                setFilteredScripts(res.scripts); // Initially set all scripts
            } else {
                console.error('Unexpected response format:', res);
                setScripts([]); // Reset state if neither condition is met
                setFilteredScripts([]); // Reset filtered scripts as well
            }
            document.getElementById('pageLoader').style.display = 'none';

        } catch (error) {
            console.error("An error occurred while fetching Scripts:", error);
            setScripts([]);
            setFilteredScripts([]); // Reset filtered scripts in case of error
            // setIsLoading(false); // Hide loading indicator
        }
    };

    const handleGroupSelect = (group) => {
        setSelectedGroup(group);
        const scriptsForGroup = scripts.filter(scriptObj => scriptObj.groupName === group.name); // Assuming each script has a groupName
        setFilteredScripts(scriptsForGroup);
    };

    const sessionExpired = () => {
        localStorage.clear();
        document.getElementById('toastMessage').style.display = "block";

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none";
            navigate('/');
        }, 3000);
    };

    const handleCopyClick = () => {
        try {
            const concatenatedScripts = filteredScripts?.map(scriptObj => scriptObj.scripts).join("\n");
            navigator.clipboard.writeText(concatenatedScripts);
            setIsCopied(true);
            setTimeout(() => { setIsCopied(false) }, 2000);
        } catch (err) {
            console.error('Error while copying to clipboard:', err);
        }
    };

    const handleDownload = () => {
        try {
            const concatenatedScripts = filteredScripts?.map(scriptObj => scriptObj.scripts).join("\n");
            const blob = new Blob([concatenatedScripts], { type: 'text/plain' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'script.sql';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (err) {
            console.error('Error while downloading:', err);
        }
    };

    return (
        <div className="container-fluid">
            <div className="overlay" id="pageLoader" style={{ display: "none" }}>
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="d-flex align-items-center loader-bg">
                        <div className="spinner-border Loader text-dark align-center " role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <span className="ms-3 font-18 loader-text mt-2 font-medium">Loading...</span>
                    </div>
                </div>
            </div>
            <div className="row">
              <HeaderCom value='1' />
                <div className="col-md-12 pt-4 mt-5">
                    <div className="mt-3">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-12 font-medium"><a >Manage Projects</a></li>
                            <li className="font-12 font-medium active">{state.projectName}</li>
                        </ul>
                    </div>

                    <div className="row p-4">
                        <div className="d-flex  mb-4 justify-content-between align-items-center">
                            <div className="d-flex align-items-center mb-xl-0 ">
                                <a  className="d-flex"><img src="images/back-arrow.svg" alt="back-arrow" onClick={() => navigate('/GroupList', { state: stateValue })}/></a>
                                <h2 className="text-black font-bold font-22 mb-0 ms-3">{state.projectName}</h2>
                            </div>
                            <div className="d-lg-flex d-md-block">
                                <div className="d-flex mb-md-6 mb-sm-6">
                                    <div className="cust-input-sty font-11 rounded-2 font-medium text-grey-v7 px-3 me-3 d-flex align-items-center">
                                        <span className="text-nowrap">Organization :</span>
                                        <span><img src={state.orgLogo} alt="hubspot-logo"  className="figma-logo"/></span>
                                        <span className="me-2 text-nowrap font-medium font-12 text-black">{state.orgName}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="code-review-tab-width">
                            <div className="code-review-page tabs nav">
                            <div className="tab nav-link font-bold" data-bs-toggle="pill" data-bs-target="#project-info">
                                <a
                                        onClick={() => {
                                            navigate("/ProjectInfo", {
                                                state: {  ...stateValue.stateValue },
                                            });
                                        }}
                                    >
                                    Project Info
                                    </a>
                                    </div>
                                <div className="tab nav-link font-bold" data-bs-toggle="pill" data-bs-target="#ask-gen" onClick={() => {
                                            navigate("/uc-chat", {
                                                state: stateValue, // Corrected the syntax here
                                            });
                                        }}>
                                <a
                                        
                                    >
                                        Ask GenETL
                                    </a>
                                </div>
                            <div className="tab nav-link font-bold active" data-bs-toggle="pill" data-bs-target="#acc-control">Access Control</div>
                            </div>
                        </div>
                        <div className="tab-content">
                            <div id="acc-control" className="show active">
                                <div className="row mt-5">
                                    <div className="col-md-2">
                                        <div className="tab-container">
                                            <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow">
                                                <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/User', { state: stateValue })}>
                                                    <span>User Details</span>
                                                </li>
                                                <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/Roles', { state: stateValue })}>
                                                    <span>Roles</span>
                                                </li>
                                                <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/GroupList', { state: stateValue })}>
                                                    <span>Groups</span>
                                                </li>
                                                <li className="side-nav-item d-flex align-items-center active" onClick={() => navigate('/Script', { state: stateValue })}>
                                                    <span>Scripts</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <div className="d-flex align-items-center">
                                                {/* <a  className="d-flex"><img src="images/back-arrow.svg" alt="back-arrow" className="arrow-icon" /></a> */}
                                                <h2 className="text-black font-bold font-20 mb-0 ms-3" >Scripts</h2>
                                            </div>
                                            <div className="d-flex gap-3">
                                                <div className="dropdown">
                                                    <a
                                                        className="btn custom-dropdown-toggle custom-dropdown-selecttoggle form-control cust-input-sty font-14 font-regular d-flex align-items-center custum-width-230"
                                                        
                                                        role="button"
                                                        id="clientBusiness"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                        style={{ zIndex: 1001 }}
                                                    >
                                                        {selectedGroup ? selectedGroup.name : 'Select Group'}
                                                    </a>
                                                    <ul className="dropdown-menu w-100 cust-drop-menu">
                                                        {groups?.map((group) => (
                                                            <li key={group.name}>
                                                                <a
                                                                    className="dropdown-item font-12"
                                                                    
                                                                    onClick={() => handleGroupSelect(group)}
                                                                >
                                                                    {group.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                <button type="button" className="btn cust-secondary-btn" onClick={handleDownload}>
                                                    <label className="font-14 font-regular">
                                                        <span className="text-nowrap">
                                                            <img src="images/download-icon.svg" alt="Import" className="me-2" />Download
                                                        </span>
                                                    </label>
                                                </button>
                                                <button type="button" onClick={handleCopyClick} className={`btn cust-primary-btn font-14 font-medium w-auto px-3 ${isCopied ? 'copied' : ''}`}>
                                                    <img src="images/copy-code-icon.svg" alt="copy" className="me-2" />
                                                    {isCopied ? 'Copied' : 'Copy Code'}
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <textarea
                                                    className="form-control custom-textarea logger-textarea-scroll add-code-snippet-1"
                                                    id="scriptTextarea"
                                                    value={filteredScripts?.map(scriptObj => scriptObj.scripts).join("\n")}
                                                    readOnly
                                                ></textarea>

                                            </div>
                                            {/* {isLoading && <p>Loading...</p>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ScriptComponent;
// -=-=-=-=-=-=-=-1234567890
import React, { useState, useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { generateAccessControl } from '../../Service/Api';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { HeaderCom } from '../Header';

// import { X } from 'lucide-react';

const Groups = () => {
    const navigate = useNavigate();
    const [groups, setGroups] = useState([]);
    const[Tab,setTab]=useState("")
    const [newGroup, setNewGroup] = useState({ name: '', role: '' });
    const [leftUsers, setLeftUsers] = useState([]);
    const [rightUsers, setRightUsers] = useState([]);
    const[gridData,setGridData]=useState([])
    const [selectedLeftUsers, setSelectedLeftUsers] = useState([]);
    const [selectedRightUsers, setSelectedRightUsers] = useState([]);
    const [activeTab, setActiveTab] = useState('user');
    const [catalogData, setCatalogData] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [isFormValid, setIsFormValid] = useState(true);
    const [validationErrors, setValidationErrors] = useState([]);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [enable, setEnable] = useState(true)
    const [userDetails, setUserDetails] = useState([{ name: '', email: '' }]);    
    const [jsonData,setJsonData]=useState([])
    const [deleteUsers, setDeleteUsers] = useState(false);
    const [roles, setRoles] = useState([]);
    
    const location=useLocation()
    // const nav = useNavigate()

    const {state} = useLocation()
console.log(state,'stateeeeeee')
const stateValue = { ...state };
console.log(stateValue,'stateValue')
const refreshGridData = async () => {
    try {

        // await fetchDa()
        await fetchGroups();
        await fetchUsers();
        // await fetchCatalogData();
    } catch (error) {
        console.error("An error occurred while refreshing grid data:", error);
    }
};

// Trigger on mount or re-renders
useEffect(() => {
    
    const initializeData = async () => {
        await fetchGroups();
        await fetchUsers();
        await fetchRoles();
        propdata();
    };

    initializeData();
}, []);



    useEffect(() => {
        console.log('Updated catalogData:', catalogData);
        console.log('Updated gridData:', gridData)
        console.log('Updated userDetails:', userDetails)
        console.log('Updated userDetails:', userDetails)
        console.log('updated roles:', roles)
        console.log('updated leftUser:',leftUsers)
        console.log('updated leftUser:',rightUsers)

    }, [catalogData,gridData,userDetails,roles,leftUsers,rightUsers]);

    useEffect(() => {
        if (state && state.groupDetails) {
            if (state.groupDetails.isEdit) {
                console.log(state,'stateteetetetetet');
                const data = state.groupDetails;
                console.log(data);
                setCatalogData(data); // Assuming resource is part of group details
                setGridData(data.users); 
                setUserDetails(data.users)// Assuming users is part of group details
                setNewGroup({ name: data.name, role: data.role }); // Corrected to role instead of email
            } 
        }
        else {
            fetchCatalogData(); // Fetch catalog data if not in edit mode
        }
    }, [state]); // 
    

    
     const fetchRoles = async () => {
            const body = {
                projectId: state.stateValue.projectId,
                generationtype: "FetchRoles",
                jwt_token: state.stateValue.jwt,
                userId: state.stateValue.userId
            };
            document.getElementById('pageLoader').style.display = 'block';
    
            const data = await generateAccessControl(body, state.stateValue.jwt);
            console.log(data,'dataRoles')
            setRoles(data.roles.map(role => ({...role,privilege: Array.isArray(role.privilege) ? role.privilege.join(', ') : role.privilege,
            })));
            document.getElementById('pageLoader').style.display = 'none';
        };

    const sessionExpired = () => {
        localStorage.clear()
        document.getElementById('toastMessage').style.display = "block"

        // Navigate to Login page after 3 seconds
        setTimeout(() => {
            document.getElementById('toastMessage').style.display = "none"
            navigate('/');
        }, 3000);
    };

    const navigateToGroup = () => {
  
        const stateValue ={...state}
          // Navigate to /Group with state containing group details
          navigate(`/Group`, {
              state: {
                  stateValue: stateValue, // Use optional chaining to avoid errors if 'state' is undefined
                 type:"New" // Include groupDetails directly
              }
          });
        };
        
    
    const fetchGroups = async () => {
        const body = {
            projectId: state.stateValue.projectId,
            generationtype: "FetchGroup",
            userId: state.stateValue.userId,
            jwt_token:state.stateValue.jwt
        };
    
        try {
            document.getElementById('pageLoader').style.display = 'block';
          const res = await generateAccessControl(body,state.stateValue.jwt);
          console.log("res", res, "Response from groupss");
          console.log("res", typeof(res), "Response from groupss");
    
          if (Array.isArray(res.groups)) {
            console.log(res.groups, 'Groups - already an array');
            setGroups(res.groups);
            // tableData(sortedGroups)
          } else {
            console.error('Unexpected format for groups:', res.groups);
            setGroups([]);
          }
        } catch (error) {
          console.error("An error occurred while fetching groups:", error);
          setGroups([]);
        }
        document.getElementById('pageLoader').style.display = 'none';

    };
    const propdata=()=>
    {

        const tab1=location.state?.activeTab
        setTab(tab1)
        setNewGroup({ name: location.state?.name, role: location.state?.role })
    }

    const fetchUsers = async() => {
        console.log('triggered')
        
        const body = {
            projectId: state.stateValue.projectId,
            generationtype: "FetchUsers",
            userId: state.stateValue.userId,
            jwt_token:state.stateValue.jwt
        };
    
        try {
            document.getElementById('pageLoader').style.display = 'block';
          const res = await generateAccessControl(body,state.stateValue.jwt);
          console.log("res", res, "Response from generateAccessControl");
          console.log("res", typeof(res), "Response from generateAccessControl");
    
          if (Array.isArray(res)) {
            console.log(res, 'Groups - already an array');
            setLeftUsers(res);
        } else if (typeof res === 'object' && res !== null) {
            console.log(res, 'Response is an object');
            // Assuming you want to extract users from the object
            // Modify this according to your actual object structure
            if (res && Array.isArray(res)) {
                setLeftUsers(res); // Set left users from the object property
            } else {
                console.error('Unexpected format for users:', res);
                setLeftUsers([]); // Optionally reset if the format is not as expected
            }
        } else {
            console.error('Unexpected response format:', res);
            setLeftUsers([]); // Reset state if neither condition is met
        }
        
        } catch (error) {
          console.error("An error occurred while fetching groups:", error);
          setLeftUsers([]);
        }
        document.getElementById('pageLoader').style.display = 'none';

    };

    const fetchCatalogData = async () => {
        const body = {
            projectId: state.stateValue.projectId,
            generationtype: "FetchResource",
            userId: state.stateValue.userId,
            jwt_token:state.stateValue.jwt
        };
    
        try {
            document.getElementById('pageLoader').style.display = 'block';
            const res = await generateAccessControl(body,state.stateValue.jwt);
            console.log("Response from generateAccessControl:", res);
    
            if (res === null) {
                console.error('Received null response from generateAccessControl');
                setCatalogData([]); // Reset state if response is null
                return;
            }
    
            if (Array.isArray(res)) {
                console.log('Catalog - already an array:', res);
                setCatalogData(res);
            } else if (typeof res === 'object' && res !== null) {
                console.log('Response is an object:', res);
               
                    setCatalogData(res); // Set catalog data from the resource property
                
            } else {
                console.error('Unexpected response format:', res);
                setCatalogData([]); // Reset state if neither condition is met
            }
            
        } catch (error) {
            console.error("An error occurred while fetching groups:", error);
            setCatalogData([]);
        }
        document.getElementById('pageLoader').style.display = 'none';
    };
    


    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        setSelectedFileName(file.name);
    
        try {
            const data = await readExcelFile(file);
            const { valid, errors } = validateExcelData(data);
    
            if (!valid) {
                setValidationErrors(errors);
                setIsFormValid(false);
                return;
            }
    
            // setUserDetails(data);
            setIsFormValid(true);
            setValidationErrors([]);
            setFileUploaded(true);
        } catch (error) {
            console.error("Error reading excel file:", error);
            setValidationErrors([`Error reading file: ${error.message}`]);
            setIsFormValid(false);
        }
    };
    
    const readExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet);
                    console.log(jsonData,'jsosososos')
                    setJsonData(jsonData)
                    resolve(jsonData);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => reject(error);
            reader.readAsBinaryString(file);
        });
    };
    
    const validateExcelData = (data) => {
        const errors = [];
        const isValid = data.every((row, index) => {
            if (!row.Username || !row.Email) {
                errors.push(`Row ${index + 2}: Username and Email are required`);
                return false;
            }
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(row.Email)) {
                errors.push(`Row ${index + 2}: Invalid email format`);
                return false;
            }
            return true;
        });
        return { valid: isValid, errors };
    };
    
    const exportTemplate = () => {
        const ws = XLSX.utils.json_to_sheet([
            { Username: 'JohnDoe', Email: 'john.doe@example.com' },
            { Username: 'JaneSmith', Email: 'jane.smith@example.com' }
        ]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "UserDetails");
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, 'UserDetailsTemplate.xlsx');
    };
    
    const handleSubmit = async () => {
        if (!isFormValid) return;
    
        try {
            // Show loading indicator
            // document.getElementById('pageLoader').style.display = 'block';
    
            // Simulate API call
            await new Promise(resolve => setTimeout(resolve, 1000));
    
            // Hide loading indicator
            // document.getElementById('pageLoader').style.display = 'none';
    
            // Check if there are any users to add
            if (jsonData.length > 0) {
                const updatedUserDetails = jsonData.map(user => ({
                    name: user.Username || '',
                    email: user.Email || ''
                }));
    
                // Append new user details to existing userDetails
                setUserDetails(prevUserDetails => [
                    ...prevUserDetails,
                    ...updatedUserDetails
                ]);
          setGridData(updatedUserDetails)
                console.log(updatedUserDetails, 'Updated User Details');
                setJsonData([]);
                selectedFileName('') // Clear jsonData after processing
            } else {
                console.warn("No users selected to add.");
            }
        } catch (error) {
            console.error("Error submitting user details:", error);
        }
    };
    
    
    const handleAddGroup = async () => {
        if ((newGroup.name || catalogData.name) && (newGroup.role || catalogData.role)) {
            const combinedUserDetails = [
                ...userDetails,
                ...gridData.filter(user => !userDetails.some(existingUser => existingUser.email === user.email))
            ];
    
            const groupData = {
                name: newGroup.name || catalogData.name,
                users: combinedUserDetails, // Use combined user details
                role: newGroup.role || catalogData.role,
                resource: catalogData.resource // Include the updated catalog data
            };
    
            console.log(groupData, 'groupData');
    
            // Check if the group already exists
            const existingGroupIndex = groups.findIndex(group => group.name === (newGroup.name || catalogData.name));
    
            let updatedGroups;
    
            if (existingGroupIndex !== -1) {
                const existingGroup = groups[existingGroupIndex];
                const mergedUsers = [
                    ...existingGroup.users,
                    ...combinedUserDetails.filter(user => !existingGroup.users.some(existingUser => existingUser.email === user.email))
                ];
    
                const updatedGroup = {
                    ...existingGroup,
                    users: mergedUsers,
                    resource: groupData.resource
                };
    
                updatedGroups = [
                    ...groups.slice(0, existingGroupIndex),
                    updatedGroup,
                    ...groups.slice(existingGroupIndex + 1)
                ];
            } else {
                updatedGroups = [...groups, groupData];
            }
    
            const body = {
                projectId: state.stateValue.projectId,
                generationtype: "UpdateGroup",
                userId: state.stateValue.userId,
                groups: updatedGroups,
                jwt_token: state.stateValue.jwt
            };
    
            console.log(body, 'body');
    
            try {
                const res = await generateAccessControl(body, state.stateValue.jwt);
                console.log("res", res, "Response from generateAccessControl");
    
                if (res && typeof res === 'object') {
                    if (Array.isArray(res.groups)) {
                        setGroups(res.groups);
                        refreshGridData();
                    } else if (res.message) {
                        console.log(res.message);
                    } else {
                        console.error('Unexpected format for users:', res);
                        setGroups([]);
                    }
                } else {
                    console.error('Unexpected response:', res);
                    setGroups([]);
                }
            } catch (error) {
                console.error("An error occurred while updating groups:", error);
                setGroups([]);
            }
    
            setNewGroup({ name: '', role: '' });
            setCatalogData([]);
            setRightUsers([]);
        } else {
            console.log(groups, 'groups');
            console.log(userDetails, 'userDetails');
            console.log(catalogData, 'catalogData');
            
            const editedData = {
                name: state.groupDetails.name,
                users: userDetails,
                role: state.groupDetails.role,
                resource: catalogData.resource
            };
    
            const groupIndex = groups.findIndex(group => group.name === state.groupDetails.name);
    
            if (groupIndex !== -1) {
                const updatedGroups = [
                    ...groups.slice(0, groupIndex),
                    editedData,
                    ...groups.slice(groupIndex + 1)
                ];
    
                const body = {
                    projectId: state.stateValue.projectId,
                    generationtype: "UpdateGroup",
                    userId: state.stateValue.userId,
                    groups: updatedGroups,
                    jwt_token: state.stateValue.jwt
                };
    
                try {
                    const res = await generateAccessControl(body, state.stateValue.jwt);
                    console.log("res", res, "Response from generateAccessControl");
    
                    if (res && typeof res === 'object') {
                        if (Array.isArray(res.groups)) {
                            setGroups(res.groups);
                            refreshGridData();
                        } else if (res.message) {
                            console.log(res.message);
                        } else {
                            console.error('Unexpected format for users:', res);
                            setGroups([]);
                        }
                    } else {
                        console.error('Unexpected response:', res);
                        setGroups([]);
                    }
                } catch (error) {
                    console.error("An error occurred while updating groups:", error);
                    setGroups([]);
                }
    
                setNewGroup({ name: '', role: '' });
                setRightUsers([]);
            }
        }
        const stateValue = { ...state };
        console.log(stateValue, 'stateValue');
        navigate(`/GroupList`, { state: stateValue.stateValue });
    };
    
    
    const handleAddUsers = async () => {
        if (rightUsers.length > 0) { // Check if there are any users to add
            // Update userDetails with names and emails from rightUsers
            const updatedUserDetails = rightUsers.map(user => ({
                name: user.Username,  // Assuming 'name' is the correct property
                email: user.Email  // Assuming 'email' is the correct property
            }));
            console.log(rightUsers,"after the add ");
            console.log(leftUsers,"after the add left");
            
            setUserDetails(updatedUserDetails); // Set userDetails with mapped names and emails
            setGridData(prevGridData => [...prevGridData, ...updatedUserDetails]);    
             console.log(updatedUserDetails, 'Updated User Details'); // Log for confirmation
               
        } else {
            console.warn("No users selected to add."); // Warn if no users are selected
        }
    };
    
    
    
   
    const moveToRight = () => {
        console.log(leftUsers);
        setRightUsers([...rightUsers, ...selectedLeftUsers]);
        setLeftUsers(leftUsers.filter(user => !selectedLeftUsers.includes(user)));
        setSelectedLeftUsers([]);
    };

    const moveUserToRight = (user) => {
        setRightUsers(prevRightUsers => [...prevRightUsers, user]);
        setLeftUsers(prevLeftUsers => prevLeftUsers.filter(u => u !== user));
    };

    const moveAllToLeft = () => {
        setLeftUsers([...leftUsers, ...rightUsers]);
        setRightUsers([]);
    };

    const toggleLeftSelection = (user) => {
        setSelectedLeftUsers(prev =>
            prev.includes(user) ? prev.filter(u => u !== user) : [...prev, user]
        );
    };
    const moveOneToLeft = (user) => {
        debugger
        console.log(user,'userrr')
        setLeftUsers([...leftUsers, user]);
        setRightUsers(rightUsers.filter(u => u !== user));
        setGridData(gridData.filter(u => u !== user))
        console.log(leftUsers,'leftUsers')

    };

    
const getFilteredLeftUsers = () => {
    console.log(leftUsers,'leftUsersleftUsers');
    
    let leftUpdatedUser =  leftUsers.filter(leftUser => 
        !gridData.some(gridUser => 
            gridUser.name === leftUser.Username && gridUser.email === leftUser.Email
        )
    );

    console.log(leftUpdatedUser,"left updeted fffffffffffffffffffff");

    setLeftUsers(leftUpdatedUser)
    setRightUsers([])
};
    
    
    const deleteUser = (indexToDelete,user) => {
        // Create a copy of the gridData array and remove the user at the given index
        console.log(user,":::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::");
        
        const updatedGridData = [...gridData];
        updatedGridData.splice(indexToDelete, 1);
        setLeftUsers([...leftUsers, {"Username":user.name , "Email":user.email} ])
        setRightUsers(rightUsers.filter(u => u.Email !== user.email));
        // Create a copy of the userDetails array and remove the user at the given index
        const updatedUserDetails = [...userDetails];
        updatedUserDetails.splice(indexToDelete, 1);
    
        console.log(updatedUserDetails, 'updatedUserDetails');
    
        // Update groups data
        const updatedGroups = groups.map(group => {
            if (group.name === newGroup.name) {
                const updatedUsers = [...group.users];
                updatedUsers.splice(indexToDelete, 1);
                return {
                    ...group,
                    users: updatedUsers.filter(user => user.name && user.email) // Filter out empty records
                };
            }
            return group;
        });
    
        console.log(updatedGroups, 'updatedGroups');
    
        // Update states after removing the user
        setGridData(updatedGridData);
        setUserDetails(updatedUserDetails);
        setGroups(updatedGroups);
    };
    // Modify the initial state setup based on isEdit
 
    
 // Handler functions
 
    // Catalog handlers for selection
    const handleCatalogSelect = (resourceIndex, catalogName) => {
        const updatedData = catalogData.resource.map((catalog, index) => {
            if (index === resourceIndex) {
                const updatedCatalog = { ...catalog };
                // Check if the catalog itself has a status property
                const currentStatus = updatedCatalog.status || 'unSelected';
                const catalogStatus = currentStatus === 'selected' ? 'unSelected' : 'selected';
                
                // Update the catalog's status
                updatedCatalog.status = catalogStatus;
                
                // Update all child schemas and tables
                if (Array.isArray(updatedCatalog[catalogName])) {
                    updatedCatalog[catalogName] = updatedCatalog[catalogName].map(schema => {
                        const updatedSchema = { ...schema };
                        updatedSchema.status = catalogStatus;
                        
                        // Update all tables within the schema
                        Object.keys(updatedSchema).forEach(key => {
                            if (key !== 'status' && Array.isArray(updatedSchema[key])) {
                                updatedSchema[key] = updatedSchema[key].map(table => {
                                    const tableName = Object.keys(table)[0];
                                    return { [tableName]: catalogStatus };
                                });
                            }
                        });
                        
                        return updatedSchema;
                    });
                }
    
                return updatedCatalog;
            }
            return catalog;
        });
    
        setCatalogData({ resource: updatedData });
    };
    const handleSchemaSelect = (resourceIndex, catalogName, schemaIndex, schemaName) => {
        const updatedData = [...catalogData.resource];
        const schema = updatedData[resourceIndex][catalogName][schemaIndex];
        const schemaStatus = schema.status === 'selected' ? 'unSelected' : 'selected';
        
        schema.status = schemaStatus;
        schema[schemaName] = schema[schemaName].map(table => {
            const tableName = Object.keys(table)[0];
            return { [tableName]: schemaStatus };
        });

        setCatalogData({ resource: updatedData });
    };

    const handleTableSelect = (resourceIndex, catalogName, schemaIndex, schemaName, tableIndex, tableName) => {
        const updatedData = [...catalogData.resource];
        const tableStatus = updatedData[resourceIndex][catalogName][schemaIndex][schemaName][tableIndex][tableName] === 'selected' ? 'unSelected' : 'selected';
        
        updatedData[resourceIndex][catalogName][schemaIndex][schemaName][tableIndex][tableName] = tableStatus;

        setCatalogData({ resource: updatedData });
    };

    const isButtonEnabled = () => {
        debugger
        const nameValid = !!newGroup.name || !!catalogData.name; // Check if name is non-empty
        const roleValid = !!newGroup.role || !!catalogData.role; // Check if role is non-empty
        const usersValid = userDetails.length > 0; // Check if there are any users
    
        // Check if at least one catalog is selected
        const catalogValid = catalogData.resource && 
                             catalogData.resource.length > 0 && 
                             catalogData.resource.some(resource => resource.status === 'selected');
    
        console.log('Validation Results:');
        console.log('Name Valid:', nameValid);
        console.log('Role Valid:', roleValid);
        console.log('Users Valid:', usersValid);
        console.log('Catalog Valid:', catalogValid);
    
        // Enable the button if all conditions are met
        return nameValid && roleValid && usersValid && catalogValid;
    };
    
    
    
    return (
        <div className="container-fluid">
                   <div class="overlay" id="pageLoader" style={{ display: "none" }}>
                <div class="position-absolute top-50 start-50  translate-middle">
                    <div class="d-flex align-items-center loader-bg">
                        <div
                            class="spinner-border Loader text-dark align-center "
                            role="status"
                        >
                            <span class="visually-hidden"></span>
                        </div>
                        <span class="ms-3 font-18 loader-text mt-2 font-medium">
                            Loading...
                        </span>
                    </div>
                </div>
            </div>
            <HeaderCom value='1' />
            <div className="col-md-12 pt-4 mt-5">
                 {/* Breadcrumbs starts here */}
                 <div className="mt-3 px-2">
                        <ul className="cust-breadcrumb mb-0 ">
                            <li className="font-16 font-medium"><a >Manage Projects</a></li>
                            <li className="font-16 font-medium"><a>{state.stateValue.projectName}</a></li>
                            {/* <li className="font-16 font-medium active">Groups</li> */}
                        </ul>
                    </div>
                    {/* Breadcrumbs ends here */}
            
                <div className="modal" id="importUserModal" tabIndex="-1" aria-labelledby="importUserModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="importUserModalLabel">Import Users</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body py-0">
                                <div className="row mt-3">
                                    <div className="col-md-12 col-sm-12 mb-3">
                                        <label htmlFor="file-upload" className="form-label text-black font-14 font-medium">Upload User Details Documentations</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="mb-5">
                                            <span className="upload-btn-wrapper d-block">
                                                {!fileUploaded ? (
                                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                        <img src="images/upload-icon.svg" alt="browse" className="mb-2" />
                                                        <span className="font-medium font-13 d-block text-grey mb-2">Browse or drag and drop file</span>
                                                        <span className="font-medium font-12 helper-txt d-block text-center position-relative text-grey-v4">
                                                            Use this template to add info -{" "}
                                                            <a onClick={exportTemplate} className="primary-color font-medium cursor-pointer text-decoration-none down-templ-sty">
                                                                Download Template
                                                            </a>
                                                        </span>
                                                    </button>
                                                ) : (
                                                    <button type="button" className="upload-btn-sty shadow-none cursor-pointer text-center">
                                                        <img src="images/sample-logo.svg" alt="sample-logo" className="mb-2" />
                                                        <a className="font-medium font-12 d-block">{selectedFileName}</a>
                                                        <a
                                                            style={{ position: "relative", zIndex: "100" }}
                                                            onClick={() => {
                                                                setValidationErrors([]);
                                                                setIsFormValid(false);
                                                                setFileUploaded(false);
                                                                document.getElementById('definedocumentid').value = '';
                                                            }}
                                                        >
                                                            <span className="ms-2">
                                                                <img src="images/link-close-icon.svg" alt="link-close-icon" />
                                                            </span>
                                                        </a>
                                                    </button>
                                                )}
                                                <input
                                                    type="file"
                                                    name="myfile"
                                                    className="cursor-pointer cust-file"
                                                    accept=".xlsx"
                                                    id="definedocumentid"
                                                    onChange={(event) => {
                                                        handleFileUpload(event);
                                                        setEnable(false);
                                                    }}
                                                    title={selectedFileName}
                                                />
                                            </span>
                                            {validationErrors.length > 0 && (
                                                <div className="code-doc-error">
                                                    {validationErrors.map((data, index) => (
                                                        <span className="d-flex align-items-center flex-wrap font-12 font-regular field-missing-section" key={index}>
                                                            <img src="images/warning-red-icon.svg" alt="Warning icon" />
                                                            <span className="ms-2">{data}</span>
                                                        </span>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleSubmit} disabled={!isFormValid}>Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="d-flex mb-4 justify-content-between align-items-center">
                        <div className="d-flex align-items-center mb-xl-0">
                        <a  className="d-flex"><img src="images/back-arrow.svg" alt="back-arrow" onClick={() => navigate('/GroupList', { state: stateValue.stateValue })}/></a>
                            <h2 className="text-black font-bold font-22 mb-0 ms-3">{state.stateValue.projectName}</h2>
                        </div>
                        <div className="d-lg-flex d-md-block">
                                <div className="d-flex mb-md-6 mb-sm-6">
                                    <div className="cust-input-sty font-11 rounded-2 font-medium text-grey-v7 px-3 me-3 d-flex align-items-center">
                                        <span className="text-nowrap">Organization :</span>
                                        <span><img src={state.stateValue.orgLogo} alt="hubspot-logo"  className="figma-logo"/></span>
                                        <span className="me-2 text-nowrap font-medium font-12 text-black">{state.stateValue.orgName}</span>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="code-review-tab-width">
                        <div className="code-review-page tabs nav">
                        <div className="tab nav-link font-bold" data-bs-toggle="pill" data-bs-target="#project-info">
                                <a
                                        onClick={() => {
                                            navigate("/ProjectInfo", {
                                                state: {  ...stateValue.stateValue },
                                            });
                                        }}
                                    >
                                    Project Info
                                    </a>
                                    </div>
                                <div className="tab nav-link font-bold" data-bs-toggle="pill" data-bs-target="#ask-gen">
                                    <a
                                        onClick={() => {
                                            navigate("/uc-chat", {
                                                state: { ...stateValue.stateValue}, // Corrected the syntax here
                                            });
                                        }}
                                    >
                                        Ask GenETL
                                    </a>
                                </div>
                            <div className="tab nav-link font-bold active" data-bs-toggle="pill" data-bs-target="#acc-control">Access Control</div>
                        </div>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div id="acc-control" className="tab-pane fade show active code-review-tab tab-content">
                            <div className="row mt-5">
                                <div className="col-md-2">
                                    <div className="tab-container">
                                    <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow">
                                            <li className="side-nav-item d-flex align-items-center " onClick={() => navigate('/User', { state:stateValue. stateValue })}>
                                                <span>User Details</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/Roles', { state:stateValue.stateValue })}>
                                                <span>Roles</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center active" onClick={() => navigate('/GroupList', { state: stateValue.stateValue })}>
                                                <span>Groups</span>
                                            </li>
                                            <li className="side-nav-item d-flex align-items-center" onClick={() => navigate('/Script', { state: stateValue.stateValue })}>
                                                    <span>Scripts</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="d-flex align-items-center mb-5">
                                        <a  className="d-flex" >
                                            <img src="images/back-arrow.svg" alt="back-arrow"  onClick={() => navigate('/GroupList', { state: stateValue.stateValue})} />
                                        </a>
                                        <h2 className="text-black font-bold font-20 mb-0 ms-3">{state.type==="New"?"Add New Group":state.groupDetails.name}</h2>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-4 col-sm-12 mb-4">
                                            <label htmlFor="group-name" className="form-label text-black font-14 font-medium">
                                                Group Name <span className="text-red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control cust-input-sty font-12 font-regular"
                                                id="group-name"
                                                placeholder="Group Name"
                                                value={newGroup.name}
                                                onChange={(e) => setNewGroup({ ...newGroup, name: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-12 mb-4">
                                            <label className="form-label text-black font-14 font-medium">
                                                Select Role <span className="text-red">*</span>
                                            </label>
                                            {/* <input
                                                type="text"
                                                value={newGroup.name}
                                                onChange={(e) => setNewGroup({ ...newGroup, name: e.target.value })}
                                            /> */}
                                            <select
                                                className="form-select cust-input-sty font-14 font-regular"
                                                value={newGroup.role || catalogData.role}
                                                onChange={(e) => setNewGroup({ ...newGroup, role: e.target.value })}
                                            >
                                                <option value="">Select Role</option>
                                                {roles.map((role, index) => (
                                                    <option key={index} value={role.name}>{role.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center sub-border-bottom">
                                        <div className="sub-tab-content">
                                            <div className="code-review-page tabs nav border-0">
                                                <div className={`tab nav-link ${activeTab === 'user' ? 'active' : ''}`} onClick={() => setActiveTab('user')}>Users</div>
                                                <div className={`tab nav-link ${activeTab === 'catalog' ? 'active' : ''}`} onClick={() => setActiveTab('catalog')}>Catalog Details</div>
                                            </div>
                                        </div>
                                        {activeTab !== 'catalog' && (
                                            gridData.length>0 ? (
                                                <div className="d-flex gap-3">
                                                    {Tab !== "Add new"  && leftUsers.length > 0 && (
                                                        <button 
                                                        type="button" 
                                                        className={`btn cust-primary-btn font-14 font-medium w-auto px-3 ${leftUsers.length == 0 ? 'disabled' : ''}`} 
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#editRows" 
                                                        onClick={()=> { getFilteredLeftUsers();}} 
                                                        disabled={leftUsers.length == 0}
                                                    >
                                                        Add User
                                                    </button>
                                                    
                                                    )}
                                                    <button
                                                        type="button"
                                                        className="btn cust-secondary-btn"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#importUserModal"
                                                    >
                                                        <span className="text-nowrap">
                                                            <img src="images/import-icon_ETL.svg" alt="Import" className="me-2" /> Import
                                                        </span>
                                                    </button>
                                                </div>
                                            ) : null // Return null if state.type is "New"
                                        )}
                                    </div>
                                    <div className="tab-content" id="pills-tabContent">
                                     
                                        {activeTab === 'user' && (
                                            <div id="user" className="tab-pane fade show active code-review-tab tab-content">
                                                {state.type === "New" && gridData.length ==0 ? (
                                                    <div className="file-upload my-4">
                                                        <div className="upload-area w-auto">
                                                            <div className="my-auto text-center width-15">
                                                                <img src="images/group-icon.svg" alt="group-icon" className="icon-filter" />
                                                                <p className="font-13 font-medium color-black-text mt-2">To Add Users Please Click the Below Button</p>
                                                                <button type="button" className="btn cust-primary-btn font-14 font-medium w-auto px-3" data-bs-toggle="modal" data-bs-target="#editRows" disabled={leftUsers.length === 0} onClick={moveToRight}>
                                                                    Add User
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    
                                                    <div className="table-responsive mt-4">
                                                    <table className="table table-borderless rounded custom-grid custom-metadata-table">
                                                        <thead className="sticky-top-pos custom-tableHeader" style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
                                                            <tr>
                                                                <th>Username</th>
                                                                <th>Email</th>
                                                                <th className="text-center">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {gridData.map((user, index) => (
                                                                <tr key={index}>
                                                                    <td>{user.name}</td>
                                                                    <td>{user.email}</td>
                                                                    <td className="text-center">
                                                                        <div>
                                                                            <span className="cust-cursor-pointer"  onClick={() => deleteUser(index,user)}>
                                                                                <img src="images/delete-icon.svg" alt="Delete" />
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                )}
                                                {/* <p className="font-regular font-14 text-grey m-0">
                                                    # of Records: <span className="text-black font-medium">{rightUsers.length} out of {rightUsers.length}</span>
                                                </p> */}
                                            </div>
                                        )}
                                        
                                        
{activeTab === 'catalog' && (
    <div id="cat-details" className="code-review-tab tab-content mt-5">
        <div className="accordion" id="accordionExample">
            {catalogData && catalogData.resource && catalogData.resource.length > 0 ? (
                catalogData.resource.map((resource, resourceIndex) =>
                    Object.keys(resource).map(catalogName => {
                        if (catalogName === "status") return null;
                        return (
                            <div key={catalogName} className="accordion-item border-0 mb-3 mx-4">
                                <div className="template-container p-0">
                                    <div className="heading-container">
                                        <h4 className="font-16 font-bold black">{catalogName}</h4>
                                    </div>
                                    <div className="accordion-header d-flex align-items-center my-4 ps-3">
                                        <button
                                            className="cust-acc-btn-1 d-flex align-items-center gap-2 font-14 btn border-0 shadow-none flex-column"
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${resourceIndex}-${catalogName}`} aria-expanded="false"
                                            aria-controls={`collapse-${resourceIndex}-${catalogName}`}
                                        >
                                        </button>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input custom-purple-checkbox me-2"
                                                type="checkbox"
                                                checked={resource.status === "selected"} 
                                                onChange={() => handleCatalogSelect(resourceIndex, catalogName)}
                                                id={`flexCheck-${catalogName}`}
                                            />
                                            <label className="form-check-label font-14 font-regular black" htmlFor={`flexCheck-${catalogName}`}>
                                                {catalogName}
                                            </label>
                                        </div>
                                    </div>
                                    <div id={`collapse-${resourceIndex}-${catalogName}`} className="accordion-collapse collapse ps-3 pb-4" data-bs-parent="#accordionExample">
                                        <div className="accordion-body p-0 custom-accordion-border position-relative">
                                            {Array.isArray(resource[catalogName]) && resource[catalogName].length > 0 ? (
                                                resource[catalogName].map((schema, schemaIndex) =>
                                                    Object.keys(schema).map(schemaName => {
                                                        if (schemaName === "status") return null;
                                                        return (
                                                            <div key={schemaName} className="accordion-item border-0">
                                                                <div className="accordion-header d-flex align-items-center my-4 ps-2">
                                                                    <button
                                                                        className="cust-acc-btn-1 d-flex align-items-center gap-2 font-14 btn border-0 shadow-none flex-column"
                                                                        type="button"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={`#collapse-${resourceIndex}-${catalogName}-${schemaIndex}-${schemaName}`} aria-expanded="false"
                                                                        aria-controls={`collapse-${resourceIndex}-${catalogName}-${schemaIndex}-${schemaName}`}
                                                                    >
                                                                    </button>
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input custom-purple-checkbox me-2"
                                                                            type="checkbox"
                                                                            checked={schema.status === "selected"}
                                                                            onChange={() => handleSchemaSelect(resourceIndex, catalogName, schemaIndex, schemaName)}
                                                                            id={`flexCheck-${resourceIndex}-${catalogName}-${schemaIndex}`}
                                                                        />
                                                                        <label className="form-check-label font-14 font-regular black" htmlFor={`flexCheck-${resourceIndex}-${catalogName}-${schemaIndex}`}>
                                                                            {schemaName}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div id={`collapse-${resourceIndex}-${catalogName}-${schemaIndex}-${schemaName}`} className="accordion-collapse collapse pb-4 ps-4">
                                                                    <div className="accordion-body p-0 custom-accordion-border position-relative">
                                                                        {Array.isArray(schema[schemaName]) && schema[schemaName].length > 0 ? (
                                                                            schema[schemaName].map((table, tableIndex) => {
                                                                                const tableName = Object.keys(table)[0];
                                                                                return (
                                                                                    <h2 key={tableName} className="font-14 font-regular black mb-0 custom-acc-heading">
                                                                                        <span>
                                                                                            <input className="form-check-input custom-purple-checkbox mt-0 me-2" type="checkbox"
                                                                                                checked={table[tableName] === "selected"}
                                                                                                onChange={() => handleTableSelect(resourceIndex, catalogName, schemaIndex, schemaName, tableIndex, tableName)}
                                                                                                id={`check-${resourceIndex}-${schemaIndex}-${catalogName}-${tableName}`}
                                                                                            />
                                                                                        </span> {tableName}
                                                                                    </h2>
                                                                                );
                                                                            })
                                                                        ) : <p>No tables available</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                )
                                            ) : <p>No schemas available</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                )
            ) : <p>No catalog data available</p>}
        </div>
    </div>
)}
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center mt-4 gap-3">
                                        <button type="button" className="btn cust-secondary-btn" onClick={() => navigate(-1)}>
                                            Cancel
                                        </button>
                                        <button type="button" className="btn cust-primary-btn font-14 font-medium w-auto px-3" onClick={handleAddGroup} disabled={!isButtonEnabled()}>
                                            Add Group
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* User selection modal */}
            <div className="modal fade" id="editRows" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="ConfrmLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header border-0 px-4">
                            <h5 className="modal-title d-flex align-items-center font-bold font-20" id="ConfrmLabel">
                                Add Users to Group
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body py-0 px-4">
                            <div className="row">
                                <div className="popup-container mx-2">
                                    {/* ... search input and available user list ... */}
                                    {
                                        leftUsers.map((user, index) => (
                                            <div
                                                key={index}
                                                className="d-flex align-items-center py-4 custom-gray-bottom gap-3"
                                                onDoubleClick={() => moveUserToRight(user)}
                                            >
                                                <input
                                                    className="form-check-input custom-purple-checkbox mt-0 me-2"
                                                    type="checkbox"
                                                    id={`userCheck-${index}`}
                                                    checked={selectedLeftUsers.includes(user)}
                                                    onChange={() => toggleLeftSelection(user)}
                                                />
                                                <div>
                                                    <h6 className="font-14 font-regular black mb-1">{user.Username}</h6>
                                                    <p className="font-12 font-regular grey-color m-0">{user.Email}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="center-container-width d-flex flex-column align-items-center justify-content-center gap-3">
                                    <button className="icon-btn" onClick={moveToRight}>
                                        <img src="images/right-arrow.svg" alt="right" />
                                    </button>
                                    <button className="icon-btn" onClick={moveAllToLeft}>
                                        <img src="images/left-arrow.svg" alt="left" />
                                    </button>
                                </div>

                                <div className="popup-container py-0 pe-4 mx-2">
                                    {/* ... selected users list ... */}
                                    {rightUsers.map((user, index) => (
                                        <div key={index} className="d-flex align-items-center justify-content-between py-4 custom-gray-bottom">
                                            <div>
                                                <h6 className="font-14 font-regular black mb-1">{user.Username}</h6>
                                                <p className="font-12 font-regular grey-color m-0">{user.Email}</p>
                                            </div>
                                            <img
                                                src="images/close-icon.svg"
                                                alt="close"
                                                className="curser-pointer"
                                                onClick={() => moveOneToLeft(user)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4 mt-4">
                            <button type="button" className="btn cust-secondary-btn font-14 font-medium me-2" data-bs-dismiss="modal">
                                Cancel
                            </button>

                            <button
                                type="button"
                                className="btn cust-primary-btn font-14 font-medium"
                                data-bs-dismiss="modal"
                                onClick={handleAddUsers}
                                disabled={rightUsers.length === 0} // Disable if no users on the right side
                            >
                                <span>Add</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Groups;



// -=-=-=-=-=-
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text3dot {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70px !important;
}

.org-logo {
  width: 30px !important;
  height: 30px;
}


.hyperlink {
  color: #0d6efd !important;
  text-decoration: underline !important;
}

/* NEWWWWWW UC Migration projectinfo */
.input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 4px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.multi-select-bubble {
  display: inline-flex;
  align-items: center;
  margin: 2px;
  padding: 2px 8px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 15px;
}

.input-field {
  flex-grow: 1;
  min-width: 150px;
  margin: 2px;
  outline: none;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;;AAGA;EACE,yBAAyB;EACzB,qCAAqC;AACvC;;AAEA,sCAAsC;AACtC;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,YAAY;AACd","sourcesContent":[".text3dot {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: 70px !important;\n}\n\n.org-logo {\n  width: 30px !important;\n  height: 30px;\n}\n\n\n.hyperlink {\n  color: #0d6efd !important;\n  text-decoration: underline !important;\n}\n\n/* NEWWWWWW UC Migration projectinfo */\n.input-container {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  padding: 4px;\n  border: 1px solid #ced4da;\n  border-radius: 4px;\n}\n\n.multi-select-bubble {\n  display: inline-flex;\n  align-items: center;\n  margin: 2px;\n  padding: 2px 8px;\n  background-color: #f8f9fa;\n  border: 1px solid #ced4da;\n  border-radius: 15px;\n}\n\n.input-field {\n  flex-grow: 1;\n  min-width: 150px;\n  margin: 2px;\n  outline: none;\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
